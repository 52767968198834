/**
 * groupControlStore
 */

import Vue from 'vue';
import * as types from './mutation-types';
import * as urls from '../config/urls';
import * as helpers from '../utils/helpers';

const UPDATE_OPTIONS = 'UPDATE_OPTIONS';

export default {
    namespaced: true,

    state: {
        // groupWorkingRoute
        groupWorkingRouteDetail: [],
        groupWorkingRouteLoading: false,
        // options
        groupControlOptions: {},
    },

    getters: {},

    mutations: {
        [types.UPDATE_LIST](state, { type, list }) {
            state[`${type}List`] = list;
        },
        [types.UPDATE_DETAIL](state, { type, detail }) {
            state[`${type}Detail`] = detail;
        },
        [types.UPDATE_PAGE_SIZE](state, { type, size }) {
            state[`${type}PageSize`] = size;
        },
        [types.UPDATE_CURRENT_PAGINATION](state, { type, pagination }) {
            state[`${type}Pagination`] = pagination;
        },
        [types.UPDATE_CURRENT_ID](state, { type, id }) {
            state[`${type}Id`] = id;
        },
        [types.UPDATE_FILTERS](state, { type, filters }) {
            state[`${type}Filters`] = filters;
        },
        [types.UPDATE_TYPE](state, { type, theType }) {
            state[`${type}Type`] = theType;
        },
        [types.UPDATE_LOADING_STATUS](state, { type, isLoading }) {
            state[`${type}Loading`] = isLoading;
        },
        [UPDATE_OPTIONS](state, options) {
            state.groupControlOptions = {
                ...state.groupControlOptions,
                ...options,
            };
        },
    },

    actions: {
        // groupWorkingRoute: 详情
        createGroupWorkingRoute({ commit }, formData = {}) {
            const type = 'groupWorkingRoute';

            const data = helpers.pick(formData, [
                'block',
                'alignEdgeIndex',
                'home',
                'droneCount',
                'width',
            ]);

            return new Promise((resolve, reject) => {
                commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                Vue.http.put(urls.CREATE_GROUP_WORKING_ROUTE, data).then((response) => {
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    const detail = response.data || [];
                    commit(types.UPDATE_DETAIL, { type, detail });
                    resolve(response);
                }).catch((error) => {
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    reject(error);
                });
            });
        },

        // groupDroneStatus
        updateGroupDroneStatus(store, options) {
            // const type = 'groupDroneStatus';

            const { droneId } = options;
            const data = helpers.pick(options, [
                'droneStatus',
                'plan',
            ]);

            return new Promise((resolve, reject) => {
                const url = helpers.buildURL(urls.UPDATE_GROUP_DONE_STATUS, droneId);
                Vue.http.patch(url, data).then((response) => {
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        // 保存控制选项
        saveGroupControlOptions({ commit }, options = {}) {
            const data = helpers.pick(options, [
                'droneIds',
                'width',
                'height',
                'speed',
            ]);

            commit(UPDATE_OPTIONS, data);
        },

        // end
    },
};
