<template>
    <el-menu v-bind="$attrs" v-on="$listeners">
        <el-menu-item index="/workbench" v-if="showWorkbench && showMenu('Workbench')">
            <i class="iconfont icon-workbench"></i>
            <span slot="title">{{ $t('menu.overview') }}</span>
        </el-menu-item>
        <el-menu-item index="/monitor_manage/monitor" v-if="showMenu('MonitorView')">
            <i class="iconfont icon-monitor"></i>
            <span slot="title">{{ $t('menu.monitor') }}</span>
        </el-menu-item>
        <el-menu-item index="/data_stat_manage/data-stat-list" v-if="showMenu('DataStatList')">
            <i class="iconfont icon-stat"></i>
            <span slot="title">{{ $t('menu.data_stat') }}</span>
        </el-menu-item>
        <el-menu-item index="/business_manage/no-fly-zone-list" v-if="showMenu('NoFlyZoneList')">
            <i class="iconfont icon-no-entry"></i>
            <span slot="title">{{ $t('menu.no_fly_zone') }}</span>
        </el-menu-item>
        <el-menu-item index="/business_manage/drone-list" v-if="showMenu('DroneList')">
            <i class="iconfont icon-device"></i>
            <span slot="title">{{ $t('menu.drone_management') }}</span>
        </el-menu-item>
        <el-menu-item index="/business_manage/unified-task-list" v-if="showMenu('UnifiedTaskList')">
            <i class="iconfont icon-working"></i>
            <span slot="title">{{ $t('menu.unified_task') }}</span>
        </el-menu-item>
        <el-menu-item index="/business_manage/subsidy-list" v-if="showMenu('SubsidyList')">
            <i class="iconfont icon-ticket"></i>
            <span slot="title">农机补贴</span>
        </el-menu-item>
        <el-submenu index="/institution_manage" v-if="showMenuGroup(['AccountDetail', 'MemberList', 'SystemLogList'])">
            <template slot="title">
                <i class="iconfont icon-enterprise"></i>
                <span>{{ $t('menu.organization') }}</span>
            </template>
            <el-menu-item index="/institution_manage/account-detail" v-if="showMenu('AccountDetail')">{{ $tc('menu.information', 0) }}</el-menu-item>
            <el-menu-item index="/institution_manage/member-list" v-if="showMenu('MemberList')">{{ $t('menu.members') }}</el-menu-item>
            <el-menu-item index="/institution_manage/system-log-list" v-if="showMenu('SystemLogList')">{{ $t('menu.system_log') }}</el-menu-item>
        </el-submenu>
        <el-submenu index="/bulletin_manage" v-if="showMenuGroup(['BulletinList', 'BulletinCreate'])">
            <template slot="title">
                <i class="iconfont icon-notice"></i>
                <span>{{ $t('menu.announcement_management') }}</span>
            </template>
            <el-menu-item index="/bulletin_manage/bulletin-list" v-if="showMenu('BulletinList')">{{ $t('menu.announcement') }}</el-menu-item>
            <el-menu-item index="/bulletin_manage/bulletin-create" v-if="showMenu('BulletinCreate')">{{ $t('menu.send_announcement') }}</el-menu-item>
        </el-submenu>
        <el-menu-item @click="onShowSettings">
            <i class="iconfont icon-settings"></i>
            <span slot="title">{{ $t('settings.label') }}</span>
        </el-menu-item>
        <el-menu-item @click="handleLogout" v-if="showLogout">
            <i class="iconfont icon-pwoer-off"></i>
            <span slot="title">{{ $t('menu.sign_out') }}</span>
        </el-menu-item>
    </el-menu>
</template>

<script>
    import { mapState, mapGetters } from 'vuex';
    import logoutMixin from '../../mixins/logoutMixin';
    import settingMixin from '../../mixins/settingMixin';

    export default {
        name: 'AccountMenu2',

        mixins: [logoutMixin, settingMixin],

        props: {
            showLogout: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            ...mapGetters('user', ['showMenu', 'showMenuGroup']),
            ...mapState('theme', ['showWorkbench']),
        },
    };
</script>

<style lang="scss" scoped>
</style>
