/**
 * 菜单
 */

export default {
    computed: {
        activeItem() {
            const segments = this.$route.path.split('/').filter(segment => !!segment);
            const segmentLast = segments[segments.length - 1];
            const segmentPenultimate = segments[segments.length - 2];
            if (!segmentLast) {
                return '';
            }
            // 倒数第二段含数字，则去掉最后两段
            if (/\d/.test(segmentPenultimate) || segmentPenultimate === '~') {
                segments.pop();
                segments.pop();
            }
            segments.unshift('');
            return segments.join('/');
        },

        openedMenu() {
            const { meta: { group } } = this.$route;
            return group ? `/${group}` : '';
        },
    },
};
