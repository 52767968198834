/**
 * mutation types
 */

// 更新loading状态
export const UPDATE_LOADING_STATUS = 'UPDATE_LOADING_STATUS';
// 更新分页Size
export const UPDATE_PAGE_SIZE = 'UPDATE_PAGE_SIZE';
// 更新当前页码
export const UPDATE_CURRENT_PAGINATION = 'UPDATE_CURRENT_PAGINATION';
// 更新当前ID
export const UPDATE_CURRENT_ID = 'UPDATE_CURRENT_ID';
// 更新过滤条件
export const UPDATE_FILTERS = 'UPDATE_FILTERS';
// 更新列表
export const UPDATE_LIST = 'UPDATE_LIST';
// 更新类型
export const UPDATE_TYPE = 'UPDATE_TYPE';
// 更新详情
export const UPDATE_DETAIL = 'UPDATE_DETAIL';
// 更新组
export const UPDATE_GROUP = 'UPDATE_GROUP';
// 更新主体
export const UPDATE_SUBJECT = 'UPDATE_SUBJECT';

// 更新地图配置项
export const UPDATE_MAP_OPTIONS = 'UPDATE_MAP_OPTIONS';
// 更新地图显示设置
export const UPDATE_MAP_SETTINGS = 'UPDATE_MAP_SETTINGS';
// 更新监控参数
export const UPDATE_MONITOR_QUERIES = 'UPDATE_MONITOR_QUERIES';

// 更新用户数据
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';
// 更新系统日志类型
export const UPDATE_SYSTEM_LOG_TYPE = 'UPDATE_SYSTEM_LOG_TYPE';

export default null;
