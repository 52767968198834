/**
 * 全局自定义指令
 */
import Vue from 'vue';
import OverlayScrollbars from 'overlayscrollbars';

// 通用滚动条
Vue.directive('horizontal-scroll-bar', {
    bind(el) {
        OverlayScrollbars(el, {
            sizeAutoCapable: false,
            className: 'os-theme-light',
            overflowBehavior: {
                x: 'scroll',
                y: 'hidden',
            },
            scrollbars: {
                autoHide: 'leave',
                autoHideDelay: 300,
            },
        });
    },
});
