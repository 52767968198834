/**
 * appLog
 */

import Vue from 'vue';
import * as types from './mutation-types';
import * as urls from '../config/urls';
// import * as helpers from '../utils/helpers';

export default {
    namespaced: true,

    state: {
        // appLog
        appLogList: {},
        appLogLoading: false,
        appLogPagination: 1,
        appLogPageSize: 20,
        appLogFilters: {
            type: 0,
            search: '',
        },
        appLogDetail: {},
        // appLogDrone
        // appLogDroneList: {},
        // appLogDroneLoading: false,
        // appLogDronePagination: 1,
        // appLogDronePageSize: 20,
        // appLogStatDrone
        // appLogStatDroneList: [],
        // appLogStatDroneLoading: false,
        // appLogStatOwner
        // appLogStatOwnerList: [],
        // appLogStatOwnerLoading: false,
        // appLogStatOverview
        // appLogStatOverviewDetail: {},
        // appLogStatData
        // appLogStatDataList: {},
        // appLogStatDataLoading: false,
        // appLogDroneSortie
        // appLogDroneSortieList: [],
        // appLogDroneSortieLoading: false,
    },

    mutations: {
        [types.UPDATE_LIST](state, { type, list }) {
            state[`${type}List`] = list;
        },
        [types.UPDATE_DETAIL](state, { type, detail }) {
            state[`${type}Detail`] = detail;
        },
        [types.UPDATE_PAGE_SIZE](state, { type, size }) {
            state[`${type}PageSize`] = size;
        },
        [types.UPDATE_CURRENT_PAGINATION](state, { type, pagination }) {
            state[`${type}Pagination`] = pagination;
        },
        [types.UPDATE_FILTERS](state, { type, filters }) {
            state[`${type}Filters`] = filters;
        },
        [types.UPDATE_LOADING_STATUS](state, { type, isLoading }) {
            state[`${type}Loading`] = isLoading;
        },
    },

    actions: {
        // appLog: 获取地面站日志列表
        getAppLogList({ commit, state }, pagination = 1) {
            const type = 'appLog';

            return new Promise((resolve, reject) => {
                commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                Vue.http.get(urls.GET_APP_LOG_LIST, {
                    params: {
                        pageIndex: pagination,
                        size: state[`${type}PageSize`],
                        ...state[`${type}Filters`],
                    },
                }).then((response) => {
                    commit(types.UPDATE_LIST, { type, list: response.data });
                    commit(types.UPDATE_CURRENT_PAGINATION, { type, pagination });
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    resolve(response);
                }).catch((error) => {
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    reject(error);
                });
            });
        },

        // appLog: 更变地面站日志列表分页Size
        changeAppLogListPageSize({ commit, dispatch, state }, size) {
            const type = 'appLog';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            commit(types.UPDATE_PAGE_SIZE, { type, size });
            dispatch(`get${Type}List`, state[`${type}Pagination`]);
        },

        // appLog: 更新地面站日志列表过滤条件
        changeAppLogListFilters({ commit, dispatch, state }, filters) {
            const type = 'appLog';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            commit(types.UPDATE_FILTERS, { type, filters });
            dispatch(`get${Type}List`, state[`${type}Pagination`]);
        },

        // appLog: 创建统防统治任务
        // createAppLog({ dispatch, state }, formData) {
        //     const type = 'appLog';
        //     const Type = type.replace(/^\w/, _ => _.toUpperCase());
        //
        //     const data = helpers.pick(formData, ['tftzName', 'region', 'startDate', 'endDate', 'tftzType', 'workType', 'cropId', 'zfMonitor']);
        //
        //     return new Promise((resolve, reject) => {
        //         Vue.http.post(urls.CREATE_UNIFIED_TASK, data).then((response) => {
        //             dispatch(`get${Type}List`, state[`${type}Pagination`]);
        //             resolve(response);
        //         }).catch((error) => {
        //             reject(error);
        //         });
        //     });
        // },

        // appLog: 更新统防统治任务
        // updateAppLog({ dispatch, state }, { tftzId, ...formData }) {
        //     const type = 'appLog';
        //     const Type = type.replace(/^\w/, _ => _.toUpperCase());
        //
        //     const data = helpers.pick(formData, ['tftzName', 'region', 'startDate', 'endDate', 'tftzType', 'workType', 'cropId', 'zfMonitor']);
        //
        //     return new Promise((resolve, reject) => {
        //         const url = helpers.buildURL(urls.UPDATE_UNIFIED_TASK, tftzId);
        //         Vue.http.patch(url, data).then((response) => {
        //             dispatch(`get${Type}List`, state[`${type}Pagination`]);
        //             resolve(response);
        //         }).catch((error) => {
        //             reject(error);
        //         });
        //     });
        // },

        // appLogDrone: 获取统防统治农机列表
        // getAppLogDroneList({ commit, state }, { id, pagination = 1 }) {
        //     const type = 'appLogDrone';
        //
        //     return new Promise((resolve, reject) => {
        //         commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
        //         const url = helpers.buildURL(urls.GET_UNIFIED_TASK_DRONE_LIST, id);
        //         Vue.http.get(url, {
        //             params: {
        //                 pageIndex: pagination,
        //                 size: state[`${type}PageSize`],
        //                 ...state[`${type}Filters`],
        //             },
        //         }).then((response) => {
        //             commit(types.UPDATE_LIST, { type, list: response.data || [] });
        //             commit(types.UPDATE_CURRENT_PAGINATION, { type, pagination });
        //             commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
        //             resolve(response);
        //         }).catch((error) => {
        //             commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
        //             reject(error);
        //         });
        //     });
        // },

        // appLog: 更变地面站日志列表分页Size
        // changeAppLogListPageSize({ commit, dispatch, state }, { id, size }) {
        //     const type = 'appLog';
        //     const Type = type.replace(/^\w/, _ => _.toUpperCase());
        //
        //     commit(types.UPDATE_PAGE_SIZE, { type, size });
        //     dispatch(`get${Type}List`, { id, pagination: state[`${type}Pagination`] });
        // },

        // appLogDrone: 创建统防统治农机
        // createAppLogDrone({ dispatch, state }, { id, ...formData }) {
        //     const type = 'appLogDrone';
        //     const Type = type.replace(/^\w/, _ => _.toUpperCase());
        //
        //     const data = helpers.pick(formData, ['droneIds', 'dataStartDate', 'dataEndDate']);
        //
        //     return new Promise((resolve, reject) => {
        //         const url = helpers.buildURL(urls.CREATE_UNIFIED_TASK_DRONE, id);
        //         Vue.http.post(url, data).then((response) => {
        //             dispatch(`get${Type}List`, { id, pagination: state[`${type}Pagination`] });
        //             resolve(response);
        //         }).catch((error) => {
        //             reject(error);
        //         });
        //     });
        // },

        // // appLogDrone: 更新统防统治农机数据开放日期
        // updateappLogDroneDate({ dispatch, state }, { tftzId, droneId, ...formData }) {
        //     const type = 'appLogDrone';
        //     const Type = type.replace(/^\w/, _ => _.toUpperCase());
        //
        //     const data = helpers.pick(formData, ['dataStartDate', 'dataEndDate']);
        //
        //     return new Promise((resolve, reject) => {
        //         const url = helpers.buildURL(urls.UPDATE_UNIFIED_TASK_DRONE_DATE, tftzId, droneId);
        //         Vue.http.put(url, data).then((response) => {
        //             dispatch(`get${Type}List`, { id: tftzId, pagination: state[`${type}Pagination`] });
        //             resolve(response);
        //         }).catch((error) => {
        //             reject(error);
        //         });
        //     });
        // },

        // appLogDrone: 删除统防统治农机
        // deleteappLogDrone({ dispatch, state }, { tftzId, droneId }) {
        //     const type = 'appLogDrone';
        //     const Type = type.replace(/^\w/, _ => _.toUpperCase());
        //
        //     return new Promise((resolve, reject) => {
        //         const url = helpers.buildURL(urls.DELETE_UNIFIED_TASK_DRONE, tftzId, droneId);
        //         Vue.http.delete(url).then((response) => {
        //             dispatch(`get${Type}List`, { id: tftzId, pagination: state[`${type}Pagination`] });
        //             resolve(response);
        //         }).catch((error) => {
        //             reject(error);
        //         });
        //     });
        // },

        // // appLogStatDrone: 获取统防统治农机列表
        // getappLogStatDroneList({ commit }, { id }) {
        //     const type = 'appLogStatDrone';
        //
        //     return new Promise((resolve, reject) => {
        //         commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
        //         const url = helpers.buildURL(urls.GET_UNIFIED_TASK_STAT_DRONES, id);
        //         Vue.http.get(url).then((response) => {
        //             commit(types.UPDATE_LIST, { type, list: response.data || [] });
        //             commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
        //             resolve(response);
        //         }).catch((error) => {
        //             commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
        //             reject(error);
        //         });
        //     });
        // },

        // appLogStatOwner: 获取统防统治归属人列表
        // getappLogStatOwnerList({ commit }, { id }) {
        //     const type = 'appLogStatOwner';
        //
        //     return new Promise((resolve, reject) => {
        //         commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
        //         const url = helpers.buildURL(urls.GET_UNIFIED_TASK_STAT_OWNERS, id);
        //         Vue.http.get(url).then((response) => {
        //             commit(types.UPDATE_LIST, { type, list: response.data || [] });
        //             commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
        //             resolve(response);
        //         }).catch((error) => {
        //             commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
        //             reject(error);
        //         });
        //     });
        // },

        // appLogStatData: 获取统防统治植图表数据
        // getappLogStatDataList({ commit }, { id, droneIds, yyAccountIds, showItem, startTime, endTime }) {
        //     const type = 'appLogStatData';
        //
        //     return new Promise((resolve, reject) => {
        //         commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
        //         const url = helpers.buildURL(urls.GET_UNIFIED_TASK_STAT_DATA, id);
        //         Vue.http.get(url, {
        //             params: { droneIds, yyAccountIds, showItem, startTime, endTime },
        //         }).then((response) => {
        //             commit(types.UPDATE_LIST, { type, list: response.data || {} });
        //             commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
        //             resolve(response);
        //         }).catch((error) => {
        //             commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
        //             reject(error);
        //         });
        //     });
        // },

        // appLogStatOverview: 获取统防统治植统计概览
        // getappLogStatOverview({ commit }, { id, droneIds, yyAccountIds, startTime, endTime, queryType = 2 }) {
        //     const type = 'appLogStatOverview';
        //
        //     return new Promise((resolve, reject) => {
        //         const url = helpers.buildURL(urls.GET_UNIFIED_TASK_STAT_OVERVIEW, id);
        //         Vue.http.get(url, {
        //             params: { droneIds, yyAccountIds, startTime, endTime, queryType },
        //         }).then((response) => {
        //             commit(types.UPDATE_DETAIL, { type, detail: response.data });
        //             resolve(response);
        //         }).catch((error) => {
        //             reject(error);
        //         });
        //     });
        // },

        // appLogDroneSortie: 获取列表
        // getappLogDroneSortieList({ commit }, { tftzId }) {
        //     const type = 'appLogDroneSortie';
        //
        //     return new Promise((resolve, reject) => {
        //         commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
        //         const url = helpers.buildURL(urls.GET_UNIFIED_TASK_SORTIE_LIST, tftzId);
        //         Vue.http.get(url).then((response) => {
        //             const list = response.data || [];
        //             commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
        //             commit(types.UPDATE_LIST, { type, list });
        //             resolve(response);
        //         }).catch((error) => {
        //             commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
        //             reject(error);
        //         });
        //     });
        // },

        // end
    },
};
