<template>
    <component :is="screen"></component>
</template>

<script>
    import StatScreen from './StatScreen.vue';
    import SmallScreen from '../SmallScreen/SmallScreen.vue';

    export default {
        name: 'ScreenIndex',

        components: {
            StatScreen,
            SmallScreen,
        },

        computed: {
            screen() {
                if (window.localStorage.getItem('BigScreen') === 'true') {
                    return 'StatScreen';
                }
                return 'SmallScreen';
            },
        },
    };
</script>

<style scoped>

</style>
