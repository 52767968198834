<template>
    <el-dialog :title="$t('settings.label')" :visible.sync="show" custom-class="ui-main-dialog" width="480px">
        <el-form>
            <el-form-item :label="$t('settings.lang')">
                <Localization></Localization>
            </el-form-item>
            <el-form-item :label="$t('settings.area_unit.label')">
                <el-radio-group v-model="areaUnit" size="small">
                    <el-radio-button label="mu">{{ $t('settings.area_unit.mu') }}</el-radio-button>
                    <el-radio-button label="acre">{{ $t('settings.area_unit.acre') }}</el-radio-button>
                    <el-radio-button label="hectare">{{ $t('settings.area_unit.hectare') }}</el-radio-button>
                    <el-radio-button label="rai">{{ $t('settings.area_unit.rai') }}</el-radio-button>
                </el-radio-group>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>

<script>
    import { mapActions, mapState } from 'vuex';
    import eventBus from '../utils/eventBus';
    import Localization from '../components/Localization.vue';

    export default {
        name: 'GlobalSetting',

        components: { Localization },

        data() {
            return {
                show: false,

                formData: {
                    area_unit: '',
                },
            };
        },

        computed: {
            ...mapState('setting', ['area_unit']),

            areaUnit: {
                get() {
                    return this.area_unit;
                },
                set(val) {
                    this.formData.area_unit = val;
                    this.setAreaUnit(val);
                },
            },
        },

        mounted() {
            eventBus.$on('show-global-setting', () => {
                this.show = true;
                this.formData.area_unit = this.area_unit;
            });
        },

        destroyed() {
            eventBus.$off('show-global-setting');
        },

        methods: {
            ...mapActions('setting', ['setAreaUnit']),
        },
    };
</script>
