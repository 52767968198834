import Vue from 'vue';
import locale from 'element-ui/lib/locale';
import enLocale from 'element-ui/lib/locale/lang/en';
import zhLocale from 'element-ui/lib/locale/lang/zh-CN';
import thLocale from 'element-ui/lib/locale/lang/th';
import koLocale from 'element-ui/lib/locale/lang/ko';
import zhTWLocale from 'element-ui/lib/locale/lang/zh-TW';

Vue.prototype.locale = locale;
Vue.prototype.$lang = {
    en: enLocale,
    zh: zhLocale,
    th: thLocale,
    ko: koLocale,
    'zh-TW': zhTWLocale,
};
