<template>
    <component
        :is="currentAccountMenu"
        mode="horizontal"
        :default-active="activeItem"
        background-color="transparent"
        text-color="#555555"
        active-text-color="#ffffff"
        router>
    </component>
</template>

<script>
    import { mapGetters } from 'vuex';
    import AccountMenu1 from './AccountMenu1.vue';
    import AccountMenu2 from './AccountMenu2.vue';
    import AccountMenu3 from './AccountMenu3.vue';
    import AccountMenu4 from './AccountMenu4.vue';
    import menuMixin from '../../mixins/menuMixin';

    export default {
        name: 'NavMenu',

        components: { AccountMenu1, AccountMenu2, AccountMenu3, AccountMenu4 },

        mixins: [menuMixin],

        computed: {
            ...mapGetters('user', ['getUserInfo']),

            currentAccountMenu() {
                const accountType = this.getUserInfo('accountType');
                return accountType ? `AccountMenu${accountType}` : '';
            },
        },
    };
</script>

<style lang="scss" scoped>
</style>
