/**
 * partWhiteListStore
 */

import Vue from 'vue';
import * as types from './mutation-types';
import * as urls from '../config/urls';
// import * as helpers from '../utils/helpers';

export default {
    namespaced: true,

    state: {
        // partWhiteList
        partWhiteList: [],
        partWhiteListLoading: false,
    },

    getters: {},

    mutations: {
        [types.UPDATE_LIST](state, { type, list }) {
            state[`${type}`] = list;
        },
        [types.UPDATE_LOADING_STATUS](state, { type, isLoading }) {
            state[`${type}Loading`] = isLoading;
        },
    },

    actions: {
        // noFlyZone列表
        getPartsWhiteList({ commit }, { currentPage, pageSize, type, hwId } = {}) {
            return new Promise((resolve, reject) => {
                commit(types.UPDATE_LOADING_STATUS, { type: 'partWhiteList', isLoading: true });
                Vue.http.get(urls.GET_PARTS_WHITE_LIST, {
                    params: {
                        currentPage,
                        pageSize,
                        type,
                        hwId,
                    },
                }).then((response) => {
                    commit(types.UPDATE_LIST, { type: 'partWhiteList', list: response?.data?.records });
                    commit(types.UPDATE_LOADING_STATUS, { type: 'partWhiteList', isLoading: false });
                    resolve(response);
                }).catch((error) => {
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    reject(error);
                });
            });
        },
        uploadPartsWhiteList(_, { type, file } = {}) {
            const formData = new FormData();
            formData.append('type', type);
            formData.append('file', file);

            return new Promise((resolve, reject) => {
                Vue.http.post(urls.UPLOAD_PARTS_WHITE_LIST, formData, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                }).then((response) => {
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },
        // end
    },
};
