/**
 * “我的”管理（成员、系统日志等）
 */

const SystemLogList = () => import(/* webpackChunkName: "chunk!system-log-list" */'../views/OwnManage/SystemLog/SystemLogList.vue');
const AccountDetail = () => import(/* webpackChunkName: "chunk!own-account-detail" */'../views/OwnManage/Account/AccountDetail.vue');
const MemberList = () => import(/* webpackChunkName: "chunk!member-list" */'../views/OwnManage/Member/MemberList.vue');
const OrderList = () => import(/* webpackChunkName: "chunk!order-list" */'../views/OwnManage/Order/OrderList.vue');
const InvoiceList = () => import(/* webpackChunkName: "chunk!invoice-list" */'../views/OwnManage/Invoice/InvoiceList.vue');

const group = 'institution_manage';

export default [
    {
        path: `/${group}/account-detail`,
        name: 'AccountDetail',
        component: AccountDetail,
        meta: { group },
    },
    {
        path: `/${group}/system-log-list`,
        name: 'SystemLogList',
        component: SystemLogList,
        meta: { group },
    },
    {
        path: `/${group}/member-list`,
        name: 'MemberList',
        component: MemberList,
        meta: { group },
    },
    {
        path: `/${group}/order-list`,
        name: 'OrderList',
        component: OrderList,
        meta: { group },
    },
    {
        path: `/${group}/invoice-list`,
        name: 'InvoiceList',
        component: InvoiceList,
        meta: { group },
    },
];
