/**
 * 全局loading效果mixin
 * 主要用于组件本身的loading效果
 */
import Vue from 'vue';

Vue.mixin({
    data() {
        return {
            isLoading: false,
        };
    },

    methods: {
        startLoading() {
            this.isLoading = true;
        },

        stopLoading() {
            this.isLoading = false;
        },
    },
});
