/**
 * teamStore
 */

import Vue from 'vue';
import * as types from './mutation-types';
import * as urls from '../config/urls';
import * as helpers from '../utils/helpers';

export default {
    namespaced: true,

    state: {
        // team
        teamId: '',
        teamList: {},
        teamLoading: false,
        teamPagination: 1,
        teamPageSize: 20,
        teamFilters: {},
        teamDetail: {},
        // teamMember
        teamMemberList: {},
        teamMemberLoading: false,
        teamMemberPagination: 1,
        teamMemberPageSize: 20,
        teamMemberFilters: {},
        teamMemberDetail: {},
        // teamSelectableMember
        teamSelectableMemberList: [],
        teamSelectableMemberLoading: false,
    },

    getters: {},

    mutations: {
        [types.UPDATE_LIST](state, { type, list }) {
            state[`${type}List`] = list;
        },
        [types.UPDATE_DETAIL](state, { type, detail }) {
            state[`${type}Detail`] = detail;
        },
        [types.UPDATE_PAGE_SIZE](state, { type, size }) {
            state[`${type}PageSize`] = size;
        },
        [types.UPDATE_CURRENT_PAGINATION](state, { type, pagination }) {
            state[`${type}Pagination`] = pagination;
        },
        [types.UPDATE_CURRENT_ID](state, { type, id }) {
            state[`${type}Id`] = id;
        },
        [types.UPDATE_FILTERS](state, { type, filters }) {
            state[`${type}Filters`] = filters;
        },
        [types.UPDATE_LOADING_STATUS](state, { type, isLoading }) {
            state[`${type}Loading`] = isLoading;
        },
    },

    actions: {
        // team：列表
        getTeamList({ commit, state }, pagination = 1) {
            const type = 'team';

            return new Promise((resolve, reject) => {
                commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                Vue.http.get(urls.GET_TEAM_LIST, {
                    params: {
                        pageIndex: pagination,
                        size: state[`${type}PageSize`],
                        ...state[`${type}Filters`],
                    },
                }).then((response) => {
                    commit(types.UPDATE_LIST, { type, list: response.data });
                    commit(types.UPDATE_CURRENT_PAGINATION, { type, pagination });
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    resolve(response);
                }).catch((error) => {
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    reject(error);
                });
            });
        },

        //  team：变更列表分页Size
        changeTeamListPageSize({ commit, dispatch, state }, size) {
            const type = 'team';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            commit(types.UPDATE_PAGE_SIZE, { type, size });
            dispatch(`get${Type}List`, state[`${type}Pagination`]);
        },

        //  team：更新列表过滤条件
        changeTeamListFilters({ commit, dispatch, state }, filters) {
            const type = 'team';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            const f = helpers.update(state[`${type}Filters`], filters);
            commit(types.UPDATE_FILTERS, { type, filters: f });
            dispatch(`get${Type}List`, state[`${type}Pagination`]);
        },

        // team：创建
        createTeam(store, formData) {
            return new Promise((resolve, reject) => {
                Vue.http.post(urls.CREATE_TEAM, formData).then((response) => {
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        // team：更新名称
        updateTeamName(store, { groupId, groupName }) {
            return new Promise((resolve, reject) => {
                Vue.http.put(urls.UPDATE_TEAM_NAME, { groupId, groupName }).then((response) => {
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        // team：删除
        deleteTeam(store, { groupId }) {
            return new Promise((resolve, reject) => {
                const url = helpers.buildURL(urls.DELETE_TEAM, groupId);
                Vue.http.delete(url).then((response) => {
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        // team: 改变当然id
        changeTeamId({ commit }, { groupId }) {
            const type = 'team';

            commit(types.UPDATE_CURRENT_ID, { type, id: groupId });
        },

        // teamMember：列表
        getTeamMemberList({ commit, state }, pagination = 1) {
            const type = 'teamMember';

            return new Promise((resolve, reject) => {
                commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                const url = helpers.buildURL(urls.GET_TEAM_MEMBER_LIST, state.teamId);
                Vue.http.get(url, {
                    params: {
                        pageIndex: pagination,
                        size: state[`${type}PageSize`],
                        ...state[`${type}Filters`],
                    },
                }).then((response) => {
                    commit(types.UPDATE_LIST, { type, list: response.data });
                    commit(types.UPDATE_CURRENT_PAGINATION, { type, pagination });
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    resolve(response);
                }).catch((error) => {
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    reject(error);
                });
            });
        },

        //  teamMember：变更列表分页Size
        changeTeamMemberListPageSize({ commit, dispatch, state }, size) {
            const type = 'teamMember';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            commit(types.UPDATE_PAGE_SIZE, { type, size });
            dispatch(`get${Type}List`, state[`${type}Pagination`]);
        },

        // teamMember：更改队长
        updateTeamLeader({ commit, dispatch, state }, { groupId, leaderUserId }) {
            const type = 'teamMember';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            return new Promise((resolve, reject) => {
                commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                Vue.http.put(urls.UPDATE_TEAM_LEADER, { groupId, leaderUserId }).then((response) => {
                    dispatch(`get${Type}List`, state[`${type}Pagination`]);
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    resolve(response);
                }).catch((error) => {
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    reject(error);
                });
            });
        },

        // teamMember：删除
        deleteTeamMember({ commit, dispatch, state }, { groupId, userId }) {
            const type = 'teamMember';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            return new Promise((resolve, reject) => {
                commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                const url = helpers.buildURL(urls.DELETE_TEAM_MEMBER, groupId, userId);
                Vue.http.delete(url).then((response) => {
                    dispatch(`get${Type}List`, state[`${type}Pagination`]);
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    resolve(response);
                }).catch((error) => {
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    reject(error);
                });
            });
        },

        // teamMember：清空列表
        clearTeamMemberList({ commit }) {
            const type = 'teamMember';

            commit(types.UPDATE_LIST, { type, list: {} });
            commit(types.UPDATE_CURRENT_PAGINATION, { type, pagination: 1 });
        },

        // teamMember：添加
        addTeamMember(store, { groupId, userIds }) {
            return new Promise((resolve, reject) => {
                Vue.http.post(urls.REQUEST_ADD_TEAM_MEMBER, { groupId, userIds }).then((response) => {
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        // teamSelectableMember：列表
        getTeamSelectableMemberList({ commit }, { groupId }) {
            const type = 'teamSelectableMember';

            return new Promise((resolve, reject) => {
                commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                const url = helpers.buildURL(urls.GET_TEAM_SELECTABLE_MEMBER, groupId);
                Vue.http.get(url).then((response) => {
                    const { list = [] } = response.data;
                    commit(types.UPDATE_LIST, { type, list });
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    resolve(response);
                }).catch((error) => {
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    reject(error);
                });
            });
        },

        // end
    },
};
