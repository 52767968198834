/**
 * 主题配置文件
 */

// 默认主题
export const DEFAULT = {
    loginLayout: 'Primary',
    mainLayout: 'Primary',
    dataStatInlineLayout: false,
    showWorkbench: false,
    showBlockData: true,
    showTableStripe: false,
    maxChartXRange: 20,
};

// 农机协会主题
export const CAMA = {
    loginLayout: 'Alternative',
    mainLayout: 'Alternative',
    dataStatInlineLayout: true,
    showWorkbench: true,
    showBlockData: false,
    showTableStripe: true,
    maxChartXRange: 20,
};
