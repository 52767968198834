/**
 * leaseDataStat
 */

import Vue from 'vue';
import * as types from './mutation-types';
import * as urls from '../config/urls';
// import * as helpers from "../utils/helpers";
// import * as helpers from '../utils/helpers';

export default {
    namespaced: true,

    state: {
        leaseData: {},
        leaseStatData: {},
        leaseAreaData: [],
        customerAreaRanking: [],
        customerAreaRankingLoading: false,

        customerDataStat: {},
        customerAreaAndRecord: [],
        customerDronePilotRanking: [],
        customerDronePilotRankingLoading: false,
    },

    // getters: {
    //     stat(state) {
    //         const { stat: { total = 0, workArea = 0, zzNum = 0, sortieCount = 0, modelNum = 0 } = {} } = state.leaseList;
    //         return { total, workArea, zzNum, sortieCount, modelNum };
    //     },
    //
    //     activeStatusLabel: state => (status) => {
    //         const { [status]: label } = state.ACTIVE_STATUS_MAP;
    //         return label || '-';
    //     },
    // },

    mutations: {
        [types.UPDATE_SUBJECT](state, { subject, data }) {
            state[subject] = data;
        },
        [types.UPDATE_LOADING_STATUS](state, { type, isLoading }) {
            state[`${type}Loading`] = isLoading;
        },
    },

    actions: {
        getLeaseData({ commit }) {
            const subject = 'leaseData';

            return new Promise((resolve, reject) => {
                // commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                Vue.http.get(urls.GET_RENTAL_STAT_DATA).then(({ data }) => {
                    console.log('data', data);
                    commit(types.UPDATE_SUBJECT, { subject, data: data || {} });
                    // commit(types.UPDATE_LIST, { type, list: data });
                    // commit(types.UPDATE_CURRENT_PAGINATION, { type, pagination });
                    // commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    resolve(data);
                }).catch((error) => {
                    // commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    reject(error);
                });
            });
        },

        getLeaseStatData({ commit }) {
            const subject = 'leaseStatData';
            return new Promise((resolve, reject) => {
                // commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                Vue.http.get(urls.GET_JOB_STAT_DATA).then(({ data }) => {
                    commit(types.UPDATE_SUBJECT, { subject, data: data || {} });
                    resolve(data);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        getLeaseAreaData({ commit }, { startTime, endTime } = {}) {
            const subject = 'leaseAreaData';

            return new Promise((resolve, reject) => {
                // commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                Vue.http.get(urls.GET_JOB_AREA_DATA, {
                    params: {
                        startTime,
                        endTime,
                    },
                }).then(({ data }) => {
                    commit(types.UPDATE_SUBJECT, { subject, data: data || [] });
                    resolve(data);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        getCustomerAreaRanking({ commit }) {
            const subject = 'customerAreaRanking';

            return new Promise((resolve, reject) => {
                commit(types.UPDATE_LOADING_STATUS, { type: subject, isLoading: true });
                Vue.http.get(urls.GET_CUSTOMER_AREA_RANKING).then(({ data }) => {
                    commit(types.UPDATE_SUBJECT, { subject, data: data || [] });
                    commit(types.UPDATE_LOADING_STATUS, { type: subject, isLoading: false });
                    resolve(data);
                }).catch((error) => {
                    commit(types.UPDATE_LOADING_STATUS, { type: subject, isLoading: false });
                    reject(error);
                });
            });
        },


        getCustomerDataStat({ commit }, { accountId } = {}) {
            const subject = 'customerDataStat';

            return new Promise((resolve, reject) => {
                // commit(types.UPDATE_LOADING_STATUS, { type: subject, isLoading: true });
                Vue.http.get(urls.GET_CUSTOMER_DATA_STAT, {
                    params: {
                        accountId,
                    },
                }).then(({ data }) => {
                    commit(types.UPDATE_SUBJECT, { subject, data: data || {} });
                    // commit(types.UPDATE_LOADING_STATUS, { type: subject, isLoading: false });
                    resolve(data);
                }).catch((error) => {
                    // commit(types.UPDATE_LOADING_STATUS, { type: subject, isLoading: false });
                    reject(error);
                });
            });
        },


        getCustomerAreaAndRecord({ commit }, { accountId, startTime, endTime } = {}) {
            const subject = 'customerAreaAndRecord';

            return new Promise((resolve, reject) => {
                // commit(types.UPDATE_LOADING_STATUS, { type: subject, isLoading: true });
                Vue.http.get(urls.GET_CUSTOMER_AREA_AND_RECORD_STAT, {
                    params: {
                        accountId,
                        startTime,
                        endTime,
                    },
                }).then(({ data }) => {
                    commit(types.UPDATE_SUBJECT, { subject, data: data || [] });
                    // commit(types.UPDATE_LOADING_STATUS, { type: subject, isLoading: false });
                    resolve(data);
                }).catch((error) => {
                    // commit(types.UPDATE_LOADING_STATUS, { type: subject, isLoading: false });
                    reject(error);
                });
            });
        },


        getCustomerDronePilotRanking({ commit }, { accountId } = {}) {
            const subject = 'customerDronePilotRanking';

            return new Promise((resolve, reject) => {
                commit(types.UPDATE_LOADING_STATUS, { type: subject, isLoading: true });
                Vue.http.get(urls.GET_CUSTOMER_DRONE_PILOT_RANKING, {
                    params: {
                        accountId,
                    },
                }).then(({ data }) => {
                    commit(types.UPDATE_SUBJECT, { subject, data: data || [] });
                    commit(types.UPDATE_LOADING_STATUS, { type: subject, isLoading: false });
                    resolve(data);
                }).catch((error) => {
                    commit(types.UPDATE_LOADING_STATUS, { type: subject, isLoading: false });
                    reject(error);
                });
            });
        },
    },
};
