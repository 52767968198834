<template>
    <el-container>
        <el-header height="80px">
            <Header></Header>
        </el-header>
        <el-container v-bar>
            <div class="vuebar-content">
                <el-main>
                    <router-view></router-view>
                </el-main>
            </div>
        </el-container>
    </el-container>
</template>

<script>
    import Header from './HeaderAlternative.vue';

    export default {
        name: 'LayoutContainer',
        components: { Header },
    };
</script>

<style lang="scss" scoped>
</style>
