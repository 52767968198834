/**
 * NoFlyZone
 */

import Vue from 'vue';
import * as types from './mutation-types';
import * as urls from '../config/urls';
import * as helpers from '../utils/helpers';

export default {
    namespaced: true,

    state: {
        // noFlyZone
        noFlyZoneList: {},
        noFlyZoneLoading: false,
        noFlyZonePagination: 1,
        noFlyZonePageSize: 20,
        noFlyZoneFilters: {
            detailAddress: '',
        },
        noFlyZoneDetail: {},
        noFlyZoneType: '1',
        noFlyZoneTypeMap: {
            1: '其他部门',
            2: '民航',
        },
    },

    getters: {
        stat(state) {
            const { stat: { civilNoflyNum = 0, notCivilNoflyNum = 0 } = {} } = state.noFlyZoneList;
            return { civilNoflyNum, notCivilNoflyNum };
        },
    },

    mutations: {
        [types.UPDATE_LIST](state, { type, list }) {
            state[`${type}List`] = list;
        },
        [types.UPDATE_DETAIL](state, { type, detail }) {
            state[`${type}Detail`] = detail;
        },
        [types.UPDATE_PAGE_SIZE](state, { type, size }) {
            state[`${type}PageSize`] = size;
        },
        [types.UPDATE_CURRENT_PAGINATION](state, { type, pagination }) {
            state[`${type}Pagination`] = pagination;
        },
        [types.UPDATE_FILTERS](state, { type, filters }) {
            state[`${type}Filters`] = filters;
        },
        [types.UPDATE_TYPE](state, { type, theType }) {
            state[`${type}Type`] = theType;
        },
        [types.UPDATE_LOADING_STATUS](state, { type, isLoading }) {
            state[`${type}Loading`] = isLoading;
        },
    },

    actions: {
        // noFlyZone列表
        getNoFlyZoneList({ commit, state }, pagination = 1) {
            const type = 'noFlyZone';

            return new Promise((resolve, reject) => {
                commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                Vue.http.get(urls.GET_NO_FLY_ZONE_LIST, {
                    params: {
                        currentPage: pagination,
                        pageSize: state[`${type}PageSize`],
                        noflyType: state[`${type}Type`],
                        ...state[`${type}Filters`],
                    },
                }).then((response) => {
                    commit(types.UPDATE_LIST, { type, list: response.data });
                    commit(types.UPDATE_CURRENT_PAGINATION, { type, pagination });
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    resolve(response);
                }).catch((error) => {
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    reject(error);
                });
            });
        },

        //  noFlyZone：更改类型
        changeNoFlyZoneType({ commit }, theType) {
            const type = 'noFlyZone';

            commit(types.UPDATE_TYPE, { type, theType });
        },

        //  noFlyZone：变更列表分页Size
        changeNoFlyZoneListPageSize({ commit, dispatch, state }, size) {
            const type = 'noFlyZone';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            commit(types.UPDATE_PAGE_SIZE, { type, size });
            dispatch(`get${Type}List`, state[`${type}Pagination`]);
        },

        //  noFlyZone：更新列表过滤条件
        changeNoFlyZoneListFilters({ commit, dispatch, state }, filters) {
            const type = 'noFlyZone';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            const f = helpers.update(state[`${type}Filters`], filters);
            commit(types.UPDATE_FILTERS, { type, filters: f });
            dispatch(`get${Type}List`, state[`${type}Pagination`]);
        },

        // noFlyZone：创建
        createNoFlyZone({ dispatch, state }, formData) {
            const type = 'noFlyZone';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            return new Promise((resolve, reject) => {
                Vue.http.post(urls.CREATE_NO_FLY_ZONE, formData).then((response) => {
                    dispatch(`get${Type}List`, state[`${type}Pagination`]);
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        // noFlyZone：更新
        updateNoFlyZone({ dispatch, state }, formData) {
            const type = 'noFlyZone';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            return new Promise((resolve, reject) => {
                Vue.http.put(urls.UPDATE_NO_FLY_ZONE, formData).then((response) => {
                    dispatch(`get${Type}List`, state[`${type}Pagination`]);
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        // noFlyZone：更新可用状态
        updateNoFlyZoneEnable({ dispatch, commit, state }, { noflyId, opr }) {
            const type = 'noFlyZone';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            return new Promise((resolve, reject) => {
                commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                Vue.http.put(urls.UPDATE_NO_FLY_ZONE_ENABLE, { noflyId, opr }).then((response) => {
                    dispatch(`get${Type}List`, state[`${type}Pagination`]);
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    resolve(response);
                }).catch((error) => {
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    reject(error);
                });
            });
        },

        // noFlyZone：删除
        deleteNoFlyZone({ dispatch, commit, state }, { noflyId }) {
            const type = 'noFlyZone';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            return new Promise((resolve, reject) => {
                commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                const url = helpers.buildURL(urls.DELETE_NO_FLY_ZONE, noflyId);
                Vue.http.delete(url).then((response) => {
                    dispatch(`get${Type}List`, state[`${type}Pagination`]);
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    resolve(response);
                }).catch((error) => {
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    reject(error);
                });
            });
        },

        //  noFlyZone：获取详情
        getNoFlyZoneDetail({ commit }, noflyId) {
            const type = 'noFlyZone';

            return new Promise((resolve, reject) => {
                Vue.http.get(urls.GET_NO_FLY_ZONE_DETAIL, {
                    params: {
                        noflyId,
                    },
                }).then((response) => {
                    commit(types.UPDATE_DETAIL, { type, detail: response.data });
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        //  noFlyZone：清空详情数据
        clearNoFlyZoneDetail({ commit }) {
            const type = 'noFlyZone';

            commit(types.UPDATE_DETAIL, { type, detail: {} });
        },

        // end
    },
};
