<template>
    <el-upload
        class="ui-component-root"
        ref="upload"
        name="file"
        :action="uploadUrl"
        :headers="headers"
        :show-file-list="false"
        accept="image/jpeg, image/png"
        :before-upload="handleBeforeUpload"
        :on-change="handleUploadChange"
        :on-progress="handleUploadProgress"
        :on-success="handleUploadSuccess"
        :on-error="handleUploadError">
        <img :src="value" v-if="value">
        <i class="iconfont icon-photo" v-else></i>
    </el-upload>
</template>

<script>
    import { mapActions } from 'vuex';
    import emitter from 'element-ui/src/mixins/emitter';
    import { REQUEST_UPLOAD_IMAGE } from '../config/urls';

    export default {
        name: 'AvatarUploader',

        mixins: [emitter],

        props: {
            value: {
                type: String,
                default: '',
            },
        },

        data() {
            return {
                uploadUrl: REQUEST_UPLOAD_IMAGE,
                headers: {
                    Accept: 'application/json',
                },
                upload_progress: 0,
            };
        },

        methods: {
            ...mapActions('loading', ['uploadLoading']),

            // 文件状态发生改变
            handleUploadChange() {
                this.dispatch('ElFormItem', 'el.form.change', [this.image_url]);
            },

            // 上传前
            handleBeforeUpload() {
                // MARK: 可以在此对size、type做判断
                this.uploadLoading({ isLoading: true });
            },

            // 上传中
            handleUploadProgress(e) {
                this.upload_progress = e.percent;
            },

            // 上传成功
            handleUploadSuccess(respense) {
                this.uploadLoading({ isLoading: false });
                this.upload_progress = 0;
                this.$emit('input', respense.url);
            },

            // 上传失败
            handleUploadError(error) {
                this.uploadLoading({ isLoading: false });
                this.upload_progress = 0;
                try {
                    const response = JSON.parse(error.message);
                    const { message } = response;
                    if (message) {
                        this.$message.error(message);
                    }
                } catch (e) {
                    //
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    .ui-component-root /deep/ + .el-form-item__error {
        width: 100%;
        text-align: center;
    }
    /deep/ .el-upload {
        img {
            width: 120px;
            height: 120px;
            border-radius: 50%;
        }
        .iconfont {
            font-size: 80px;
            color: $--color-primary;
            box-sizing: content-box;
            border: 5px solid;
            border-radius: 50%;
            display: block;
            width: 110px;
            height: 110px;
            line-height: 110px;
            text-align: center;
        }
    }
</style>
