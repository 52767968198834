<template>
    <Box :class="$style.root">
        <div class="block">
            <Title class="block-title">{{ $t('总设备作业量排名') }}</Title>
            <Empty class="empty" v-if="!allPeriodDeviceSprayAreaRank.length">{{ $t('暂无数据') }}</Empty>
            <div class="row" v-for="(item, index) in tidy(allPeriodDeviceSprayAreaRank)" :key="item.droneId" v-else>
                <div class="sn" :class="indexColor(index)">{{ index + 1 }}</div>
                <div class="grow">{{ item.droneId }}</div>
                <div class="value">{{ areaConversion(item.sprayArea || 0).toFixed(2) }}{{ $t(areaUnit) }}</div>
            </div>
        </div>
        <div class="block">
            <Title class="block-title">{{ $t('本年设备作业量排名') }}</Title>
            <Empty class="empty" v-if="!thisYearDeviceSprayAreaRank.length">{{ $t('暂无数据') }}</Empty>
            <div class="row" v-for="(item, index) in tidy(thisYearDeviceSprayAreaRank)" :key="item.droneId" v-else>
                <div class="sn" :class="indexColor(index)">{{ index + 1 }}</div>
                <div class="grow">{{ item.droneId }}</div>
                <div class="value">{{ areaConversion(item.sprayArea || 0).toFixed(2) }}{{ $t(areaUnit) }}</div>
            </div>
        </div>
        <div class="block">
            <Title class="block-title">{{ $t('本月设备作业量排名') }}</Title>
            <Empty class="empty" v-if="!thisMonthDeviceSprayAreaRank.length">{{ $t('暂无数据') }}</Empty>
            <div class="row" v-for="(item, index) in tidy(thisMonthDeviceSprayAreaRank)" :key="item.droneId" v-else>
                <div class="sn" :class="indexColor(index)">{{ index + 1 }}</div>
                <div class="grow">{{ item.droneId }}</div>
                <div class="value">{{ areaConversion(item.sprayArea || 0).toFixed(2) }}{{ $t(areaUnit) }}</div>
            </div>
        </div>
        <div class="block">
            <Title class="block-title">{{ $t('总飞手作业量排名') }}</Title>
            <Empty class="empty" v-if="!allPeriodUserSprayAreaRank.length">{{ $t('暂无数据') }}</Empty>
            <div class="row" v-for="(item, index) in tidy(allPeriodUserSprayAreaRank)" :key="item.userId" v-else>
                <div class="sn" :class="indexColor(index)">{{ index + 1 }}</div>
                <div class="grow">{{ item.userName }}</div>
                <div class="value">{{ areaConversion(item.sprayArea || 0).toFixed(2) }}{{ $t(areaUnit) }}</div>
            </div>
        </div>
        <div class="block">
            <Title class="block-title">{{ $t('本年飞手作业量排名') }}</Title>
            <Empty class="empty" v-if="!thisYearUserSprayAreaRank.length">{{ $t('暂无数据') }}</Empty>
            <div class="row" v-for="(item, index) in tidy(thisYearUserSprayAreaRank)" :key="item.userId" v-else>
                <div class="sn" :class="indexColor(index)">{{ index + 1 }}</div>
                <div class="grow">{{ item.userName }}</div>
                <div class="value">{{ areaConversion(item.sprayArea || 0).toFixed(2) }}{{ $t(areaUnit) }}</div>
            </div>
        </div>
        <div class="block">
            <Title class="block-title">{{ $t('本月飞手作业量排名') }}</Title>
            <Empty class="empty" v-if="!thisMonthUserSprayAreaRank.length">{{ $t('暂无数据') }}</Empty>
            <div class="row" v-for="(item, index) in tidy(thisMonthUserSprayAreaRank)" :key="item.userId" v-else>
                <div class="sn" :class="indexColor(index)">{{ index + 1 }}</div>
                <div class="grow">{{ item.userName }}</div>
                <div class="value">{{ areaConversion(item.sprayArea || 0).toFixed(2) }}{{ $t(areaUnit) }}</div>
            </div>
        </div>
    </Box>
</template>

<i18n src="./languages.json"></i18n>

<script>
    import { mapActions, mapGetters, mapState } from 'vuex';
    import _throttle from 'lodash.throttle';
    import Box from './components/Box.vue';
    import Title from './components/Title.vue';
    import Empty from '../../components/Empty.vue';

    let throttle = null;
    let ticker = null;

    export default {
        name: 'SmallScreenPanel1',

        components: { Box, Title, Empty },

        computed: {
            ...mapState('statScreen', [
                'allPeriodDeviceSprayAreaRank',
                'thisYearDeviceSprayAreaRank',
                'thisMonthDeviceSprayAreaRank',
                'allPeriodUserSprayAreaRank',
                'thisYearUserSprayAreaRank',
                'thisMonthUserSprayAreaRank',
            ]),
            ...mapGetters('setting', ['areaConversion', 'areaUnit']),
        },

        mounted() {
            this.startLoadData();
        },

        destroyed() {
            this.stopLoadData();
        },

        methods: {
            ...mapActions('statScreen', ['getDeviceSprayAreaRank', 'getUserSprayAreaRank', 'getAllDevicesSprayAreaRank', 'getAllUsersSprayAreaRank']),

            startLoadData() {
                this.stopLoadData();
                throttle = _throttle(this.loadData, 300000, { trailing: false });
                ticker = setInterval(throttle, 1000);
            },

            stopLoadData() {
                clearInterval(ticker);
            },

            loadData() {
                const deviceRequests = [2, 3].map(periodType => this.getDeviceSprayAreaRank({ periodType }));
                deviceRequests.unshift(this.getAllDevicesSprayAreaRank());
                Promise.all(deviceRequests).catch(({ message }) => {
                    if (message) this.$message.error(message);
                });

                const userRequests = [2, 3].map(periodType => this.getUserSprayAreaRank({ periodType }));
                userRequests.unshift(this.getAllUsersSprayAreaRank());
                Promise.all(userRequests).catch(({ message }) => {
                    if (message) this.$message.error(message);
                });
            },

            tidy(rank = []) {
                const newRank = [...rank];
                // if (newRank.length > 6) newRank.length = 6;/**/
                if (newRank.length > 3) newRank.length = 3;
                return newRank.map(({ sprayArea, ...other }) => ({
                    sprayArea: Number(sprayArea).toFixed(2),
                    ...other,
                }));
            },

            indexColor(index) {
                const { [index]: color } = { 0: 'red', 1: 'yellow', 2: 'yellow' };
                return color || 'blue';
            },
        },
    };
</script>

<style lang="scss" module>
    .root {
        width: 230px;
        height: 100%;
        padding: 30px 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        :global {
            .block {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                margin: 0 !important;
                overflow: hidden;

                .block-title {
                    width: 100%;
                    margin-bottom: 10px;

                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;

                    &:hover {
                        width: fit-content;
                        overflow: unset;
                        text-overflow: unset;
                        animation-name: marquee3;
                        animation-duration: 4s;
                        animation-iteration-count: infinite;
                        animation-fill-mode: forwards;
                    }
                }

                .empty {
                    width: 100%;
                }
            }

            .row {
                //flex: 0 0 48%;
                flex: 0 0 100%;
                display: flex;
                align-items: center;
                font-size: 13px;
                color: white;
                line-height: 1;
                margin-top: 6px;
                overflow: hidden;

                .sn {
                    width: 13px;
                    height: 13px;
                    line-height: 13px;
                    font-size: 12px;
                    text-align: center;

                    &.red {
                        background-color: #F40E0E;
                    }

                    &.yellow {
                        background-color: #F6B72F;
                    }

                    &.blue {
                        background-color: #2ABFF7;
                    }
                }

                .grow {
                    flex: 1;
                    margin: 0 6px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;

                    &:hover {
                        text-overflow: unset;
                        animation-name: marquee;
                        animation-duration: 2s;
                        animation-iteration-count: 1;
                        animation-fill-mode: forwards;
                    }
                }
            }
        }
    }
</style>
