<template>
    <div :class="$style.root">
        <div class="scroll-wrapper">
            <div class="screen-main">
                <Heading>{{ $t('数据大屏') }}</Heading>
                <div class="screen-body">
                    <Panel1 />
                    <Panel2 />
                    <Panel3 />
                </div>
            </div>
            <Panel5 class="screen-side"></Panel5>
        </div>
    </div>
</template>

<i18n src="./languages.json"></i18n>

<script>
    import OverlayScrollbars from 'overlayscrollbars';
    import Heading from '../StatScreen/components/Heading.vue';
    import Panel1 from './Panel1.vue';
    import Panel2 from './Panel2.vue';
    import Panel3 from './Panel3.vue';
    import Panel5 from './Panel5.vue';

    export default {
        name: 'StatScreen',

        components: { Heading, Panel1, Panel2, Panel3, Panel5 },

        data() {
            return {
                scrollInstance: null,
            };
        },

        mounted() {
            this.initScroll();
            window.addEventListener('mousewheel', this.onScroll);
        },

        destroyed() {
            window.removeEventListener('mousewheel', this.onScroll);
        },

        methods: {
            initScroll() {
                this.scrollInstance = OverlayScrollbars(this.$el, {
                    sizeAutoCapable: false,
                    className: 'os-theme-light',
                    overflowBehavior: {
                        x: 'scroll',
                        y: 'hidden',
                    },
                    scrollbars: {
                        autoHide: 'leave',
                        autoHideDelay: 300,
                    },
                });
            },

            onScroll({ deltaY }) {
                if (!this.scrollInstance) return;
                const offset = deltaY >= 0 ? `+= ${deltaY}px` : `-= ${Math.abs(deltaY)}px`;
                this.scrollInstance.scroll({ x: offset });
            },
        },
    };
</script>

<style lang="scss" module>
.root {
    position: relative;
    height: 100%;

    &:global {
        &::before {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: -1;
            background: linear-gradient(0deg, #030525 0%, #05072E 100%);
        }

        &::after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: -1;
            background-image: linear-gradient(90deg, transparent 39px, rgba(255, 255, 255, 0.1) 39px), linear-gradient(transparent 39px, rgba(255, 255, 255, 0.1) 39px);
            background-size: 40px 40px;
        }
    }

    :global {
        .scroll-wrapper {
            height: 100%;
            padding: 30px 50px 30px 0;
            display: flex;
            min-width: 2240px;
        }

        .os-scrollbar {
            .os-scrollbar-handle {
                background-color: rgba(0, 156, 255, 0.3) !important;
            }

            &:hover .os-scrollbar-handle {
                background-color: rgba(0, 156, 255, 0.6) !important;
            }
        }

        .screen-main {
            flex: 1;
            display: flex;
            flex-direction: column;

            .screen-body {
                flex: 1;
                padding: 30px 30px 0 50px;
                display: flex;
            }
        }

        .screen-side {
            width: 580px;
        }

        [class*=Panel3_root] {
            min-width: 520px;
        }
    }
}
</style>
