/**
 * 数据大屏页面
 */

const StatScreen = () => import(/* webpackChunkName: "chunk!StatScreen" */'../views/StatScreen/ScreenIndex.vue');
const StatSmallScreen = () => import(/* webpackChunkName: "chunk!StatSmallScreen" */'../views/StatSmallScreen/StatSmallScreen.vue');

export default [
    {
        path: '/stat-screen',
        name: 'StatScreen',
        component: StatScreen,
        meta: {},
    },
    {
        path: '/stat/overview',
        name: 'StatOverview',
        component: StatSmallScreen,
        meta: {},
    },
];
