<template>
    <Side :class="$style.root">
        <div class="grid">
            <div class="cell">
                <i class="iconfont icon-layer"></i>
                <div class="cell-label">{{ $t('农机总数') }}</div>
                <div class="cell-value">{{ statInfo.droneNum | formatToWan(this) | unit($t('台')) | falsyTo('-') }}</div>
            </div>
            <div class="cell">
                <i class="iconfont icon-bingtu"></i>
                <div class="cell-label">{{ $t('当前在线农机数') }}</div>
                <div class="cell-value">{{ statInfo.onlineDroneCount | formatToWan(this) | falsyTo('-') }}</div>
            </div>
            <div class="cell">
                <i class="iconfont icon-weizhi"></i>
                <div class="cell-label">{{ $t('地块总面积') }}</div>
                <div class="cell-value">{{ areaConversion(statInfo.plotTotalArea || 0) | toFixed(1) | formatToWan(this) | unit($t(areaUnit)) | falsyTo('-') }}</div>
            </div>
            <div class="cell">
                <i class="iconfont icon-ditu"></i>
                <div class="cell-label">{{ $t('地块总数') }}</div>
                <div class="cell-value">{{ statInfo.ploTotalNum | formatToWan(this) | falsyTo('-') }}</div>
            </div>
            <div class="cell">
                <i class="iconfont icon-clock"></i>
                <div class="cell-label">{{ $t('作业时长') }}</div>
                <div class="cell-value">{{ statInfo.flyTotalDuration | toHour | formatToWan(this) |unit($t('小时')) | falsyTo('-') }}</div>
            </div>
            <div class="cell">
                <i class="iconfont icon-flow"></i>
                <div class="cell-label">{{ $t('喷洒量') }}</div>
                <div class="cell-value">{{ statInfo.flyTotalQuantity | formatToWan(this) |unit($t('升')) | falsyTo('-') }}</div>
            </div>
            <div class="cell">
                <i class="iconfont icon-spray"></i>
                <div class="cell-label">{{ $t('作业面积') }}</div>
                <div class="cell-value">{{ areaConversion(statInfo.sprayTotalMuNum || 0) | toFixed(1) | formatToWan(this) | unit($t(areaUnit)) | falsyTo('-') }}</div>
            </div>
            <div class="cell">
                <i class="iconfont icon-shuliang"></i>
                <div class="cell-label">{{ $t('作业架次') }}</div>
                <div class="cell-value">{{ statInfo.flySeqTotalNum | formatToWan(this) | falsyTo('-') }}</div>
            </div>
        </div>

        <div class="block">
            <Title class="block-title">{{ $t('本日数据') }}</Title>
            <div class="row">
                <div class="row-label">{{ $t('新增农机数') }}</div>
                <div class="grow blue"><div class="grow-value">{{ todayOverview.newDroneCount | formatToWan(this) | unit($t('台')) | falsyTo('-') }}</div></div>
            </div>
            <div class="row">
                <div class="row-label">{{ $t('作业时长') }}</div>
                <div class="grow red"><div class="grow-value">{{ todayOverview.flightTime | toHour | formatToWan(this) | unit($t('小时')) | falsyTo('-') }}</div></div>
            </div>
            <div class="row">
                <div class="row-label">{{ $t('活跃农机数量') }}</div>
                <div class="grow blue"><div class="grow-value">{{ todayOverview.activeDroneCount | formatToWan(this) | falsyTo('-') }}</div></div>
            </div>
            <div class="row">
                <div class="row-label">{{ $t('作业面积') }}</div>
                <div class="grow red">
                    <div class="grow-value">
                        {{ areaConversion(todayOverview.sprayArea || 0) | toFixed(1) | formatToWan(this) | unit($t(areaUnit)) | falsyTo('-') }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="row-label">{{ $t('新增地块数') }}</div>
                <div class="grow yellow"><div class="grow-value">{{ todayOverview.newBlockCount | formatToWan(this) | falsyTo('-') }}</div></div>
            </div>
            <div class="row">
                <div class="row-label">{{ $t('作业架次') }}</div>
                <div class="grow red"><div class="grow-value">{{ todayOverview.flightCount | formatToWan(this) | falsyTo('-') }}</div></div>
            </div>
            <div class="row">
                <div class="row-label">{{ $t('新增地块面积') }}</div>
                <div class="grow yellow">
                    <div class="grow-value">
                        {{ areaConversion(todayOverview.newBlockArea || 0) | toFixed(1) | formatToWan(this) | unit($t(areaUnit)) | falsyTo('-') }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="row-label">{{ $t('喷洒量') }}</div>
                <div class="grow red">
                    <div class="grow-value">
                        {{ todayOverview.sprayQuantity | formatToWan(this) | unit($t('升')) | falsyTo('-') }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="row-label">{{ $t('活跃地块') }}</div>
                <div class="grow yellow"><div class="grow-value">{{ todayOverview.activeBlockCount | formatToWan(this) | falsyTo('-') }}</div></div>
            </div>
        </div>

        <div class="block">
            <Title class="block-title">{{ $t('本月数据') }}</Title>
            <div class="row">
                <div class="row-label">{{ $t('新增农机数') }}</div>
                <div class="grow blue">
                    <div class="grow-value">
                        {{ thisMonthOverview.newDroneCount | formatToWan(this) | unit($t('台')) | falsyTo('-') }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="row-label">{{ $t('作业时长') }}</div>
                <div class="grow red">
                    <div class="grow-value">
                        {{ thisMonthOverview.flightTime | toHour | formatToWan(this) | unit($t('小时')) | falsyTo('-') }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="row-label">{{ $t('活跃农机数量') }}</div>
                <div class="grow blue"><div class="grow-value">{{ thisMonthOverview.activeDroneCount | formatToWan(this) | falsyTo('-') }}</div></div>
            </div>
            <div class="row">
                <div class="row-label">{{ $t('作业面积') }}</div>
                <div class="grow red">
                    <div class="grow-value">
                        {{ areaConversion(thisMonthOverview.sprayArea || 0) | toFixed(1) | formatToWan(this) | unit($t(areaUnit)) | falsyTo('-') }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="row-label">{{ $t('新增地块数') }}</div>
                <div class="grow yellow"><div class="grow-value">{{ thisMonthOverview.newBlockCount | formatToWan(this) | falsyTo('-') }}</div></div>
            </div>
            <div class="row">
                <div class="row-label">{{ $t('作业架次') }}</div>
                <div class="grow red"><div class="grow-value">{{ thisMonthOverview.flightCount | formatToWan(this) | falsyTo('-') }}</div></div>
            </div>
            <div class="row">
                <div class="row-label">{{ $t('新增地块面积') }}</div>
                <div class="grow yellow">
                    <div class="grow-value">
                        {{ areaConversion(thisMonthOverview.newBlockArea || 0) | toFixed(1) | formatToWan(this) | unit($t(areaUnit)) | falsyTo('-') }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="row-label">{{ $t('喷洒量') }}</div>
                <div class="grow red">
                    <div class="grow-value">
                        {{ thisMonthOverview.sprayQuantity | formatToWan(this) | unit($t('升')) | falsyTo('-') }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="row-label">{{ $t('活跃地块') }}</div>
                <div class="grow yellow"><div class="grow-value">{{ thisMonthOverview.activeBlockCount | formatToWan(this) | falsyTo('-') }}</div></div>
            </div>
        </div>

        <div class="block">
            <Title class="block-title">{{ $t('本年数据') }}</Title>
            <div class="row">
                <div class="row-label">{{ $t('新增农机数') }}</div>
                <div class="grow blue">
                    <div class="grow-value">
                        {{ thisYearOverview.newDroneCount | formatToWan(this) | unit($t('台')) | falsyTo('-') }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="row-label">{{ $t('作业时长') }}</div>
                <div class="grow red">
                    <div class="grow-value">
                        {{ thisYearOverview.flightTime | toHour | formatToWan(this) | unit($t('小时')) | falsyTo('-') }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="row-label">{{ $t('活跃农机数量') }}</div>
                <div class="grow blue"><div class="grow-value">{{ thisYearOverview.activeDroneCount | formatToWan(this) | falsyTo('-') }}</div></div>
            </div>
            <div class="row">
                <div class="row-label">{{ $t('作业面积') }}</div>
                <div class="grow red">
                    <div class="grow-value">
                        {{ areaConversion(thisYearOverview.sprayArea || 0) | toFixed(1) | formatToWan(this) | unit($t(areaUnit)) | falsyTo('-') }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="row-label">{{ $t('新增地块数') }}</div>
                <div class="grow yellow"><div class="grow-value">{{ thisYearOverview.newBlockCount | formatToWan(this) | falsyTo('-') }}</div></div>
            </div>
            <div class="row">
                <div class="row-label">{{ $t('作业架次') }}</div>
                <div class="grow red"><div class="grow-value">{{ thisYearOverview.flightCount | formatToWan(this) | falsyTo('-') }}</div></div>
            </div>
            <div class="row">
                <div class="row-label">{{ $t('新增地块面积') }}</div>
                <div class="grow yellow">
                    <div class="grow-value">
                        {{ areaConversion(thisYearOverview.newBlockArea || 0) | toFixed(1) | formatToWan(this) | unit($t(areaUnit)) | falsyTo('-') }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="row-label">{{ $t('喷洒量') }}</div>
                <div class="grow red">
                    <div class="grow-value">
                        {{ thisYearOverview.sprayQuantity | formatToWan(this) | unit($t('升')) | falsyTo('-') }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="row-label">{{ $t('活跃地块') }}</div>
                <div class="grow yellow"><div class="grow-value">{{ thisYearOverview.activeBlockCount | formatToWan(this) | falsyTo('-') }}</div></div>
            </div>
        </div>
    </Side>
</template>

<i18n src="./languages.json"></i18n>

<script>
    import { mapActions, mapGetters, mapState } from 'vuex';
    import _throttle from 'lodash.throttle';
    import Side from './components/Side.vue';
    import Title from './components/Title.vue';

    let throttle1 = null;
    let throttle2 = null;
    let ticker = null;

    export default {
        name: 'Panel5',

        components: { Side, Title },

        filters: {
            toHour(sec) {
                return ((sec || 0) / 3600).toFixed(1);
            },

            formatToWan(number, that) {
                if (!number) {
                    return number;
                }

                const num = number - 0;
                // if (number >= 1000000) {
                //     return (number / 1000000).toFixed(2) + '百万';
                // }
                if (that.isEn) {
                    if (num >= 1000000) {
                        // 转换为以“万”为单位
                        const temp = num / 1000000;
                        return `${(Math.floor(temp * 100)) / 100} ${that.$t('万')}`;
                    }
                    if (num >= 1000) {
                        // 转换为以“万”为单位
                        const temp = num / 1000;
                        return `${(Math.floor(temp * 100)) / 100} k`;
                    }
                    // 如果小于一万，则直接返回原数值
                    return num;
                }
                // 判断数值是否大于等于一万
                if (num >= 10000) {
                    // 转换为以“万”为单位
                    const temp = num / 10000;
                    return `${(Math.floor(temp * 100)) / 100} ${that.$t('万')}`;
                }
                // 如果小于一万，则直接返回原数值
                return num;
            },
        },

        computed: {
            ...mapGetters('monitor', ['statInfo']),
            ...mapGetters('setting', ['areaConversion', 'areaUnit']),
            ...mapGetters('lang', ['isEn']),
            ...mapState('statScreen', [
                'todayOverview',
                'thisMonthOverview',
                'thisYearOverview',
            ]),
        },

        mounted() {
            this.startLoadData();
        },

        destroyed() {
            this.stopLoadData();
        },

        methods: {
            ...mapActions('monitor', ['getMonitorDetail']),
            ...mapActions('statScreen', ['getPeriodStatOverview', 'getTodayDataOverview']),

            startLoadData() {
                this.stopLoadData();
                throttle1 = _throttle(this.loadData1, 30000, { trailing: false });
                throttle2 = _throttle(this.loadData2, 300000, { trailing: false });
                ticker = setInterval(() => {
                    throttle1();
                    throttle2();
                }, 1000);
            },

            stopLoadData() {
                clearInterval(ticker);
            },

            loadData1() {
                this.getMonitorDetail().catch(({ message }) => {
                    if (message) this.$message.error(message);
                });
            },

            loadData2() {
                const requests = [2, 3].map(periodType => this.getPeriodStatOverview({ periodType }));
                requests.unshift(this.getTodayDataOverview());
                Promise.all(requests).catch(({ message }) => {
                    if (message) this.$message.error(message);
                });
            },
        },
    };
</script>

<style lang="scss" module>
    .root {
        height: 100%;
        padding: 30px 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        :global {
            .grid {
                //display: flex;
                //flex-wrap: wrap;
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                column-gap: 4px;

                .cell {
                    overflow: hidden;
                    flex: 0 0 25%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    font-size: 18px;
                    line-height: 1;
                    margin-bottom: 10px;

                    i {
                        font-size: 50px;
                        color: #4AC2E0;
                    }

                    .cell-label {
                        margin: 6px 0;
                        //font-size: 13px;
                        width: 100%;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        text-align: center;

                        &:hover {
                            width: fit-content;
                            overflow: unset;
                            text-overflow: unset;
                            animation-name: marquee2;
                            animation-duration: 4s;
                            animation-iteration-count: infinite;
                            animation-fill-mode: forwards;
                        }
                    }

                    .cell-value {
                        //font-size: 14px;
                        color: #4AC2E0;

                        width: 100%;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        text-align: center;

                        &:hover {
                            width: fit-content;
                            overflow: unset;
                            text-overflow: unset;
                            animation-name: marquee2;
                            animation-duration: 4s;
                            animation-iteration-count: infinite;
                            animation-fill-mode: forwards;
                        }
                    }
                }
            }

            .block {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                .block-title {
                    width: 100%;
                    margin-bottom: 10px;
                }
            }

            .row {
                overflow: hidden;
                flex: 0 0 45%;
                display: flex;
                align-items: center;
                //font-size: 13px;
                line-height: 1;
                margin-top: 10px;

                .row-label {
                    width: 62%;
                    color: white;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;

                    &:hover {
                        //width: fit-content;
                        overflow: unset;
                        text-overflow: unset;
                        animation-name: marquee3;
                        animation-duration: 4s;
                        animation-iteration-count: infinite;
                        animation-fill-mode: forwards;
                    }
                }

                .grow {
                    flex: 1;
                    white-space: nowrap;
                    text-align: right;
                    //overflow: hidden;
                    //max-width: 250px;
                    //animation-name: marquee4;
                    //animation-duration: 4s;
                    //animation-iteration-count: infinite;
                    //animation-fill-mode: forwards;

                    .grow-value {
                        //width: 100%;
                        //max-width: 80px;
                        //overflow: hidden;
                        //text-overflow: ellipsis;
                        //white-space: nowrap;
                    }


                    //&:hover {
                    //    .grow-value {
                    //        //width: fit-content;
                    //        width: fit-content;
                    //        overflow: unset;
                    //        text-overflow: unset;
                    //        animation-name: marquee4;
                    //        animation-duration: 4s;
                    //        animation-iteration-count: 1;
                    //        animation-fill-mode: forwards;
                    //    }
                    //}

                    &.red {
                        color: #f47500;
                    }

                    &.yellow {
                        color: #F6B72F;
                    }

                    &.blue {
                        color: #2ABFF7;
                    }
                }
            }
        }
    }
</style>
