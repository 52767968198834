<template>
    <div class="columns is-mobile ui-uploader">
        <div class="column is-narrow">
            <el-upload
                ref="upload"
                name="file"
                :action="uploadUrl"
                :headers="headers"
                :show-file-list="false"
                accept="image/jpeg, image/png"
                :before-upload="handleBeforeUpload"
                :on-change="handleUploadChange"
                :on-progress="handleUploadProgress"
                :on-success="handleUploadSuccess"
                :on-error="handleUploadError">
                <el-button type="primary" size="small" slot="trigger">{{ $t('upload') }}</el-button>
            </el-upload>
        </div>
        <div class="column is-narrow" v-if="value">
            <el-button size="small" icon="el-icon-view" plain @click="handlePreview">{{ $t('preview') }}</el-button>
        </div>
        <el-dialog :visible.sync="showPreview" :lock-scroll="false" width="320px" center append-to-body>
            <img :src="value">
        </el-dialog>
    </div>
</template>

<i18n>
    {
        "en": {
            "upload": "Upload",
            "preview": "Preview"
        },
        "zh": {
            "upload": "点击上传",
            "preview": "预览"
        },
        "zh-TW": {
            "upload": "點擊上傳",
            "preview": "預覽"
        },
        "th": {
            "upload": "อัปโหลด",
            "preview": "แสดงตัวอย่าง"
        },
        "ko": {
            "upload": "업로드",
            "preview": "미리보기"
        }
    }
</i18n>

<script>
    import { mapActions } from 'vuex';
    import emitter from 'element-ui/src/mixins/emitter';
    import { REQUEST_UPLOAD_IMAGE } from '../config/urls';

    export default {
        name: 'ImageUploader',

        mixins: [emitter],

        props: {
            value: {
                type: String,
                default: '',
            },
        },

        data() {
            return {
                uploadUrl: REQUEST_UPLOAD_IMAGE,
                headers: {
                    Accept: 'application/json',
                },
                upload_progress: 0,
                showPreview: false,
            };
        },

        methods: {
            ...mapActions('loading', ['uploadLoading']),

            // 文件状态发生改变
            handleUploadChange() {
                this.dispatch('ElFormItem', 'el.form.change', [this.image_url]);
            },

            // 上传前
            handleBeforeUpload() {
                // MARK: 可以在此对size、type做判断
                this.uploadLoading({ isLoading: true });
            },

            // 上传中
            handleUploadProgress(e) {
                this.upload_progress = e.percent;
            },

            // 上传成功
            handleUploadSuccess(respense) {
                this.uploadLoading({ isLoading: false });
                this.upload_progress = 0;
                this.$emit('input', respense.url);
            },

            // 上传失败
            handleUploadError(error) {
                this.uploadLoading({ isLoading: false });
                this.upload_progress = 0;
                try {
                    const response = JSON.parse(error.message);
                    const { message } = response;
                    if (message) {
                        this.$message.error(message);
                    }
                } catch (e) {
                    //
                }
            },

            // 预览
            handlePreview() {
                if (this.value) {
                    this.showPreview = true;
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    .el-dialog__wrapper img {
        display: block;
        margin: 0 auto;
    }
    .columns {
        margin-top: 0;
        margin-bottom: 0;
        .column {
            padding-top: 0;
            padding-bottom: 0;
        }
    }
</style>
