/**
 * 全局过滤器
 */
import { formatSec, formatTime, toFixed, falsyTo } from '../utils/helpers';

export default {
    install(Vue) {
        // 将总秒数格式化为“x时y分z秒”形式
        Vue.filter('formatSec', formatSec);

        // 保留小数位（是整数则不保留）
        Vue.filter('toFixed', toFixed);

        // 格式化时间
        Vue.filter('formatTime', formatTime);

        // 如果是falsy值，则输出另外的值
        Vue.filter('falsyTo', falsyTo);

        // 单位
        Vue.filter('unit', (value, unitLabel) => (value || value === 0 ? `${value}${unitLabel}` : ''));
    },
};
