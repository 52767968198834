/**
 * 将blob中的数据转换为json
 */
export const readJsonFromBlob = blob => new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onerror = reject;

    reader.onload = () => {
        const json = JSON.parse(reader.result);
        resolve(json);
    };

    reader.readAsText(blob);
});

export default null;
