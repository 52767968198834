<template>
    <div class="columns is-gapless ui-root">
        <div class="column" v-if="isProxyMode && isPlatform">
            <img :src="`/images/${logoFilename}`" class="ui-logo" alt="logo">
            <!-- <span class="ui-title">{{ title }}</span>-->
            <span class="ui-title">{{ !isCn ? $t('站点标题') : title }}</span>
        </div>
        <div class="column" v-else-if="!isPersonalOperator">
            <img :src="accountLogo" class="ui-logo" alt="logo" v-if="accountLogo">
            <img src="/images/logo.png" class="ui-logo" alt="logo" v-else>
            <div>
                <span class="ui-title">{{ getUserInfo('accountName') }}</span>
                <!--<div class="superior" v-if="userInfo.parentAccountName !== null">{{ $t("superior_company") }}:{{ getUserInfo('parentAccountName') }}</div>-->
            </div>
        </div>
        <div class="column" v-else></div>
        <div class="column is-narrow ui-user-info">
            <img :src="userHeadUrl" class="ui-avatar" alt="avatar" @click="showProfileEditor = true" v-if="userHeadUrl">
            <i class="iconfont icon-user" @click="showProfileEditor = true" v-else></i>
            <span>{{ phoneNum }}（{{ roleName ? `${$t(roleName)}：` : '' }}{{ getUserInfo('userName') }}）</span>
        </div>
        <ProfileEdit :visible.sync="showProfileEditor"></ProfileEdit>
    </div>
</template>

<i18n src="../../config/account-languages.json"></i18n>

<script>
    import { mapGetters, mapState } from 'vuex';
    import ProfileEdit from './ProfileEdit.vue';

    export default {
        name: 'Header',

        components: { ProfileEdit },

        data() {
            return {
                showProfileEditor: false,
            };
        },

        computed: {
            ...mapState('theme', ['isProxyMode', 'title', 'logoFilename']),
            ...mapGetters('user', ['userTypeLabel', 'phoneNum', 'getUserInfo', 'isPlatform', 'isPersonalOperator', 'isIndia']),
            ...mapState('user', ['userInfo']),
            ...mapGetters('lang', ['isEn', 'isCn']),

            accountLogo() {
                return this.getUserInfo('accountLogo');
            },
            userHeadUrl() {
                return this.getUserInfo('userHeadUrl');
            },
            roleName() {
                const userType = this.getUserInfo('userType');
                return this.userTypeLabel(userType);
            },
        },
    };
</script>

<style lang="scss" scoped>
    .ui-root {
        height: inherit;
        align-items: center;
    }

    .ui-logo {
        max-height: 50px;
    }

    .ui-title {
        margin-left: 10px;
        font-size: 25px;
        color: $brandColor;
    }

    .column {
        display: flex;
        align-items: center;
    }

    .superior {
        font-size: 12px;
        color: #14A7FF;
        margin-left: 10px;
    }

    .ui-user-info {
        font-size: 16px;
        i {
            font-size: 32px;
            color: $brandColor;
            margin-right: 6px;
            cursor: pointer;
        }
        .ui-avatar {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            margin-right: 6px;
            vertical-align: middle;
            cursor: pointer;
        }
        span {
            vertical-align: middle;
        }
    }
</style>
