/**
 * 数据统计管理
 */

const DataStatList = () => import(/* webpackChunkName: "chunk!data-stat-list" */'../views/DataStatManage/DataStatList.vue');
const DroneStatistics = () => import(/* webpackChunkName: "drone-statistics" */'../views/DataStatManage/DroneStatistics.vue');

const group = 'data_stat_manage';

export default [
    {
        path: `/${group}/data-stat-list`,
        name: 'DataStatList',
        component: DataStatList,
        meta: { group },
    },
    {
        path: `/${group}/data-stat-list/~/drone-statistics`,
        name: 'DroneStatistics',
        component: DroneStatistics,
        meta: { group },
    },
];
