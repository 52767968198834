/**
 * DataStat
 */

import Vue from 'vue';
import * as types from './mutation-types';
import * as urls from '../config/urls';
import * as helpers from '../utils/helpers';

export default {
    namespaced: true,

    state: {
        // dataStat
        dataStatList: {},
        dataStatLoading: false,
        dataStatPagination: 1,
        dataStatPageSize: 10,
        dataStatFilters: {
            startTime: '',
            endTime: '',
            order: '1',
        },
        dataStatDetail: {},
        dataStatSettings: {
            periodType: '',
            startTime: '',
            endTime: '',
            region: '',
            tftzType: '',
            cropId: '',
            medId: '',
            zzAccountId: '',
            yyAccountId: '',
            startDroneId: '',
            endDroneId: '',
            flyModelId: '',
            taskIds: '',
            oprId: '',
            areaUnit: '',
            showType: '',
            refreshFre: '',
            x: '',
            y: '',
            dimension: '',
            summary: '',
        },
        // dataStatOverview
        dataStatOverviewDetail: {},
    },

    getters: {},

    mutations: {
        [types.UPDATE_LIST](state, { type, list }) {
            state[`${type}List`] = list;
        },
        [types.UPDATE_DETAIL](state, { type, detail }) {
            state[`${type}Detail`] = detail;
        },
        [types.UPDATE_PAGE_SIZE](state, { type, size }) {
            state[`${type}PageSize`] = size;
        },
        [types.UPDATE_CURRENT_PAGINATION](state, { type, pagination }) {
            state[`${type}Pagination`] = pagination;
        },
        [types.UPDATE_FILTERS](state, { type, filters }) {
            state[`${type}Filters`] = filters;
        },
        [types.UPDATE_LOADING_STATUS](state, { type, isLoading }) {
            state[`${type}Loading`] = isLoading;
        },
    },

    actions: {
        // dataStat列表
        getDataStatList({ commit, state }, pagination = 1) {
            const type = 'dataStat';
            return new Promise((resolve, reject) => {
                commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                Vue.http.get(urls.GET_DATA_STAT_LIST, {
                    params: {
                        currentPage: pagination,
                        pageSize: state[`${type}PageSize`],
                        ...state[`${type}Filters`],
                    },
                }).then((response) => {
                    commit(types.UPDATE_LIST, { type, list: response.data });
                    commit(types.UPDATE_CURRENT_PAGINATION, { type, pagination });
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    resolve(response);
                }).catch((error) => {
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    reject(error);
                });
            });
        },

        //  dataStat：变更列表分页Size
        changeDataStatListPageSize({ commit, dispatch, state }, size) {
            const type = 'dataStat';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            commit(types.UPDATE_PAGE_SIZE, { type, size });
            dispatch(`get${Type}List`, state[`${type}Pagination`]);
        },

        //  dataStat：更新列表过滤条件
        changeDataStatListFilters({ commit, dispatch, state }, filters) {
            const type = 'dataStat';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            const f = helpers.update(state[`${type}Filters`], filters);
            commit(types.UPDATE_FILTERS, { type, filters: f });
            dispatch(`get${Type}List`, state[`${type}Pagination`]);
        },

        //  dataStat：获取详情
        getDataStatDetail({ commit }, statId) {
            const type = 'dataStat';

            return new Promise((resolve, reject) => {
                Vue.http.get(urls.GET_DATA_STAT_DETAIL, {
                    params: { statId },
                }).then((response) => {
                    commit(types.UPDATE_DETAIL, { type, detail: response.data });
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        // dataStat：清除详情
        clearDataStatDetail({ commit }) {
            const type = 'dataStat';

            commit(types.UPDATE_DETAIL, { type, detail: {} });
        },

        //  dataStat：创建统计配置
        createDataStatSettings({ state }, formData) {
            const data = helpers.update(state.dataStatSettings, formData);

            return new Promise((resolve, reject) => {
                Vue.http.post(urls.CREATE_DATA_STAT_SETTINGS, data).then((response) => {
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        //  dataStat：删除
        deleteDataStat({ dispatch, commit, state }, { statId }) {
            const type = 'dataStat';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            return new Promise((resolve, reject) => {
                commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                const url = helpers.buildURL(urls.DELETE_DATA_STAT, statId);
                Vue.http.delete(url).then((response) => {
                    dispatch(`get${Type}List`, state[`${type}Pagination`]);
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    resolve(response);
                }).catch((error) => {
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    reject(error);
                });
            });
        },

        //  dataStat：创建统计并获取详情
        createDataStatWithDetail({ state }, formData) {
            const data = helpers.update(state.dataStatSettings, formData);

            return new Promise((resolve, reject) => {
                Vue.http.post(urls.CREATE_DATA_STAT_WITH_DETAIL, data).then((response) => {
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        //  dataStat：更新统计配置
        updateDataStatSettings({ state }, formData) {
            const data = helpers.update({
                statId: '',
                ...state.dataStatSettings,
            }, formData);

            return new Promise((resolve, reject) => {
                Vue.http.put(urls.UPDATE_DATA_STAT_SETTINGS, data).then((response) => {
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        //  dataStat：获取总览
        getDataStatOverview({ commit }) {
            const type = 'dataStatOverview';

            return new Promise((resolve, reject) => {
                Vue.http.get(urls.CREATE_DATA_STAT_OVERVIEW).then((response) => {
                    commit(types.UPDATE_DETAIL, { type, detail: response.data });
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        // end
    },
};
