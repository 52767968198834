import Vue from 'vue';
import ECharts from 'vue-echarts';
import { use } from 'echarts';

// 手动引入ECharts各模块来减小打包体积
import { CanvasRenderer } from 'echarts/renderers';
import { BarChart, PieChart, LineChart } from 'echarts/charts';
import { TooltipComponent, TitleComponent, DataZoomComponent, LegendComponent, LegendScrollComponent } from 'echarts/components';

use([
    CanvasRenderer,
    BarChart,
    PieChart,
    LineChart,
    TooltipComponent,
    TitleComponent,
    DataZoomComponent,
    LegendComponent,
    LegendScrollComponent,
]);

// 注册组件后即可使用
Vue.component('v-chart', ECharts);
