/**
 * settings
 */

const UPDATE_AREA_UNIT = 'UPDATE_AREA_UNIT';

export default {
    namespaced: true,

    state: {
        area_unit: localStorage.getItem('areaUnit') || 'mu',
        isBigScreen: !!localStorage.getItem('bigScreen'),
    },

    getters: {
        areaConversion: state => (value) => {
            switch (state.area_unit) {
                case 'rai': return value * 0.4166667;
                case 'acre': return value * 0.1647369;
                case 'hectare': return value * 0.0666667;
                default: return value - 0;
            }
        },

        areaUnit: state => `settings.area_unit.${state.area_unit}`,
    },

    mutations: {
        UPDATE_AREA_UNIT(state, unit) {
            state.area_unit = unit;
        },
    },

    actions: {
        // 设置面积单位
        setAreaUnit({ commit }, unit) {
            localStorage.setItem('areaUnit', unit);
            commit(UPDATE_AREA_UNIT, unit);
        },

        // 设置各国默认面积单位
        setDefaultAreaUnitByLang({ commit }, lang) {
            switch (lang) {
                case 'en': return commit(UPDATE_AREA_UNIT, 'acre');
                case 'th': return commit(UPDATE_AREA_UNIT, 'rai');
                default: return commit(UPDATE_AREA_UNIT, 'mu');
            }
        },
    },
};
