/**
 * 设备管理
 */

const DroneList = () => import(/* webpackChunkName: "chunk!drone-list" */'../views/DeviceManage/Drone/DroneList.vue');
const DroneModelList = () => import(/* webpackChunkName: "chunk!drone-model-list" */'../views/DeviceManage/DroneModel/DroneModelList.vue');
const Tools = () => import(/* webpackChunkName: "chunk!tools" */'../views/Tools/Tools.vue');
const AppLog = () => import(/* webpackChunkName: "chunk!app-log" */'../views/AppLog/AppLog.vue');
const FirmwareManage = () => import(/* webpackChunkName: "chunk!firmware-list" */'../views/FirmwareManage/FirmwareManage.vue');
const GroundStationConfig = () => import(/* webpackChunkName: "chunk!ground-station-config" */'../views/GroundStationConfig/GroundStationConfig.vue');
const PartWhiteList = () => import(/* webpackChunkName: "chunk!part-white-list" */'../views/PartWhiteList/PartWhiteList.vue');

const group = 'business_manage';

export default [
    {
        path: `/${group}/drone-list`,
        name: 'DroneList',
        component: DroneList,
        meta: { group },
    },
    {
        path: `/${group}/drone-model-list`,
        name: 'DroneModelList',
        component: DroneModelList,
        meta: { group },
    },
    {
        path: `/${group}/tools`,
        name: 'Tools',
        component: Tools,
        meta: { group },
    },
    {
        path: `/${group}/app-log`,
        name: 'AppLog',
        component: AppLog,
        meta: { group },
    },
    {
        path: `/${group}/firmware-list`,
        name: 'FirmwareManage',
        component: FirmwareManage,
        meta: { group },
    },
    {
        path: `/${group}/ground-station-config`,
        name: 'GroundStationConfig',
        component: GroundStationConfig,
        meta: { group },
    },
    {
        path: `/${group}/accessory-white-list`,
        name: 'PartWhiteList',
        component: PartWhiteList,
        meta: { group },
    },
];
