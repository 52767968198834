/**
 * 禁飞区管理
 */

const NoFlyZoneList = () => import(/* webpackChunkName: "chunk!no-fly-zone-list" */'../views/NoFlyZoneManage/NoFlyZoneList.vue');

const group = 'business_manage';

export default [
    {
        path: `/${group}/no-fly-zone-list`,
        name: 'NoFlyZoneList',
        component: NoFlyZoneList,
        meta: { group },
    },
];
