/**
 * subsidy
 */

import Vue from 'vue';
import * as types from './mutation-types';
import * as urls from '../config/urls';
import * as helpers from '../utils/helpers';

export default {
    namespaced: true,

    state: {
        // subsidy
        subsidyList: {},
        subsidyLoading: false,
        subsidyPagination: 1,
        subsidyPageSize: 20,
        subsidyFilters: {
            year: '',
            region: '',
        },
        subsidyDetail: {},
        // subsidyStatData
        subsidyStatDataList: [],
        subsidyStatDataLoading: false,
        // subsidySummary
        subsidySummaryDetail: {},
        // subsidyTrack
        subsidyTrackList: [],
        subsidyTrackLoading: false,
    },

    mutations: {
        [types.UPDATE_LIST](state, { type, list }) {
            state[`${type}List`] = list;
        },
        [types.UPDATE_DETAIL](state, { type, detail }) {
            state[`${type}Detail`] = detail;
        },
        [types.UPDATE_PAGE_SIZE](state, { type, size }) {
            state[`${type}PageSize`] = size;
        },
        [types.UPDATE_CURRENT_PAGINATION](state, { type, pagination }) {
            state[`${type}Pagination`] = pagination;
        },
        [types.UPDATE_FILTERS](state, { type, filters }) {
            state[`${type}Filters`] = filters;
        },
        [types.UPDATE_LOADING_STATUS](state, { type, isLoading }) {
            state[`${type}Loading`] = isLoading;
        },
    },

    actions: {
        // subsidy: 获取农机补贴列表
        getSubsidyList({ commit, state }, pagination = 1) {
            const type = 'subsidy';

            return new Promise((resolve, reject) => {
                commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                Vue.http.get(urls.GET_SUBSIDY_LIST, {
                    params: {
                        pageIndex: pagination,
                        size: state[`${type}PageSize`],
                        ...state[`${type}Filters`],
                    },
                }).then((response) => {
                    commit(types.UPDATE_LIST, { type, list: response.data });
                    commit(types.UPDATE_CURRENT_PAGINATION, { type, pagination });
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    resolve(response);
                }).catch((error) => {
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    reject(error);
                });
            });
        },

        // subsidy: 刷新列表
        refreshSubsidyList({ dispatch, state }) {
            const type = 'subsidy';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            dispatch(`get${Type}List`, state[`${type}Pagination`]);
            dispatch('getSubsidySummary');
        },

        // subsidy: 更变农机补贴列表分页Size
        changeSubsidyListPageSize({ commit, dispatch }, size) {
            const type = 'subsidy';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            commit(types.UPDATE_PAGE_SIZE, { type, size });
            dispatch(`refresh${Type}List`);
        },

        // subsidy: 更新农机补贴列表过滤条件
        changeSubsidyListFilters({ commit, dispatch, state }, filters) {
            const type = 'subsidy';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            const f = helpers.update(state[`${type}Filters`], filters);
            commit(types.UPDATE_FILTERS, { type, filters: f });
            dispatch(`refresh${Type}List`);
        },

        // subsidy: 获取汇总数据
        getSubsidySummary({ commit }) {
            const type = 'subsidySummary';

            return new Promise((resolve, reject) => {
                Vue.http.get(urls.GET_SUBSIDY_SUMMARY).then((response) => {
                    commit(types.UPDATE_DETAIL, { type, detail: response.data });
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        // subsidy: 删除农机补贴
        deleteSubsidy({ dispatch }, { subsidyId }) {
            const type = 'subsidy';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            return new Promise((resolve, reject) => {
                const url = helpers.buildURL(urls.DELETE_SUBSIDY, subsidyId);
                Vue.http.delete(url).then((response) => {
                    dispatch(`refresh${Type}List`);
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        // subsidyStatData: 获取农机补贴图表数据
        getSubsidyStatDataList({ commit }, { region }) {
            const type = 'subsidyStatData';

            return new Promise((resolve, reject) => {
                commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                Vue.http.get(urls.GET_SUBSIDY_STAT_DATA, {
                    params: { region },
                }).then((response) => {
                    commit(types.UPDATE_LIST, { type, list: response.data || [] });
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    resolve(response);
                }).catch((error) => {
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    reject(error);
                });
            });
        },

        // subsidyTrack: 获取农机补贴轨迹列表
        getSubsidyTrackList({ commit }, { subsidyId }) {
            const type = 'subsidyTrack';

            return new Promise((resolve, reject) => {
                commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                const url = helpers.buildURL(urls.GET_SUBSIDY_TRACK_LIST, subsidyId);
                Vue.http.get(url).then((response) => {
                    commit(types.UPDATE_LIST, { type, list: response.data || [] });
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    resolve(response);
                }).catch((error) => {
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    reject(error);
                });
            });
        },

        // end
    },
};
