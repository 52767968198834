<template>
    <Card :class="$style.root">
        <div class="block">
            <TitleBig>作业区域统计</TitleBig>
            <Empty class="empty" v-if="!allPeriodChinaY.length && !allPeriodForeignY.length">暂无数据</Empty>
            <v-chart :option="chartOption1" :update-options="{ notMerge: true }" theme="light" autoresize v-else />
        </div>
        <div class="multi-column">
            <div class="block">
                <Title class="block-title">本日区域统计</Title>
                <Empty class="empty" v-if="!todayChinaY.length && !todayForeignY.length">暂无数据</Empty>
                <v-chart :option="chartOption2" :update-options="{ notMerge: true }" theme="light" autoresize v-else />
            </div>
            <div class="block">
                <Title class="block-title">本月区域统计</Title>
                <Empty class="empty" v-if="!thisMonthChinaY.length && !thisMonthForeignY.length">暂无数据</Empty>
                <v-chart :option="chartOption3" :update-options="{ notMerge: true }" theme="light" autoresize v-else />
            </div>
            <div class="block">
                <Title class="block-title">本年区域统计</Title>
                <Empty class="empty" v-if="!thisYearChinaY.length && !thisYearForeignY.length">暂无数据</Empty>
                <v-chart :option="chartOption4" :update-options="{ notMerge: true }" theme="light" autoresize v-else />
            </div>
        </div>
    </Card>
</template>

<script>
    import { mapActions, mapState } from 'vuex';
    import _throttle from 'lodash.throttle';
    import Card from './components/Card.vue';
    import Title from './components/Title.vue';
    import TitleBig from './components/TitleBig.vue';
    import Empty from '../../components/Empty.vue';

    let throttle = null;
    let ticker = null;

    const tooltip = {
        trigger: 'axis',
        backgroundColor: 'rgba(0,192,250,0.5)',
        borderColor: 'rgba(0,192,250,0.8)',
        textStyle: { color: 'white' },
        axisPointer: {
            lineStyle: {
                color: 'rgba(0,192,250,0.4)',
            },
        },
        formatter(params) {
            if (Array.isArray(params)) {
                const [{ name }] = params;
                const title = `${name}`;
                const rows = params.map(({ marker, seriesName, value }) => {
                    const nameEl = `<span style="flex: 1; margin-left: 3px;">${seriesName}：</span>`;
                    // const valueEl = `<span>${(value / 10000).toFixed(2)}万亩</span>`;
                    const valueEl = `<span>${(value || 0).toFixed(2)}亩</span>`;
                    return `<div style="display: flex; align-items: center;">${marker}${nameEl}${valueEl}</div>`;
                }).filter(Boolean).join('');
                return `<div>${title}</div>${rows}`;
            }
            const { marker, name, value } = params;
            return `<div>${marker}${name}：${value}万亩</div>`;
        },
    };
    const xAxisOption = {
        type: 'category',
        axisLine: {
            show: false,
        },
        axisTick: {
            show: false,
        },
        axisLabel: {
            color: 'rgba(255, 255, 255, 1)',
            rotate: 30,
            formatter: val => val
                .replace('省', '')
                .replace('市', '')
                .replace(/^内蒙古.*/, '内蒙古')
                .replace(/^新疆.*/, '新疆')
                .replace(/^西藏.*/, '西藏')
                .replace(/^广西.*/, '广西')
                .replace(/^宁夏.*/, '宁夏'),
        },
    };
    const yAxisOption = {
        type: 'log',
        // logBase: 10,
        axisLabel: {
            color: 'rgba(255, 255, 255, 1)',
            // formatter: val => `${(val / 10000).toFixed(2) - 0}万亩`,
            formatter: val => `${val.toFixed(2) - 0}亩`,
        },
        splitLine: {
            lineStyle: {
                color: 'rgba(255, 255, 255, 0.4)',
            },
        },
    };

    export default {
        name: 'Panel4',

        components: { Card, Title, TitleBig, Empty },

        computed: {
            ...mapState('statScreen', [
                'allPeriodChinaSprayArea',
                'todayChinaSprayArea',
                'thisMonthChinaSprayArea',
                'thisYearChinaSprayArea',
                'allPeriodForeignSprayArea',
                'todayForeignSprayArea',
                'thisMonthForeignSprayArea',
                'thisYearForeignSprayArea',
            ]),

            allPeriodChinaX() {
                const { xData } = this.allPeriodChinaSprayArea;
                return xData || [];
            },

            allPeriodChinaY() {
                const { yData: [data] = [] } = this.allPeriodChinaSprayArea;
                const dataTemp = [];
                (data || []).forEach((item) => {
                    dataTemp.push((item === 0 || item < 0.9999) ? null : item);
                });
                return dataTemp || data || [];
            },

            allPeriodForeignX() {
                const { xData } = this.allPeriodForeignSprayArea;
                return xData || [];
            },

            allPeriodForeignY() {
                const { yData: [data] = [] } = this.allPeriodForeignSprayArea;
                const dataTemp = [];
                (data || []).forEach((item) => {
                    dataTemp.push((item === 0 || item < 0.9999) ? null : item);
                });

                return dataTemp || data || [];
            },

            allPeriodPie() {
                const china = this.allPeriodChinaY.reduce((acc, cur) => acc + cur, 0);
                const foreign = this.allPeriodForeignY.reduce((acc, cur) => acc + cur, 0);
                return [
                    { name: '国内', value: (china / 10000).toFixed(2), selected: true },
                    { name: '海外', value: (foreign / 10000).toFixed(2) },
                ];
            },

            todayChinaX() {
                const { xData } = this.todayChinaSprayArea;
                return xData || [];
            },

            todayChinaY() {
                const { yData: [data] = [] } = this.todayChinaSprayArea;
                const dataTemp = [];
                (data || []).forEach((item) => {
                    dataTemp.push((item === 0 || item < 0.9999) ? null : item);
                });
                return dataTemp || data || [];
            },

            todayForeignX() {
                const { xData } = this.todayForeignSprayArea;
                return xData || [];
            },

            todayForeignY() {
                const { yData: [data] = [] } = this.todayForeignSprayArea;
                const dataTemp = [];
                (data || []).forEach((item) => {
                    dataTemp.push((item === 0 || item < 0.9999) ? null : item);
                });
                return dataTemp || data || [];
            },

            todayPie() {
                const china = this.todayChinaY.reduce((acc, cur) => acc + cur, 0);
                const foreign = this.todayForeignY.reduce((acc, cur) => acc + cur, 0);
                return [
                    { name: '国内', value: (china / 10000).toFixed(2), selected: true },
                    { name: '海外', value: (foreign / 10000).toFixed(2) },
                ];
            },

            thisMonthChinaX() {
                const { xData } = this.thisMonthChinaSprayArea;
                return xData || [];
            },

            thisMonthChinaY() {
                const { yData: [data] = [] } = this.thisMonthChinaSprayArea;
                const dataTemp = [];
                (data || []).forEach((item) => {
                    dataTemp.push((item === 0 || item < 0.9999) ? null : item);
                });
                return dataTemp || data || [];
            },

            thisMonthForeignX() {
                const { xData } = this.thisMonthForeignSprayArea;
                return xData || [];
            },

            thisMonthForeignY() {
                const { yData: [data] = [] } = this.thisMonthForeignSprayArea;
                const dataTemp = [];
                (data || []).forEach((item) => {
                    dataTemp.push((item === 0 || item < 0.9999) ? null : item);
                });
                return dataTemp || data || [];
            },

            thisMonthPie() {
                const china = this.thisMonthChinaY.reduce((acc, cur) => acc + cur, 0);
                const foreign = this.thisMonthForeignY.reduce((acc, cur) => acc + cur, 0);
                return [
                    { name: '国内', value: (china / 10000).toFixed(2), selected: true },
                    { name: '海外', value: (foreign / 10000).toFixed(2) },
                ];
            },

            thisYearChinaX() {
                const { xData } = this.thisYearChinaSprayArea;
                return xData || [];
            },

            thisYearChinaY() {
                const { yData: [data] = [] } = this.thisYearChinaSprayArea;
                const dataTemp = [];
                (data || []).forEach((item) => {
                    dataTemp.push((item === 0 || item < 0.9999) ? null : item);
                });
                return dataTemp || data || [];
            },

            thisYearForeignX() {
                const { xData } = this.thisYearForeignSprayArea;
                return xData || [];
            },

            thisYearForeignY() {
                const { yData: [data] = [] } = this.thisYearForeignSprayArea;
                const dataTemp = [];
                (data || []).forEach((item) => {
                    dataTemp.push((item === 0 || item < 0.9999) ? null : item);
                });
                return dataTemp || data || [];
            },

            thisYearPie() {
                const china = this.thisYearChinaY.reduce((acc, cur) => acc + cur, 0);
                const foreign = this.thisYearForeignY.reduce((acc, cur) => acc + cur, 0);
                return [
                    { name: '国内', value: (china / 10000).toFixed(2), selected: true },
                    { name: '海外', value: (foreign / 10000).toFixed(2) },
                ];
            },

            chartOption1() {
                const color = ['#96A9E1', '#F1814F'];
                return {
                    color,
                    grid: [
                        { left: '58%', top: 10, bottom: 0, width: '23%', containLabel: true },
                        { left: 0, top: 10, bottom: 0, width: '56%', containLabel: true },
                    ],
                    legend: [{
                        show: false,
                    }, {
                        show: false,
                    }, {
                        bottom: 0,
                        left: '86%',
                        textStyle: {
                            color: 'white',
                        },
                        selectedMode: false,
                        data: ['国内', '海外'],
                    }],
                    tooltip,
                    xAxis: [{
                        data: this.allPeriodChinaX,
                        ...xAxisOption,
                        gridIndex: 0,
                    }, {
                        data: this.allPeriodForeignX,
                        ...xAxisOption,
                        gridIndex: 1,
                    }],
                    yAxis: [
                        { ...yAxisOption, gridIndex: 0 },
                        { ...yAxisOption, gridIndex: 1 },
                    ],
                    series: [{
                        name: '作业面积',
                        type: 'bar',
                        barMinWidth: 1,
                        barMaxWidth: 40,
                        xAxisIndex: 0,
                        yAxisIndex: 0,
                        itemStyle: {
                            color: color[0],
                        },
                        data: this.allPeriodChinaY,
                    }, {
                        name: '作业面积',
                        type: 'bar',
                        barMinWidth: 1,
                        barMaxWidth: 40,
                        xAxisIndex: 1,
                        yAxisIndex: 1,
                        itemStyle: {
                            color: color[1],
                        },
                        data: this.allPeriodForeignY,
                    }, {
                        type: 'pie',
                        top: 0,
                        left: '80%',
                        width: '22%',
                        height: '100%',
                        radius: ['40%', '74%'],
                        label: {
                            show: false,
                            position: 'center',
                            color: 'white',
                            lineHeight: 15,
                            formatter: '{label|{b}}\n{value|{c}万亩}\n{parent|{d}%}',
                            rich: {
                                label: { fontSize: 18, lineHeight: 24 },
                                value: { fontSize: 14, color: '#999' },
                                parent: { fontSize: 10, color: '#999' },
                            },
                        },
                        tooltip: {
                            trigger: 'item',
                        },
                        selectedMode: true,
                        selectedOffset: 0,
                        select: {
                            label: { show: true },
                        },
                        data: this.allPeriodPie,
                    }],
                };
            },

            chartOption2() {
                const color = ['#96A9E1', '#F1814F'];
                return {
                    color,
                    grid: [
                        { left: 0, top: '52%', bottom: 0, width: '60%', containLabel: true },
                        { left: 0, top: 10, bottom: '52%', right: 0, containLabel: true },
                    ],
                    legend: [{
                        show: false,
                    }, {
                        show: false,
                    }, {
                        bottom: 0,
                        left: '66%',
                        textStyle: {
                            color: 'white',
                        },
                        selectedMode: false,
                        data: ['国内', '海外'],
                    }],
                    tooltip,
                    xAxis: [{
                        data: this.todayChinaX,
                        ...xAxisOption,
                        gridIndex: 0,
                    }, {
                        data: this.todayForeignX,
                        ...xAxisOption,
                        gridIndex: 1,
                    }],
                    yAxis: [
                        { ...yAxisOption, gridIndex: 0 },
                        { ...yAxisOption, gridIndex: 1 },
                    ],
                    series: [{
                        name: '作业面积',
                        type: 'bar',
                        barMinWidth: 1,
                        barMaxWidth: 40,
                        xAxisIndex: 0,
                        yAxisIndex: 0,
                        itemStyle: {
                            color: color[0],
                        },
                        data: this.todayChinaY,
                    }, {
                        name: '作业面积',
                        type: 'bar',
                        barMinWidth: 1,
                        barMaxWidth: 40,
                        xAxisIndex: 1,
                        yAxisIndex: 1,
                        itemStyle: {
                            color: color[1],
                        },
                        data: this.todayForeignY,
                    }, {
                        type: 'pie',
                        top: '46%',
                        left: '62%',
                        width: '40%',
                        height: '50%',
                        radius: ['48%', '82%'],
                        label: {
                            show: false,
                            position: 'center',
                            color: 'white',
                            lineHeight: 15,
                            formatter: '{label|{b}}\n{value|{c}万亩}\n{parent|{d}%}',
                            rich: {
                                label: { fontSize: 16, lineHeight: 20 },
                                value: { fontSize: 12, color: '#999' },
                                parent: { fontSize: 10, color: '#999' },
                            },
                        },
                        tooltip: {
                            trigger: 'item',
                        },
                        selectedMode: true,
                        selectedOffset: 0,
                        select: {
                            label: { show: true },
                        },
                        data: this.todayPie,
                    }],
                };
            },

            chartOption3() {
                const color = ['#96A9E1', '#F1814F'];
                return {
                    color,
                    grid: [
                        { left: 0, top: '52%', bottom: 0, width: '60%', containLabel: true },
                        { left: 0, top: 10, bottom: '52%', right: 0, containLabel: true },
                    ],
                    legend: [{
                        show: false,
                    }, {
                        show: false,
                    }, {
                        bottom: 0,
                        left: '66%',
                        textStyle: {
                            color: 'white',
                        },
                        selectedMode: false,
                        data: ['国内', '海外'],
                    }],
                    tooltip,
                    xAxis: [{
                        data: this.thisMonthChinaX,
                        ...xAxisOption,
                        gridIndex: 0,
                    }, {
                        data: this.thisMonthForeignX,
                        ...xAxisOption,
                        gridIndex: 1,
                    }],
                    yAxis: [
                        { ...yAxisOption, gridIndex: 0 },
                        { ...yAxisOption, gridIndex: 1 },
                    ],
                    series: [{
                        name: '作业面积',
                        type: 'bar',
                        barMinWidth: 1,
                        barMaxWidth: 40,
                        xAxisIndex: 0,
                        yAxisIndex: 0,
                        itemStyle: {
                            color: color[0],
                        },
                        data: this.thisMonthChinaY,
                    }, {
                        name: '作业面积',
                        type: 'bar',
                        barMinWidth: 1,
                        barMaxWidth: 40,
                        xAxisIndex: 1,
                        yAxisIndex: 1,
                        itemStyle: {
                            color: color[1],
                        },
                        data: this.thisMonthForeignY,
                    }, {
                        type: 'pie',
                        top: '46%',
                        left: '62%',
                        width: '40%',
                        height: '50%',
                        radius: ['48%', '82%'],
                        label: {
                            show: false,
                            position: 'center',
                            color: 'white',
                            lineHeight: 15,
                            formatter: '{label|{b}}\n{value|{c}万亩}\n{parent|{d}%}',
                            rich: {
                                label: { fontSize: 16, lineHeight: 20 },
                                value: { fontSize: 12, color: '#999' },
                                parent: { fontSize: 10, color: '#999' },
                            },
                        },
                        tooltip: {
                            trigger: 'item',
                        },
                        selectedMode: true,
                        selectedOffset: 0,
                        select: {
                            label: { show: true },
                        },
                        data: this.thisMonthPie,
                    }],
                };
            },

            chartOption4() {
                const color = ['#96A9E1', '#F1814F'];
                return {
                    color,
                    grid: [
                        { left: 0, top: '52%', bottom: 0, width: '60%', containLabel: true },
                        { left: 0, top: 10, bottom: '52%', right: 0, containLabel: true },
                    ],
                    legend: [{
                        show: false,
                    }, {
                        show: false,
                    }, {
                        bottom: 0,
                        left: '66%',
                        textStyle: {
                            color: 'white',
                        },
                        selectedMode: false,
                        data: ['国内', '海外'],
                    }],
                    tooltip,
                    xAxis: [{
                        data: this.thisYearChinaX,
                        ...xAxisOption,
                        gridIndex: 0,
                    }, {
                        data: this.thisYearForeignX,
                        ...xAxisOption,
                        gridIndex: 1,
                    }],
                    yAxis: [
                        { ...yAxisOption, gridIndex: 0 },
                        { ...yAxisOption, gridIndex: 1 },
                    ],
                    series: [{
                        name: '作业面积',
                        type: 'bar',
                        barMinWidth: 1,
                        barMaxWidth: 40,
                        xAxisIndex: 0,
                        yAxisIndex: 0,
                        itemStyle: {
                            color: color[0],
                        },
                        data: this.thisYearChinaY,
                    }, {
                        name: '作业面积',
                        type: 'bar',
                        barMinWidth: 1,
                        barMaxWidth: 40,
                        xAxisIndex: 1,
                        yAxisIndex: 1,
                        itemStyle: {
                            color: color[1],
                        },
                        data: this.thisYearForeignY,
                    }, {
                        type: 'pie',
                        top: '46%',
                        left: '62%',
                        width: '40%',
                        height: '50%',
                        radius: ['48%', '82%'],
                        label: {
                            show: false,
                            position: 'center',
                            color: 'white',
                            lineHeight: 15,
                            formatter: '{label|{b}}\n{value|{c}万亩}\n{parent|{d}%}',
                            rich: {
                                label: { fontSize: 16, lineHeight: 20 },
                                value: { fontSize: 12, color: '#999' },
                                parent: { fontSize: 10, color: '#999' },
                            },
                        },
                        tooltip: {
                            trigger: 'item',
                        },
                        selectedMode: true,
                        selectedOffset: 0,
                        select: {
                            label: { show: true },
                        },
                        data: this.thisYearPie,
                    }],
                };
            },
        },

        mounted() {
            this.startLoadData();
        },

        destroyed() {
            this.stopLoadData();
        },

        methods: {
            ...mapActions('statScreen', ['getChinaPeriodSprayAreaPerRegion', 'getForeignPeriodSprayAreaPerRegion']),

            startLoadData() {
                this.stopLoadData();
                throttle = _throttle(this.loadData, 300000, { trailing: false });
                ticker = setInterval(throttle, 1000);
            },

            stopLoadData() {
                clearInterval(ticker);
            },

            loadData() {
                const chinaRequests = ['5', '1', '3', '4'].map(periodType => this.getChinaPeriodSprayAreaPerRegion({ periodType }));
                const foreignRequests = ['1', '2', '3', '4'].map(periodType => this.getForeignPeriodSprayAreaPerRegion({ periodType }));
                Promise.all([...chinaRequests, ...foreignRequests]).catch(({ message }) => {
                    if (message) this.$message.error(message);
                });
            },
        },
    };
</script>

<style lang="scss" module>
    .root {
        width: 1320px;
        height: 100%;
        padding: 30px 20px 20px;
        margin-left: 20px;
        display: flex;
        flex-direction: column;

        :global {
            .block {
                flex: 1;
                display: flex;
                flex-direction: column;
                margin: 0 !important;

                .block-title {
                    margin-bottom: 10px;
                }

                .echarts {
                    flex: 1;
                }
            }

            .multi-column {
                flex: 1;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                margin-top: 20px;

                .block {
                    flex: 0 0 32%;
                }
            }

            .empty {
                flex: 1;
            }
        }
    }
</style>
