/**
 * CommonStore
 */

import Vue from 'vue';
import * as types from './mutation-types';
import * as urls from '../config/urls';
import * as helpers from '../utils/helpers';
import * as auth from '../utils/auth';

export default {
    namespaced: true,

    state: {
        // manufacturer
        manufacturerList: {},
        manufacturerLoading: false,
        // operator
        operatorList: [],
        operatorLoading: false,
        // droneModel
        droneModelList: {},
        droneModelLoading: false,
        // bulletin
        bulletinList: {},
        bulletinLoading: false,
        // country
        countryList: [],
        countryLoading: false,
        // region
        regionList: [],
        regionLoading: false,
        // workType
        workTypeList: [],
        workTypeLoading: false,
        // droneTrack
        droneTrackList: {},
        droneTrackLoading: false,

        // allRegion
        allRegionList: [],
        allRegionLoading: false,

        // crop
        cropList: [],
        cropLoading: false,

        // droneByAccount
        rentalDrone: [],
    },

    getters: {
        // regionList(state) {
        //     // const tmp = JSON.stringify(state.regionList)
        //     //     .replace(/cities/g, 'children')
        //     //     .replace(/counties/g, 'children');
        //     // return JSON.parse(tmp);
        //
        //     if (!state.countryList.length) {
        //         return [];
        //     }
        //     // 国家级
        //     const country = {};
        //
        //     // 兼容 ag
        //     const countrys = state.countryList.map(item => ({
        //             ...item,
        //             areaId: item.areaId.length > 3 ? item.areaId.slice(0, -6) : item.areaId,
        //         }));
        //
        //     countrys.forEach(({ areaId, areaName }) => {
        //         const countryId = areaId.padStart(3, '0');
        //         country[countryId] = { areaId, areaName, children: [] };
        //     });
        //
        //     // 省级
        //     const province = {};
        //
        //     // 市级
        //     // const city = {};
        //
        //     // 县级
        //     // const county = {};
        //
        //     // 字段名 转换
        //     const tmp = JSON.parse(JSON.stringify(state.regionList)
        //         .replace(/cities/g, 'children')
        //         .replace(/counties/g, 'children'));
        //
        //     tmp.forEach((regionItem) => {
        //         // 中国 级联数据
        //         if (regionItem.areaId.length === 6) {
        //             country['156'].children.push(regionItem);
        //             return;
        //         }
        //
        //         // 完整 区域码
        //         const fullId = regionItem.areaId.padStart(9, '0');
        //         // 国家码
        //         const countryId = fullId.slice(0, 3);
        //         // 省码
        //         const provinceId = fullId.slice(3, 5);
        //         // 市码
        //         // const cityId = fullId.slice(5, 7);
        //
        //         // 国外 省
        //         if (fullId.slice(5, 9) === '0000') {
        //             province[`${countryId}${provinceId}`] = { ...regionItem, ...province[`${countryId}${provinceId}`] ? province[`${countryId}${provinceId}`] : {} };
        //             delete province[`${countryId}${provinceId}`].children;
        //             country[countryId].children.push(province[`${countryId}${provinceId}`]);
        //         }
        //         // 国外 市
        //         // if (fullId.slice(7, 9) === '00') {
        //         //     city[`${countryId}${cityId}`] = { ...regionItem, ...city[`${countryId}${cityId}`] ? city[`${countryId}${cityId}`] : {} };
        //         //     if (!province[`${countryId}${provinceId}`]) {
        //         //         province[`${countryId}${provinceId}`] = { children: [] };
        //         //     }
        //         //     province[`${countryId}${provinceId}`].children.push(city[cityId]);
        //         //     return;
        //         // }
        //         // 国外 县
        //         // if (!city[`${countryId}${cityId}`]) {
        //         //     city[`${countryId}${cityId}`] = { children: [] };
        //         // }
        //         // city[`${countryId}${cityId}`].children.push(regionItem);
        //     });
        //
        //
        //     return Object.values(country);
        // },
        regionList(_, getters) {
            return Object.values(getters.tidyAllRegionList);
        },

        // countryRegion(state) {
        //     // const tmp = JSON.stringify(state.regionList)
        //     //     .replace(/cities/g, 'children')
        //     //     .replace(/counties/g, 'children');
        //     // return JSON.parse(tmp);
        //
        //     if (!state.countryList.length) {
        //         return {};
        //     }
        //     // 国家级
        //     const country = {};
        //
        //     // 兼容ag
        //     const countrys = state.countryList.map(item => ({
        //             ...item,
        //             areaId: item.areaId.length > 3 ? item.areaId.slice(0, -6) : item.areaId,
        //         }));
        //
        //     countrys.forEach(({ areaId, areaName }) => {
        //         const countryId = areaId.padStart(3, '0');
        //         country[countryId] = { areaId, areaName, children: [] };
        //     });
        //
        //     // 省级
        //     const province = {};
        //
        //     // 市级
        //     // const city = {};
        //
        //     // 县级
        //     // const county = {};
        //
        //     // 字段名 转换
        //     const tmp = JSON.parse(JSON.stringify(state.regionList)
        //         .replace(/cities/g, 'children')
        //         .replace(/counties/g, 'children'));
        //
        //     tmp.forEach((regionItem) => {
        //         // 中国 级联数据
        //         if (regionItem.areaId.length === 6) {
        //             country['156'].children.push(regionItem);
        //             return;
        //         }
        //
        //         // 完整 区域码
        //         const fullId = regionItem.areaId.padStart(9, '0');
        //         // 国家码
        //         const countryId = fullId.slice(0, 3);
        //         // 省码
        //         const provinceId = fullId.slice(3, 5);
        //         // 市码
        //         // const cityId = fullId.slice(5, 7);
        //
        //         // 国外 省
        //         if (fullId.slice(5, 9) === '0000') {
        //             province[`${countryId}${provinceId}`] = { ...regionItem, ...province[`${countryId}${provinceId}`] ? province[`${countryId}${provinceId}`] : {} };
        //             delete province[`${countryId}${provinceId}`].children;
        //             country[countryId].children.push(province[`${countryId}${provinceId}`]);
        //         }
        //     });
        //
        //     return country;
        // },
        countryRegion(_, getters) {
            return getters.tidyAllRegionList || {};
        },

        // chineseCity(state) {
        //     const tmp = JSON.stringify(state.regionList)
        //         .replace(/cities/g, 'children')
        //         .replace(/counties/g, 'children');
        //     return JSON.parse(tmp).map((item) => {
        //         if (item.areaId.length > 6) return false;
        //         return item;
        //     }).filter(Boolean);
        // },

        chineseCity(_, getters) {
            return getters.tidyAllRegionList['156']?.children || [];
        },

        tidyAllRegionList(state) {
            // 字段名称转换
            const tmp = JSON.parse(JSON.stringify(state.allRegionList)
                .replace(/"c":/g, '"areaId":')
                .replace(/"n":/g, '"areaName":'));

            // 国家级
            const country = {};

            // 省级
            const province = {};

            // 市级
            const city = {};

            // 县级
            const county = {};

            // 遍历
            tmp.forEach((regionItem) => {
                // 字符串化 -- 当前区域代码
                const areaId = String(regionItem.areaId);
                // 是中国区域
                const isChina = areaId.length === 6;
                // 是国家级code
                const isCountry = areaId.length < 6;
                // 父级code
                const parentId = String(regionItem.p);
                const parentFullId = isChina ? `156${parentId}` : parentId.padStart(9, '0');

                // 国家
                if (isCountry) {
                    const fullCountryId = areaId.padStart(3, '0');
                    country[fullCountryId] = { ...regionItem, ...country[fullCountryId] ? country[fullCountryId] : { } };
                    return;
                }

                // 完整 区域码 - xxx , yy, zz, vv
                const fullId = isChina ? `156${areaId}` : areaId.padStart(9, '0');
                // 国家码 -- xxx
                const countryId = fullId.slice(0, 3);
                // 省码 -- yy
                const provinceId = fullId.slice(3, 5);
                // 市码 -- zz
                const cityId = fullId.slice(5, 7);
                // 县码 -- vv
                // const countyId = fullId.slice(7, 9);

                // 是省级
                const isProvince = fullId.slice(-4) === '0000';
                if (isProvince) {
                    province[fullId] = { ...regionItem, ...(province[fullId] ? province[fullId] : {}) };

                    if (!country[countryId]) {
                        country[countryId] = { children: [] };
                    }
                    if (!country[countryId].children) {
                        country[countryId].children = [];
                    }
                    country[countryId].children.push(province[fullId]);
                    return;
                }

                // 是市
                const isCity = fullId.slice(-2) === '00';
                if (isCity) {
                    const provinceCode = `${countryId}${provinceId}`.padEnd(9, '0');
                    city[fullId] = { ...regionItem, ...(city[fullId] ? city[fullId] : { }) };
                    if (!province[provinceCode]) {
                        province[provinceCode] = { children: [] };
                    }
                    if (!province[provinceCode].children) {
                        province[provinceCode].children = [];
                    }
                    province[provinceCode].children.push(city[fullId]);
                    return;
                }

                {
                    const cityCode = `${countryId}${provinceId}${cityId}`.padEnd(9, '0');
                    // 是县
                    county[fullId] = { ...regionItem, ...(county[fullId] ? county[fullId] : {}) };

                    // 某些县是直接属省的
                    if (!province[parentFullId]) {
                        province[parentFullId] = { children: [] };
                    }

                    if (!province[parentFullId].children) {
                        province[parentFullId].children = [];
                    }
                    province[parentFullId].children.push(county[fullId]);

                    // 属于市
                    if (!city[cityCode]) {
                        city[cityCode] = { children: [] };
                    }
                    if (!city[cityCode].children) {
                        city[cityCode].children = [];
                    }
                    city[cityCode].children.push(county[fullId]);
                }
            });

            // console.timeEnd('aa');
            return country;
        },

        droneTrackList(state) {
            return Object.values(state.droneTrackList);
        },
    },

    mutations: {
        [types.UPDATE_LIST](state, { type, list }) {
            state[`${type}List`] = list;
        },
        [types.UPDATE_DETAIL](state, { type, detail }) {
            state[`${type}Detail`] = detail;
        },
        [types.UPDATE_PAGE_SIZE](state, { type, size }) {
            state[`${type}PageSize`] = size;
        },
        [types.UPDATE_CURRENT_PAGINATION](state, { type, pagination }) {
            state[`${type}Pagination`] = pagination;
        },
        [types.UPDATE_FILTERS](state, { type, filters }) {
            state[`${type}Filters`] = filters;
        },
        [types.UPDATE_TYPE](state, { type, theType }) {
            state[`${type}Type`] = theType;
        },
        [types.UPDATE_LOADING_STATUS](state, { type, isLoading }) {
            state[`${type}Loading`] = isLoading;
        },
        [types.UPDATE_SUBJECT](state, { subject, data }) {
            state[subject] = data;
        },
    },

    actions: {
        // manufacturer: 列表
        getManufacturerList({ commit }, { accountCatalogLevel, isAll }) {
            const type = 'manufacturer';

            return new Promise((resolve, reject) => {
                commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                Vue.http.get(urls.GET_MANUFACTURER_SELECT_LIST, {
                    params: { accountCatalogLevel, isAll },
                }).then((response) => {
                    commit(types.UPDATE_LIST, { type, list: response.data });
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    resolve(response);
                }).catch((error) => {
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    reject(error);
                });
            });
        },

        getDroneByAccount({ state }) {
            return new Promise((resolve, reject) => {
                Vue.http.get(urls.GET_DRONE_BY_ACCOUNT).then((response) => {
                    state.rentalDrone = response?.data || [];
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        // operator: 列表
        getOperatorList({ commit }) {
            const type = 'operator';

            return new Promise((resolve, reject) => {
                commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                Vue.http.get(urls.GET_OPERATOR_SELECT_LIST).then((response) => {
                    commit(types.UPDATE_LIST, { type, list: response.data });
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    resolve(response);
                }).catch((error) => {
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    reject(error);
                });
            });
        },

        // droneModel: 列表
        getDroneModelList({ commit }, id) {
            const type = 'droneModel';

            return new Promise((resolve, reject) => {
                commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                const url = helpers.buildURL(urls.GET_DRONE_MODEL_SELECT_LIST, id);
                Vue.http.get(url).then((response) => {
                    commit(types.UPDATE_LIST, { type, list: response.data });
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    resolve(response);
                }).catch((error) => {
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    reject(error);
                });
            });
        },

        // droneModel: 清空列表
        clearDroneModelList({ commit }) {
            const type = 'droneModel';
            commit(types.UPDATE_LIST, { type, list: {} });
        },

        // bulletin
        getUnreadBulletin({ commit }) {
            const type = 'bulletin';

            return new Promise((resolve, reject) => {
                commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                Vue.http.get(urls.GET_UNREAD_BULLETINS).then((response) => {
                    commit(types.UPDATE_LIST, { type, list: response.data });
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    resolve(response);
                }).catch((error) => {
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    reject(error);
                });
            });
        },

        // 更新农机所属
        updateDroneOwner(store, { droneIds, zzAccountId }) {
            return new Promise((resolve, reject) => {
                Vue.http.post(urls.UPDATE_DRONE_OWNER, { droneIds, zzAccountId }).then((response) => {
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        // 更新农机所属
        requestMergeManufacturer(store, { zzAccountId, targetZzAccountId }) {
            return new Promise((resolve, reject) => {
                Vue.http.put(urls.REQUEST_MERGE_MANUFACTURER, { zzAccountId, targetZzAccountId }).then((response) => {
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        // 请求续费
        requestRenewRTKAvailableDays(store, { phone, availableDays }) {
            const url = helpers.buildURL(urls.RENEW_RTK_AVAILABLE_DAYS, availableDays);
            return new Promise((resolve, reject) => {
                Vue.http.post(url, [phone]).then((response) => {
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        // 请求续费 - 2
        requestRenewRTKAvailableDaysWithBtaddr(store, { btaddr, availableDays }) {
            // const url = helpers.buildURL(urls.RENEW_RTK_AVAILABLE_DAYS, availableDays);
            return new Promise((resolve, reject) => {
                Vue.http.post(urls.RENEW_RTK_AVAILABLE_DAYS_WITH_BTADDR, {
                    btaddr,
                    availableDays,
                }).then((response) => {
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        // country
        getCountryList({ commit }) {
            const type = 'country';

            return new Promise((resolve, reject) => {
                commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                Vue.http.get(urls.GET_COUNTRY_LIST).then((response) => {
                    commit(types.UPDATE_LIST, { type, list: response.data });
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    resolve(response);
                }).catch((error) => {
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    reject(error);
                });
            });
        },

        // region
        getAllRegionList({ commit, rootGetters }) {
            const type = 'allRegion';

            return new Promise((resolve, reject) => {
                commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                // Vue.http.get(urls.GET_ALL_REGION_LIST).then((response) => {
                if (auth.getToken()) {
                    Vue.http.get(urls.GET_ALL_REGION_LIST).then((response) => {
                        commit(types.UPDATE_LIST, { type, list: response.data });
                        commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                        resolve(response);
                    }).catch((error) => {
                        commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                        reject(error);
                    });
                } else {
                    Vue.axios.get(urls.GET_ALL_REGION_LIST, {
                        headers: {
                            'Accept-Language': rootGetters['lang/rfc'],
                        },
                    }).then((response) => {
                        commit(types.UPDATE_LIST, { type, list: response.data });
                        commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                        resolve(response);
                    }).catch((error) => {
                        commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                        reject(error);
                    });
                }
            });
        },

        // region
        getRegionList({ commit }) {
            const type = 'region';

            return new Promise((resolve, reject) => {
                commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                Vue.http.get(urls.GET_CHINA_REGION_LIST).then((response) => {
                    commit(types.UPDATE_LIST, { type, list: response.data });
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    resolve(response);
                }).catch((error) => {
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    reject(error);
                    // return error;
                });
            });


            // return new Promise((resolve, reject) => {
            //     commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
            //     Vue.http.get(urls.GET_CHINA_REGION_LIST).then((response) => {
            //         commit(types.UPDATE_LIST, { type, list: response.data });
            //         commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
            //         resolve(response);
            //     }).catch((error) => {
            //         commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
            //         reject(error);
            //     });
            // });
        },

        // workType
        getWorkTypeList({ commit }) {
            const type = 'workType';

            return new Promise((resolve, reject) => {
                commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                Vue.http.get(urls.GET_WORK_TYPE_LIST).then((response) => {
                    commit(types.UPDATE_LIST, { type, list: response.data });
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    resolve(response);
                }).catch((error) => {
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    reject(error);
                });
            });
        },

        // droneTrack
        clearDroneTrackCache({ commit }) {
            commit(types.UPDATE_SUBJECT, { subject: 'droneTrackList', data: {} });
        },

        // droneTrack（注意：在同一处调用此action前需要先调用clearDroneTrackCache）
        getDroneTrackList({ commit, state }, { sortieIds, cancelToken }) {
            const type = 'droneTrack';
            const subject = `${type}List`;

            const cached = state[subject];
            const ids = sortieIds.filter(id => !(id in cached));
            if (!ids.length) return Promise.reject(new Error());

            return new Promise((resolve, reject) => {
                commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                // Vue.http.get(urls.GET_TRACK_PURE_LIST, {
                //     timeout: 0,
                //     headers: { ids: ids.toString() },
                // }).then((response) => {
                Vue.http.post(urls.GET_TRACK_PURE_LIST, {
                    // timeout: 0,
                    // headers: { ids: ids.toString() },
                    ids: ids.toString(),
                }, { timeout: 0, cancelToken }).then((response) => {
                    const list = (response.data || []).map(item => ({ [item.id]: item }));
                    const data = {
                        ...state[subject],
                        ...Object.assign({}, ...list),
                    };
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    commit(types.UPDATE_SUBJECT, { subject, data });
                    resolve(response);
                }).catch((error) => {
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    reject(error);
                });
            });
        },

        // end

        // crop
        getCropsList({ commit }, { pageIndex = 1, size = 1000 } = {}) {
            const type = 'crop';

            return new Promise((resolve, reject) => {
                commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                Vue.http.get(urls.GET_CROP_LIST, { params: { pageIndex, size } }).then((response) => {
                    commit(types.UPDATE_LIST, { type, list: response.data.list });
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    resolve(response);
                }).catch((error) => {
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    reject(error);
                });
            });
        },
    },
};
