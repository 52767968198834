/**
 * 公共表单验证器
 */

/**
 * 验证原密码
 * 约定：假设原密码字段名为“password”，那么确认密码的字段名必须为“passwordConfirmation”
 */
export const validatePassword = ($this, formName) => (rule, value, callback) => {
    if (!/^(?=.*\d)(?=.*[a-zA-Z])[a-zA-Z0-9]{8,16}$/.test(value)) {
        callback(new Error('密码必须为8~16位的字母、数字组合!'));
    }
    const { field: password } = rule;
    const passwordConfirmation = `${password}Confirmation`;
    if ($this[formName][passwordConfirmation] !== '') {
        $this.$refs[formName].validateField(passwordConfirmation);
    }
    callback();
};

/*
 * 验证确认密码
 * 约定：假设确认密码的字段名为“passwordConfirmation”，那么原密码的字段名就为“password”
 */
export const validatePasswordCheck = ($this, formName) => (rule, value, callback) => {
    if (!/^(?=.*\d)(?=.*[a-zA-Z])[a-zA-Z0-9]{8,16}$/.test(value)) {
        callback(new Error('密码必须为8~16位的字母、数字组合!'));
    }
    const { field: passwordConfirmation } = rule;
    const password = passwordConfirmation.replace('Confirmation', '');
    if (value !== $this[formName][password]) {
        callback(new Error('两次输入密码不一致!'));
    } else {
        callback();
    }
};

/**
 * 验证用户账号是否为手机号码或Email
 */
export const validateAccountNumber = (rule, value, callback) => {
    const { messages: [phoneMsg, emailMsg] = [] } = rule;
    // const phoneRe = /^1[3-9]\d{9}$/g;
    // 泰国 手机号 9 - 10位， 中国 手机号 11 位
    // const phoneRe = /^\d{9,11}$/g;
    const phoneRe = /^\d+$/g;
    const mailRe = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isPhone = phoneRe.test(value);
    const isEmail = mailRe.test(value);
    if (isPhone || isEmail) {
        callback();
    }
    if (/^\d+$/.test(value)) {
        callback(new Error(phoneMsg));
    } else {
        callback(new Error(emailMsg));
    }
};
