/**
 * 基础页面路由
 */

const Login = () => import(/* webpackChunkName: "chunk!login" */'../views/BasePages/Login');
const Register = () => import(/* webpackChunkName: "chunk!register" */'../views/BasePages/Register.vue');
const RetrievePassword = () => import(/* webpackChunkName: "chunk!retrieve-password" */'../views/BasePages/RetrievePassword.vue');

export default [
    {
        path: '/login',
        name: 'Login',
        component: Login,
    },
    {
        path: '/register',
        name: 'Register',
        component: Register,
    },
    {
        path: '/retrieve-password',
        name: 'RetrievePassword',
        component: RetrievePassword,
    },
];
