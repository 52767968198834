/**
 * workRecord
 */

import Vue from 'vue';
import * as types from './mutation-types';
import * as urls from '../config/urls';
// import * as helpers from "../utils/helpers";
// import * as helpers from '../utils/helpers';

export default {
    namespaced: true,

    state: {
        workRecordList: {},
        workRecordLoading: false,
        workRecordPagination: 1,
        workRecordPageSize: 20,
        workRecordFilters: {
            accountId: undefined,
        },
        workRecordDetail: {},
        // ACTIVE_STATUS_MAP: {
        //     0: 'debugging',
        //     1: 'unactivated',
        //     2: 'activated',
        // },
        // workRecordModel
        // workRecordModelList: {},
    },

    getters: {
        // stat(state) {
        //     const { stat: { total = 0, workArea = 0, zzNum = 0, sortieCount = 0, modelNum = 0 } = {} } = state.workRecordList;
        //     return { total, workArea, zzNum, sortieCount, modelNum };
        // },
        //
        // activeStatusLabel: state => (status) => {
        //     const { [status]: label } = state.ACTIVE_STATUS_MAP;
        //     return label || '-';
        // },
    },

    mutations: {
        [types.UPDATE_LIST](state, { type, list }) {
            state[`${type}List`] = list;
        },
        [types.UPDATE_DETAIL](state, { type, detail }) {
            state[`${type}Detail`] = detail;
        },
        [types.UPDATE_PAGE_SIZE](state, { type, size }) {
            state[`${type}PageSize`] = size;
        },
        [types.UPDATE_CURRENT_PAGINATION](state, { type, pagination }) {
            state[`${type}Pagination`] = pagination;
        },
        [types.UPDATE_FILTERS](state, { type, filters }) {
            state[`${type}Filters`] = filters;
        },
        [types.UPDATE_LOADING_STATUS](state, { type, isLoading }) {
            state[`${type}Loading`] = isLoading;
        },
    },

    actions: {
        getWorkRecordList({ commit, state }, pagination = 1) {
            const type = 'workRecord';

            return new Promise((resolve, reject) => {
                commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                Vue.http.get(urls.GET_WORK_RECORD_LIST, {
                    params: {
                        pageIndex: pagination,
                        size: state[`${type}PageSize`],
                        ...state[`${type}Filters`],
                    },
                }).then(({ data }) => {
                    console.log('records', data);
                    commit(types.UPDATE_LIST, { type, list: data });
                    commit(types.UPDATE_CURRENT_PAGINATION, { type, pagination });
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    resolve(data);
                }).catch((error) => {
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    reject(error);
                });
            });
        },

        // createWorkRecord({ dispatch, state }, formData) {
        //     const type = 'workRecord';
        //     const Type = type.replace(/^\w/, _ => _.toUpperCase());
        //
        //     return new Promise((resolve, reject) => {
        //         Vue.http.post(urls.CREATE_LEASE, formData).then((response) => {
        //             dispatch(`get${Type}List`, state[`${type}Pagination`]);
        //             resolve(response);
        //         }).catch((error) => {
        //             reject(error);
        //         });
        //     });
        // },
        //
        // // 更变分页Size
        changeWorkRecordListPageSize({ commit, dispatch, state }, size) {
            const type = 'workRecord';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            commit(types.UPDATE_PAGE_SIZE, { type, size });
            dispatch(`get${Type}List`, state[`${type}Pagination`]);
        },
        //
        // // 更新过滤条件
        changeWorkRecordListFilters({ commit, dispatch, state }, filters) {
            const type = 'workRecord';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            commit(types.UPDATE_FILTERS, { type, filters });
            dispatch(`get${Type}List`, state[`${type}Pagination`]);
        },
        //
        // // 获取详情
        // getWorkRecordDetail({ commit }, phone) {
        //     const type = 'workRecord';
        //
        //     return new Promise((resolve, reject) => {
        //         Vue.http.get(urls.GET_CLIENT_INFO_BY_PHONE, {
        //             params: {
        //                 phone,
        //             },
        //         }).then((response) => {
        //             commit(types.UPDATE_DETAIL, { type, detail: response.data || {} });
        //             resolve(response);
        //         }).catch((error) => {
        //             reject(error);
        //         });
        //     });
        // },

        // getWorkRecordDetail({ commit }, workRecordId) {
        //     const type = 'workRecord';
        //
        //     return new Promise((resolve, reject) => {
        //         Vue.http.get(urls.GET_CLIENT_INFO_BY_PHONE, {
        //             params: {
        //                 workRecordId,
        //             },
        //         }).then((response) => {
        //             commit(types.UPDATE_DETAIL, { type, detail: response.data });
        //             resolve(response);
        //         }).catch((error) => {
        //             reject(error);
        //         });
        //     });
        // },

        //
        // // 清空农机详情数据
        // clearWorkRecordDetail({ commit }) {
        //     const type = 'workRecord';
        //
        //     commit(types.UPDATE_DETAIL, { type, detail: {} });
        // },
        //
        // // 更新农机数据
        // returnDrone({ dispatch, commit, state }, { id, returnTime }) {
        //     const type = 'workRecord';
        //     const Type = type.replace(/^\w/, _ => _.toUpperCase());
        //
        //     return new Promise((resolve, reject) => {
        //         commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
        //         Vue.http.post(urls.RETURN_DRONE_OF_LEASE, { id, returnTime }).then((response) => {
        //             dispatch(`get${Type}List`, state[`${type}Pagination`]);
        //             commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
        //             resolve(response);
        //         }).catch((error) => {
        //             commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
        //             reject(error);
        //         });
        //     });
        // },
        //
        // cancelWorkRecord({ dispatch, commit, state }, { id }) {
        //     const type = 'workRecord';
        //     const Type = type.replace(/^\w/, _ => _.toUpperCase());
        //
        //     return new Promise((resolve, reject) => {
        //         commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
        //         Vue.http.post(urls.CANCEL_LEASE, { id }).then((response) => {
        //             dispatch(`get${Type}List`, state[`${type}Pagination`]);
        //             commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
        //             resolve(response);
        //         }).catch((error) => {
        //             commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
        //             reject(error);
        //         });
        //     });
        // },

        //
        // // 编辑农机制造商标识
        // updateManufacturerSign({ dispatch, commit, state }, { workRecordId, zzworkRecordNum }) {
        //     const type = 'workRecord';
        //     const Type = type.replace(/^\w/, _ => _.toUpperCase());
        //
        //     return new Promise((resolve, reject) => {
        //         commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
        //         const url = helpers.buildURL(urls.UPDATE_MANUFACTURER_SIGN, workRecordId);
        //         Vue.http.put(url, { zzworkRecordNum }).then((response) => {
        //             dispatch(`get${Type}List`, state[`${type}Pagination`]);
        //             commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
        //             resolve(response);
        //         }).catch((error) => {
        //             commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
        //             reject(error);
        //         });
        //     });
        // },
        //
        // // 更新农机锁定状态
        // updateWorkRecordLock({ dispatch, commit, state }, { workRecordId, opr, password }) {
        //     const type = 'workRecord';
        //     const Type = type.replace(/^\w/, _ => _.toUpperCase());
        //
        //     return new Promise((resolve, reject) => {
        //         commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
        //         Vue.http.put(urls.UPDATE_workRecord_LOCK, { workRecordId, opr, password }).then((response) => {
        //             dispatch(`get${Type}List`, state[`${type}Pagination`]);
        //             commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
        //             resolve(response);
        //         }).catch((error) => {
        //             commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
        //             reject(error);
        //         });
        //     });
        // },
        //
        // // 更新农机销售状态
        // updateWorkRecordSaleStatus({ dispatch, commit, state }, { workRecordId, saleType }) {
        //     const type = 'workRecord';
        //     const Type = type.replace(/^\w/, _ => _.toUpperCase());
        //
        //     return new Promise((resolve, reject) => {
        //         commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
        //         const url = helpers.buildURL(urls.UPDATE_workRecord_SALE_STATUS, workRecordId);
        //         Vue.http.put(url, { saleType }).then((response) => {
        //             dispatch(`get${Type}List`, state[`${type}Pagination`]);
        //             commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
        //             resolve(response);
        //         }).catch((error) => {
        //             commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
        //             reject(error);
        //         });
        //     });
        // },
        //
        // // 获取农机型号下拉列表
        // getWorkRecordModelList({ commit }) {
        //     const type = 'workRecordModel';
        //
        //     return new Promise((resolve, reject) => {
        //         Vue.http.get(urls.GET_OWN_workRecord_MODEL_SELECT_LIST).then((response) => {
        //             commit(types.UPDATE_LIST, { type, list: response.data });
        //             resolve(response);
        //         }).catch((error) => {
        //             reject(error);
        //         });
        //     });
        // },
        //
        // // 更新农机类型
        // updateDeviceType({ dispatch, state }, { workRecordId, deviceType, sprayWidth }) {
        //     const type = 'workRecord';
        //     const Type = type.replace(/^\w/, _ => _.toUpperCase());
        //
        //     return new Promise((resolve, reject) => {
        //         const url = helpers.buildURL(urls.UPDATE_DEVICE_TYPE, workRecordId);
        //         Vue.http.put(url, { deviceType, sprayWidth }).then((response) => {
        //             dispatch(`get${Type}List`, state[`${type}Pagination`]);
        //             resolve(response);
        //         }).catch((error) => {
        //             reject(error);
        //         });
        //     });
        // },

        // end
    },
};
