<template>
    <div class="columns is-mobile is-gapless is-vcentered ui-root">
        <div class="column" v-if="!isPersonalOperator">
            <img :src="accountLogo" class="ui-logo" alt="logo" v-if="accountLogo">
            <img src="/images/logo.png" class="ui-logo" alt="logo" v-else>
            <span class="ui-title">{{ getUserInfo('accountName') }}</span>
        </div>
        <div class="column" v-else></div>
        <NavMenu class="column is-narrow"></NavMenu>
        <el-dropdown class="column is-narrow" placement="bottom" @command="handleClickDropdownItem">
            <div class="columns is-mobile is-gapless is-vcentered ui-user-info">
                <div class="column is-narrow">
                    <img :src="userHeadUrl" class="ui-avatar" alt="avatar" v-if="userHeadUrl">
                    <i class="iconfont icon-user" v-else></i>
                </div>
                <div class="column is-narrow">
                    <p>{{ getUserInfo('userName') }}（{{ roleName || '' }}）</p>
                    <p>{{ phoneNum }}</p>
                </div>
            </div>
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="setting"><i class="iconfont icon-settings"></i><span>修改资料</span></el-dropdown-item>
                <el-dropdown-item command="logout" divided><i class="iconfont icon-pwoer-off"></i><span>退出登录</span></el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
        <ProfileEdit :visible.sync="showProfileEditor"></ProfileEdit>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import NavMenu from './NavMenu.vue';
    import ProfileEdit from './ProfileEdit.vue';
    import menuMixin from '../../mixins/menuMixin';
    import logoutMixin from '../../mixins/logoutMixin';

    export default {
        name: 'Header',

        components: { NavMenu, ProfileEdit },

        mixins: [menuMixin, logoutMixin],

        data() {
            return {
                showProfileEditor: false,
            };
        },

        computed: {
            ...mapGetters('user', ['userTypeLabel', 'phoneNum', 'getUserInfo', 'isPersonalOperator']),
            accountLogo() {
                return this.getUserInfo('accountLogo');
            },
            userHeadUrl() {
                return this.getUserInfo('userHeadUrl');
            },
            roleName() {
                const userType = this.getUserInfo('userType');
                return this.userTypeLabel(userType);
            },
        },

        methods: {
            handleClickDropdownItem(command) {
                switch (command) {
                    case 'setting':
                        this.showProfileEditor = true;
                        break;
                    case 'logout':
                        this.handleLogout();
                        break;
                    default:
                        break;
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    .ui-root {
        height: inherit;
        align-items: center;
    }

    .ui-logo {
        max-height: 60px;
    }

    .ui-title {
        margin-left: 20px;
        font-size: 20px;
        color: white;
        vertical-align: middle;
    }

    .el-dropdown {
        margin-left: 20px !important;
        i + span {
            vertical-align: middle;
            margin-left: 5px;
        }
    }

    .ui-user-info {
        font-size: 14px;
        color: white;
        margin: 0 !important;
        .column + .column {
            margin-left: 5px;
        }
        .icon-user {
            font-size: 40px;
        }
        .ui-avatar {
            width: 32px;
            height: 32px;
            border-radius: 50%;
        }
    }
</style>
