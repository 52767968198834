/**
 * 业务管理
 */

const UnifiedTaskList = () => import(/* webpackChunkName: "chunk!unified-task-list" */'../views/UnifiedTaskManage/UnifiedTaskList.vue');
const UnifiedTaskDroneList = () => import(/* webpackChunkName: "chunk!unified-task-drone-list" */'../views/UnifiedTaskManage/UnifiedTaskDroneList.vue');
const UnifiedTaskChart = () => import(/* webpackChunkName: "chunk!unified-task-chart" */'../views/UnifiedTaskManage/UnifiedTaskChart.vue');
const SubsidyList = () => import(/* webpackChunkName: "chunk!subsidy-list" */'../views/SubsidyManage/SubsidyList.vue');
const RtkList = () => import(/* webpackChunkName: "chunk!rtk-list" */'../views/RtkManage/RtkList.vue');
const BlockList = () => import(/* webpackChunkName: "chunk!rtk-list" */'../views/BlockManage/BlockList.vue');
const GroupControl = () => import(/* webpackChunkName: "chunk!group-control" */'../views/GroupControl/GroupControl.vue');
const FlightControl = () => import(/* webpackChunkName: "chunk!flight-control" */'../views/FlightControl/FlightControl.vue');

const group = 'business_manage';

export default [
    {
        path: `/${group}/unified-task-list`,
        name: 'UnifiedTaskList',
        component: UnifiedTaskList,
        meta: { group },
    },
    {
        path: `/${group}/unified-task-list/:id(\\d+)/drone-list`,
        name: 'UnifiedTaskDroneList',
        component: UnifiedTaskDroneList,
        meta: { group },
    },
    {
        path: `/${group}/unified-task-list/:id(\\d+)/chart`,
        name: 'UnifiedTaskChart',
        component: UnifiedTaskChart,
        meta: { group },
    },
    {
        path: `/${group}/subsidy-list`,
        name: 'SubsidyList',
        component: SubsidyList,
        meta: { group },
    },
    {
        path: `/${group}/rtk-list`,
        name: 'RtkList',
        component: RtkList,
        meta: { group },
    },
    {
        path: `/${group}/block-list`,
        name: 'BlockList',
        component: BlockList,
        meta: { group },
    },
    {
        path: `/${group}/group-control`,
        name: 'GroupControl',
        component: GroupControl,
        meta: { group },
    },
    {
        path: `/${group}/flight-control`,
        name: 'FlightControl',
        component: FlightControl,
        meta: { group },
    },
];
