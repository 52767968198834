/**
 * themeStore
 */
import * as theme from '../config/themes';

const {
    VUE_APP_TITLE,
    VUE_APP_TH_TITLE = '',
    VUE_APP_EN_TITLE = '',
    VUE_APP_KO_TITLE = '',
    VUE_APP_ZH_TW_TITLE = '',
    VUE_APP_THEME = 'default',
    VUE_APP_LOGO_FILENAME,
    VUE_APP_COPYRIGHT = '',
} = process.env;
const { AppTitle, AppThTitle, AppEnTitle, AppKoTitle, AppTwTitle } = window;

const { [VUE_APP_THEME.toUpperCase()]: themeOptions = {} } = theme;

export default {
    namespaced: true,

    state: {
        isProxyMode: !!AppTitle,
        title: AppTitle || VUE_APP_TITLE,
        thTitle: AppThTitle || VUE_APP_TH_TITLE,
        enTitle: AppEnTitle || VUE_APP_EN_TITLE,
        koTitle: AppKoTitle || VUE_APP_KO_TITLE,
        tWTitle: AppTwTitle || VUE_APP_ZH_TW_TITLE,
        logoFilename: VUE_APP_LOGO_FILENAME,
        copyright: VUE_APP_COPYRIGHT,
        loginLayout: 'Primary',
        mainLayout: 'Primary',
        dataStatInlineLayout: false,
        showWorkbench: false,
        ...themeOptions,
    },
};
