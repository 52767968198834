<template>
    <div>
        <div class="ui-toggle-button" @click="handleChangeCollapse">
            <i :class="toggleButtonIcon"></i>
        </div>
        <component
            :is="currentAccountMenu"
            mode="vertical"
            :default-openeds="[openedMenu]"
            :default-active="activeItem"
            background-color="#0f619b"
            text-color="#ffffff"
            active-text-color="#ffd04b"
            unique-opened
            :collapse="isCollapse"
            :collapse-transition="false"
            router
            showLogout>
        </component>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import AccountMenu1 from './AccountMenu1.vue';
    import AccountMenu2 from './AccountMenu2.vue';
    import AccountMenu3 from './AccountMenu3.vue';
    import AccountMenu4 from './AccountMenu4.vue';
    import menuMixin from '../../mixins/menuMixin';
    import commonRefs from '../../utils/commonRefs';

    export default {
        name: 'SideMenu',

        components: { AccountMenu1, AccountMenu2, AccountMenu3, AccountMenu4 },

        mixins: [menuMixin],

        data() {
            return {
                isCollapse: false,
            };
        },

        computed: {
            ...mapGetters('user', ['getUserInfo']),

            toggleButtonIcon() {
                return this.isCollapse ? 'iconfont icon-expanded' : 'iconfont icon-collapsed';
            },

            currentAccountMenu() {
                const accountType = this.getUserInfo('accountType');
                return accountType ? `AccountMenu${accountType}` : '';
            },
        },

        mounted() {
            this.initStatus();
        },

        methods: {
            initStatus() {
                const asideStatus = localStorage.getItem('aside-status');
                this.isCollapse = asideStatus === 'closed';
            },

            handleChangeCollapse() {
                this.isCollapse = !this.isCollapse;
                localStorage.setItem('aside-status', this.isCollapse ? 'closed' : 'opened');
                commonRefs.getRef('map').then((map) => {
                    map.resize();
                });
            },
        },
    };
</script>

<style lang="scss" scoped>
    .ui-toggle-button {
        width: 100%;
        color: white;
        border-radius: 0;
        padding-top: 5px;
        padding-bottom: 5px;
        text-align: center;
        box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.1);
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        position: relative;
        z-index: 1;
        cursor: pointer;
        i {
            font-size: 20px;
        }
    }
</style>
