<template>
    <div :class="$style.root">
        <svg xmlns="http://www.w3.org/2000/svg" :viewBox="`0 0 ${w} ${h}`">
            <defs>
                <linearGradient id="box-lg" x1="50%" y1="0" x2="50%" y2="100%" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stop-color="#00c0fa" />
                    <stop offset="1" stop-color="#015eea" />
                </linearGradient>
            </defs>
            <path fill="url(#box-lg)" :d="`M${w},${h}H0V0H${w}ZM2,${h-2}H${w-2}V2H2Z`" />
        </svg>
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: 'Box',

        data() {
            return {
                w: 1920,
                h: 1080,
            };
        },

        computed: {},

        mounted() {
            this.init();
            window.addEventListener('resize', this.init);
        },

        methods: {
            init() {
                const { width, height } = this.$el.getBoundingClientRect();
                this.w = width;
                this.h = height;
            },
        },
    };
</script>

<style lang="scss" module>
    .root {
        position: relative;
        color: white;

        :global {
            svg {
                position: absolute;
                left: 0;
                top: 0;
                pointer-events: none;
            }
        }
    }
</style>
