import axios, { CanceledError } from 'axios';
import * as auth from '../utils/auth';
import { readJsonFromBlob } from '../utils/reader';
import requestTerminator from '../utils/requestTerminator';

const REQUEST_TIMEOUT = 60000;
const UNAUTHORIZED_ROUTE_NAME = 'Login';

const $axios = axios.create({
    timeout: REQUEST_TIMEOUT,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Terminal-Type': 'web',
    },
    withCredentials: true,
});
const http = axios.create({
    timeout: REQUEST_TIMEOUT,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Terminal-Type': 'web',
    },
    withCredentials: true,
});

function routeRedirect(router) {
    if (UNAUTHORIZED_ROUTE_NAME !== router.currentRoute.name) {
        router.replace({
            name: UNAUTHORIZED_ROUTE_NAME,
        });
    }
}

export default {
    install(Vue, { router, store }) {
        // 需要认证的接口（自动跳转或添加认证token）
        const requestInterceptor = (config) => {
            if (!auth.checkToken()) {
                routeRedirect(router);
                return null;
            }
            store.dispatch('loading/uploadLoading', { isLoading: true });
            const conf = config;

            conf.signal = requestTerminator.addController().signal;
            conf.requestId = requestTerminator.requestId;

            const token = auth.getToken();
            conf.headers.Authorization = `Bearer ${token}`;
            conf.headers['Accept-Language'] = store.getters['lang/rfc'];
            return conf;
        };

        const responseInterceptor = (response) => {
            store.dispatch('loading/uploadLoading', { isLoading: false });
            const { requestId } = response.config || {};
            requestTerminator.removeController(requestId);
            return response;
        };

        const errorHandler = (error) => {
            store.dispatch('loading/uploadLoading', { isLoading: false });
            if (error instanceof CanceledError) {
                console.log('请求被取消');
                return Promise.reject(new Error());
            }
            const { response: { data } = {}, request } = error;
            const { status } = data || request || {};
            if (status === 401) {
                routeRedirect(router);
            }
            if (error.response) {
                return Promise.reject(error.response.data);
            } else if (error.request) {
                return Promise.reject(error.request);
            }
            return Promise.reject(error);
        };

        http.interceptors.request.use(requestInterceptor, errorHandler);
        http.interceptors.response.use(responseInterceptor, errorHandler);
        $axios.interceptors.response.use(responseInterceptor, errorHandler);

        // Vue.axios用于请求那些不需要登录认证的接口
        Vue.axios = $axios;
        // Vue.http用于请求需要登录认证的接口
        Vue.http = http;
        // Vue.download用于下载文件
        Vue.prototype.download = (url, queries = {}) => new Promise((resolve, reject) => {
            http.get(url, {
                responseType: 'blob',
                params: queries,
            }).then((response) => {
                resolve(response);
            }).catch(async (error) => {
                if (error instanceof Blob) {
                    const result = await readJsonFromBlob(error);
                    resolve(result);
                }
                reject(error);
            });
        });
    },
};
