/*
 * 数据统计大屏
 * caf = china and foreign
 */

import Vue from 'vue';
import moment from 'moment';
import * as types from './mutation-types';
import * as urls from '../config/urls';
// import * as helpers from '../utils/helpers';

export default {
    namespaced: true,

    state: {
        // 所有喷洒面积（每小时）
        allSprayAreaPerHour: {},
        // 本年和本月喷洒面积（每小时）
        thisYearMonthSprayAreaPerHour: {},

        // 国内国外喷洒面积（所有）
        cafSprayAreaAllPeriod: {},
        // 国内国外喷洒面积（本年）
        cafSprayAreaThisYear: {},
        // 国内国外喷洒面积（本月）
        cafSprayAreaThisMonth: {},

        // 国外喷洒面积（所有）
        foreignSprayAreaAllPeriod: {},
        // 国外喷洒面积（本年）
        foreignSprayAreaThisYear: {},
        // 国外喷洒面积（本月）
        foreignSprayAreaThisMonth: {},

        // 所有时段喷洒面积（每种模式）
        allPeriodSprayAreaPerMode: {},
        // 本月喷洒面积（每种模式）
        thisMonthSprayAreaPerMode: {},
        // 上月时段喷洒面积（每种模式）
        lastMonthSprayAreaPerMode: {},
        // 本年喷洒面积（每种模式）
        thisYearSprayAreaPerMode: {},
        // 去年喷洒面积（每种模式）
        lastYearSprayAreaPerMode: {},

        // 所有时段设备喷洒面积排名
        allPeriodDeviceSprayAreaRank: [],
        // 本年设备喷洒面积排名
        thisYearDeviceSprayAreaRank: [],
        // 本月设备喷洒面积排名
        thisMonthDeviceSprayAreaRank: [],

        // 所有时段用户喷洒面积排名
        allPeriodUserSprayAreaRank: [],
        // 本年用户喷洒面积排名
        thisYearUserSprayAreaRank: [],
        // 本月用户喷洒面积排名
        thisMonthUserSprayAreaRank: [],

        // 本日统计总览
        todayOverview: {},
        // 本月统计总览
        thisMonthOverview: {},
        // 本年统计总览
        thisYearOverview: {},

        // 所有时段中国喷洒面积（各地区）
        allPeriodChinaSprayArea: {},
        // 本日中国喷洒面积（各地区）
        todayChinaSprayArea: {},
        // 本月中国喷洒面积（各地区）
        thisMonthChinaSprayArea: {},
        // 本年中国喷洒面积（各地区）
        thisYearChinaSprayArea: {},

        // 所有时段国外喷洒面积（各地区）
        allPeriodForeignSprayArea: {},
        // 本日国外喷洒面积（各地区）
        todayForeignSprayArea: {},
        // 本月国外喷洒面积（各地区）
        thisMonthForeignSprayArea: {},
        // 本年国外喷洒面积（各地区）
        thisYearForeignSprayArea: {},
    },

    getters: {},

    mutations: {
        [types.UPDATE_SUBJECT](state, { subject, data }) {
            state[subject] = data;
        },
    },

    actions: {
        // 获取所有喷洒面积（按每小时）
        getAllSprayAreaPerHour({ commit }) {
            const subject = 'allSprayAreaPerHour';

            return new Promise((resolve, reject) => {
                Vue.http.get(urls.GET_ALL_SPRAY_AREA_PER_HOUR).then((response) => {
                    const stat = response.data;
                    commit(types.UPDATE_SUBJECT, { subject, data: stat || {} });
                    resolve(response);
                }).catch(reject);
            });
        },

        // 获取本年和本月喷洒面积（按每小时）
        getThisYearMonthSprayAreaPerHour({ commit }) {
            const subject = 'thisYearMonthSprayAreaPerHour';

            return new Promise((resolve, reject) => {
                Vue.http.get(urls.GET_THIS_YEAR_MONTH_SPRAY_AREA_PER_HOUR).then((response) => {
                    const stat = response.data;
                    commit(types.UPDATE_SUBJECT, { subject, data: stat || {} });
                    resolve(response);
                }).catch(reject);
            });
        },

        // 获取国内国外喷洒面积（按每个时间段）
        getCafSprayAreaPerPeriod({ commit }, { periodType }) {
            const { [periodType]: subject } = {
                1: 'cafSprayAreaAllPeriod',
                2: 'cafSprayAreaThisYear',
                3: 'cafSprayAreaThisMonth',
            };

            return new Promise((resolve, reject) => {
                Vue.http.get(urls.GET_CHINA_AND_FOREIGN_SPRAY_AREA_PER_PERIOD, {
                    params: { periodType },
                }).then((response) => {
                    const stat = response.data;
                    commit(types.UPDATE_SUBJECT, { subject, data: stat || {} });
                    resolve(response);
                }).catch(reject);
            });
        },

        // 获取国外喷洒面积（按每个时间段）
        getForeignSprayAreaPerPeriod({ commit }, { periodType }) {
            const { [periodType]: subject } = {
                1: 'foreignSprayAreaAllPeriod',
                2: 'foreignSprayAreaThisYear',
                3: 'foreignSprayAreaThisMonth',
            };

            return new Promise((resolve, reject) => {
                Vue.http.get(urls.GET_FOREIGN_SPRAY_AREA_PER_PERIOD, {
                    params: { periodType },
                }).then((response) => {
                    const stat = response.data;
                    commit(types.UPDATE_SUBJECT, { subject, data: stat || {} });
                    resolve(response);
                }).catch(reject);
            });
        },

        // 获取时段内喷洒面积（按每个时间段）
        getPeriodSprayAreaPerMode({ commit }, { periodType }) {
            const { [periodType]: subject } = {
                1: 'allPeriodSprayAreaPerMode',
                2: 'thisMonthSprayAreaPerMode',
                3: 'lastMonthSprayAreaPerMode',
                4: 'thisYearSprayAreaPerMode',
                5: 'lastYearSprayAreaPerMode',
            };

            const now = moment();
            const lastMonth = now.clone().subtract(1, 'months');
            const lastYear = now.clone().subtract(1, 'years');
            const { [periodType]: [startTime, endTime] } = {
                1: [undefined, undefined],
                2: [now.clone().startOf('month').valueOf(), now.clone().endOf('month').valueOf()],
                3: [lastMonth.clone().startOf('month').valueOf(), lastMonth.clone().endOf('month').valueOf()],
                4: [now.clone().startOf('year').valueOf(), now.clone().endOf('year').valueOf()],
                5: [lastYear.clone().startOf('year').valueOf(), lastYear.clone().endOf('year').valueOf()],
            };

            return new Promise((resolve, reject) => {
                Vue.http.get(urls.GET_PERIOD_SPRAY_AREA_PER_MODE, {
                    params: { startTime, endTime },
                }).then((response) => {
                    const stat = response.data;
                    commit(types.UPDATE_SUBJECT, { subject, data: stat || {} });
                    resolve(response);
                }).catch(reject);
            });
        },

        // 获取时段内设备喷洒面积排名
        getDeviceSprayAreaRank({ commit }, { periodType }) {
            const { [periodType]: subject } = {
                2: 'thisYearDeviceSprayAreaRank',
                3: 'thisMonthDeviceSprayAreaRank',
            };

            const now = moment();
            const { [periodType]: [startTime, endTime] } = {
                2: [now.clone().startOf('year').valueOf(), now.clone().endOf('year').valueOf()],
                3: [now.clone().startOf('month').valueOf(), now.clone().endOf('month').valueOf()],
            };

            return new Promise((resolve, reject) => {
                Vue.http.get(urls.GET_DEVICE_SPRAY_AREA_RANK, {
                    params: { startTime, endTime },
                }).then((response) => {
                    const stat = response.data;
                    commit(types.UPDATE_SUBJECT, { subject, data: stat || [] });
                    resolve(response);
                }).catch(reject);
            });
        },

        // 获取总设备作业排名  (大屏-- 总设备作业排名)
        getAllDevicesSprayAreaRank({ commit }) {
            const subject = 'allPeriodDeviceSprayAreaRank';

            return new Promise((resolve, reject) => {
                Vue.http.get(urls.GET_ALL_DEVICES_SPRAY_AREA_RANK).then((response) => {
                    const stat = response.data;
                    commit(types.UPDATE_SUBJECT, { subject, data: stat || [] });
                    resolve(response);
                }).catch(reject);
            });
        },

        // 获取时段内用户喷洒面积排名
        getUserSprayAreaRank({ commit }, { periodType }) {
            const { [periodType]: subject } = {
                2: 'thisYearUserSprayAreaRank',
                3: 'thisMonthUserSprayAreaRank',
            };

            const now = moment();
            const { [periodType]: [startTime, endTime] } = {
                2: [now.clone().startOf('year').valueOf(), now.clone().endOf('year').valueOf()],
                3: [now.clone().startOf('month').valueOf(), now.clone().endOf('month').valueOf()],
            };

            return new Promise((resolve, reject) => {
                Vue.http.get(urls.GET_USER_SPRAY_AREA_RANK, {
                    params: { startTime, endTime },
                }).then((response) => {
                    const stat = response.data;
                    commit(types.UPDATE_SUBJECT, { subject, data: stat || [] });
                    resolve(response);
                }).catch(reject);
            });
        },

        // 获取总用户喷洒面积排名 （大屏-- 总飞手作业排名）
        getAllUsersSprayAreaRank({ commit }) {
            const subject = 'allPeriodUserSprayAreaRank';

            return new Promise((resolve, reject) => {
                Vue.http.get(urls.GET_ALL_USERS_SPRAY_AREA_RANK).then((response) => {
                    const stat = response.data;
                    commit(types.UPDATE_SUBJECT, { subject, data: stat || [] });
                    resolve(response);
                }).catch(reject);
            });
        },

        // 获取时段内统计总览
        getPeriodStatOverview({ commit }, { periodType }) {
            const { [periodType]: subject } = {
                2: 'thisMonthOverview',
                3: 'thisYearOverview',
            };

            const now = moment();
            const { [periodType]: [startTime, endTime] } = {
                2: [now.clone().startOf('month').valueOf(), now.clone().endOf('month').valueOf()],
                3: [now.clone().startOf('year').valueOf(), now.clone().endOf('year').valueOf()],
            };

            return new Promise((resolve, reject) => {
                Vue.http.get(urls.GET_STAT_SCREEN_OVERVIEW, {
                    params: { startTime, endTime },
                }).then((response) => {
                    const stat = response.data;
                    commit(types.UPDATE_SUBJECT, { subject, data: stat || {} });
                    resolve(response);
                }).catch(reject);
            });
        },

        // 获取本日内统计总览（大屏--本日数据）
        getTodayDataOverview({ commit }) {
            const subject = 'todayOverview';

            const [startTime, endTime] = [moment().clone().startOf('day').valueOf(), moment().clone().endOf('day').valueOf()];

            return new Promise((resolve, reject) => {
                Vue.http.get(urls.GET_TODAY_DATA_OVERVIEW, {
                    params: { startTime, endTime },
                }).then((response) => {
                    const stat = response.data;
                    commit(types.UPDATE_SUBJECT, { subject, data: stat || {} });
                    resolve(response);
                }).catch(reject);
            });
        },

        // 获取中国各时段喷洒面积（按地区）
        getChinaPeriodSprayAreaPerRegion({ commit }, { periodType }) {
            const { [periodType]: subject } = {
                5: 'allPeriodChinaSprayArea',
                1: 'todayChinaSprayArea',
                3: 'thisMonthChinaSprayArea',
                4: 'thisYearChinaSprayArea',
            };

            const formData = {
                periodType,
                startTime: '',
                endTime: '',
                region: '156000000',
                tftzType: '',
                cropId: '',
                medId: '',
                zzAccountId: '',
                yyAccountId: '',
                startDroneId: '',
                endDroneId: '',
                flyModelId: '',
                taskIds: '',
                oprId: '',
                areaUnit: '',
                showType: '1',
                refreshFre: '0',
                x: '1',
                y: '3',
                dimension: '',
                summary: '',
                // ...(periodType === '5' ? { startTime: '2000-01-01', endTime: moment().format('YYYY-MM-DD') } : {}),
            };

            return new Promise((resolve, reject) => {
                Vue.http.post(urls.CREATE_DATA_STAT_WITH_DETAIL, formData).then((response) => {
                    const { data: stat } = response.data;
                    commit(types.UPDATE_SUBJECT, { subject, data: stat || {} });
                    resolve(response);
                }).catch(reject);
            });
        },

        // 获取国外各时段喷洒面积（按地区）
        getForeignPeriodSprayAreaPerRegion({ commit }, { periodType }) {
            const { [periodType]: subject } = {
                1: 'allPeriodForeignSprayArea',
                2: 'todayForeignSprayArea',
                3: 'thisMonthForeignSprayArea',
                4: 'thisYearForeignSprayArea',
            };

            const now = moment();
            const { [periodType]: [startTime, endTime] } = {
                1: [undefined, undefined],
                2: [now.clone().startOf('day').valueOf(), now.clone().endOf('day').valueOf()],
                3: [now.clone().startOf('month').valueOf(), now.clone().endOf('month').valueOf()],
                4: [now.clone().startOf('year').valueOf(), now.clone().endOf('year').valueOf()],
            };

            return new Promise((resolve, reject) => {
                Vue.http.get(urls.GET_FOREIGN_PERIOD_SPRAY_AREA_PER_REGION, {
                    params: { startTime, endTime },
                }).then((response) => {
                    const stat = response.data;
                    commit(types.UPDATE_SUBJECT, { subject, data: stat || {} });
                    resolve(response);
                }).catch(reject);
            });
        },
    },
};
