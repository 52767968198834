/**
 * Block
 */

import Vue from 'vue';
import * as types from './mutation-types';
import * as urls from '../config/urls';
import * as helpers from '../utils/helpers';
import mapConfig from '../config/map';

export default {
    namespaced: true,

    state: {
        // block
        blockList: {},
        blockLoading: false,
        blockPagination: 1,
        blockPageSize: 20,
        blockFilters: {
            search: undefined,
            region: undefined,
            onlyMyAccount: undefined,
            onlySubAccount: undefined,
        },
        allBlocks: {},
        blockDetail: {},
        mapSettings: {
            currentZoom: mapConfig.zoom,
            currentCenter: mapConfig.center,
        },
    },

    getters: {
        allBlocks(state) {
            return Object.keys(state.allBlocks).sort((a, b) => a - b).map(k => state.allBlocks[k]).flat();
        },
    },

    mutations: {
        [types.UPDATE_LIST](state, { type, list }) {
            state[`${type}List`] = list;
        },
        [types.UPDATE_GROUP](state, { group, key, value }) {
            if (!key) {
                state[group] = {};
                return;
            }
            state[group] = {
                ...state[group],
                [key]: value,
            };
        },
        [types.UPDATE_DETAIL](state, { type, detail }) {
            state[`${type}Detail`] = detail;
        },
        [types.UPDATE_PAGE_SIZE](state, { type, size }) {
            state[`${type}PageSize`] = size;
        },
        [types.UPDATE_CURRENT_PAGINATION](state, { type, pagination }) {
            state[`${type}Pagination`] = pagination;
        },
        [types.UPDATE_FILTERS](state, { type, filters }) {
            state[`${type}Filters`] = filters;
        },
        [types.UPDATE_LOADING_STATUS](state, { type, isLoading }) {
            state[`${type}Loading`] = isLoading;
        },
        [types.UPDATE_MAP_SETTINGS](state, options) {
            Object.keys(options).forEach((key) => {
                if (Object.prototype.hasOwnProperty.call(state.mapSettings, key)) {
                    state.mapSettings[key] = options[key];
                }
            });
        },
    },

    actions: {
        // block列表
        getBlockList({ commit, state }, pagination = 1) {
            const type = 'block';

            return new Promise((resolve, reject) => {
                commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                Vue.http.get(urls.GET_BLOCK_LIST, {
                    params: {
                        pageIndex: pagination,
                        size: state[`${type}PageSize`],
                        ...state[`${type}Filters`],
                    },
                }).then((response) => {
                    commit(types.UPDATE_LIST, { type, list: response.data });
                    commit(types.UPDATE_CURRENT_PAGINATION, { type, pagination });
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    resolve(response);
                }).catch((error) => {
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    reject(error);
                });
            });
        },

        //  block：变更列表分页Size
        changeBlockListPageSize({ commit, dispatch, state }, size) {
            const type = 'block';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            commit(types.UPDATE_PAGE_SIZE, { type, size });
            dispatch(`get${Type}List`, state[`${type}Pagination`]);
        },

        //  block：更新列表过滤条件
        changeBlockListFilters({ commit, dispatch, state }, filters) {
            const type = 'block';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            const f = helpers.update(state[`${type}Filters`], filters);
            commit(types.UPDATE_FILTERS, { type, filters: f });
            dispatch(`get${Type}List`, state[`${type}Pagination`]);
        },

        // allBlocks
        getAllBlocks({ commit }, filters = {}) {
            const type = 'allBlocks';
            commit(types.UPDATE_GROUP, { group: type });

            const size = 500;
            let count = 1;

            return new Promise((resolve, reject) => {
                const httpHandler = () => {
                    Vue.http.get(urls.GET_BLOCK_LIST, {
                        params: { pageIndex: count, size, ...filters },
                    }).then((response) => {
                        const { list, total } = response.data;
                        const pages = Math.ceil(total / size);
                        commit(types.UPDATE_GROUP, { group: type, key: count, value: list });
                        if (count === pages) {
                            resolve();
                            return;
                        }
                        count += 1;
                        httpHandler();
                    }).catch((error) => {
                        reject(error);
                    });
                };
                httpHandler();
            });
        },

        //  block：创建
        createBlock({ dispatch, state }, formData) {
            const type = 'block';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            return new Promise((resolve, reject) => {
                Vue.http.post(urls.CREATE_BLOCK, formData).then((response) => {
                    dispatch(`get${Type}List`, state[`${type}Pagination`]);
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        //  block：详情
        getBlockDetail({ commit }, id) {
            const type = 'block';

            return new Promise((resolve, reject) => {
                const url = helpers.buildURL(urls.GET_BLOCK_DETAIL, id);
                Vue.http.get(url).then((response) => {
                    const detail = response.data || {};
                    commit(types.UPDATE_DETAIL, { type, detail });
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        //  block：更新
        updateBlock({ dispatch, state }, formData) {
            const type = 'block';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            return new Promise((resolve, reject) => {
                Vue.http.put(urls.UPDATE_BLOCK, formData).then((response) => {
                    dispatch(`get${Type}List`, state[`${type}Pagination`]);
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        //  block：删除
        deleteBlock({ dispatch, commit, state }, blockIds = []) {
            const type = 'block';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            return new Promise((resolve, reject) => {
                if (!blockIds.length) {
                    reject(new Error('没有选择任何地块'));
                    return;
                }
                commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                const url = helpers.buildURL(urls.DELETE_BLOCKS, blockIds.join());
                Vue.http.delete(url).then((response) => {
                    dispatch(`get${Type}List`, state[`${type}Pagination`]);
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    resolve(response);
                }).catch((error) => {
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    reject(error);
                });
            });
        },

        //  block：强制删除
        forceDeleteBlock({ dispatch, commit, state }, blockId) {
            const type = 'block';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            return new Promise((resolve, reject) => {
                commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                const url = helpers.buildURL(urls.FORCE_DELETE_BLOCKS, blockId);
                Vue.http.delete(url).then((response) => {
                    dispatch(`get${Type}List`, state[`${type}Pagination`]);
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    resolve(response);
                }).catch((error) => {
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    reject(error);
                });
            });
        },

        //  block：删除
        shareBlock(store, formData = {}) {
            const { blockId, targetUserPhone } = formData;

            return new Promise((resolve, reject) => {
                const url = helpers.buildURL(urls.SHARE_BLOCK, blockId);
                Vue.http.post(url, {
                    targetUserPhone,
                }).then((response) => {
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        // 保存地图设置
        saveMapSettings({ state, commit }, options = {}) {
            const newOptions = helpers.update(state.mapSettings, options);
            commit(types.UPDATE_MAP_SETTINGS, newOptions);
        },

        // end
    },
};
