<template>
    <el-dialog title="" :visible.sync="show" custom-class="ui-no-title-dialog" @open="handleOpen" @closed="handleClosed" center append-to-body width="560px">
        <el-form v-loading="isLoading" ref="modalForm" :model="modalForm" :rules="modalRules" label-width="140px">
            <el-form-item prop="userHeadUrl" label-width="0" class="has-text-centered">
                <AvatarUploader v-model="modalForm.userHeadUrl"></AvatarUploader>
            </el-form-item>
            <el-form-item prop="userName" :label="$t('name')">
                <el-input v-model="modalForm.userName" :placeholder="$t('form.input')"></el-input>
            </el-form-item>
            <el-form-item prop="oldPassword" :label="$t('old_password')">
                <el-input type="password" v-model="modalForm.oldPassword" :placeholder="$t('form.input')"></el-input>
            </el-form-item>
            <el-form-item prop="newPassword" :label="$t('new_password')">
                <el-input type="password" v-model="modalForm.newPassword" :placeholder="$t('form.input')"></el-input>
            </el-form-item>
            <el-form-item prop="newPasswordConfirmation" :label="$t('reenter_password')">
                <el-input type="password" v-model="modalForm.newPasswordConfirmation" :placeholder="$t('form.input')"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button @click="handleCancel('modalForm')">{{ $t('form.cancel') }}</el-button>
                <el-button type="primary" @click="handleSubmit('modalForm')">{{ $t('form.confirm') }}</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>

<i18n src="./languages.json"></i18n>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import { validatePassword, validatePasswordCheck } from '../../utils/validators';
    import AvatarUploader from '../AvatarUploader.vue';

    export default {
        name: 'ProfileEdit',

        components: { AvatarUploader },

        props: {
            visible: {
                type: Boolean,
                default: false,
            },
            value: {
                type: Object,
                default: () => ({}),
            },
        },

        data() {
            return {
                modalForm: {
                    userName: '',
                    userHeadUrl: '',
                    oldPassword: '',
                    newPassword: '',
                    newPasswordConfirmation: '',
                },
            };
        },

        computed: {
            ...mapGetters('user', ['getUserInfo']),

            show: {
                get() {
                    return this.visible;
                },
                set(val) {
                    this.$emit('update:visible', val);
                },
            },

            modalRules() {
                return {
                    userName: [
                        { required: true, message: this.$t('name_tips'), trigger: 'blur' },
                    ],
                    userHeadUrl: [
                        { required: true, message: this.$t('avatar_tips'), trigger: 'change' },
                    ],
                    oldPassword: [
                        { required: true, message: this.$t('old_pwd_tips'), trigger: 'blur' },
                    ],
                    newPassword: [
                        { required: true, message: this.$t('new_pwd_tips'), trigger: 'blur' },
                        { validator: validatePassword(this, 'modalForm'), trigger: 'blur' },
                    ],
                    newPasswordConfirmation: [
                        { required: true, message: this.$t('re_pwd_tips'), trigger: 'blur' },
                        { validator: validatePasswordCheck(this, 'modalForm'), trigger: 'blur' },
                    ],
                };
            },
        },

        methods: {
            ...mapActions('own', ['updateOwnProfile']),
            ...mapActions('user', ['updateUserInfo']),

            handleOpen() {
                this.modalForm.userHeadUrl = this.getUserInfo('userHeadUrl');
                this.modalForm.userName = this.getUserInfo('userName');
            },

            handleClosed() {
                this.$emit('closed');
            },

            handleSubmit(formName) {
                this.$refs[formName].validate((valid) => {
                    if (!valid) {
                        return;
                    }
                    this.startLoading();
                    this.updateOwnProfile(this.modalForm).then(() => {
                        this.stopLoading();
                        this.$message.success(this.$t('dialog.updated'));
                        this.updateUserInfo({
                            userName: this.modalForm.userName,
                            userHeadUrl: this.modalForm.userHeadUrl,
                        });
                        this.resetForm(formName);
                        this.show = false;
                    }).catch(({ message }) => {
                        this.stopLoading();
                        if (message) {
                            this.$message.error(message);
                        }
                    });
                });
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
            },

            handleCancel(formName) {
                this.resetForm(formName);
                this.show = false;
            },
        },
    };
</script>

<style lang="scss" scoped>
    /deep/ .ui-no-title-dialog {
        .el-dialog__header {
            display: none;
        }
    }
</style>
