/**
 * 租赁业务
 */
const LeaseDataStat = () => import(/* webpackChunkName: "chunk!lease-data-stat" */'../views/LeaseDataStat/LeaseDataStat.vue');
const ClientList = () => import(/* webpackChunkName: "chunk!client-list" */'../views/ClientManage/ClientList.vue');
const LeaseList = () => import(/* webpackChunkName: "chunk!lease-list" */'../views/LeaseManage/LeaseList.vue');
const BillList = () => import(/* webpackChunkName: "chunk!bill-list" */'../views/BillManage/BillList.vue');
const WorkRecordList = () => import(/* webpackChunkName: "chunk!work-record" */'../views/WorkRecordManage/WorkRecordList.vue');
const ClientDataView = () => import(/* webpackChunkName: "chunk!work-record" */'../views/ClientDataView/ClientDataView.vue');
const OperatorDataStat = () => import(/* webpackChunkName: "chunk!work-record" */'../views/OperatorDataStat/OperatorDataStat.vue');

const group = 'leasing_business';

export default [
    {
        path: `/${group}/lease-data-stat`,
        name: 'LeaseDataStat',
        component: LeaseDataStat,
        meta: { group },
    },
    {
        path: `/${group}/client-list`,
        name: 'ClientList',
        component: ClientList,
        meta: { group },
    },
    {
        path: `/${group}/client-list/:id(\\d+)/chart`,
        name: 'ClientDataView',
        component: ClientDataView,
        meta: { group },
    },
    {
        path: `/${group}/lease-list`,
        name: 'LeaseList',
        component: LeaseList,
        meta: { group },
    },
    {
        path: `/${group}/bill-list`,
        name: 'BillList',
        component: BillList,
        meta: { group },
    },
    {
        path: `/${group}/work-record`,
        name: 'WorkRecordList',
        component: WorkRecordList,
        meta: { group },
    },
    {
        path: `/${group}/operator-data-stat`,
        name: 'OperatorDataStat',
        component: OperatorDataStat,
        meta: { group },
    },
];
