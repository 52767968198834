<template>
    <div class="ui-root" v-loading="isLoading">
        <div class="columns is-mobile is-centered is-gapless ui-header">
            <div class="column is-8">
                <div class="columns is-mobile is-gapless is-vcentered">
                    <div class="column">
                        <img :src="`/images/${logoFilename}`" alt="logo" class="ui-logo">
                        <span>{{ !isCn ? $t('站点标题') : title }}<em>{{ $t('欢迎注册') }}</em></span>
                    </div>
                    <div class="column is-narrow ui-login">
                        <span>{{ $t('已有账号') }}<router-link to="/login" replace>{{ $t('去登录') }}<i class="el-icon-d-arrow-right"></i></router-link></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="columns is-mobile is-gapless is-centered ui-form">
            <div class="column is-narrow">
                <el-tabs v-model="stepStatus">
                    <el-tab-pane name="step1">
                        <el-form ref="formData" :model="formData" :rules="formRules" label-width="200px">
                            <el-form-item prop="userSn" :label="$t('管理员账号')">
                                <!-- 手机号 输入 -->
                                <el-input v-if="isPhoneAccount" v-model="formData.userSn" :placeholder="$t('请输入手机号')">
                                    <el-select v-model="mobilePhoneCountryCode" slot="prepend" :placeholder="$t('请选择国家码')" style="min-width: 90px;">
                                        <el-option
                                            v-for="item in countryOptions"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                            <div style="display: flex;align-items: center">
                                                <img
                                                    style="width: 20px; height: 20px; object-fit: fill; margin-right: 8px"
                                                    :src="item.flag" />
                                                <span style="color: #8492a6; font-size: 13px;">{{ item.value }}</span>
                                            </div>
                                        </el-option>
                                    </el-select>
                                </el-input>
                                <!-- 邮箱 输入 -->
                                <el-input v-else v-model="formData.userSn" :placeholder="$t('请输入邮箱')"></el-input>
                                <!-- 输入框切换 -->
                                <el-button
                                    style="margin-left: 8px;"
                                    icon="el-icon-refresh"
                                    @click="onSwitchAccountInput">{{ isPhoneAccount ? $t('使用邮箱注册') : $t('使用手机号注册') }}
                                </el-button>
                            </el-form-item>
                            <el-form-item prop="password" :label="$t('密码')">
                                <el-input type="password" v-model="formData.password" autocomplete="off" :placeholder="$t('请输入密码')"></el-input>
                            </el-form-item>
                            <el-form-item prop="passwordConfirmation" :label="$t('再次输入密码')">
                                <el-input type="password" v-model="formData.passwordConfirmation" autocomplete="off" :placeholder="$t('请再次输入密码')"></el-input>
                            </el-form-item>
                            <el-form-item prop="verifyCode" :label="$t('验证码')">
                                <el-input v-model="formData.verifyCode" autocomplete="off" :placeholder="$t('请输入验证码')">
                                    <CountdownButton
                                        slot="append"
                                        :label="$t('获取验证码')"
                                        :started-tpl="`%n%${$t('等待重新获取')}`"
                                        @click="handleRequestVerifyCode('formData', $event)"></CountdownButton>
                                </el-input>
                            </el-form-item>
                            <el-form-item prop="accountType" :label="$t('账号类型')">
                                <el-select v-model="formData.accountType" :placeholder="`${$t('请选择')} ${$t('注册后无法更改')}`">
                                    <el-option v-for="(label, key) in accountTypeMap" :key="key" :label="$t(label)" :value="key"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item prop="accountName" :label="$t('企业名称')">
                                <el-input v-model="formData.accountName" :placeholder="`${$t('请输入')} ${$t('企业名称')}`"></el-input>
                            </el-form-item>
                            <el-form-item prop="legal" :label="$t('法人代表')">
                                <el-input v-model="formData.legal" :placeholder="`${$t('请输入')} ${$t('法人代表')}`"></el-input>
                            </el-form-item>
                            <el-form-item prop="creditCode" :label="$t('统一社会信用代码')">
                                <el-input v-model="formData.creditCode" :placeholder="`${$t('请输入')} ${$t('统一社会信用代码')}`"></el-input>
                            </el-form-item>
                            <el-form-item prop="contactName" :label="$t('联系人姓名')">
                                <el-input v-model="formData.contactName" :placeholder="`${$t('请输入')} ${$t('联系人姓名')}`"></el-input>
                            </el-form-item>
                            <el-form-item prop="province" class="is-hidden"></el-form-item>
                            <el-form-item prop="city" class="is-hidden"></el-form-item>
                            <el-form-item prop="county" :label="$t('联系地址')">
                                <RegionSelector v-model="formData.province" filterable />
                            </el-form-item>
                            <el-form-item prop="contactAddress">
                                <el-input v-model="formData.contactAddress" :placeholder="$t('请输入详细地址')"></el-input>
                            </el-form-item>
                            <el-form-item prop="contactPhone" :label="$t('联系电话')">
                                <el-input v-model="formData.contactPhone" :placeholder="`${$t('请输入')} ${$t('联系电话')}`"></el-input>
                            </el-form-item>
                            <el-form-item prop="email" label="E-mail">
                                <el-input v-model="formData.email" :placeholder="`${$t('请输入')} E-mail`"></el-input>
                            </el-form-item>
                            <el-form-item prop="busiLicense" :label="$t('营业执照扫描件')">
                                <ImageUploader v-model="formData.busiLicense"></ImageUploader>
                            </el-form-item>
                            <el-form-item prop="companyLogo" :label="$t('公司Logo')">
                                <ImageUploader v-model="formData.companyLogo"></ImageUploader>
                            </el-form-item>
                            <el-form-item prop="customDomain" :label="$t('自定义公司二级域名')">
                                <el-input v-model="formData.customDomain" :placeholder="$t('请输入公司二级域名')"></el-input>
                            </el-form-item>
                            <el-form-item v-if="isCn">
                                <el-checkbox v-model="isAgreed">
                                    {{ $t('阅读并同意') }}<br v-if="isEn"><a href="javascript:void(0);" @click="showUserAgreement = true">{{ $t('农机监管平台用户服务协议') }}</a>
                                </el-checkbox>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" class="ui-full-button" :disabled="!isAgreed" @click="handleSubmit('formData')">{{ $t('form.submit') }}</el-button>
                            </el-form-item>
                        </el-form>
                    </el-tab-pane>
                    <el-tab-pane name="step2">
                        <div class="ui-finish has-text-centered">
                            <i class="el-icon-time"></i>
                            <p>{{ $t('申请资料审核中') }}</p>
                            <el-button type="primary" @click="handleFinish">{{ $t('form.confirm') }}</el-button>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
        <UserAgreement :visible.sync="showUserAgreement"></UserAgreement>
    </div>
</template>

<i18n src="./languages.json"></i18n>

<script>
    import { mapState, mapActions, mapGetters } from 'vuex';
    import scrollbarMixin from '../../mixins/scrollbarMixin';
    import CountdownButton from '../../components/CountdownButton.vue';
    import ImageUploader from '../../components/ImageUploader.vue';
    // import RegionSelector from '../../components/RegionSelector.vue';
    import RegionSelector from './RegionCascader.vue';
    import UserAgreement from './UserAgreement.vue';
    import { validatePassword, validatePasswordCheck, validateAccountNumber } from '../../utils/validators';
    import countryCode from '../../config/countryCode';

    const accountTypeMap = {
        3: '制造企业',
        4: '运营人',
    };

    export default {
        name: 'Register',

        components: { CountdownButton, ImageUploader, RegionSelector, UserAgreement },

        mixins: [scrollbarMixin],

        data() {
            return {
                mobilePhoneCountryCode: '+86',
                isPhoneAccount: true,
                step: 0,
                accountTypeMap,
                isAgreed: true,
                showUserAgreement: false,
                formData: {
                    userSn: '',
                    password: '',
                    passwordConfirmation: '', // 仅：确认密码时使用
                    verifyCode: '',
                    accountType: '',
                    operatorType: '1', // 1:企业账号 2：个人账号（现在只保留了1）
                    checkOpr: '2', // 仅：校验验证码时使用
                    commitWay: '1', // 1:立即提交 2:下次提交（现在只保留了1）
                    accountName: '',
                    legal: '',
                    creditCode: '',
                    contactName: '',
                    province: '',
                    city: '',
                    county: '',
                    contactAddress: '',
                    contactPhone: '',
                    email: '',
                    busiLicense: '',
                    companyLogo: '',
                    customDomain: '',
                },
            };
        },

        computed: {
            ...mapState('theme', ['title', 'logoFilename']),
            ...mapGetters('lang', ['isEn', 'isCn']),
            ...mapGetters('user', ['isIndia', 'isTW']),

            countryOptions() {
                return this.isIndia ? countryCode(['india']) : countryCode();
            },

            stepStatus: {
                get() {
                    return `step${this.step + 1}`;
                },
                set() {},
            },

            formRules() {
                return {
                    userSn: [
                        { required: true, message: this.$t('请输入管理员账号'), trigger: 'blur' },
                        { validator: validateAccountNumber, messages: [this.$t('请输入正确的手机号码'), this.$t('请输入正确的E-mail')], trigger: 'blur' },
                    ],
                    verifyCode: [
                        { required: true, message: this.$t('请输入验证码'), trigger: 'blur' },
                        // { pattern: /^\d{4}$/g, message: this.$t('请输入验证码'), trigger: 'blur' },
                    ],
                    password: [
                        { required: true, message: this.$t('请输入密码'), trigger: 'blur' },
                        { validator: validatePassword(this, 'formData'), trigger: 'blur' },
                    ],
                    passwordConfirmation: [
                        { required: true, message: this.$t('请再次输入密码'), trigger: 'blur' },
                        { validator: validatePasswordCheck(this, 'formData'), trigger: 'blur' },
                    ],
                    accountType: [
                        { required: true, message: this.$t('请选择账号类型'), trigger: 'change' },
                    ],
                    operatorType: [
                        { required: true, message: this.$t('请选择运营人类型'), trigger: 'change' },
                    ],
                    accountName: [
                        { required: true, message: `${this.$t('请输入')} ${this.$t('企业名称')}`, trigger: 'blur' },
                        // { pattern: /^[\u4e00-\u9fa5\uff08\uff09]+$/, message: '请填写中文', trigger: 'blur' },
                    ],
                    legal: [
                        { message: `${this.$t('请输入')} ${this.$t('法人代表')}`, trigger: 'blur' },
                        // { required: true, message: `${this.$t('请输入')} ${this.$t('法人代表')}`, trigger: 'blur' },
                        // { pattern: /^[\u4e00-\u9fa5]{2,4}$/, message: '请填写中文（2~4个字）', trigger: 'blur' },
                    ],
                    creditCode: [
                        { message: `${this.$t('请输入')} ${this.$t('统一社会信用代码')}`, trigger: 'blur' },
                        // { required: true, message: `${this.$t('请输入')} ${this.$t('统一社会信用代码')}`, trigger: 'blur' },
                        // { pattern: /[^_IOZSVa-z\W]{2}\d{6}[^_IOZSVa-z\W]{10}/, message: '无效代码，请正确输入', trigger: 'blur' },
                    ],
                    contactName: [
                        { required: true, message: `${this.$t('请输入')} ${this.$t('联系人姓名')}`, trigger: 'blur' },
                        // { pattern: /^[\u4e00-\u9fa5]{2,4}$/, message: '请填写中文（2~4个字）', trigger: 'blur' },
                    ],
                    province: [],
                    city: [],
                    county: [],
                    contactAddress: [],
                    // contactPhone: [
                    //     { required: true, message: `${this.$t('请输入')} ${this.$t('联系电话')}`, trigger: 'blur' },
                    //     { pattern: /^1[3-9]\d{9}$/g, message: this.$t('请输入正确的手机号码'), trigger: 'blur' },
                    // ],
                    email: [
                        // { required: true, message: `${this.$t('请输入')} E-mail`, trigger: 'blur' },
                        { message: `${this.$t('请输入')} E-mail`, trigger: 'blur' },
                        { type: 'email', message: this.$t('请输入正确的E-mail'), trigger: 'blur' },
                    ],
                    busiLicense: [
                        { message: this.$t('请上传营业执照'), trigger: 'change' },
                        // { required: true, message: this.$t('请上传营业执照'), trigger: 'change' },
                    ],
                    companyLogo: [],
                    customDomain: [],
                };
            },
        },

        mounted() {
            if (this.isIndia) this.mobilePhoneCountryCode = '+91';
            if (this.isTW) this.mobilePhoneCountryCode = '+886';
        },

        methods: {
            ...mapActions('user', ['requestSmsVerifyCode', 'requestCheckSmsVerifyCode', 'requestRegister']),

            // 提交
            handleSubmit(formName) {
                this.$refs[formName].validate((valid) => {
                    if (!valid) {
                        return;
                    }
                    // 合并表单数据，并删除多余字段
                    const { userSn } = this.formData;
                    // const isPhone = /^\d{11}$/.test(userSn);
                    // 表单校验 - 手机号 和 邮箱 混在一起， 手动区分
                    const isPhone = /^\d{9,11}$/.test(userSn);
                    const formData = {
                        ...this.formData,
                        // phoneNum: isPhone ? userSn : '',
                        // email: !isPhone ? userSn : '',
                        phoneNum: (this.isPhoneAccount || isPhone) ? `${this.mobilePhoneCountryCode}-${userSn}` : '',
                        email: (!this.isPhoneAccount && !isPhone) ? userSn : '',
                    };
                    delete formData.passwordConfirmation;
                    delete formData.checkOpr;
                    delete formData.userSn;
                    // 提交注册信息
                    this.startLoading();
                    this.requestRegister(formData).then(() => {
                        this.stopLoading();
                        this.step += 1;
                    }).catch(({ message }) => {
                        this.stopLoading();
                        if (message) {
                            this.$message.error(message);
                        }
                    });
                });
            },

            // 请求验证码
            handleRequestVerifyCode(formName, { countdownStart, countdownReset }) {
                this.$refs[formName].validateField('userSn', (validateMessage, invalidFields) => {
                    if (invalidFields) {
                        return;
                    }
                    // 开始倒计时
                    countdownStart();
                    this.startLoading();
                    const isPhone = /^\d{9,11}$/.test(this[formName].userSn);
                    this.requestSmsVerifyCode({
                        phoneNum: (this.isPhoneAccount && isPhone) ? `${this.mobilePhoneCountryCode}-${this[formName].userSn}` : this[formName].userSn,
                        codeType: '2',
                    }).then(() => {
                        this.stopLoading();
                        this.$message.success(this.$t('验证码已发送'));
                    }).catch(({ message }) => {
                        // 恢复倒计时按钮原始状态
                        countdownReset();
                        this.stopLoading();
                        if (message) {
                            this.$message.error(message);
                        }
                    });
                });
            },

            // 完成
            handleFinish() {
                this.$router.replace('/login');
            },

            // 切换账号输入框
            onSwitchAccountInput() {
                this.isPhoneAccount = !this.isPhoneAccount;
                // 副作用
                this.formData.userSn = '';
            },
        },
    };
</script>

<style lang="scss" scoped>
    .ui-header {
        height: 80px;
        margin: 0 !important;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.05);
        & > .column {
            padding: 0 20px !important;
        }
        .columns {
            height: 100%;
        }
        .ui-logo {
            height: 60px;
        }
        .ui-logo + span {
            color: $brandColor;
            font-size: 24px;
            margin-left: 10px;
            vertical-align: middle;
            em {
                font-style: normal;
                font-size: 16px;
                color: $grey-dark;
                margin-left: 10px;
            }
        }
        .ui-login span {
            color: $grey;
        }
    }

    .ui-steps {
        padding: 0 24px;
        .el-steps {
            margin: 50px 0;
        }
    }

    .el-radio.is-bordered {
        background-color: #fff;
    }

    .el-tabs /deep/ .el-tabs__header {
        display: none;
    }

    .ui-form {
        padding: 0 24px;
        background-color: #f7f7f7;
        overflow: hidden;
        .el-form {
            margin: 50px 0;
        }
        .el-input {
            width: 420px;
        }
        .el-alert--info {
            background-color: #deedfd;
            color: #409eff;
        }
    }

    .ui-finish {
        margin: 80px 0;
        color: $red;
        i {
            font-size: 50px;
            margin-bottom: 20px;
        }
        p {
            font-size: 20px;
            margin-bottom: 30px;
        }
    }

    .ui-full-button {
        width: 100%;
    }
</style>
