<template>
    <div :class="$style.root" v-show="isVisible"></div>
</template>

<script>
    import { mapActions, mapGetters, mapState } from 'vuex';
    import mapbox from 'mapbox-gl';
    import config, { mapStyles } from '../../config/map';
    import mapMixin from '../../mixins/mapMixin';
    import commonRefs from '../../utils/commonRefs';

    export default {
        name: 'SingletonMap',

        mixins: [mapMixin],

        data() {
            return {
                map: null,
                isVisible: false,
            };
        },

        computed: {
            ...mapState('monitor', ['mapSettings']),
            ...mapGetters('lang', ['isEn']),
        },

        watch: {
            'mapSettings.showHdMap': 'switchHdLayer',
            isEn: 'switchMapLanguage',
        },

        mounted() {
            commonRefs.setRef('singletonMapComponent', this);
            this.initMap();
        },

        destroyed() {
            this.destroy();
        },

        methods: {
            ...mapActions('monitor', ['updateMapOptions']),

            initMap() {
                if (this.map) return;
                const { accessToken, center, zoom } = config;
                mapbox.accessToken = accessToken;
                this.map = new mapbox.Map({
                    container: this.$el,
                    style: mapStyles.satelliteMap,
                    center,
                    zoom,
                    minZoom: 3,
                    maxZoom: 22,
                    dragRotate: false,
                    projection: 'globe',
                    preserveDrawingBuffer: true,
                    // antialias: true,
                });

                this.map.on('styledata', () => {
                    this.addKeyLayer();
                    this.switchMapLanguage();
                    this.showChinaBoundary();
                    // console.log(this.map.getStyle());
                });

                this.map.on('style.load', () => {
                    // this.hideBgLayer();
                    this.switchHdLayer();
                    commonRefs.setRef('map', this.map);
                    this.map.setFog({
                        color: 'rgb(186, 210, 235)',
                        'high-color': 'rgb(36, 92, 223)',
                        'horizon-blend': 0.02,
                        'space-color': 'rgb(11, 11, 25)',
                        'star-intensity': 0.35,
                    });
                });

                this.map.on('load', () => {
                    //
                });
            },

            // 隐藏黑色背景层
            hideBgLayer() {
                this.map.setLayoutProperty('background', 'visibility', 'none');
            },

            // 追加到指定元素内部
            appendTo(el) {
                this.isVisible = true;
                el.appendChild(this.$el);
                setTimeout(() => {
                    if (this.map) this.map.resize();
                }, 0);
            },

            // 挪回原来的位置
            restore() {
                this.isVisible = false;
                this.destroy();
                this.$root.$el.appendChild(this.$el);
            },

            destroy() {
                setTimeout(() => {
                    if (this.map) {
                        this.map.remove();
                        commonRefs.removeRef('map');
                        commonRefs.removeRef('singletonMapComponent');
                        this.map = null;
                    }
                }, 100);
            },
        },
    };
</script>

<style lang="scss" module>
    .root {
        position: relative;
        width: inherit;
        height: inherit;
    }
</style>
