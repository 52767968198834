<template>
    <el-button :disabled="disabled" @click="handleClick">
        <span>{{ disabled ? startedLabel : label }}</span>
    </el-button>
</template>

<script>
    export default {
        name: 'CountdownButton',

        props: {
            label: {
                type: String,
                default: '获取验证码',
            },
            startedTpl: {
                type: String,
                default: '%n%秒后重新获取',
            },
            countdown: {
                type: Number,
                default: 60,
            },
        },

        data() {
            return {
                disabled: false,
                counter: this.countdown,
                interval: null,
            };
        },

        computed: {
            startedLabel() {
                return this.startedTpl.replace('%n%', this.counter);
            },
        },

        methods: {
            // 开始倒计时
            start() {
                this.disabled = true;
                this.interval = setInterval(() => {
                    this.counter -= 1;
                    if (this.counter === 0) {
                        this.counter = this.countdown;
                        this.disabled = false;
                        clearInterval(this.interval);
                    }
                }, 1000);
            },

            // 重置到原始状态
            reset() {
                clearInterval(this.interval);
                this.disabled = false;
                this.counter = this.countdown;
            },

            // 组件按钮被点击，触发click事件，并携带组件方法
            handleClick() {
                this.$emit('click', {
                    countdownStart: this.start,
                    countdownReset: this.reset,
                });
            },
        },
    };
</script>

<style lang="scss" scoped>
</style>
