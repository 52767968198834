const { [window.location.host]: center = [104.46043684176152, 35.71932309941779] } = {
    'ag.xagrotortek.com': [79.05595653850673, 21.091768834699394],
    'dgca.aipl.cloud': [79.05595653850673, 21.091768834699394],
    '192.250.226.217': [79.05595653850673, 21.091768834699394],
    'syferocompanionpro.com': [79.05595653850673, 21.091768834699394],
    'pixelrobotics.in': [79.05595653850673, 21.091768834699394],
    'mgcs.marutdrones.com': [79.05595653850673, 21.091768834699394],
    'ag-ko.jiagutech.com': [126.98954519037017, 37.56245716844566],
    'ag.mirair.co.kr': [126.98954519037017, 37.56245716844566],
    'helselkorea.com': [126.98954519037017, 37.56245716844566],
};

export default {
    // accessToken: 'pk.eyJ1IjoiOTc0MTU2MzE5IiwiYSI6ImNrZXJ1cHVqMzIwd2QyenFtbnNzdHQ3eWwifQ.ptn11EbKLrx1ei_DGDwUHA',
    accessToken: 'pk.eyJ1IjoieGlhb3NpNTIxIiwiYSI6ImNsZ293b3BtejBlcHQzcGxkdmlmZnYxNzQifQ.dL-6BxRxsB-uW1IvI2wwqA',
    // center: [104.46043684176152, 35.71932309941779],
    center,
    zoom: 3.5,
};

export const mapStyles = {
    streetMap: 'mapbox://styles/mapbox/streets-v11',
    satelliteMap: 'mapbox://styles/mapbox/satellite-streets-v11',
};

export const mapStyleNames = {
    streetMap: 'Mapbox Streets',
    satelliteMap: 'Mapbox Satellite Streets',
};
