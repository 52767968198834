/*
 * 使用此mixin会使页面临时恢复滚动条显示
 */

export default {
    mounted() {
        document.documentElement.style.overflowY = 'auto';
    },
    destroyed() {
        document.documentElement.style.overflowY = '';
    },
};
