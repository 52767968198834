/**
 * 地区级联数据
 * 数据来源：v-distpicker@1.0.17
 * https://github.com/jcc/v-distpicker
 */

import _findKey from 'lodash.findkey';
import data from '../config/districts';

const result = [];

// 级联地区
export const districts = (() => {
    if (result.length) {
        return result;
    }
    const oriProvinces = '100000' in data ? data['100000'] : {};
    Object.keys(oriProvinces).forEach((provinceId) => {
        const province = {
            label: oriProvinces[provinceId],
            value: provinceId,
            children: [],
        };
        const oriCities = provinceId in data ? data[provinceId] : {};
        Object.keys(oriCities).forEach((cityId) => {
            const city = {
                label: oriCities[cityId],
                value: cityId,
                children: [],
            };
            province.children.push(city);
            const oriCounties = cityId in data ? data[cityId] : {};
            Object.keys(oriCounties).forEach((countyId) => {
                const county = {
                    label: oriCounties[countyId],
                    value: countyId,
                };
                city.children.push(county);
            });
        });
        result.push(province);
    });
    return result;
})();

// 扁平地区
export const districtFlat = (() => {
    let flat = {};
    Object.keys(data).forEach((key) => {
        flat = {
            ...flat,
            ...data[key],
        };
    });
    return flat;
})();

// 通过key找到label
export const getDistrictLabel = key => (key in districtFlat ? districtFlat[key] : '');
// 通过label找到key
export const getDistrictKey = label => _findKey(districtFlat, v => v === label) || '';

// 通过key查找层级label
export const getAddressLabel = (key) => {
    if (!(key in districtFlat)) {
        return '';
    }

    const cityKey = String(key).replace(/\d{2}$/, '00');
    const provinceKey = String(key).replace(/\d{4}$/, '0000');
    if (/0000$/.test(key)) {
        return districtFlat[key];
    } else if (/00$/.test(key)) {
        const cityLabel = districtFlat[key];
        const provinceLabel = districtFlat[provinceKey];
        return `${provinceLabel}${cityLabel}`;
    }
    const provinceLabel = districtFlat[provinceKey];
    const cityLabel = districtFlat[cityKey];
    const countyLabel = districtFlat[key];

    return `${provinceLabel}${cityLabel}${countyLabel}`;
};
