/**
 * 地图图层切换
 */
import { mapGetters, mapState } from 'vuex';
import commonRefs from '../utils/commonRefs';
import { until } from '../utils/helpers';

export default {
    computed: {
        ...mapState('lang', ['lang']),
        ...mapGetters('lang', ['isCn']),
    },

    methods: {
        initMap() {
            return commonRefs.getRef('singletonMapComponent').then(async (ref) => {
                await until(() => this.$refs.mapContainer, 10000);
                ref.appendTo(this.$refs.mapContainer);
                this.observeMapContainer(this.$refs.mapContainer);
                await commonRefs.getRef('map');
                return Promise.resolve(ref);
            });
        },

        // 观察当前地图容器，若子节点为空，则清空地图
        observeMapContainer(target) {
            const observer = new MutationObserver(() => {
                if (target.children.length) return;
                if (this.clearMap) this.clearMap();
            });
            observer.observe(target, { childList: true });
        },

        // 添加一个关键图层
        // 添加在所有label层之前，那么不想挡住label层的图层就可以添加到此图层之前
        addKeyLayer() {
            if (!this.map.getLayer('keyLayer')) {
                const { layers } = this.map.getStyle();
                // 找到第一个label图层
                const { id: layerId } = (layers || []).find(({ id }) => id.endsWith('-label')) || {};
                this.map.addLayer({
                    id: 'keyLayer',
                    type: 'background',
                    layout: {
                        visibility: 'none',
                    },
                }, layerId || null);
            }
        },

        // 切换高清图层
        switchHdLayer() {
            const { showHdMap } = this.mapSettings;
            if (!this.map.getSource('hd-tiles')) {
                this.map.addSource('hd-tiles', {
                    type: 'raster',
                    tiles: ['http://jiagu-map.oss-cn-shanghai.aliyuncs.com/tile/{z}/{x}/{y}.webp'],
                    tileSize: 256,
                    minzoom: 12,
                    maxzoom: 24,
                });

                this.map.addLayer({
                    id: 'hd-tiles-layer',
                    type: 'raster',
                    source: 'hd-tiles',
                    layout: {
                        visibility: 'none',
                    },
                }, 'keyLayer');
            }
            this.map.setLayoutProperty('hd-tiles-layer', 'visibility', showHdMap ? 'visible' : 'none');
        },

        // 切换语言
        switchMapLanguage() {
            [
                'country-label',
                'state-label',
                'settlement-label',
                'settlement-subdivision-label',
                'road-label',
                'poi-label',
                'transit-label',
                'airport-label',
                'waterway-label',
                'natural-line-label',
                'natural-point-label',
                'water-line-label',
                'water-point-label',
            ].forEach((layerId) => {
                if (!this.map) return;

                this.map.setLayoutProperty(layerId, 'text-field', [
                    'coalesce',
                    // ...(this.isEn ? [] : [['get', 'name_zh-Hans']]),
                    // 如果是isCn，匹配iso_3166_1的值是CN的话，返回'name_zh'，否则返回'name_zh-Hans'；如果不是isCn，直接返回'name_{LANG}'
                    ['get', this.isCn ? ['match', ['get', 'iso_3166_1'], 'CN', 'name_zh', 'name_zh-Hans'] : `name_${this.lang}`],
                    ['get', 'name'],
                ]);
            });
        },

        // 中国边界
        showChinaBoundary(worldview = 'CN') {
            this.map.setFilter('admin-0-boundary-disputed', [
                'all',
                ['==', ['get', 'disputed'], 'true'],
                ['==', ['get', 'admin_level'], 0],
                ['==', ['get', 'maritime'], 'false'],
                ['match', ['get', 'worldview'], ['all', worldview], true, false],
            ]);
            this.map.setFilter('admin-0-boundary', [
                'all',
                ['==', ['get', 'admin_level'], 0],
                ['==', ['get', 'disputed'], 'false'],
                ['==', ['get', 'maritime'], 'false'],
                ['match', ['get', 'worldview'], ['all', worldview], true, false],
            ]);
            this.map.setFilter('admin-0-boundary-bg', [
                'all',
                ['==', ['get', 'admin_level'], 0],
                ['==', ['get', 'maritime'], 'false'],
                ['match', ['get', 'worldview'], ['all', worldview], true, false],
            ]);
        },
    },
};
