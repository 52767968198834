<template>
    <Box :class="$style.root">
        <div class="block">
            <TitleBig>作业时间统计</TitleBig>
            <Empty class="empty" v-if="!allPeriodY.length">暂无数据</Empty>
            <v-chart :option="chartOption1" :update-options="{ notMerge: true }" theme="light" autoresize v-else />
        </div>
        <div class="block">
            <Title class="block-title">本月时间统计</Title>
            <Empty class="empty" v-if="!thisMonthY.length">暂无数据</Empty>
            <v-chart :option="chartOption2" :update-options="{ notMerge: true }" theme="light" autoresize v-else />
        </div>
        <div class="block">
            <Title class="block-title">本年时间统计</Title>
            <Empty class="empty" v-if="!thisYearY.length">暂无数据</Empty>
            <v-chart :option="chartOption3" :update-options="{ notMerge: true }" theme="light" autoresize v-else />
        </div>
    </Box>
</template>

<script>
    import { mapActions, mapState } from 'vuex';
    import _throttle from 'lodash.throttle';
    import * as echarts from 'echarts';
    import Box from './components/Box.vue';
    import Title from './components/Title.vue';
    import TitleBig from './components/TitleBig.vue';
    import Empty from '../../components/Empty.vue';

    let throttle = null;
    let ticker = null;

    const tooltip = {
        trigger: 'axis',
        backgroundColor: 'rgba(0,192,250,0.5)',
        borderColor: 'rgba(0,192,250,0.8)',
        textStyle: { color: 'white' },
        axisPointer: {
            lineStyle: {
                color: 'rgba(0,192,250,0.4)',
            },
        },
        formatter(params) {
            const [{ name }] = params;
            const title = `${name}`;
            const rows = params.map(({ marker, seriesName, value }) => {
                const nameEl = `<span style="flex: 1; margin-left: 3px;">${seriesName}：</span>`;
                const valueEl = `<span>${(value / 10000).toFixed(2)}万亩</span>`;
                return `<div style="display: flex; align-items: center;">${marker}${nameEl}${valueEl}</div>`;
            }).filter(Boolean).join('');
            return `<div>${title}</div>${rows}`;
        },
    };
    const areaColor = [
        new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: 'rgba(50,225,169,1)' },
            { offset: 1, color: 'rgba(50,225,169,0.2)' },
        ]),
        new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: 'rgba(50,163,243,1)' },
            { offset: 1, color: 'rgba(50,163,243,0.2)' },
        ]),
    ];

    export default {
        name: 'Panel3',

        components: { Box, Title, TitleBig, Empty },

        computed: {
            ...mapState('statScreen', ['cafSprayAreaAllPeriod', 'cafSprayAreaThisYear', 'cafSprayAreaThisMonth']),

            allPeriodX() {
                const { xData } = this.cafSprayAreaAllPeriod;
                return xData || [];
            },

            allPeriodY() {
                const { yData } = this.cafSprayAreaAllPeriod;
                return yData || [];
            },

            allPeriodZ() {
                const { zData } = this.cafSprayAreaAllPeriod;
                return zData || [];
            },

            thisYearX() {
                const { xData } = this.cafSprayAreaThisYear;
                return xData || [];
            },

            thisYearY() {
                const { yData } = this.cafSprayAreaThisYear;
                return yData || [];
            },

            thisYearZ() {
                const { zData } = this.cafSprayAreaThisYear;
                return zData || [];
            },

            thisMonthX() {
                const { xData } = this.cafSprayAreaThisMonth;
                return xData || [];
            },

            thisMonthY() {
                const { yData } = this.cafSprayAreaThisMonth;
                return yData || [];
            },

            thisMonthZ() {
                const { zData } = this.cafSprayAreaThisMonth;
                return zData || [];
            },

            chartOption1() {
                return {
                    color: ['rgba(50,225,169,1)', 'rgba(50,163,243,1)'],
                    grid: [{
                        left: 0,
                        right: 0,
                        top: 10,
                        bottom: 0,
                        containLabel: true,
                    }],
                    legend: {
                        right: 0,
                        orient: 'vertical',
                        selectedMode: false,
                        textStyle: { color: 'white' },
                    },
                    tooltip,
                    xAxis: [{
                        type: 'category',
                        data: this.allPeriodX,
                        axisLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLabel: {
                            color: 'rgba(255, 255, 255, 1)',
                        },
                    }],
                    yAxis: [{
                        type: 'value',
                        axisLabel: {
                            color: 'rgba(255, 255, 255, 1)',
                            formatter: val => `${(val / 10000).toFixed(2) - 0}万亩`,
                        },
                        splitLine: {
                            lineStyle: {
                                color: 'rgba(255, 255, 255, 0.4)',
                            },
                        },
                    }],
                    series: this.allPeriodY.map((data, index) => ({
                        name: this.allPeriodZ[index],
                        type: 'line',
                        smooth: true,
                        symbol: 'none',
                        areaStyle: {
                            opacity: 0.8,
                            color: areaColor[index],
                        },
                        emphasis: {
                            focus: 'series',
                        },
                        data,
                    })),
                };
            },

            chartOption2() {
                return {
                    color: ['rgba(50,225,169,1)', 'rgba(50,163,243,1)'],
                    grid: [{
                        left: 0,
                        right: 0,
                        top: 10,
                        bottom: 0,
                        containLabel: true,
                    }],
                    legend: {
                        right: 0,
                        orient: 'vertical',
                        selectedMode: false,
                        textStyle: { color: 'white' },
                    },
                    tooltip,
                    xAxis: [{
                        type: 'category',
                        data: this.thisMonthX,
                        axisLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLabel: {
                            color: 'rgba(255, 255, 255, 1)',
                        },
                    }],
                    yAxis: [{
                        type: 'value',
                        axisLabel: {
                            color: 'rgba(255, 255, 255, 1)',
                            formatter: val => `${(val / 10000).toFixed(2) - 0}万亩`,
                        },
                        splitLine: {
                            lineStyle: {
                                color: 'rgba(255, 255, 255, 0.4)',
                            },
                        },
                    }],
                    series: this.thisMonthY.map((data, index) => ({
                        name: this.thisMonthZ[index],
                        type: 'line',
                        smooth: true,
                        symbol: 'none',
                        areaStyle: {
                            opacity: 0.8,
                            color: areaColor[index],
                        },
                        emphasis: {
                            focus: 'series',
                        },
                        data,
                    })),
                };
            },

            chartOption3() {
                return {
                    color: ['rgba(50,225,169,1)', 'rgba(50,163,243,1)'],
                    grid: [{
                        left: 0,
                        right: 0,
                        top: 10,
                        bottom: 0,
                        containLabel: true,
                    }],
                    legend: {
                        right: 0,
                        orient: 'vertical',
                        selectedMode: false,
                        textStyle: { color: 'white' },
                    },
                    tooltip,
                    xAxis: [{
                        type: 'category',
                        data: this.thisYearX,
                        axisLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLabel: {
                            color: 'rgba(255, 255, 255, 1)',
                        },
                    }],
                    yAxis: [{
                        type: 'value',
                        axisLabel: {
                            color: 'rgba(255, 255, 255, 1)',
                            formatter: val => `${(val / 10000).toFixed(2) - 0}万亩`,
                        },
                        splitLine: {
                            lineStyle: {
                                color: 'rgba(255, 255, 255, 0.4)',
                            },
                        },
                    }],
                    series: this.thisYearY.map((data, index) => ({
                        name: this.thisYearZ[index],
                        type: 'line',
                        smooth: true,
                        symbol: 'none',
                        areaStyle: {
                            opacity: 0.8,
                            color: areaColor[index],
                        },
                        emphasis: {
                            focus: 'series',
                        },
                        data,
                    })),
                };
            },
        },

        mounted() {
            this.startLoadData();
        },

        destroyed() {
            this.stopLoadData();
        },

        methods: {
            ...mapActions('statScreen', ['getCafSprayAreaPerPeriod']),

            startLoadData() {
                this.stopLoadData();
                throttle = _throttle(this.loadData, 300000, { trailing: false });
                ticker = setInterval(throttle, 1000);
            },

            stopLoadData() {
                clearInterval(ticker);
            },

            loadData() {
                this.getCafSprayAreaPerPeriod({ periodType: 1 }).catch(({ message }) => {
                    if (message) this.$message.error(message);
                });
                this.getCafSprayAreaPerPeriod({ periodType: 2 }).catch(({ message }) => {
                    if (message) this.$message.error(message);
                });
                this.getCafSprayAreaPerPeriod({ periodType: 3 }).catch(({ message }) => {
                    if (message) this.$message.error(message);
                });
            },
        },
    };
</script>

<style lang="scss" module>
    .root {
        flex: 1;
        height: 100%;
        padding: 30px 20px;
        margin-left: 20px;
        display: flex;
        flex-direction: column;

        :global {
            .block {
                flex: 1;
                display: flex;
                flex-direction: column;
                margin: 0 !important;

                .block-title {
                    margin-bottom: 10px;
                }

                .empty,
                .echarts {
                    flex: 1;
                }
            }

            .block + .block {
                margin-top: 20px !important;
            }
        }
    }
</style>
