/**
 * UnifiedTask
 */

import Vue from 'vue';
import * as types from './mutation-types';
import * as urls from '../config/urls';
import * as helpers from '../utils/helpers';

export default {
    namespaced: true,

    state: {
        // unifiedTask
        unifiedTaskList: {},
        unifiedTaskLoading: false,
        unifiedTaskPagination: 1,
        unifiedTaskPageSize: 20,
        unifiedTaskFilters: {
        },
        unifiedTaskDetail: {},
        // unifiedTaskDrone
        unifiedTaskDroneList: {},
        unifiedTaskDroneLoading: false,
        unifiedTaskDronePagination: 1,
        unifiedTaskDronePageSize: 20,
        // unifiedTaskStatDrone
        unifiedTaskStatDroneList: [],
        unifiedTaskStatDroneLoading: false,
        // unifiedTaskStatOwner
        unifiedTaskStatOwnerList: [],
        unifiedTaskStatOwnerLoading: false,
        // unifiedTaskStatOverview
        unifiedTaskStatOverviewDetail: {},
        // unifiedTaskStatData
        unifiedTaskStatDataList: {},
        unifiedTaskStatDataLoading: false,
        // unifiedTaskDroneSortie
        unifiedTaskDroneSortieList: [],
        unifiedTaskDroneSortieLoading: false,
    },

    mutations: {
        [types.UPDATE_LIST](state, { type, list }) {
            state[`${type}List`] = list;
        },
        [types.UPDATE_DETAIL](state, { type, detail }) {
            state[`${type}Detail`] = detail;
        },
        [types.UPDATE_PAGE_SIZE](state, { type, size }) {
            state[`${type}PageSize`] = size;
        },
        [types.UPDATE_CURRENT_PAGINATION](state, { type, pagination }) {
            state[`${type}Pagination`] = pagination;
        },
        [types.UPDATE_FILTERS](state, { type, filters }) {
            state[`${type}Filters`] = filters;
        },
        [types.UPDATE_LOADING_STATUS](state, { type, isLoading }) {
            state[`${type}Loading`] = isLoading;
        },
    },

    actions: {
        // unifiedTask: 获取统防统治列表
        getUnifiedTaskList({ commit, state }, pagination = 1) {
            const type = 'unifiedTask';

            return new Promise((resolve, reject) => {
                commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                Vue.http.get(urls.GET_UNIFIED_TASK_LIST, {
                    params: {
                        pageIndex: pagination,
                        size: state[`${type}PageSize`],
                        ...state[`${type}Filters`],
                    },
                }).then((response) => {
                    commit(types.UPDATE_LIST, { type, list: response.data });
                    commit(types.UPDATE_CURRENT_PAGINATION, { type, pagination });
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    resolve(response);
                }).catch((error) => {
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    reject(error);
                });
            });
        },

        // unifiedTask: 更变统防统治列表分页Size
        changeUnifiedTaskListPageSize({ commit, dispatch, state }, size) {
            const type = 'unifiedTask';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            commit(types.UPDATE_PAGE_SIZE, { type, size });
            dispatch(`get${Type}List`, state[`${type}Pagination`]);
        },

        // unifiedTask: 更新统防统治列表过滤条件
        changeUnifiedTaskListFilters({ commit, dispatch, state }, filters) {
            const type = 'unifiedTask';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            commit(types.UPDATE_FILTERS, { type, filters });
            dispatch(`get${Type}List`, state[`${type}Pagination`]);
        },

        // unifiedTask: 创建统防统治任务
        createUnifiedTask({ dispatch, state }, formData) {
            const type = 'unifiedTask';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            const data = helpers.pick(formData, ['tftzName', 'region', 'startDate', 'endDate', 'tftzType', 'workType', 'cropId', 'zfMonitor']);

            return new Promise((resolve, reject) => {
                Vue.http.post(urls.CREATE_UNIFIED_TASK, data).then((response) => {
                    dispatch(`get${Type}List`, state[`${type}Pagination`]);
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        // unifiedTask: 更新统防统治任务
        updateUnifiedTask({ dispatch, state }, { tftzId, ...formData }) {
            const type = 'unifiedTask';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            const data = helpers.pick(formData, ['tftzName', 'region', 'startDate', 'endDate', 'tftzType', 'workType', 'cropId', 'zfMonitor']);

            return new Promise((resolve, reject) => {
                const url = helpers.buildURL(urls.UPDATE_UNIFIED_TASK, tftzId);
                Vue.http.patch(url, data).then((response) => {
                    dispatch(`get${Type}List`, state[`${type}Pagination`]);
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        // unifiedTaskDrone: 获取统防统治农机列表
        getUnifiedTaskDroneList({ commit, state }, { id, pagination = 1 }) {
            const type = 'unifiedTaskDrone';

            return new Promise((resolve, reject) => {
                commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                const url = helpers.buildURL(urls.GET_UNIFIED_TASK_DRONE_LIST, id);
                Vue.http.get(url, {
                    params: {
                        pageIndex: pagination,
                        size: state[`${type}PageSize`],
                        ...state[`${type}Filters`],
                    },
                }).then((response) => {
                    commit(types.UPDATE_LIST, { type, list: response.data || [] });
                    commit(types.UPDATE_CURRENT_PAGINATION, { type, pagination });
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    resolve(response);
                }).catch((error) => {
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    reject(error);
                });
            });
        },

        // unifiedTaskDrone: 更变统防统治农机列表分页Size
        changeUnifiedTaskDroneListPageSize({ commit, dispatch, state }, { id, size }) {
            const type = 'unifiedTaskDrone';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            commit(types.UPDATE_PAGE_SIZE, { type, size });
            dispatch(`get${Type}List`, { id, pagination: state[`${type}Pagination`] });
        },

        // unifiedTaskDrone: 创建统防统治农机
        createUnifiedTaskDrone({ dispatch, state }, { id, ...formData }) {
            const type = 'unifiedTaskDrone';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            const data = helpers.pick(formData, ['droneIds', 'dataStartDate', 'dataEndDate']);

            return new Promise((resolve, reject) => {
                const url = helpers.buildURL(urls.CREATE_UNIFIED_TASK_DRONE, id);
                Vue.http.post(url, data).then((response) => {
                    dispatch(`get${Type}List`, { id, pagination: state[`${type}Pagination`] });
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        // unifiedTaskDrone: 更新统防统治农机数据开放日期
        updateUnifiedTaskDroneDate({ dispatch, state }, { tftzId, droneId, ...formData }) {
            const type = 'unifiedTaskDrone';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            const data = helpers.pick(formData, ['dataStartDate', 'dataEndDate']);

            return new Promise((resolve, reject) => {
                const url = helpers.buildURL(urls.UPDATE_UNIFIED_TASK_DRONE_DATE, tftzId, droneId);
                Vue.http.put(url, data).then((response) => {
                    dispatch(`get${Type}List`, { id: tftzId, pagination: state[`${type}Pagination`] });
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        // unifiedTaskDrone: 删除统防统治农机
        deleteUnifiedTaskDrone({ dispatch, state }, { tftzId, droneId }) {
            const type = 'unifiedTaskDrone';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            return new Promise((resolve, reject) => {
                const url = helpers.buildURL(urls.DELETE_UNIFIED_TASK_DRONE, tftzId, droneId);
                Vue.http.delete(url).then((response) => {
                    dispatch(`get${Type}List`, { id: tftzId, pagination: state[`${type}Pagination`] });
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        // unifiedTaskStatDrone: 获取统防统治农机列表
        getUnifiedTaskStatDroneList({ commit }, { id }) {
            const type = 'unifiedTaskStatDrone';

            return new Promise((resolve, reject) => {
                commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                const url = helpers.buildURL(urls.GET_UNIFIED_TASK_STAT_DRONES, id);
                Vue.http.get(url).then((response) => {
                    commit(types.UPDATE_LIST, { type, list: response.data || [] });
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    resolve(response);
                }).catch((error) => {
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    reject(error);
                });
            });
        },

        // unifiedTaskStatOwner: 获取统防统治归属人列表
        getUnifiedTaskStatOwnerList({ commit }, { id }) {
            const type = 'unifiedTaskStatOwner';

            return new Promise((resolve, reject) => {
                commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                const url = helpers.buildURL(urls.GET_UNIFIED_TASK_STAT_OWNERS, id);
                Vue.http.get(url).then((response) => {
                    commit(types.UPDATE_LIST, { type, list: response.data || [] });
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    resolve(response);
                }).catch((error) => {
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    reject(error);
                });
            });
        },

        // unifiedTaskStatData: 获取统防统治植图表数据
        getUnifiedTaskStatDataList({ commit }, { id, droneIds, yyAccountIds, showItem, startTime, endTime }) {
            const type = 'unifiedTaskStatData';

            return new Promise((resolve, reject) => {
                commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                const url = helpers.buildURL(urls.GET_UNIFIED_TASK_STAT_DATA, id);
                Vue.http.get(url, {
                    params: { droneIds, yyAccountIds, showItem, startTime, endTime },
                }).then((response) => {
                    commit(types.UPDATE_LIST, { type, list: response.data || {} });
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    resolve(response);
                }).catch((error) => {
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    reject(error);
                });
            });
        },

        // unifiedTaskStatOverview: 获取统防统治植统计概览
        getUnifiedTaskStatOverview({ commit }, { id, droneIds, yyAccountIds, startTime, endTime, queryType = 2 }) {
            const type = 'unifiedTaskStatOverview';

            return new Promise((resolve, reject) => {
                const url = helpers.buildURL(urls.GET_UNIFIED_TASK_STAT_OVERVIEW, id);
                Vue.http.get(url, {
                    params: { droneIds, yyAccountIds, startTime, endTime, queryType },
                }).then((response) => {
                    commit(types.UPDATE_DETAIL, { type, detail: response.data });
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        // unifiedTaskDroneSortie: 获取列表
        getUnifiedTaskDroneSortieList({ commit }, { tftzId }) {
            const type = 'unifiedTaskDroneSortie';

            return new Promise((resolve, reject) => {
                commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                const url = helpers.buildURL(urls.GET_UNIFIED_TASK_SORTIE_LIST, tftzId);
                Vue.http.get(url).then((response) => {
                    const list = response.data || [];
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    commit(types.UPDATE_LIST, { type, list });
                    resolve(response);
                }).catch((error) => {
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    reject(error);
                });
            });
        },

        // end
    },
};
