<template>
    <el-dialog title="农机监管平台用户服务协议" :visible.sync="show" custom-class="ui-main-dialog" center @closed="handleClosed" width="960px" top="80px">
        <div class="content">
            <p><strong>1、农机监管平台服务条款确认</strong></p>
            <p>
                农机监管平台的各项网络电子服务的所有权和运营权归中国农业机械化协会（以下简称本协会）所有，任何单位和个人非经本协会授权不得以本协会及其所有的网站名义进行商业或非商业活动。
                农机监管平台提供的服务将严格按照本协会及本网发布的服务条款和操作规则执行。用户必须确认所有服条款并完成注册程序，才能成为农机监管平台的正式用户。
            </p>
            <p><strong>2、农机监管平台版权声明</strong></p>
            <p>
                农机监管平台旗下网站的所有内容，包括但不限于文字报导、图片、声音、录像、图表、标志、标识、广告、商标、商号、域名、软件、程序、版面设计、专栏目录与名称、内容分类标准以及为注册用户提供的任何或所有信息，
                均受《中华人民共和国著作权法》、《中华人民共和国商标法》、《中华人民共和国专利法》及适用之国际公约中有关著作权、商标权、专利权及其它财产所有权法律的保护，为本协会及其相关权利人专属所有或持有。
                使用者将农机监管平台提供的内容与服务用于非商业用途、非盈利、非广告目的而纯属个人使用时，应遵守著作权法以及其他相关法律的规定，不得侵犯农机监管平台及其相关权利人的合法权益。
                使用者将农机监管平台提供的内容与服务用于商业、盈利、广告性目的时，需征得农机监管平台及其相关权利人的书面特别授权，注明作者及文章出处“农机监管平台”，
                并按有关国际公约和中华人民共和国法律的有关规定向相关权利人支付费用。否则权利有将追究有关人员的侵权责任。
                未经农机监管平台的明确书面特别授权，任何人不得变更、发行、播送、转载、复制、重制、改动、散布、表演、展示或利用农机监管平台的局部或全部的内容或服务或在非农机监管平台所属的服务器上作镜像，
                否则以侵权论，依法追究法律责任。农机监管平台所使用的所有软件归属本协会其及软件提供商所有，受《中华人民共和国著作权法》及国际版权公约法律保护。
                除经本网特别说明用作销售或免费下载、使用等目的外， 任何其他用途包括但不限于复制、修改、经销、转储、发表、展示、演示以及反向工程均在严格禁止之列，任何单位和个人非法使用均构成对本协会及本网的侵权。
            </p>
            <p><strong>3、服务约定</strong></p>
            <p>
                农机监管平台运用自己的电脑操作系统通过国际互联网络为用户提供网络服务。由于服务栏目和内容的不同，要求用户必须： (1)自行配备上网的所需设备， 包括个人电脑、调制解调器或其他必备上网装置。
                (2)自行负担个人上网所支付的与此服务有关的电话费用、 网络费用。 (3)提供详尽、准确的个人资料。 (4)不断更新注册资料，符合及时、详尽、准确的要求。
                如果用户提供的资料包含有不正确的信息，农机监管平台保留结束用户使用网络服务资格的权利。
            </p>
            <p><strong>4、服务修订</strong></p>
            <p>
                农机监管平台根据自身的服务内容，以及与用户达成的协议提供服务。用户在享用本网提供的专项服务的同时，同意接受网站提供的各类信息服务。
                农机监管平台有权在必要时修改服务条款， 农机监管平台服务条款一旦发生变动，将会在重要页面上提示修改内容。
                如果不同意所改动的内容，用户可以在与本网取得联系后取消获得的网络服务。如果用户继续享用网络服务，则视为接受服务条款的变动。
                农机监管平台保留随时修改或中断服务而不需提前告知用户的权利。农机监管平台行使修改或中断服务的权利，不需对用户或第三方负责。
            </p>
            <p><strong>5、注册用户隐私保护</strong></p>
            <p>
                用户一旦注册成功，成为农机监管平台的合法用户，将得到一个密码和用户名。 由用户对用户名和密码安全负全部责任。另外，每个用户都要对以其用户名进行的所有活动和事件负全责。您可随时根据规则改变您的密码。
                用户若发现任何非法使用用户帐号或存在安全漏洞的情况，请立即通告农机监管平台。 农机监管平台尊重用户个人隐私，未经合法用户授权，本网不会在公开、编辑或透露其注册资料及保存在本网的非公开内容，
                除非有法律许可要求或未经合法用户授权在诚信的基础上认为透露这些信息是必要的： (1)用户授权本网或授权某人通过电子邮件服务透露这些信息。 (2)相应的法律及程序要求本网提供用户的个人资料。
                (3)遵守有关法律规定，遵从本网合法服务程序。 (4)保持维护本网的商标所有权。 (5)在紧急情况下竭力维护用户个人和社会大众的隐私安全。 (6)符合其他相关的要求。
            </p>
            <p><strong>6、风险承担</strong></p>
            <p>
                用户个人对网络服务的使用自行承担风险。农机监管平台所提供的所有资料权限于对用户参考，不对用户的商业运作做任何具体性指导，用户应自行承担使用或提供本网信息的商业活动及其风险。
                农机监管平台不保证服务一定能满足用户的要求，也不保证服务不会受中断。本网将尽力保证服务的及时性、准确性、安全性，但对及时性、准确性、安全性等都不作任何具体承诺。
                对用户在运用农机监管平台得到的任何商品购物服务或交易进程，均不作任何担保。
            </p>
            <p><strong>7、免责事由</strong></p>
            <p>
                （1）农机监管平台对用户在接受服务过程中所受的任何直接、间接的损害不负责任，这些损害可能来自：不正当使用网络服务，在网上购买商品或进行同类型服务，在网上进行交易，非法使用网络服务或用户传送的信息有所变动等。
                （2）农机监管平台不对用户所发布信息的删除或储存失败负责。农机监管平台有判定用户的行为是否符合本网服务条款的要求和精神的保留权利，如果用户违背了服务条款的规定，农机监管平台有中断对其提供网络服务的权利。
                （3）对用户自行提供的信息，由用户依法自行承担全部责任。农机监管平台对此等信息的准确性、完整性、合法性或真实性均不承担任何责任。
                （4）用户在本网论坛所发表的任何意见均属于个人意见，并不代表本协会及农机监管平台也持同样的观点。
            </p>
            <p><strong>8、用户承诺</strong></p>
            <p>
                用户自行承担发布内容的责任。用户对服务的使用是根据所有适用于农机监管平台的国家法律、地方法律和国际法律准则的规定的。 用户必须遵循： (1)用户必须提供真实的自我信息，对其在本网站上发布的信息负责全部责任。
                (2)从中国境内向外传输资料信息时必须符合中国有关法规。 (3)使用网络服务不作非法用途。 (4)不干扰或攻击网络服务。 (5)遵守所有使用网络服务的网络协议、规定、程序和惯例。
                用户须承诺不传输任何非法的、骚扰性的、中伤他人的、辱骂性的、恐吓性的、伤害性的、庸俗的，淫秽等信息资料。另外，用户也不能传输任何教唆他人构成犯罪行为的资料；不能传输涉及国家安全的资料；
                不能传输任何不符合当地法规、国家法律和国际法律的资料。禁止未经许可而非法进入其它电脑系统。用户需对自己在网上的行为承担法律责任。
                用户若在农机监管平台上散布和传播反动、色情或其他违反国家法律的信息，本网的系统记录有可能作为用户违反法律的证据。农机监管平台有权对违法行为作出独立判断并立即取消用户服务。
                用户特别承诺：用户一旦在农机监管平台网站注册，即以明示或默示的方式同意并允许农机监管平台将用户的商号、商标等用于农机监管平台信息及开发的衍生产品中。
                但农机监管平台在使用过程中应保证其公正的立场，正确反应客观事实，不得恶意诋毁或损害用户的名誉。
            </p>
            <p><strong>9、服务终止或中止</strong></p>
            <p>
                农机监管平台和用户可根据合同约定或实际情况终止、中止一项或多项网络服务。农机监管平台不需对任何个人或第三方负责而随时中止服务。用户对后来的条款修改有异议，或对本网的服务不满，可以行使如下权利：
                (1)停止使用本网的网络服务。 (2)通告本网停止对该用户的服务。 结束用户服务后，用户使用网络服务的权利立即终止或中止。从终止或中止之时，用户没有权利，本网也没有义务传送任何未处理的信息或未完成的服务给用户或第三方。
            </p>
            <p><strong>10、通告形式</strong></p>
            <p>农机监管平台服务条款的修改、服务变更、或其它重要事件发生变动而需要通告时，可根据实际情况选择通过重要页面公告、电子邮件、常规信件等形式进行。</p>
            <p><strong>11、法律适用</strong></p>
            <p>
                本网网络服务条款与中华人民共和国的法律解释相一致，用户和农机监管平台一致同意服从中华人民共和国法律的管辖。
                如发生农机监管平台服务条款与中华人民共和国法律相抵触时，则这些条款将完全按法律规定重新解释，而其它条款则依旧保持对用户产生法律效力和影响。
            </p>
        </div>
    </el-dialog>
</template>

<script>
    export default {
        name: 'UserAgreement',

        props: {
            visible: {
                type: Boolean,
                default: false,
            },
            value: {
                type: Object,
                default: () => ({}),
            },
        },

        computed: {
            show: {
                get() {
                    return this.visible;
                },
                set(val) {
                    this.$emit('update:visible', val);
                },
            },
        },

        methods: {
            handleClosed() {
                this.$emit('closed');
            },
        },
    };
</script>

<style lang="scss" scoped>
</style>
