import { render, staticRenderFns } from "./Panel1.vue?vue&type=template&id=b90573dc&"
import script from "./Panel1.vue?vue&type=script&lang=js&"
export * from "./Panel1.vue?vue&type=script&lang=js&"
import style0 from "./Panel1.vue?vue&type=style&index=0&id=b90573dc&prod&lang=scss&module=true&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

/* custom blocks */
import block0 from "./languages.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fhome%2Fjiagu%2F.jenkins%2Fworkspace%2Fag-uav-console%2Fsrc%2Fviews%2FStatSmallScreen%2FPanel1.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports