<template>
    <div :class="$style.root" v-loading="loading">
        <template v-if="!loading">
            <i class="iconfont icon-empty"></i>
            <div>
                <slot></slot>
            </div>
        </template>
    </div>
</template>

<script>
    export default {
        name: 'Empty',

        props: {
            loading: {
                type: Boolean,
                default: false,
            },
        },
    };
</script>

<style lang="scss" module>
    .root {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        min-height: 70px;

        :global {
            .icon-empty {
                font-size: 48px;
                line-height: 1;
            }

            .el-loading-mask {
                background-color: rgba(0, 0, 0, 0.2);

                svg {
                    position: relative;
                }
            }
        }
    }
</style>
