<template>
    <el-cascader
        v-model="region"
        :options="regions"
        :placeholder="placeholder"
        :props="{ label: 'areaName', value: 'areaName' }"
        @change="handleChange"
        @blur="handleBlur"
        @visible-change="visibleChange"
        :filterable="filterable"
        :filter-method="filterMethod"
        :change-on-select="changeOnSelect"
        :clearable="clearable">
    </el-cascader>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import emitter from 'element-ui/src/mixins/emitter';
    // import { getDistrictLabel } from '../../utils/cascadeDistrict';

    export default {
        name: 'RegionCascader',

        mixins: [emitter],

        props: {
            value: {
                type: String,
                default: '',
            },
            placeholder: {
                type: String,
                default: undefined,
            },
            changeOnSelect: {
                type: Boolean,
                default: false,
            },
            clearable: {
                type: Boolean,
                default: false,
            },
            filterable: {
                type: Boolean,
                default: false,
            },
            country: {
                type: String,
                default: '',
            },
            province: {
                type: String,
                default: '',
            },
            city: {
                type: String,
                default: '',
            },
            county: {
                type: String,
                default: '',
            },
            regionCode: {
                type: [String, Number],
                default: '',
            },
        },

        computed: {
            ...mapGetters('user', ['adCode']),
            ...mapGetters('common', ['regionList', 'chineseCity']),

            regions() {
                if (!this.adCode) return this.regionList;

                const countyCode = `${this.adCode}`;
                const cityCode = countyCode.replace(/\d{2}$/, '00');
                const provinceCode = countyCode.replace(/\d{4}$/, '0000');

                // return this.regionList.map((province) => {
                return [
                    {
                        areaId: '156',
                        areaName: '中国',
                        children: this.chineseCity.map((province) => {
                            if (province.areaId !== provinceCode) return false;
                            if (this.adCode % 10000 === 0) return province;
                            const { areaName: provinceLabel, areaId: provinceValue, children: provinceChildren } = province;
                            const cities = provinceChildren.map((city) => {
                                if (city.areaId !== cityCode) return false;
                                if (this.adCode % 100 === 0) return city;
                                const { areaName: cityLabel, areaId: cityValue, children: cityChildren } = city;
                                const counties = cityChildren.map(county => (county.areaId === countyCode ? county : false)).filter(Boolean);
                                return { areaName: cityLabel, areaId: cityValue, children: counties };
                            }).filter(Boolean);
                            return { areaName: provinceLabel, areaId: provinceValue, children: cities };
                        }).filter(Boolean),
                    }];
            },

            region: {
                get() {
                    // if (!this.value) return [];
                    //
                    // const countyCode = `${this.value}`;
                    //
                    // if (countyCode.length <= 3) return [countyCode];
                    // // 国外
                    // if (countyCode.length > 6) return [countyCode.slice(0, -6), countyCode];
                    //
                    // // // 中国
                    // // if (countyCode.length === 6) {
                    // const cityCode = countyCode.replace(/\d{2}$/, '00');
                    // const provinceCode = countyCode.replace(/\d{4}$/, '0000');
                    //
                    // // 省
                    // if (provinceCode === countyCode) return ['156', provinceCode];
                    // // 市
                    // if (cityCode === countyCode) {
                    //     return ['156', provinceCode, cityCode];
                    // }
                    // // 台湾、香港、澳门 特殊处理
                    // if (provinceCode === '710000' || provinceCode === '810000' || provinceCode === '820000') {
                    //     return ['156', provinceCode, countyCode];
                    // }
                    // // 县
                    // return ['156', provinceCode, cityCode, countyCode];
                    // const regionItem = this.regions.find(item => item.areaName === (this.country || '中国'));
                    // if (!regionItem) {
                    //     return [];
                    // }
                    // console.log('regionItem', regionItem);
                    // const provinceItem = (regionItem?.children || []).find(item => item.areaName === this.province);
                    // const cityItem = (provinceItem?.children || []).find(item => item.areaName === this.city);
                    // const countyItem = (cityItem?.children || []).find(item => item.areaName === this.county);
                    // console.log('provinceItem', provinceItem);
                    // console.log('cityItem', cityItem);
                    // console.log('countyItem', countyItem);
                    // if (this.county) {
                    //     return [regionItem.areaId, provinceItem?.areaId, cityItem?.areaId, countyItem?.areaId];
                    // }
                    // if (this.city) {
                    //     return [regionItem.areaId, provinceItem?.areaId, cityItem?.areaId];
                    // }
                    // if (this.province) {
                    //     return [regionItem.areaId, provinceItem?.areaId];
                    // }
                    // return [regionItem.areaId];
                    // return [this.country, this.province, this.city, this.county].filter(Boolean);

                    return this.value.split('/').filter(Boolean);
                },
                set(val) {
                    // console.log(val);
                    // const [country = '', province = '', city = '', county = ''] = val || [];
                    // this.$emit('update:province', (val || []).toString().replaceAll(',', '/'));
                    this.$emit('input', (val || []).toString().replaceAll(',', '/'));
                    this.$emit('change', (val || []).toString().replaceAll(',', '/'));
                    // const code = val[val.length - 1] || '';
                    // this.$emit('input', code);

                    // this.$emit('update:country', country);
                    // this.$emit('update:province', province);
                    // this.$emit('update:city', city);
                    // this.$emit('update:county', county);

                    // const regionItem = this.regions.find(item => item.areaName === country);
                    // const provinceItem = (regionItem?.children || []).find(item => item.areaName === province);
                    // const cityItem = (provinceItem?.children || []).find(item => item.areaName === city);
                    // const countyItem = (cityItem?.children || []).find(item => item.areaName === county);
                    //
                    // const codes = [regionItem.areaId, provinceItem?.areaId, cityItem?.areaId, countyItem?.areaId].filter(Boolean);
                    // const code = codes[codes.length - 1] || '';
                    // this.$emit('update:regionCode', code);
                },
            },
        },

        mounted() {
            // if (!this.regionList.length) {
            //     this.handleLoadRegions();
            // }
            this.handleLoadRegions();
        },

        methods: {
            ...mapActions('common', ['getRegionList', 'getCountryList', 'getAllRegionList']),

            filterMethod(node, keyword = '') {
                return (node.text || '').toLowerCase().indexOf(keyword.toLowerCase()) > -1;
            },

            // 加载地区
            handleLoadRegions() {
                // this.getCountryList().then(() => {
                //     this.getRegionList().catch(({ message }) => {
                //         if (message) this.$message.error(message);
                //     });
                // }).catch(({ message }) => {
                //     if (message) this.$message.error(message);
                // });

                this.getAllRegionList().catch(({ message }) => {
                    if (message) this.$message.error(message);
                });
            },

            handleChange() {
                this.dispatch('ElFormItem', 'el.form.change', [!!this.county]);
            },

            handleBlur() {
                this.dispatch('ElFormItem', 'el.form.blur', [!!this.county]);
            },

            visibleChange(val) {
                if (val) {
                    // 此处可以出来选中下拉选项，清空input搜索值
                }

                // element ui 级联选择器  可搜索功能 卡顿解决
                this.$nextTick(() => {
                    const $el = document.querySelectorAll('.el-cascader-panel .el-cascader-node[aria-owns]');
                    Array.from($el).map(item => item.removeAttribute('aria-owns'));
                });
            },
        },
    };
</script>

<style lang="scss" scoped>
</style>
