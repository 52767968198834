/**
 * 账户管理
 */

const AccountList = () => import(/* webpackChunkName: "chunk!account-list" */'../views/AccountManage/Account/AccountList.vue');
const AccountApplyList = () => import(/* webpackChunkName: "chunk!account-apply-list" */'../views/AccountManage/AccountApply/AccountApplyList.vue');
const UserApplyList = () => import(/* webpackChunkName: "chunk!account-apply-list" */'../views/AccountManage/UserApply/UserApplyList.vue');
const SubCompanyList = () => import(/* webpackChunkName: "sub-company-list" */'../views/SubCompanyManage/SubCompanyList.vue');

const group = 'institution_manage';

export default [
    {
        path: `/${group}/account-list`,
        name: 'AccountList',
        component: AccountList,
        meta: { group },
    },
    {
        path: `/${group}/account-apply-list`,
        name: 'AccountApplyList',
        component: AccountApplyList,
        meta: { group },
    },
    {
        path: `/${group}/user-apply-list`,
        name: 'UserApplyList',
        component: UserApplyList,
        meta: { group },
    },
    {
        path: `/${group}/sub-company-list`,
        name: 'SubCompanyList',
        component: SubCompanyList,
        meta: { group },
    },
];
