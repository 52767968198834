/**
 * 退出
 */
import { mapActions } from 'vuex';
import * as auth from '../utils/auth';
import commonRefs from '../utils/commonRefs';

export default {
    methods: {
        ...mapActions('user', ['requestLogout']),
        ...mapActions('monitor', ['clearMonitorQueries']),

        handleLogout() {
            this.$confirm(this.$t('dialog.exit_tips'), this.$t('dialog.title'), {
                confirmButtonText: this.$t('dialog.confirm'),
                cancelButtonText: this.$t('dialog.cancel'),
                type: 'warning',
            }).then(() => {
                // this.requestLogout().then(() => {
                //     this.$message.success('已退出');
                //     this.$router.replace('/login');
                // }).catch(({ message }) => {
                //     if (message) {
                //         this.$message.error(message);
                //     }
                // });
                commonRefs.getRef('singletonMapComponent').then((ref) => {
                    ref.restore();
                });
                this.logout();
            }).catch(() => {});
        },

        logout() {
            auth.removeToken();
            auth.removeUserInfo();
            this.clearMonitorQueries();
            this.$router.replace('/login');
            this.$message.success(this.$t('dialog.exited'));
        },
    },
};
