/**
 * 接口列表
 */

const urls = {
    development: process.env.VUE_APP_API_BASE_URL,
    // production: `http://${window.location.host}/api`,
    production: `${window.location.origin}/api`,
};
const { [process.env.NODE_ENV]: url } = urls;
export const BASE_URL = url || urls.development;
// export const BASE_URL = '/api' ||url || urls.development;
// export const BASE_URL = 'http://ag.jiagutech.com/api';

// 上传图片
export const REQUEST_UPLOAD_IMAGE = `${BASE_URL}/user/upload`;

// 上传添加的流量包
export const REQUEST_UPLOAD_ADD_TRAFFIC_PACKAGE = `${BASE_URL}/user/user/manage/addPackage`;

// 上传删除的流量包
export const REQUEST_UPLOAD_DELETE_TRAFFIC_PACKAGE = `${BASE_URL}/user/user/manage/deletePackage`;

// 上传添加的原始机型
export const REQUEST_UPLOAD_ADD_ORIGINAL_MODEL = `${BASE_URL}/drone/device/manage/addOriFlyModel`;

// 获取图像验证码
export const REQUEST_IMG_VERIFY_CODE = `${BASE_URL}/user/baseaccount/getVerify`;
// 请求短信验证码、校验验证码
export const REQUEST_SMS_VERIFY_CODE = `${BASE_URL}/user/baseaccount/getVerifyCode`;
export const REQUEST_CHECK_SMS_VERIFY_CODE = `${BASE_URL}/user/baseaccount/checkVerifyCode`;
// 请求密码登录、重置密码
export const REQUEST_PASSWORD_LOGIN = `${BASE_URL}/user/baseaccount/loginPd`;
export const REQUEST_RESET_PASSWORD = `${BASE_URL}/user/baseaccount/resetPassword`;
export const REQUEST_REFRESH_TOKEN = `${BASE_URL}/user/baseaccount/refreshToken`;
// 请求短信验证码登录
export const REQUEST_SMS_LOGIN = `${BASE_URL}/user/baseaccount/loginSmS`;
// 请求退出
export const REQUEST_LOGOUT = `${BASE_URL}/user/baseaccount/loginOut`;
// 注册账户
export const REQUEST_REGISTER = `${BASE_URL}/user/baseaccount/reg`;

// 设备管理
export const GET_DRONE_LIST = `${BASE_URL}/drone/device/manage/getDroneList`;
export const GET_DRONE_DETAIL = `${BASE_URL}/drone/device/manage/getDroneDetail`;
export const UPDATE_DRONE_LOCK = `${BASE_URL}/drone/device/manage/lockOpr`;
export const UPDATE_DRONE_SALE_STATUS = `${BASE_URL}/drone/device/manage/{droneId}/saleType`;
export const UPDATE_DRONE = `${BASE_URL}/drone/device/manage/updateDrone`;
export const GET_DRONE_MODEL_LIST = `${BASE_URL}/drone/device/manage/getFlyModelList`;
export const CREATE_DRONE_MODEL = `${BASE_URL}/drone/device/manage/addFlyModel`;
export const UPDATE_DRONE_MODEL = `${BASE_URL}/drone/device/manage/updateFlyModel`;
export const DELETE_DRONE_MODEL = `${BASE_URL}/drone/device/manage/deleteFlyModel/{modelId}`;
export const REQUEST_MERGE_DRONE_MODEL = `${BASE_URL}/drone/device/manage/mergeFlyModel`;
export const ADD_DRONE_MODEL_RANGE = `${BASE_URL}/drone/device/manage/addFlyModelIdRange`;
export const GET_OWN_DRONE_MODEL_SELECT_LIST = `${BASE_URL}/drone/device/manage/flyModelList`;
export const GET_DRONE_LOG_LIST = `${BASE_URL}/oper/droneFiles/{droneId}`;
export const GET_DRONE_SORTIES_STAT = `${BASE_URL}/oper/drones-sorties/statistics/{droneId}`;
export const UPDATE_MANUFACTURER_SIGN = `${BASE_URL}/drone/device/manage/{droneId}/zzDroneNum`;
export const UPDATE_DEVICE_TYPE = `${BASE_URL}/drone/device/manage/{droneId}/deviceType`;
export const GET_DEVICE_WORKING_PICTURES = `${BASE_URL}/oper/sortie/pictures`;

// 账户管理
export const GET_ACCOUNT_LIST = `${BASE_URL}/user/account/manage/getAccountList`;
export const GET_ACCOUNT_DETAIL = `${BASE_URL}/user/account/manage/getAccountDetail`;
export const CREATE_ACCOUNT = `${BASE_URL}/user/account/manage/addAccount`;
export const UPDATE_ACCOUNT = `${BASE_URL}/user/account/manage/updateAccount`;
export const DELETE_ACCOUNT = `${BASE_URL}/user/account/manage/deleteAccount/{accountId}`;
export const UPDATE_ACCOUNT_ENABLE = `${BASE_URL}/user/account/manage/enableAccount`;
export const UPDATE_ACCOUNT_APPLY_STATUS = `${BASE_URL}/user/account/manage/applyCheck`;
// 账户申请管理
export const GET_ACCOUNT_APPLY_LIST = `${BASE_URL}/user/account/manage/getApplyList`;
export const GET_ACCOUNT_APPLY_DETAIL = `${BASE_URL}/user/account/manage/getApplyDetail`;
// 账号申请管理
export const GET_USER_APPLY_LIST = `${BASE_URL}/user/user/manage/getApplyList`;
export const GET_USER_APPLY_DETAIL = `${BASE_URL}/user/user/manage/getApplyDetail`;

// 我的企业、信息、组织
export const GET_OWN_ACCOUNT_DETAIL = `${BASE_URL}/user/user/manage/getUserAccountDetail`;
export const REQUEST_SUBMIT_ACCOUNT_INFO = `${BASE_URL}/user/user/manage/commitAccountInfo`;
export const CREATE_MANUFACTURER_SECRET_KEY = `${BASE_URL}/user/user/manage/saveKey`;
export const DOWNLOAD_MANUFACTURER_SECRET_KEY = `${BASE_URL}/user/user/manage/downloadKey`;
export const GET_SYSTEM_LOG_LIST = `${BASE_URL}/user/user/manage/getLogList`;
export const GET_MEMBER_LIST = `${BASE_URL}/user/user/manage/getUserList`;
export const CREATE_MEMBER = `${BASE_URL}/user/user/manage/addUser`;
export const UPDATE_MEMBER = `${BASE_URL}/user/user/manage/updateUser`;
export const UPDATE_MEMBER_ENABLE = `${BASE_URL}/user/user/manage/enableUser`;
export const DELETE_MEMBER = `${BASE_URL}/user/user/manage/deleteUser/{userId}`;
export const REQUEST_MANAGE_SMS_VERIFY_CODE = `${BASE_URL}/user/user/manage/getVerifyCode`;
export const REQUEST_CHECK_MANAGE_VERIFY_CODE = `${BASE_URL}/user/user/manage/checkAdminVerifyCode`;
export const UPDATE_OWN_PROFILE = `${BASE_URL}/user/user/manage/updateUserPh`;
export const CREATE_SUB_COMPANY = `${BASE_URL}/user/account/manage/addSubAccount`;
export const CREATE_MANUFACTURER_COMPANY = `${BASE_URL}/user/account/manage/addZzAccount`;

// 团队管理
export const GET_TEAM_LIST = `${BASE_URL}/oper/groups`;
export const CREATE_TEAM = `${BASE_URL}/oper/groups`;
export const UPDATE_TEAM_NAME = `${BASE_URL}/oper/groups/updateName`;
export const DELETE_TEAM = `${BASE_URL}/oper/groups/{groupId}`;
export const GET_TEAM_MEMBER_LIST = `${BASE_URL}/oper/groups/userList/{groupId}`;
export const UPDATE_TEAM_LEADER = `${BASE_URL}/oper/groups/transferLeader`;
export const GET_TEAM_SELECTABLE_MEMBER = `${BASE_URL}/oper/groups/addableUserList/{groupId}`;
export const REQUEST_ADD_TEAM_MEMBER = `${BASE_URL}/oper/groups-users`;
export const DELETE_TEAM_MEMBER = `${BASE_URL}/oper/groups-users/{groupId}/{userId}`;

// 订单管理
export const GET_ORDER_LIST = `${BASE_URL}/oper/payOrders`;
export const GET_INVOICE_INFO = `${BASE_URL}/oper/user-taxs`;
export const UPDATE_INVOICE_INFO = `${BASE_URL}/oper/user-taxs`;
export const REQUEST_APPLY_INVOICE = `${BASE_URL}/oper/taxTickets`;
export const CREATE_ORDER = `${BASE_URL}/oper/payOrders/native`;
export const GET_ORDER_DETAIL = `${BASE_URL}/oper/payOrders/orderNum/{orderNum}`;

// 发票管理
export const GET_INVOICE_LIST = `${BASE_URL}/oper/taxTickets`;
export const UPDATE_INVOICE_DEAL_STATUS = `${BASE_URL}/oper/taxTickets/deal/{ticketId}`;

// 公告管理
export const GET_BULLETIN_LIST = `${BASE_URL}/user/bulletin/manage/getBulletinList`;
export const GET_ODD_BULLETIN_LIST = `${BASE_URL}/user/bulletin/manage/getOddBulletinList`;
export const CREATE_BULLETIN = `${BASE_URL}/user/bulletin/manage/addBulletin`;
export const UPDATE_BULLETIN_READ = `${BASE_URL}/user/bulletin/manage/markRead`;

// 禁飞区管理
export const GET_NO_FLY_ZONE_LIST = `${BASE_URL}/drone/nofly/manage/getNoflyList`;
export const GET_NO_FLY_ZONE_DETAIL = `${BASE_URL}/drone/nofly/manage/getNoflyInfo`;
export const DELETE_NO_FLY_ZONE = `${BASE_URL}/drone/nofly/manage/deleteNofly/{noflyId}`;
export const CREATE_NO_FLY_ZONE = `${BASE_URL}/drone/nofly/manage/addNofly`;
export const UPDATE_NO_FLY_ZONE = `${BASE_URL}/drone/nofly/manage/updateNofly`;
export const UPDATE_NO_FLY_ZONE_ENABLE = `${BASE_URL}/drone/nofly/manage/enablenofly`;

// 数据统计
export const GET_DATA_STAT_LIST = `${BASE_URL}/drone/datastat/manage/getStatList`;
export const GET_DATA_STAT_DETAIL = `${BASE_URL}/drone/datastat/manage/getStatDetail`;
export const CREATE_DATA_STAT_SETTINGS = `${BASE_URL}/drone/datastat/manage/startStat`;
export const UPDATE_DATA_STAT_SETTINGS = `${BASE_URL}/drone/datastat/manage/updateStat`;
export const DELETE_DATA_STAT = `${BASE_URL}/drone/datastat/manage/delStat/{statId}`;
export const CREATE_DATA_STAT_WITH_DETAIL = `${BASE_URL}/drone/datastat/manage/startStatWithChart`;
export const CREATE_DATA_STAT_OVERVIEW = `${BASE_URL}/drone/datastat/manage/off-line`;
export const GET_DRONE_STATISTICS_LIST = `${BASE_URL}/drone/device/manage/statisticList`;

// 数据大屏
export const GET_CHINA_AND_FOREIGN_SPRAY_AREA_PER_PERIOD = `${BASE_URL}/drone/datastat/manage/chinaWithForeign_sprayArea`;
export const GET_FOREIGN_SPRAY_AREA_PER_PERIOD = `${BASE_URL}/drone/datastat/manage/withForeign_sprayArea`;
export const GET_ALL_SPRAY_AREA_PER_HOUR = `${BASE_URL}/drone/datastat/manage/hour_sprayArea`;
export const GET_THIS_YEAR_MONTH_SPRAY_AREA_PER_HOUR = `${BASE_URL}/drone/datastat/manage/yearWithMonth/hour_sprayArea`;
export const GET_PERIOD_SPRAY_AREA_PER_MODE = `${BASE_URL}/drone/datastat/manage/flyModule_sprayArea`;
export const GET_DEVICE_SPRAY_AREA_RANK = `${BASE_URL}/drone/datastat/manage/drone_sprayArea_order`;
export const GET_USER_SPRAY_AREA_RANK = `${BASE_URL}/drone/datastat/manage/user_sprayArea_order`;
export const GET_STAT_SCREEN_OVERVIEW = `${BASE_URL}/drone/datastat/manage/stat_outline`;
export const GET_FOREIGN_PERIOD_SPRAY_AREA_PER_REGION = `${BASE_URL}/drone/datastat/manage/foreign_region_sprayArea`;
export const GET_ALL_DEVICES_SPRAY_AREA_RANK = `${BASE_URL}/drone/datastat/manage/drone_sprayArea_order_total`;
export const GET_ALL_USERS_SPRAY_AREA_RANK = `${BASE_URL}/drone/datastat/manage/user_sprayArea_order_total`;
export const GET_TODAY_DATA_OVERVIEW = `${BASE_URL}/drone/datastat/manage/stat_outline_day`;

// 实时监控
export const GET_NOW_MONITOR = `${BASE_URL}/drone/mointor/manage/nowMointor`;
export const GET_MONITOR_NO_FLY_ZONE_LIST = `${BASE_URL}/drone/mointor/manage/noflys`;
export const GET_MONITOR_NO_FLY_ZONE_DETAIL = `${BASE_URL}/drone/mointor/manage/getNoflyZoneDetail`;
export const GET_MONITOR_BLOCK_LIST = `${BASE_URL}/drone/mointor/manage/blocks`;
export const GET_DRONE_HISTORY_LIST = `${BASE_URL}/drone/mointor/manage/getHistoryFlyList`;
export const GET_MOCK_DRONE_HISTORY_LIST = `${BASE_URL}/drone/mointor/manage/getSimulateFlyList`;
export const GET_DRONE_SORTIE_DETAIL = `${BASE_URL}/drone/mointor/manage/getSortieDetail`;
export const GET_MONITOR_DRONE_DETAIL = `${BASE_URL}/drone/mointor/manage/getDroneDetail`;
export const GET_ONLINE_DRONE_LIST = `${BASE_URL}/drone/mointor/manage/onlineDrones`;
export const GET_OFFLINE_DRONE_LIST = `${BASE_URL}/drone/mointor/manage/offlineDrones`;
export const GET_ONLINE_DRONE_TRACK = `${BASE_URL}/drone/mointor/manage/getTrackByDroneId`;

// 作业管理
export const GET_UNIFIED_TASK_LIST = `${BASE_URL}/tftz/tftzs`;
export const CREATE_UNIFIED_TASK = `${BASE_URL}/tftz/tftzs`;
export const UPDATE_UNIFIED_TASK = `${BASE_URL}/tftz/tftzs/{tftzId}`;
export const GET_UNIFIED_TASK_DRONE_LIST = `${BASE_URL}/tftz/tftzs/{tftzId}/drones`;
export const CREATE_UNIFIED_TASK_DRONE = `${BASE_URL}/tftz/tftzs/{tftzId}/drones`;
export const DELETE_UNIFIED_TASK_DRONE = `${BASE_URL}/tftz/tftzs/{tftzId}/{droneId}`;
export const UPDATE_UNIFIED_TASK_DRONE_DATE = `${BASE_URL}/tftz/tftzs/{tftzId}/drones/{droneId}/dataDate`;
export const GET_UNIFIED_TASK_STAT_DRONES = `${BASE_URL}/tftz/tftzs/{tftzId}/droneGroup`;
export const GET_UNIFIED_TASK_STAT_OWNERS = `${BASE_URL}/tftz/tftzs/{tftzId}/yyAccountGroup`;
export const GET_UNIFIED_TASK_STAT_OVERVIEW = `${BASE_URL}/tftz/tftz-statistics/{tftzId}`;
export const GET_UNIFIED_TASK_STAT_DATA = `${BASE_URL}/tftz/tftz-charts/{tftzId}`;
export const GET_UNIFIED_TASK_LOG_FILE = `${BASE_URL}/tftz/tftz-files/{tftzId}`;
export const GET_UNIFIED_TASK_SORTIE_LIST = `${BASE_URL}/tftz/tftzs/{tftzId}/sorties`;

// 农机补贴
export const GET_SUBSIDY_LIST = `${BASE_URL}/tftz/subsidys`;
export const GET_SUBSIDY_SUMMARY = `${BASE_URL}/tftz/subsidys-statistic`;
export const REQUEST_UPLOAD_SUBSIDY_DATA = `${BASE_URL}/tftz/subsidys`;
export const DELETE_SUBSIDY = `${BASE_URL}/tftz/subsidys/{subsidyId}`;
export const GET_SUBSIDY_STAT_DATA = `${BASE_URL}/tftz/subsidys-chart`;
export const GET_SUBSIDY_TRACK_LIST = `${BASE_URL}/tftz/track-subsidy/{subsidyId}`;

// rtk
export const GET_RTK_LIST = `${BASE_URL}/rtk/info`;
export const UPDATE_RTK_ALLOW_STATUS = `${BASE_URL}/rtk/device/{id}/allow`;
export const UPDATE_RTK_DEVICE = `${BASE_URL}/rtk/binding`;

// 地块
export const GET_BLOCK_LIST = `${BASE_URL}/oper/blocks`;
export const CREATE_BLOCK = `${BASE_URL}/oper/blocks`;
export const UPDATE_BLOCK = `${BASE_URL}/oper/blocks`;
export const GET_BLOCK_DETAIL = `${BASE_URL}/oper/blocks/{blockId}`;
export const DELETE_BLOCKS = `${BASE_URL}/oper/blocks/{blockIds}`;
export const FORCE_DELETE_BLOCKS = `${BASE_URL}/oper/blocks/{blockId}/force`;
export const SHARE_BLOCK = `${BASE_URL}/oper/blocks-share/{blockId}`;
export const REQUEST_IMPORT_BLOCKS = `${BASE_URL}/route/block`;
export const REQUEST_EXPORT_BLOCKS = `${BASE_URL}/oper/export/block`;

// 集群控制
export const CREATE_GROUP_WORKING_ROUTE = `${BASE_URL}/route/split/uType`;
export const UPDATE_GROUP_DONE_STATUS = `${BASE_URL}/remote/control/{droneId}`;

// 工具接口
export const UPDATE_DRONE_OWNER = `${BASE_URL}/drone/extra/drones`;
export const REQUEST_MERGE_MANUFACTURER = `${BASE_URL}/drone/extra/zzAccounts/merge`;

// 公共接口
export const GET_MANUFACTURER_SELECT_LIST = `${BASE_URL}/drone/common/manufactures`;
export const GET_OPERATOR_SELECT_LIST = `${BASE_URL}/user/account/manage/getYyAccountList`;
export const GET_DRONE_MODEL_SELECT_LIST = `${BASE_URL}/drone/common/models/{manufacturer_id}`;
export const GET_UNREAD_BULLETINS = `${BASE_URL}/user/bulletin/manage/getUnReadCount`;
export const GET_COUNTRY_LIST = `${BASE_URL}/oper/regions/countries`;
export const GET_ALL_REGION_LIST = `${BASE_URL}/oper/regions/allCountries`;
export const GET_CHINA_REGION_LIST = `${BASE_URL}/oper/regions/tree`;
export const GET_WORK_TYPE_LIST = `${BASE_URL}/oper/worktypes`;
export const GET_TRACK_PURE_LIST = `${BASE_URL}/drone/device/manage/tracks/brief`;
export const GET_CROP_LIST = `${BASE_URL}/oper/crops`;
export const RENEW_RTK_AVAILABLE_DAYS = `${BASE_URL}/user/user/manage/rtk/renew_add/{availableDays}`;
export const RENEW_RTK_AVAILABLE_DAYS_WITH_BTADDR = `${BASE_URL}/user/user/manage/rtk/renew`;

// 地面站日志
export const GET_APP_LOG_LIST = `${BASE_URL}/oper/appLog/page`;

// 固件管理
// export const GET_PLAIN_FIRMWARE_LIST = `${BASE_URL}/encrypt/firmware/plain`.replace('/api', '');
// export const GET_ENCRYPTION_FIRMWARE_LIST = `${BASE_URL}/encrypt/firmware/list`.replace('/api', '');
// export const DELETE_ENCRYPTION_FIRMWARE = `${BASE_URL}/encrypt/firmware/delete/{fileName}`.replace('/api', '');
// export const ENCRYPT_FIRMWARE = `${BASE_URL}/encrypt/firmware/encrypt`.replace('/api', '');
// export const GENERATE_FIRMWARE_KEY = `${BASE_URL}/encrypt/firmware/genkey`.replace('/api', '');
// export const DOWNLOAD_GENERATE_FIRMWARE_KEY = `${BASE_URL}/encrypt/firmware/getkey`.replace('/api', '');
// export const DOWNLOAD_GENERATE_FIRMWARE_KEY = `http://ag.xagrotortek.com/encrypt/firmware/getkey`;

export const GET_PLAIN_FIRMWARE_LIST = `${BASE_URL}/firmware/plain`;
export const GET_ENCRYPTION_FIRMWARE_LIST = `${BASE_URL}/firmware/list`;
export const DELETE_ENCRYPTION_FIRMWARE = `${BASE_URL}/firmware/list/{fileName}`;
export const ENCRYPT_FIRMWARE = `${BASE_URL}/firmware/encrypt`;
export const CREATE_SIGNRC = `${BASE_URL}/firmware/signrc`;
export const GENERATE_FIRMWARE_KEY = `${BASE_URL}/firmware/genkey`;
export const DOWNLOAD_GENERATE_FIRMWARE_KEY = `${BASE_URL}/firmware/getkey`;
export const VERIFY_LOG = `${BASE_URL}/firmware/verifylog`;
export const VERIFY_FIRMWARE = `${BASE_URL}/firmware/verifyfw`;

// 地面站配置
export const GET_GROUND_STATION_CONFIG = `${BASE_URL}/user/account/manage/theme/{accountId}`;
export const UPDATE_GROUND_STATION_CONFIG = `${BASE_URL}/user/account/manage/theme`;

// 配件白名单
export const GET_PARTS_WHITE_LIST = `${BASE_URL}/device/deviceWhiteList`;
export const UPLOAD_PARTS_WHITE_LIST = `${BASE_URL}/device/deviceWhite`;

// 客户
export const GET_CLIENT_LIST = `${BASE_URL}/rental/rental/getCustomerList`;
export const GET_CLIENT_INFO_BY_PHONE = `${BASE_URL}/rental/rental/getCustomerByPhone`;
export const UPLOAD_CLIENT = `${BASE_URL}/rental/rental/updateCustomer`;
export const CREATE_CLIENT = `${BASE_URL}/rental/rental/addCustomer`;
// export const GET_CLIENT_WORK_RECORD = `${BASE_URL}/rental/rental/getJobRecordByUser`;

// 租赁
export const GET_LEASE_LIST = `${BASE_URL}/rental/rental/getRentalList`;
export const CANCEL_LEASE = `${BASE_URL}/rental/rental/cancelRental`;
export const RETURN_DRONE_OF_LEASE = `${BASE_URL}/rental/rental/returnDrone`;
export const CREATE_LEASE = `${BASE_URL}/rental/rental/addRental`;

// 充值
export const GET_RECHARGE_RECORD = `${BASE_URL}/rental/rental/rechargeRecord`;
export const GET_DEDUCTION_RECORD = `${BASE_URL}/rental/rental/deductionRecord`;

// 作业记录
export const GET_WORK_RECORD_LIST = `${BASE_URL}/rental/rental/getJobRecord`;

// 制造商获取租赁模式
export const GET_RENTAL_MODEL_BY_ACCOUNT = `${BASE_URL}/rental/rental/getRentalModelByAccount`;

// 修改飞机租赁标记
export const UPDATE_DRONE_RENTAL = `${BASE_URL}/rental/rental/updateDroneRental`;

// 获取租赁类型
export const GET_USER_RENTAL_OPTIONS = `${BASE_URL}/rental/rental/getRentalDict`;

// 修改制造商租赁标记
export const UPDATE_MANUFACTURER_RENTAL = `${BASE_URL}/rental/rental/updateAccountRental`;

// 根据当前用户获取可出租飞机
export const GET_DRONE_BY_ACCOUNT = `${BASE_URL}/rental/rental/getDronesByAccount`;

// 根据登录运营人获取所属制造商
export const GET_MANUFACTURER_BY_ACCOUNT = `${BASE_URL}/rental/rental/getZzAccountByUser`;

// 获取微信充值二维码
export const GET_WX_QR_CODE = `${BASE_URL}/rental/rental/getWxQrCode`;

// 获取客户余额
export const GET_AMOUNT = `${BASE_URL}/rental/rental/getAmount`;

// 获取租赁飞机
export const GET_LEASE_DRONE_LIST = `${BASE_URL}/rental/rental/getRentalDrones`;

// 租赁数据统计
export const GET_JOB_STAT_DATA = `${BASE_URL}/rental/rental/jobStatistics`;
export const GET_RENTAL_STAT_DATA = `${BASE_URL}/rental/rental/rentalStatistics`;
export const GET_JOB_AREA_DATA = `${BASE_URL}/rental/rental/jobAreaStatistics`;
export const GET_CUSTOMER_AREA_RANKING = `${BASE_URL}/rental/rental/jobAreaRanking`;
export const GET_CUSTOMER_DATA_STAT = `${BASE_URL}/rental/rental/yyJobStatistics`;
export const GET_CUSTOMER_AREA_AND_RECORD_STAT = `${BASE_URL}/rental/rental/yyJobAreaStatistics`;
export const GET_CUSTOMER_DRONE_PILOT_RANKING = `${BASE_URL}/rental/rental/yyJobAreaRanking`;

// // 无人机组件
// // export const GET_DRONE_PARTS_LIST = '/appi/device/dronePartsRel/list' || `${BASE_URL}/device/dronePartsRel/list`;
// export const GET_DRONE_PARTS_LIST = '/appi/device/drone/list' || `${BASE_URL}/device/drone/list`;
// // export const GET_DRONE_PARTS_INFO = '/appi/device/dronePartsRel/{droneId}' || `${BASE_URL}/device/dronePartsRel/{droneId}`;
// export const GET_DRONE_PARTS_INFO = '/appi/device/dronePartsRel/list' || `${BASE_URL}/device/dronePartsRel/list`;
// export const GET_DRONE_PARTS_REPLACE_HISTORY = '/appi/device/partsReplaceHistory/list' || `${BASE_URL}/device/partsReplaceHistory/list`;
// export const EXPORT_PARTS_REPLACE_HISTORY = '/appi/device/partsReplaceHistory/export' || `${BASE_URL}/device/partsReplaceHistory/export`;
// export const EXPORT_PARTS_INFO = '/appi/device/dronePartsRel/export' || `${BASE_URL}/device/dronePartsRel/export`;
//
// // 配件白名单
// export const GET_DRONE_PARTS_WHITE_LIST = '/appi/device/whiteList/list' || `${BASE_URL}/device/whiteList/list`;
// export const CREATE_PARTS_WHITE_LIST = '/appi/device/whiteList' || `${BASE_URL}/device/whiteList`;
// export const DELETE_PARTS_WHITE_LIST = '/appi/device/whiteList' || `${BASE_URL}/device/whiteList`;
// export const EXPORT_PARTS_WHITE_LIST = '/appi/device/whiteList/export' || `${BASE_URL}/device/whiteList/export`;
//
// // 配件类型
// export const GET_PARTS_TYPE_LIST = '/appi/device/partsType/list' || `${BASE_URL}/device/partsType/list`;
// export const EXPORT_PARTS_TYPE_LIST = '/appi/device/partsType/export' || `${BASE_URL}/device/partsType/export`;
