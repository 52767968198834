<template>
    <div :class="$style.root">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: 'TitleBig',
    };
</script>

<style lang="scss" module>
    .root {
        position: relative;
        line-height: 1;
        font-size: 24px;
        font-weight: bold;
        color: #4AC2E0;
        padding-bottom: 20px;
        margin-bottom: 20px;

        &:global:after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 1px;
            background: linear-gradient(90deg, #00F2FE 0%, transparent 100%);
        }
    }
</style>
