<template>
    <Side :class="$style.root">
        <div class="grid">
            <div class="cell">
                <i class="iconfont icon-layer"></i>
                <div class="cell-label">{{ $t('农机总数') }}</div>
                <div class="cell-value">{{ statInfo.droneNum | falsyTo('-') }}</div>
            </div>
            <div class="cell">
                <i class="iconfont icon-bingtu"></i>
                <div class="cell-label">{{ $t('当前在线农机数') }}</div>
                <div class="cell-value">{{ statInfo.onlineDroneCount | falsyTo('-') }}</div>
            </div>
            <div class="cell">
                <i class="iconfont icon-weizhi"></i>
                <div class="cell-label">{{ $t('地块总面积') }}</div>
                <div class="cell-value">{{ areaConversion(statInfo.plotTotalArea) | toFixed(1) | unit($t(areaUnit)) | falsyTo('-') }}</div>
            </div>
            <div class="cell">
                <i class="iconfont icon-ditu"></i>
                <div class="cell-label">{{ $t('地块总数') }}</div>
                <div class="cell-value">{{ statInfo.ploTotalNum | falsyTo('-') }}</div>
            </div>
            <div class="cell">
                <i class="iconfont icon-clock"></i>
                <div class="cell-label">{{ $t('作业时长') }}</div>
                <div class="cell-value">{{ statInfo.flyTotalDuration | toHour | unit(this.$t('unit.hour')) | falsyTo('-') }}</div>
            </div>
            <div class="cell">
                <i class="iconfont icon-flow"></i>
                <div class="cell-label">{{ $t('喷洒量') }}</div>
                <div class="cell-value">{{ statInfo.flyTotalQuantity | unit(this.$t('unit.liter')) | falsyTo('-') }}</div>
            </div>
            <div class="cell">
                <i class="iconfont icon-spray"></i>
                <div class="cell-label">{{ $t('作业亩数') }}</div>
                <div class="cell-value">{{ areaConversion(statInfo.sprayTotalMuNum) | toFixed(1) | unit($t(areaUnit)) | falsyTo('-') }}</div>
            </div>
            <div class="cell">
                <i class="iconfont icon-shuliang"></i>
                <div class="cell-label">{{ $t('作业架次') }}</div>
                <div class="cell-value">{{ statInfo.flySeqTotalNum | falsyTo('-') }}</div>
            </div>
        </div>

        <div class="block">
            <Title class="block-title">{{ $t('本日数据') }}</Title>
            <div class="row">
                <div class="row-label">{{ $t('新增农机数') }}</div>
                <div class="grow blue">{{ todayOverview.newDroneCount | falsyTo('-') }}</div>
            </div>
            <div class="row">
                <div class="row-label">{{ $t('作业时长') }}</div>
                <div class="grow red">{{ todayOverview.flightTime | toHour | unit(this.$t('unit.hour')) | falsyTo('-') }}</div>
            </div>
            <div class="row">
                <div class="row-label">{{ $t('活跃农机数量') }}</div>
                <div class="grow blue">{{ todayOverview.activeDroneCount | falsyTo('-') }}</div>
            </div>
            <div class="row">
                <div class="row-label">{{ $t('作业亩数') }}</div>
                <div class="grow red">{{ areaConversion(todayOverview.sprayArea) | toFixed(1) | unit($t(areaUnit)) | falsyTo('-') }}</div>
            </div>
            <div class="row">
                <div class="row-label">{{ $t('新增地块数') }}</div>
                <div class="grow yellow">{{ todayOverview.newBlockCount | falsyTo('-') }}</div>
            </div>
            <div class="row">
                <div class="row-label">{{ $t('作业架次') }}</div>
                <div class="grow red">{{ todayOverview.flightCount | falsyTo('-') }}</div>
            </div>
            <div class="row">
                <div class="row-label">{{ $t('新增地块面积') }}</div>
                <div class="grow yellow">{{ areaConversion(todayOverview.newBlockArea) | toFixed(1) | unit($t(areaUnit)) | falsyTo('-') }}</div>
            </div>
            <div class="row">
                <div class="row-label">{{ $t('喷洒量') }}</div>
                <div class="grow red">{{ todayOverview.sprayQuantity | unit(this.$t('unit.liter')) | falsyTo('-') }}</div>
            </div>
            <div class="row">
                <div class="row-label">{{ $t('活跃地块') }}</div>
                <div class="grow yellow">{{ todayOverview.activeBlockCount | falsyTo('-') }}</div>
            </div>
        </div>

        <div class="block">
            <Title class="block-title">{{ $t('本月数据') }}</Title>
            <div class="row">
                <div class="row-label">{{ $t('新增农机数') }}</div>
                <div class="grow blue">{{ thisMonthOverview.newDroneCount | falsyTo('-') }}</div>
            </div>
            <div class="row">
                <div class="row-label">{{ $t('作业时长') }}</div>
                <div class="grow red">{{ thisMonthOverview.flightTime | toHour | unit(this.$t('unit.hour')) | falsyTo('-') }}</div>
            </div>
            <div class="row">
                <div class="row-label">{{ $t('活跃农机数量') }}</div>
                <div class="grow blue">{{ thisMonthOverview.activeDroneCount | falsyTo('-') }}</div>
            </div>
            <div class="row">
                <div class="row-label">{{ $t('作业亩数') }}</div>
                <div class="grow red">{{ areaConversion(thisMonthOverview.sprayArea) | toFixed(1) | unit($t(areaUnit)) | falsyTo('-') }}</div>
            </div>
            <div class="row">
                <div class="row-label">{{ $t('新增地块数') }}</div>
                <div class="grow yellow">{{ thisMonthOverview.newBlockCount | falsyTo('-') }}</div>
            </div>
            <div class="row">
                <div class="row-label">{{ $t('作业架次') }}</div>
                <div class="grow red">{{ thisMonthOverview.flightCount | falsyTo('-') }}</div>
            </div>
            <div class="row">
                <div class="row-label">{{ $t('新增地块面积') }}</div>
                <div class="grow yellow">{{ areaConversion(thisMonthOverview.newBlockArea) | toFixed(1) | unit($t(areaUnit)) | falsyTo('-') }}</div>
            </div>
            <div class="row">
                <div class="row-label">{{ $t('喷洒量') }}</div>
                <div class="grow red">{{ thisMonthOverview.sprayQuantity | unit(this.$t('unit.liter')) | falsyTo('-') }}</div>
            </div>
            <div class="row">
                <div class="row-label">{{ $t('活跃地块') }}</div>
                <div class="grow yellow">{{ thisMonthOverview.activeBlockCount | falsyTo('-') }}</div>
            </div>
        </div>

        <div class="block">
            <Title class="block-title">{{ $t('本年数据') }}</Title>
            <div class="row">
                <div class="row-label">{{ $t('新增农机数') }}</div>
                <div class="grow blue">{{ thisYearOverview.newDroneCount | falsyTo('-') }}</div>
            </div>
            <div class="row">
                <div class="row-label">{{ $t('作业时长') }}</div>
                <div class="grow red">{{ thisYearOverview.flightTime | toHour | unit(this.$t('unit.hour')) | falsyTo('-') }}</div>
            </div>
            <div class="row">
                <div class="row-label">{{ $t('活跃农机数量') }}</div>
                <div class="grow blue">{{ thisYearOverview.activeDroneCount | falsyTo('-') }}</div>
            </div>
            <div class="row">
                <div class="row-label">{{ $t('作业亩数') }}</div>
                <div class="grow red">{{ areaConversion(thisYearOverview.sprayArea) | toFixed(1) | unit($t(areaUnit)) | falsyTo('-') }}</div>
            </div>
            <div class="row">
                <div class="row-label">{{ $t('新增地块数') }}</div>
                <div class="grow yellow">{{ thisYearOverview.newBlockCount | falsyTo('-') }}</div>
            </div>
            <div class="row">
                <div class="row-label">{{ $t('作业架次') }}</div>
                <div class="grow red">{{ thisYearOverview.flightCount | falsyTo('-') }}</div>
            </div>
            <div class="row">
                <div class="row-label">{{ $t('新增地块面积') }}</div>
                <div class="grow yellow">{{ areaConversion(thisYearOverview.newBlockArea) | toFixed(1) | unit($t(areaUnit)) | falsyTo('-') }}</div>
            </div>
            <div class="row">
                <div class="row-label">{{ $t('喷洒量') }}</div>
                <div class="grow red">{{ thisYearOverview.sprayQuantity | unit(this.$t('unit.liter')) | falsyTo('-') }}</div>
            </div>
            <div class="row">
                <div class="row-label">{{ $t('活跃地块') }}</div>
                <div class="grow yellow">{{ thisYearOverview.activeBlockCount | falsyTo('-') }}</div>
            </div>
        </div>
    </Side>
</template>

<i18n src="./languages.json"></i18n>

<script>
    import { mapActions, mapGetters, mapState } from 'vuex';
    import _throttle from 'lodash.throttle';
    import Side from '../StatScreen/components/Side.vue';
    import Title from '../StatScreen/components/Title.vue';

    let throttle1 = null;
    let throttle2 = null;
    let ticker = null;

    export default {
        name: 'Panel5',

        components: { Side, Title },

        filters: {
            toHour(sec) {
                return ((sec || 0) / 3600).toFixed(1);
            },
        },

        computed: {
            ...mapGetters('monitor', ['statInfo']),
            ...mapState('statScreen', [
                'todayOverview',
                'thisMonthOverview',
                'thisYearOverview',
            ]),
            ...mapGetters('setting', ['areaConversion', 'areaUnit']),
        },

        mounted() {
            this.startLoadData();
        },

        destroyed() {
            this.stopLoadData();
        },

        methods: {
            ...mapActions('monitor', ['getMonitorDetail']),
            ...mapActions('statScreen', ['getPeriodStatOverview', 'getTodayDataOverview']),

            startLoadData() {
                this.stopLoadData();
                throttle1 = _throttle(this.loadData1, 30000, { trailing: false });
                throttle2 = _throttle(this.loadData2, 300000, { trailing: false });
                ticker = setInterval(() => {
                    throttle1();
                    throttle2();
                }, 1000);
            },

            stopLoadData() {
                clearInterval(ticker);
            },

            loadData1() {
                this.getMonitorDetail().catch(({ message }) => {
                    if (message) this.$message.error(message);
                });
            },

            loadData2() {
                const requests = [2, 3].map(periodType => this.getPeriodStatOverview({ periodType }));
                requests.unshift(this.getTodayDataOverview());
                Promise.all(requests).catch(({ message }) => {
                    if (message) this.$message.error(message);
                });
            },
        },
    };
</script>

<style lang="scss" module>
    .root {
        height: 100%;
        padding: 30px 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        :global {
            .grid {
                display: flex;
                flex-wrap: wrap;

                .cell {
                    flex: 0 0 25%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    font-size: 18px;
                    line-height: 1;
                    margin-bottom: 10px;

                    i {
                        font-size: 50px;
                        color: #4AC2E0;
                    }

                    .cell-label {
                        margin: 6px 0;
                        text-align: center;
                    }

                    .cell-value {
                        font-size: 16px;
                        color: #4AC2E0;
                    }
                }
            }

            .block {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                .block-title {
                    width: 100%;
                    margin-bottom: 10px;
                }
            }

            .row {
                flex: 0 0 45%;
                display: flex;
                align-items: center;
                font-size: 18px;
                line-height: 1;
                margin-top: 10px;

                .row-label {
                    width: 60%;
                    color: white;
                    white-space: nowrap;
                }

                .grow {
                    flex: 1;
                    white-space: nowrap;
                    text-align: right;

                    &.red {
                        color: #f47500;
                    }

                    &.yellow {
                        color: #F6B72F;
                    }

                    &.blue {
                        color: #2ABFF7;
                    }
                }
            }
        }
    }
</style>
