/**
 * lang
 */

const UPDATE_LANG = 'UPDATE_LANG';

const LANG_MAPPING = {
    en: 'en-US',
    'en-US': 'en-US',
    zh: 'zh-CN',
    cn: 'zh-CN',
    'zh-CN': 'zh-CN',
    th: 'th',
    ko: 'ko',
    'zh-TW': 'zh-TW',
};

const { [navigator.language]: defaultLang } = {
    en: 'en',
    'zh-CN': 'zh',
    th: 'th',
    ko: 'ko',
    'zh-TW': 'zh-TW',
};

export default {
    namespaced: true,

    state: {
        lang: localStorage.getItem('lang') || defaultLang || 'en',
        langMapping: {
            en: ['en', 'en'],
            zh: ['zh-CN', 'cn'],
            th: ['th', 'th'],
            ko: ['ko', 'ko'],
            'zh-TW': ['zh-TW', 'zh-TW'],
        },
    },

    getters: {
        isEn(state) {
            return state.lang === 'en';
        },

        isCn(state) {
            return state.lang === 'zh';
        },

        isTh(state) {
            return state.lang === 'th';
        },

        isKo(state) {
            return state.lang === 'ko';
        },

        isTW(state) {
            return state.lang === 'zh-TW';
        },

        rfc(state) {
            const { [state.lang]: value } = LANG_MAPPING;
            return value || 'zh-CN';
        },
    },

    mutations: {
        UPDATE_LANG(state, lang) {
            state.lang = lang;
        },
    },

    actions: {
        setLang({ commit, dispatch }, lang) {
            localStorage.setItem('lang', lang);
            commit(UPDATE_LANG, lang);

            dispatch('switchWebTitle', lang);
        },

        // 切换网页标题
        switchWebTitle({ rootState }, lang) {
            const { theme: { title, thTitle, enTitle, koTitle, tWTitle } } = rootState;
            // const { AppEnTitle } = window;

            const { [lang]: t } = { en: enTitle, th: thTitle, zh: title, ko: koTitle, 'zh-TW': tWTitle };
            document.title = t;

            // if (lang !== 'zh') {
            //     document.title = AppEnTitle || title;
            // } else {
            //     document.title = title;
            // }
        },
    },
};
