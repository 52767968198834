<template>
    <div class="ui-login-root">
        <swiper :options="swiperOption" ref="mySwiper">
            <!-- slides -->
            <swiper-slide>
                <img src="/images/slides/2e9f3d54.jpg" alt="">
            </swiper-slide>
            <swiper-slide>
                <img src="/images/slides/42219ee5.jpg" alt="">
            </swiper-slide>
            <swiper-slide>
                <img src="/images/slides/98bf59fc.jpg" alt="">
            </swiper-slide>
            <swiper-slide>
                <img src="/images/slides/f945b97.jpg" alt="">
            </swiper-slide>
        </swiper>
        <div class="ui-main">
            <img :src="`/images/${logoFilename}`" alt="logo">
            <h1>{{ title }}</h1>
            <el-tabs value="pwd" stretch>
                <el-tab-pane label="密码登录" name="pwd">
                    <el-form ref="pwdLoginForm" :model="pwdLoginForm" :rules="pwdLoginRules" size="medium" @keyup.enter.native="submitPwdForm('pwdLoginForm')">
                        <el-form-item prop="phoneNum">
                            <el-input v-model="pwdLoginForm.phoneNum" name="username" placeholder="请输入手机号"></el-input>
                        </el-form-item>
                        <el-form-item prop="password">
                            <el-input
                                type="password"
                                v-model="pwdLoginForm.password"
                                minlength="8"
                                maxlength="16"
                                autocomplete="off"
                                name="password"
                                placeholder="请输入密码">
                            </el-input>
                        </el-form-item>
                        <el-form-item prop="verifyCode">
                            <el-input v-model="pwdLoginForm.verifyCode" autocomplete="off" placeholder="请输入验证码">
                                <el-button slot="append" class="ui-code" @click="handleRefreshCode">
                                    <img :src="codeUrl" alt="验证码">
                                </el-button>
                            </el-input>
                        </el-form-item>
                        <div class="columns is-mobile ui-buttons">
                            <div class="column">
                                <el-button type="primary" size="medium" @click="submitPwdForm('pwdLoginForm')">登录</el-button>
                            </div>
                        </div>
                        <div class="columns is-mobile is-gapless has-text-left">
                            <div class="column">
                                <el-checkbox v-model="pwdLoginForm.rememberPassword">记住密码（一周）</el-checkbox>
                            </div>
                            <div class="column is-narrow ui-font">
                                <router-link to="/retrieve-password">忘记密码？</router-link>
                            </div>
                        </div>
                        <div class="has-text-left ui-pull-top">
                            <span class="ui-font">没有账号？立即<router-link to="/register" replace>注册<i class="el-icon-d-arrow-right"></i></router-link></span>
                        </div>
                    </el-form>
                </el-tab-pane>
                <el-tab-pane label="短信登录" name="sms">
                    <el-form ref="smsLoginForm" :model="smsLoginForm" :rules="smsLoginRules" @keyup.enter.native="submitPwdForm('smsLoginForm')">
                        <el-form-item prop="phoneNum">
                            <el-input v-model="smsLoginForm.phoneNum" name="username" placeholder="请输入手机号"></el-input>
                        </el-form-item>
                        <el-form-item prop="verifyCode">
                            <el-input v-model="smsLoginForm.verifyCode" autocomplete="off" placeholder="请输入验证码">
                                <CountdownButton slot="append" @click="handleRequestVerifyCode('smsLoginForm', $event)"></CountdownButton>
                            </el-input>
                        </el-form-item>
                        <div class="columns is-mobile ui-buttons">
                            <div class="column">
                                <el-button type="primary" size="medium" @click="submitSmsForm('smsLoginForm')">登录</el-button>
                            </div>
                        </div>
                        <div class="columns is-mobile is-gapless has-text-left">
                            <div class="column">
                                <el-checkbox v-model="smsLoginForm.rememberPassword">记住密码（一周）</el-checkbox>
                            </div>
                        </div>
                        <div class="has-text-left ui-pull-top">
                            <span class="ui-font">没有账号？立即<router-link to="/register" replace>注册<i class="el-icon-d-arrow-right"></i></router-link></span>
                        </div>
                    </el-form>
                </el-tab-pane>
            </el-tabs>
        </div>
        <div class="copyright" v-if="copyright">
            <p v-html="copyright"></p>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapActions } from 'vuex';
    import { swiper, swiperSlide } from 'vue-awesome-swiper';
    import 'swiper/dist/css/swiper.css';
    import scrollbarMixin from '../../../mixins/scrollbarMixin';
    import { REQUEST_IMG_VERIFY_CODE } from '../../../config/urls';
    import CountdownButton from '../../../components/CountdownButton.vue';

    export default {
        name: 'Login',

        components: { CountdownButton, swiper, swiperSlide },

        mixins: [scrollbarMixin],

        data() {
            return {
                codeUrl: `${REQUEST_IMG_VERIFY_CODE}?${Math.random()}`,
                swiperOption: {
                    autoplay: {
                        delay: 5000,
                        disableOnInteraction: false,
                    },
                    speed: 1500,
                    loop: true,
                    effect: 'fade',
                    fadeEffect: {
                        crossFade: true,
                    },
                },
                pwdLoginForm: {
                    phoneNum: '',
                    password: '',
                    verifyCode: '',
                    rememberPassword: false,
                },
                smsLoginForm: {
                    phoneNum: '',
                    verifyCode: '',
                    rememberPassword: false,
                },
                pwdLoginRules: {
                    phoneNum: [
                        { required: true, message: '请输入11位手机号码', trigger: 'blur' },
                        { pattern: /^1[3-9][0-9]\d{8}$/g, message: '请输入正确的手机号码', trigger: 'blur' },
                    ],
                    password: [
                        { required: true, message: '请输入密码', trigger: 'blur' },
                        { pattern: /^(?=.*\d)(?=.*[a-zA-Z])[a-zA-Z0-9]{8,16}$/, message: '密码必须为8~16位的字母、数字组合', trigger: 'blur' },
                    ],
                    verifyCode: [
                        { required: true, message: '请输入验证码', trigger: 'blur' },
                        { pattern: /^\d{4}$/g, message: '请输入4位验证码', trigger: 'blur' },
                    ],
                },
                smsLoginRules: {
                    phoneNum: [
                        { required: true, message: '请输入11位手机号码', trigger: 'blur' },
                        { pattern: /^1[3-9][0-9]\d{8}$/g, message: '请输入正确的手机号码', trigger: 'blur' },
                    ],
                    verifyCode: [
                        { required: true, message: '请输入验证码', trigger: 'blur' },
                        { pattern: /^\d{4}$/g, message: '请输入4位验证码', trigger: 'blur' },
                    ],
                },
            };
        },

        // 路由守卫
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.handleRefreshCode();
            });
        },

        computed: {
            ...mapGetters('user', ['getUserInfo']),
            ...mapState('theme', ['title', 'logoFilename', 'copyright']),
        },

        methods: {
            ...mapActions('user', ['requestSmsVerifyCode', 'requestPasswordLogin', 'requestSmsLogin']),

            // 刷新图形验证码
            handleRefreshCode() {
                this.codeUrl = `${REQUEST_IMG_VERIFY_CODE}?${Math.random()}`;
                this.pwdLoginForm.verifyCode = '';
            },

            // 请求短信验证码
            handleRequestVerifyCode(formName, { countdownStart, countdownReset }) {
                this.$refs[formName].validateField('phoneNum', (validateMessage, invalidFields) => {
                    if (invalidFields) {
                        return;
                    }
                    // 开始倒计时
                    countdownStart();
                    this.startLoading();
                    this.requestSmsVerifyCode({
                        phoneNum: this.smsLoginForm.phoneNum,
                        codeType: '1',
                    }).then(() => {
                        this.stopLoading();
                        this.$message.success('验证码已发送');
                    }).catch(({ message }) => {
                        // 恢复倒计时按钮原始状态
                        countdownReset();
                        this.stopLoading();
                        if (message) {
                            this.$message.error(message);
                        }
                    });
                });
            },

            // 密码登录
            submitPwdForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (!valid) {
                        return;
                    }
                    this.startLoading();
                    this.requestPasswordLogin(this.pwdLoginForm).then(() => {
                        this.stopLoading();
                        this.$message.success('登录成功');
                        this.resetForm(formName);
                        this.handleRefreshCode();
                        this.handleLoginSuccess();
                    }).catch(({ message }) => {
                        this.stopLoading();
                        this.handleRefreshCode();
                        if (message) {
                            this.$message.error(message);
                        }
                    });
                });
            },

            // 验证码登录
            submitSmsForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (!valid) {
                        return;
                    }
                    this.startLoading();
                    this.requestSmsLogin(this.smsLoginForm).then(() => {
                        this.stopLoading();
                        this.$message.success('登录成功');
                        this.resetForm(formName);
                        this.handleLoginSuccess();
                    }).catch(({ message }) => {
                        this.stopLoading();
                        if (message) {
                            this.$message.error(message);
                        }
                    });
                });
            },

            // 重置表单
            resetForm(formName) {
                this.$refs[formName].resetFields();
            },

            // 登出成功后
            handleLoginSuccess() {
                const accountType = this.getUserInfo('accountType');
                const checkStatus = this.getUserInfo('checkStatus');
                // 协会、政府、其他账户已通过审核的，都跳转到根url
                if (accountType === 1 || accountType === 2 || checkStatus === 2) {
                    this.$router.replace('/');
                } else {
                    this.$router.replace('/redirect_to_account');
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    .ui-login-root {
        /deep/ input {
            background-color: rgba(255, 255, 255, 0.6);
        }
        /deep/ .el-tabs__nav-wrap::after {
            background-color: rgba(64, 158, 255, 0.2);
        }
    }

    .ui-login-root {
        height: inherit;
        .swiper-container {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            .swiper-slide img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .ui-main {
            text-align: center;
            width: 350px;
            height: 540px;
            background-color: rgba(255, 255, 255, 0.6);
            border-radius: 5px;
            position: absolute;
            left: 72%;
            top: 40%;
            transform: translate(-50%, -50%);
            z-index: 1;
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
            padding: 20px 40px;
            @media screen and (max-width: 1600px) {
                zoom: 95%;
            }
            @media screen and (max-width: 1536px) {
                zoom: 93%;
            }
            @media screen and (max-width: 1440px) {
                zoom: 90%;
            }
            @media screen and (max-width: 1366px) {
                zoom: 87%;
            }
            @media screen and (max-width: 1280px) {
                zoom: 84%;
            }
            @media screen and (max-width: 1024px) {
                zoom: 75%;
            }
            h1 {
                font-size: 20px;
                color: $brandColor;
                /*font-weight: bold;*/
                margin-top: 10px;
                margin-bottom: 30px;
            }
            .el-input-group--append /deep/ .el-input-group__append {
                background-color: white;
            }
            .ui-code {
                padding: 4px 0;
                & /deep/ span {
                    display: inline-block;
                    width: 130px;
                    height: 34px;
                }
                img {
                    height: 100%;
                }
            }
            .ui-buttons {
                margin-top: -10px;
                margin-bottom: 0;
                button {
                    width: 100%;
                }
            }
            .ui-font {
                font-size: 14px;
            }
            .ui-pull-top {
                margin-top: -20px;
            }
        }
    }

    .el-checkbox /deep/ .el-checkbox__inner {
        border: 1px solid #9a9a9a;
        background-color: rgba(255, 255, 255, 0.25);
    }

    .copyright {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 20px;
        z-index: 1;
        text-align: center;
        p {
            display: inline-block;
            padding: 5px 20px;
            border-radius: 3px;
            background-color: rgba(0, 0, 0, 0.75);
            font-size: 14px;
            color: white;
        }
        /deep/ a {
            color: inherit;
            &:hover {
                text-decoration: underline;
            }
        }
    }
</style>
