/**
 * bill
 */

import Vue from 'vue';
import * as types from './mutation-types';
import * as urls from '../config/urls';
// import * as helpers from "../utils/helpers";
// import * as helpers from '../utils/helpers';

export default {
    namespaced: true,

    state: {
        rechargeList: {},
        rechargeLoading: false,
        rechargePagination: 1,
        rechargePageSize: 20,
        rechargeFilters: {
            accountId: undefined,
        },
        rechargeDetail: {},
        // ACTIVE_STATUS_MAP: {
        //     0: 'debugging',
        //     1: 'unactivated',
        //     2: 'activated',
        // },
        // // rechargeModel
        // rechargeModelList: {},

        deductionList: {},
        deductionLoading: false,
        deductionPagination: 1,
        deductionPageSize: 20,
        deductionFilters: {
            accountId: undefined,
        },
    },

    getters: {
        // stat(state) {
        //     const { stat: { total = 0, workArea = 0, zzNum = 0, sortieCount = 0, modelNum = 0 } = {} } = state.rechargeList;
        //     return { total, workArea, zzNum, sortieCount, modelNum };
        // },

        // activeStatusLabel: state => (status) => {
        //     const { [status]: label } = state.ACTIVE_STATUS_MAP;
        //     return label || '-';
        // },
    },

    mutations: {
        [types.UPDATE_LIST](state, { type, list }) {
            state[`${type}List`] = list;
        },
        [types.UPDATE_DETAIL](state, { type, detail }) {
            state[`${type}Detail`] = detail;
        },
        [types.UPDATE_PAGE_SIZE](state, { type, size }) {
            state[`${type}PageSize`] = size;
        },
        [types.UPDATE_CURRENT_PAGINATION](state, { type, pagination }) {
            state[`${type}Pagination`] = pagination;
        },
        [types.UPDATE_FILTERS](state, { type, filters }) {
            state[`${type}Filters`] = filters;
        },
        [types.UPDATE_LOADING_STATUS](state, { type, isLoading }) {
            state[`${type}Loading`] = isLoading;
        },
    },

    actions: {
        getRechargeList({ commit, state }, pagination = 1) {
            const type = 'recharge';

            return new Promise((resolve, reject) => {
                commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                Vue.http.get(urls.GET_RECHARGE_RECORD, {
                    params: {
                        pageIndex: pagination,
                        size: state[`${type}PageSize`],
                        ...state[`${type}Filters`],
                    },
                }).then(({ data }) => {
                    commit(types.UPDATE_LIST, { type, list: data });
                    commit(types.UPDATE_CURRENT_PAGINATION, { type, pagination });
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    resolve(data);
                }).catch((error) => {
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    reject(error);
                });
            });
        },

        getRechargeWxCode(_, formData) {
            return new Promise((resolve, reject) => {
                Vue.http.post(urls.GET_WX_QR_CODE, formData, {
                    responseType: 'blob',
                }).then(({ data }) => {
                    // commit(types.UPDATE_LIST, { type, list: data });
                    // commit(types.UPDATE_CURRENT_PAGINATION, { type, pagination });
                    // commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    resolve(data);
                }).catch((error) => {
                    // commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    reject(error);
                });
            });
        },

        getAmount(_, { zzAccountId }) {
            return new Promise((resolve, reject) => {
                Vue.http.get(urls.GET_AMOUNT, {
                    params: {
                        zzAccountId,
                    },
                }).then(({ data }) => {
                    // commit(types.UPDATE_LIST, { type, list: data });
                    // commit(types.UPDATE_CURRENT_PAGINATION, { type, pagination });
                    // commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    resolve(data);
                }).catch((error) => {
                    // commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    reject(error);
                });
            });
        },

        //
        // // 更变分页Size
        changeRechargeListPageSize({ commit, dispatch, state }, size) {
            const type = 'recharge';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            commit(types.UPDATE_PAGE_SIZE, { type, size });
            dispatch(`get${Type}List`, state[`${type}Pagination`]);
        },
        //
        // // 更新过滤条件
        changeRechargeListFilters({ commit, dispatch, state }, { filters, refresh }) {
            const type = 'recharge';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            commit(types.UPDATE_FILTERS, { type, filters });
            if (refresh) dispatch(`get${Type}List`, state[`${type}Pagination`]);
        },

        getDeductionList({ commit, state }, pagination = 1) {
            const type = 'deduction';

            return new Promise((resolve, reject) => {
                commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                Vue.http.get(urls.GET_DEDUCTION_RECORD, {
                    params: {
                        pageIndex: pagination,
                        size: state[`${type}PageSize`],
                        ...state[`${type}Filters`],
                    },
                }).then(({ data }) => {
                    commit(types.UPDATE_LIST, { type, list: data });
                    commit(types.UPDATE_CURRENT_PAGINATION, { type, pagination });
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    resolve(data);
                }).catch((error) => {
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    reject(error);
                });
            });
        },
        //
        // // 更变分页Size
        changeDeductionListPageSize({ commit, dispatch, state }, size) {
            const type = 'deduction';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            commit(types.UPDATE_PAGE_SIZE, { type, size });
            dispatch(`get${Type}List`, state[`${type}Pagination`]);
        },
        //
        // // 更新过滤条件
        changeDeductionListFilters({ commit, dispatch, state }, { filters, refresh }) {
            const type = 'deduction';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            commit(types.UPDATE_FILTERS, { type, filters });
            if (refresh) dispatch(`get${Type}List`, state[`${type}Pagination`]);
        },
        //
        // // 获取详情
        // getRechargeDetail({ commit }, phone) {
        //     const type = 'recharge';
        //
        //     return new Promise((resolve, reject) => {
        //         Vue.http.get(urls.GET_CLIENT_INFO_BY_PHONE, {
        //             params: {
        //                 phone,
        //             },
        //         }).then((response) => {
        //             commit(types.UPDATE_DETAIL, { type, detail: response.data || {} });
        //             resolve(response);
        //         }).catch((error) => {
        //             reject(error);
        //         });
        //     });
        // },

        // getRechargeDetail({ commit }, rechargeId) {
        //     const type = 'recharge';
        //
        //     return new Promise((resolve, reject) => {
        //         Vue.http.get(urls.GET_CLIENT_INFO_BY_PHONE, {
        //             params: {
        //                 rechargeId,
        //             },
        //         }).then((response) => {
        //             commit(types.UPDATE_DETAIL, { type, detail: response.data });
        //             resolve(response);
        //         }).catch((error) => {
        //             reject(error);
        //         });
        //     });
        // },

        //
        // // 清空农机详情数据
        // clearRechargeDetail({ commit }) {
        //     const type = 'recharge';
        //
        //     commit(types.UPDATE_DETAIL, { type, detail: {} });
        // },
        //
        // // 更新农机数据
        // returnDrone({ dispatch, commit, state }, { id, returnTime }) {
        //     const type = 'recharge';
        //     const Type = type.replace(/^\w/, _ => _.toUpperCase());
        //
        //     return new Promise((resolve, reject) => {
        //         commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
        //         Vue.http.post(urls.RETURN_DRONE_OF_LEASE, { id, returnTime }).then((response) => {
        //             dispatch(`get${Type}List`, state[`${type}Pagination`]);
        //             commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
        //             resolve(response);
        //         }).catch((error) => {
        //             commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
        //             reject(error);
        //         });
        //     });
        // },

        // cancelRecharge({ dispatch, commit, state }, { id }) {
        //     const type = 'recharge';
        //     const Type = type.replace(/^\w/, _ => _.toUpperCase());
        //
        //     return new Promise((resolve, reject) => {
        //         commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
        //         Vue.http.post(urls.CANCEL_LEASE, { id }).then((response) => {
        //             dispatch(`get${Type}List`, state[`${type}Pagination`]);
        //             commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
        //             resolve(response);
        //         }).catch((error) => {
        //             commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
        //             reject(error);
        //         });
        //     });
        // },

        //
        // // 编辑农机制造商标识
        // updateManufacturerSign({ dispatch, commit, state }, { rechargeId, zzrechargeNum }) {
        //     const type = 'recharge';
        //     const Type = type.replace(/^\w/, _ => _.toUpperCase());
        //
        //     return new Promise((resolve, reject) => {
        //         commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
        //         const url = helpers.buildURL(urls.UPDATE_MANUFACTURER_SIGN, rechargeId);
        //         Vue.http.put(url, { zzrechargeNum }).then((response) => {
        //             dispatch(`get${Type}List`, state[`${type}Pagination`]);
        //             commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
        //             resolve(response);
        //         }).catch((error) => {
        //             commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
        //             reject(error);
        //         });
        //     });
        // },
        //
        // // 更新农机锁定状态
        // updateRechargeLock({ dispatch, commit, state }, { rechargeId, opr, password }) {
        //     const type = 'recharge';
        //     const Type = type.replace(/^\w/, _ => _.toUpperCase());
        //
        //     return new Promise((resolve, reject) => {
        //         commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
        //         Vue.http.put(urls.UPDATE_recharge_LOCK, { rechargeId, opr, password }).then((response) => {
        //             dispatch(`get${Type}List`, state[`${type}Pagination`]);
        //             commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
        //             resolve(response);
        //         }).catch((error) => {
        //             commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
        //             reject(error);
        //         });
        //     });
        // },
        //
        // // 更新农机销售状态
        // updateRechargeSaleStatus({ dispatch, commit, state }, { rechargeId, saleType }) {
        //     const type = 'recharge';
        //     const Type = type.replace(/^\w/, _ => _.toUpperCase());
        //
        //     return new Promise((resolve, reject) => {
        //         commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
        //         const url = helpers.buildURL(urls.UPDATE_recharge_SALE_STATUS, rechargeId);
        //         Vue.http.put(url, { saleType }).then((response) => {
        //             dispatch(`get${Type}List`, state[`${type}Pagination`]);
        //             commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
        //             resolve(response);
        //         }).catch((error) => {
        //             commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
        //             reject(error);
        //         });
        //     });
        // },
        //
        // // 获取农机型号下拉列表
        // getRechargeModelList({ commit }) {
        //     const type = 'rechargeModel';
        //
        //     return new Promise((resolve, reject) => {
        //         Vue.http.get(urls.GET_OWN_recharge_MODEL_SELECT_LIST).then((response) => {
        //             commit(types.UPDATE_LIST, { type, list: response.data });
        //             resolve(response);
        //         }).catch((error) => {
        //             reject(error);
        //         });
        //     });
        // },
        //
        // // 更新农机类型
        // updateDeviceType({ dispatch, state }, { rechargeId, deviceType, sprayWidth }) {
        //     const type = 'recharge';
        //     const Type = type.replace(/^\w/, _ => _.toUpperCase());
        //
        //     return new Promise((resolve, reject) => {
        //         const url = helpers.buildURL(urls.UPDATE_DEVICE_TYPE, rechargeId);
        //         Vue.http.put(url, { deviceType, sprayWidth }).then((response) => {
        //             dispatch(`get${Type}List`, state[`${type}Pagination`]);
        //             resolve(response);
        //         }).catch((error) => {
        //             reject(error);
        //         });
        //     });
        // },

        // end
    },
};
