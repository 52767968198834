<template>
    <div :class="$style.root">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: 'Title',
    };
</script>

<style lang="scss" module>
    .root {
        background: linear-gradient(-90deg, rgba(79, 172, 254, 0) 0%, rgba(0, 242, 254, 0.31) 100%);
        padding: 5px;
        line-height: 1;
        font-size: 19px;
        color: #FEFEFE;
    }
</style>
