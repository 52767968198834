/**
 * 团队管理
 */

const TeamList = () => import(/* webpackChunkName: "chunk!team-list" */'../views/TeamManage/TeamList.vue');

const group = 'institution_manage';

export default [
    {
        path: `/${group}/team-list`,
        name: 'TeamList',
        component: TeamList,
        meta: { group },
    },
];
