/**
 * 我的企业、信息、组织
 */
import Vue from 'vue';
import * as types from './mutation-types';
import * as urls from '../config/urls';
import { buildURL, clearKeys } from '../utils/helpers';
import { readJsonFromBlob } from '../utils/reader';

export default {
    namespaced: true,

    state: {
        // 系统日志
        systemLogList: {},
        systemLogLoading: false,
        systemLogPagination: 1,
        systemLogPageSize: 20,
        systemLogType: '1,2',
        systemLogFilters: {
            accountName: '',
            userName: '',
            startTime: '',
            endTime: '',
            order: '1',
        },
        // 成员管理
        memberList: {},
        memberLoading: false,
        memberPagination: 1,
        memberPageSize: 20,
        // 数据范围
        dataScopeMap: {
            1: '企业内相关数据',
            2: '个人数据',
        },
        // 组织信息
        ownAccountDetail: {},
    },

    mutations: {
        [types.UPDATE_LIST](state, { type, list }) {
            state[`${type}List`] = list;
        },
        [types.UPDATE_DETAIL](state, { type, detail }) {
            state[`${type}Detail`] = detail;
        },
        [types.UPDATE_SYSTEM_LOG_TYPE](state, payload) {
            state.systemLogType = payload;
        },
        [types.UPDATE_PAGE_SIZE](state, { type, size }) {
            state[`${type}PageSize`] = size;
        },
        [types.UPDATE_CURRENT_PAGINATION](state, { type, pagination }) {
            state[`${type}Pagination`] = pagination;
        },
        [types.UPDATE_FILTERS](state, { type, filters }) {
            state[`${type}Filters`] = filters;
        },
        [types.UPDATE_LOADING_STATUS](state, { type, isLoading }) {
            state[`${type}Loading`] = isLoading;
        },
    },

    actions: {
        // 系统日志：获取列表
        getSystemLogList({ commit, state }, pagination = 1) {
            const type = 'systemLog';

            return new Promise((resolve, reject) => {
                commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                Vue.http.get(urls.GET_SYSTEM_LOG_LIST, {
                    params: {
                        currentPage: pagination,
                        pageSize: state[`${type}PageSize`],
                        logType: state[`${type}Type`],
                        ...state[`${type}Filters`],
                    },
                }).then((response) => {
                    commit(types.UPDATE_LIST, { type, list: response.data });
                    commit(types.UPDATE_CURRENT_PAGINATION, { type, pagination });
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    resolve(response);
                }).catch((error) => {
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    reject(error);
                });
            });
        },

        // 系统日志：变更列表分页Size
        changeSystemLogListPageSize({ commit, dispatch, state }, size) {
            const type = 'systemLog';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            commit(types.UPDATE_PAGE_SIZE, { type, size });
            dispatch(`get${Type}List`, state[`${type}Pagination`]);
        },

        // 系统日志：更新列表过滤条件
        changeSystemLogListFilters({ commit, dispatch, state }, filters) {
            const type = 'systemLog';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            commit(types.UPDATE_FILTERS, { type, filters });
            dispatch(`get${Type}List`, state[`${type}Pagination`]);
        },

        // 系统日志：更新系统日志类型
        changeSystemLogType({ commit, dispatch, state }, logTypes) {
            const type = 'systemLog';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            commit(types.UPDATE_SYSTEM_LOG_TYPE, logTypes);
            dispatch(`get${Type}List`, state[`${type}Pagination`]);
        },

        // 成员管理：获取列表
        getMemberList({ commit, state }, pagination = 1) {
            const type = 'member';

            return new Promise((resolve, reject) => {
                commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                Vue.http.get(urls.GET_MEMBER_LIST, {
                    params: {
                        currentPage: pagination,
                        pageSize: state[`${type}PageSize`],
                    },
                }).then((response) => {
                    commit(types.UPDATE_LIST, { type, list: response.data });
                    commit(types.UPDATE_CURRENT_PAGINATION, { type, pagination });
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    resolve(response);
                }).catch((error) => {
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    reject(error);
                });
            });
        },

        // 成员管理：变更列表分页Size
        changeMemberListPageSize({ commit, dispatch, state }, size) {
            const type = 'member';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            commit(types.UPDATE_PAGE_SIZE, { type, size });
            dispatch(`get${Type}List`, state[`${type}Pagination`]);
        },

        // 成员管理：请求短信验证码（codeType：4=提交资料，5=创建用户，6=修改账号时向原手机发验证码，7=修改账号时向新手机发验证码）
        requestSmsVerifyCode(store, { codeType, sendPhoneNo }) {
            return new Promise((resolve, reject) => {
                Vue.http.get(urls.REQUEST_MANAGE_SMS_VERIFY_CODE, {
                    params: { codeType, sendPhoneNo },
                }).then((response) => {
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        // 成员管理：检验验证码
        requestCheckVerifyCode(store, { phoneNum, verifyCode, checkOpr }) {
            return new Promise((resolve, reject) => {
                Vue.http.get(urls.REQUEST_CHECK_MANAGE_VERIFY_CODE, {
                    params: { phoneNum, code: verifyCode, checkOpr },
                }).then((response) => {
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        // 成员管理：创建账号
        createMemberDetail(store, formData) {
            return new Promise((resolve, reject) => {
                Vue.http.post(urls.CREATE_MEMBER, formData).then((response) => {
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        // 成员管理：编辑账号
        updateMemberDetail({ dispatch, state }, formData) {
            const type = 'member';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            return new Promise((resolve, reject) => {
                Vue.http.put(urls.UPDATE_MEMBER, formData).then((response) => {
                    dispatch(`get${Type}List`, state[`${type}Pagination`]);
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        // 成员管理：删除账号
        deleteMember({ dispatch, commit, state }, { userId }) {
            const type = 'member';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            return new Promise((resolve, reject) => {
                commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                const url = buildURL(urls.DELETE_MEMBER, userId);
                Vue.http.delete(url).then((response) => {
                    dispatch(`get${Type}List`, state[`${type}Pagination`]);
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    resolve(response);
                }).catch((error) => {
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    reject(error);
                });
            });
        },

        // 成员管理：更新账号可用状态
        updateMemberEnable({ dispatch, commit, state }, { userId, opr }) {
            const type = 'member';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            return new Promise((resolve, reject) => {
                commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                Vue.http.put(urls.UPDATE_MEMBER_ENABLE, { userId, opr }).then((response) => {
                    dispatch(`get${Type}List`, state[`${type}Pagination`]);
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    resolve(response);
                }).catch((error) => {
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    reject(error);
                });
            });
        },

        // 账户资料：更新密码、头像、手机号
        updateOwnProfile(store, formData) {
            return new Promise((resolve, reject) => {
                Vue.http.put(urls.UPDATE_OWN_PROFILE, clearKeys(formData)).then((response) => {
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        // 组织信息：获取
        getOwnAccountDetail({ commit }) {
            const type = 'ownAccount';

            return new Promise((resolve, reject) => {
                Vue.http.get(urls.GET_OWN_ACCOUNT_DETAIL).then((response) => {
                    const detail = {
                        ...(response.data || {}),
                        // 适配之前数据
                        province: response?.data?.county ? `中国/${response.data.province}/${response.data.city}/${response.data.county}` : '',
                        city: '',
                        county: '',
                    };
                    commit(types.UPDATE_DETAIL, { type, detail });
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        // 组织信息：更新并提交审核
        updateAccountInfo({ dispatch }, formData) {
            const type = 'ownAccount';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            return new Promise((resolve, reject) => {
                Vue.http.post(urls.REQUEST_SUBMIT_ACCOUNT_INFO, formData).then((response) => {
                    dispatch(`get${Type}Detail`);
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        // 组织信息：生成密钥
        createSecretKey({ dispatch }, { vid }) {
            const type = 'ownAccount';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            return new Promise((resolve, reject) => {
                Vue.http.put(urls.CREATE_MANUFACTURER_SECRET_KEY, { vid }).then((response) => {
                    dispatch(`get${Type}Detail`);
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        // 组织信息：下载密钥
        getSecretKey() {
            return new Promise((resolve, reject) => {
                Vue.http.get(urls.DOWNLOAD_MANUFACTURER_SECRET_KEY, {
                    responseType: 'blob',
                }).then((response) => {
                    resolve(response);
                }).catch(async (error) => {
                    if (error instanceof Blob) {
                        const result = await readJsonFromBlob(error);
                        reject(result);
                    }
                    reject(error);
                });
            });
        },

        // end
    },
};
