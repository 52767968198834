/**
 * Account
 */

import Vue from 'vue';
import * as types from './mutation-types';
import * as urls from '../config/urls';
import * as helpers from '../utils/helpers';

export default {
    namespaced: true,

    state: {
        accountTypes: {
            1: 'account.ama',
            2: 'account.government',
            3: 'account.manufacturer',
            4: 'account.owner',
            5: 'account.farmer',
        },
        applyType: {
            1: 'apply_registration',
            2: 'apply_modification',
        },
        applyStatus: {
            1: 'unaudited',
            2: 'passed',
            3: 'rejected',
        },
        // 账户一览
        accountList: {},
        accountLoading: false,
        accountPagination: 1,
        accountPageSize: 20,
        accountFilters: {
            accountType: '2',
            accountName: '',
            contactName: '',
            userPhone: '',
            teamType: '',
            search: '',
            isAll: 0,
        },
        accountDetail: {},
        // 账户申请
        accountApplyList: {},
        accountApplyLoading: false,
        accountApplyPagination: 1,
        accountApplyPageSize: 20,
        accountApplyFilters: {
            accountId: '',
            accountName: '',
            applyType: '',
            applyStatus: '',
            order: '1',
        },
        accountApplyDetail: {},
        // 账号申请
        userApplyList: {},
        userApplyLoading: false,
        userApplyPagination: 1,
        userApplyPageSize: 20,
        userApplyFilters: {
            adminPhone: '',
            userName: '',
            accountName: '',
            accountType: '',
            applyStatus: '',
            order: '1',
        },
        userApplyDetail: {},

        accountRentalOptions: [],
    },

    getters: {
        // 不同账户类型下的账号数量
        accountTypeNumbers(state) {
            const { stat: { zfNum = 0, zzqyNum = 0, yyqyNum = 0 } = {} } = state.accountList;
            return { zfNum, zzqyNum, yyqyNum };
        },
        // 不同账户类型下的账号数量
        accountApplyNumbers(state) {
            const { stat: { zfNotCheckNum = 0, zzqyCheckNum = 0, yyqyCheckNum = 0 } = {} } = state.accountApplyList;
            return { zfNotCheckNum, zzqyCheckNum, yyqyCheckNum };
        },
    },

    mutations: {
        [types.UPDATE_LIST](state, { type, list }) {
            state[`${type}List`] = list;
        },
        [types.UPDATE_DETAIL](state, { type, detail }) {
            state[`${type}Detail`] = detail;
        },
        [types.UPDATE_TYPE](state, { type, theType }) {
            state[`${type}Type`] = theType;
        },
        [types.UPDATE_PAGE_SIZE](state, { type, size }) {
            state[`${type}PageSize`] = size;
        },
        [types.UPDATE_CURRENT_PAGINATION](state, { type, pagination }) {
            state[`${type}Pagination`] = pagination;
        },
        [types.UPDATE_FILTERS](state, { type, filters }) {
            state[`${type}Filters`] = filters;
        },
        [types.UPDATE_LOADING_STATUS](state, { type, isLoading }) {
            state[`${type}Loading`] = isLoading;
        },
    },

    actions: {
        getAccountRentalOptions({ state }) {
            return new Promise((resolve, reject) => {
                Vue.http.get(urls.GET_USER_RENTAL_OPTIONS).then((response) => {
                    state.accountRentalOptions = response?.data || [];
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        // 账户一览：更新账户
        updateAccountRental({ dispatch, state }, formData) {
            const type = 'account';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            return new Promise((resolve, reject) => {
                Vue.http.post(urls.UPDATE_MANUFACTURER_RENTAL, formData).then((response) => {
                    dispatch(`get${Type}List`, state[`${type}Pagination`]);
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        // 账户一览：获取列表
        getAccountList({ commit, state }, pagination = 1) {
            const type = 'account';

            return new Promise((resolve, reject) => {
                commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                Vue.http.get(urls.GET_ACCOUNT_LIST, {
                    params: {
                        currentPage: pagination,
                        pageSize: state[`${type}PageSize`],
                        ...state[`${type}Filters`],
                    },
                }).then((response) => {
                    commit(types.UPDATE_LIST, { type, list: response.data });
                    commit(types.UPDATE_CURRENT_PAGINATION, { type, pagination });
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    resolve(response);
                }).catch((error) => {
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    reject(error);
                });
            });
        },

        //  账户一览：变更列表分页Size
        changeAccountListPageSize({ commit, dispatch, state }, size) {
            const type = 'account';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            commit(types.UPDATE_PAGE_SIZE, { type, size });
            dispatch(`get${Type}List`, state[`${type}Pagination`]);
        },

        //  账户一览：更新列表过滤条件
        changeAccountListFilters({ commit, dispatch, state }, filters) {
            const type = 'account';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            const f = helpers.update(state[`${type}Filters`], filters);
            commit(types.UPDATE_FILTERS, { type, filters: f });
            dispatch(`get${Type}List`, state[`${type}Pagination`]);
        },

        //  账户一览：获取账户详情
        getAccountDetail({ commit }, accountId) {
            const type = 'account';

            return new Promise((resolve, reject) => {
                Vue.http.get(urls.GET_ACCOUNT_DETAIL, {
                    params: {
                        accountId,
                    },
                }).then((response) => {
                    commit(types.UPDATE_DETAIL, { type, detail: response.data });
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        //  账户一览：清空账户详情数据
        clearAccountDetail({ commit }) {
            const type = 'account';

            commit(types.UPDATE_DETAIL, { type, detail: {} });
        },

        //  账户一览：创建账户
        createAccount({ dispatch, state }, formData) {
            const type = 'account';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            return new Promise((resolve, reject) => {
                Vue.http.post(urls.CREATE_ACCOUNT, formData).then((response) => {
                    dispatch(`get${Type}List`, state[`${type}Pagination`]);
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        //  账户一览：创建下级账户
        createSubCompany({ dispatch, state }, { formData, isSubPlatform = false } = {}) {
            const type = 'account';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            return new Promise((resolve, reject) => {
                Vue.http.post(isSubPlatform ? urls.CREATE_MANUFACTURER_COMPANY : urls.CREATE_SUB_COMPANY, formData).then((response) => {
                    dispatch(`get${Type}List`, state[`${type}Pagination`]);
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        // 账户一览：更新账户
        updateAccount({ dispatch, state }, formData) {
            const type = 'account';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            return new Promise((resolve, reject) => {
                Vue.http.put(urls.UPDATE_ACCOUNT, formData).then((response) => {
                    dispatch(`get${Type}List`, state[`${type}Pagination`]);
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        // 账户一览：更新账户可用状态
        updateAccountEnable({ dispatch, commit, state }, { accountId, opr }) {
            const type = 'account';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            return new Promise((resolve, reject) => {
                commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                Vue.http.put(urls.UPDATE_ACCOUNT_ENABLE, { accountId, opr }).then((response) => {
                    dispatch(`get${Type}List`, state[`${type}Pagination`]);
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    resolve(response);
                }).catch((error) => {
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    reject(error);
                });
            });
        },

        // 账户一览：删除账户
        deleteAccount({ dispatch, commit, state }, { accountId }) {
            const type = 'account';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            return new Promise((resolve, reject) => {
                commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                const url = helpers.buildURL(urls.DELETE_ACCOUNT, accountId);
                Vue.http.delete(url).then((response) => {
                    dispatch(`get${Type}List`, state[`${type}Pagination`]);
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    resolve(response);
                }).catch((error) => {
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    reject(error);
                });
            });
        },

        // 账户审核：获取账户申请列表
        getAccountApplyList({ commit, state }, pagination = 1) {
            const type = 'accountApply';

            return new Promise((resolve, reject) => {
                commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                Vue.http.get(urls.GET_ACCOUNT_APPLY_LIST, {
                    params: {
                        currentPage: pagination,
                        pageSize: state[`${type}PageSize`],
                        accountType: state.accountType, // 只有accountType
                        ...state[`${type}Filters`],
                    },
                }).then((response) => {
                    commit(types.UPDATE_LIST, { type, list: response.data });
                    commit(types.UPDATE_CURRENT_PAGINATION, { type, pagination });
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    resolve(response);
                }).catch((error) => {
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    reject(error);
                });
            });
        },

        // 账户审核：更改账户申请类型
        changeAccountApplyType({ commit, dispatch, state }, theType) {
            const type = 'accountApply';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            commit(types.UPDATE_TYPE, { type: 'account', theType }); // 只有accountType
            dispatch(`get${Type}List`, state[`${type}Pagination`]);
        },

        // 账户审核：变更账户申请列表分页Size
        changeAccountApplyListPageSize({ commit, dispatch, state }, size) {
            const type = 'accountApply';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            commit(types.UPDATE_PAGE_SIZE, { type, size });
            dispatch(`get${Type}List`, state[`${type}Pagination`]);
        },

        // 账户审核：更新账户申请列表过滤条件
        changeAccountApplyListFilters({ commit, dispatch, state }, filters) {
            const type = 'accountApply';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            commit(types.UPDATE_FILTERS, { type, filters });
            dispatch(`get${Type}List`, state[`${type}Pagination`]);
        },

        // 账户审核：获取账户申请详情
        getAccountApplyDetail({ commit }, applyId) {
            const type = 'accountApply';

            return new Promise((resolve, reject) => {
                Vue.http.get(urls.GET_ACCOUNT_APPLY_DETAIL, {
                    params: {
                        applyId,
                    },
                }).then((response) => {
                    commit(types.UPDATE_DETAIL, { type, detail: response.data });
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        // 账户审核：清空账户申请详情数据
        clearAccountApplyDetail({ commit }) {
            const type = 'accountApply';

            commit(types.UPDATE_DETAIL, { type, detail: {} });
        },

        // 账户审核：更新账户申请状态
        updateAccountApplyStatus({ dispatch, state }, formData) {
            const type = 'accountApply';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            return new Promise((resolve, reject) => {
                Vue.http.put(urls.UPDATE_ACCOUNT_APPLY_STATUS, formData).then((response) => {
                    dispatch(`get${Type}List`, state[`${type}Pagination`]);
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        // 账号审核：获取账号申请列表
        getUserApplyList({ commit, state }, pagination = 1) {
            const type = 'userApply';

            return new Promise((resolve, reject) => {
                commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                Vue.http.get(urls.GET_USER_APPLY_LIST, {
                    params: {
                        currentPage: pagination,
                        pageSize: state[`${type}PageSize`],
                        ...state[`${type}Filters`],
                    },
                }).then((response) => {
                    commit(types.UPDATE_LIST, { type, list: response.data });
                    commit(types.UPDATE_CURRENT_PAGINATION, { type, pagination });
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    resolve(response);
                }).catch((error) => {
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    reject(error);
                });
            });
        },

        // 账号审核：变更账号申请列表分页Size
        changeUserApplyListPageSize({ commit, dispatch, state }, size) {
            const type = 'userApply';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            commit(types.UPDATE_PAGE_SIZE, { type, size });
            dispatch(`get${Type}List`, state[`${type}Pagination`]);
        },

        // 账号审核：更新账号申请列表过滤条件
        changeUserApplyListFilters({ commit, dispatch, state }, filters) {
            const type = 'userApply';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            const f = helpers.update(state[`${type}Filters`], filters);
            commit(types.UPDATE_FILTERS, { type, filters: f });
            dispatch(`get${Type}List`, state[`${type}Pagination`]);
        },

        // 账号审核：获取账号申请详情
        getUserApplyDetail({ commit }, applyId) {
            const type = 'userApply';

            return new Promise((resolve, reject) => {
                Vue.http.get(urls.GET_USER_APPLY_DETAIL, {
                    params: {
                        applyId,
                    },
                }).then((response) => {
                    commit(types.UPDATE_DETAIL, { type, detail: response.data });
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        // 账号审核：清空账号申请详情数据
        clearUserApplyDetail({ commit }) {
            const type = 'userApply';

            commit(types.UPDATE_DETAIL, { type, detail: {} });
        },

        // 账号审核：更新账号申请状态
        updateUserApplyStatus({ dispatch, state }, formData) {
            const type = 'userApply';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            return new Promise((resolve, reject) => {
                Vue.http.put(urls.UPDATE_ACCOUNT_APPLY_STATUS, formData).then((response) => {
                    dispatch(`get${Type}List`, state[`${type}Pagination`]);
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        // end
    },
};
