/**
 * 手机号开头
 */
// import taiwanFlag from '../assets/flag_taiwan.jpg';
import chinaFlag from '../assets/flag_china.jpg';
import thailandFlag from '../assets/flag_thailand.jpg';
import koreaFlag from '../assets/flag_southkorea.jpg';
import japanFlag from '../assets/flag_japan.jpg';
import brazilFlag from '../assets/flag_brazil.jpg';
import vietnamFlag from '../assets/flag_vietnam.jpg';
import uruguayFlag from '../assets/flag_uruguay.jpg';
import myanmarFlag from '../assets/flag_myanmar.jpg';
import malaysiaFlag from '../assets/flag_malaysia.jpg';
import turkeyFlag from '../assets/flag_turkey.jpg';
import indiaFlag from '../assets/flag_india.jpg';

const options = [
    { value: '+86', flag: chinaFlag, name: '中国', alias: 'china' },
    { value: '+66', flag: thailandFlag, name: '泰国', alias: 'thailand' },
    { value: '+91', flag: indiaFlag, name: '印度', alias: 'india' },
    { value: '+82', flag: koreaFlag, name: '朝鲜', alias: 'korea' },
    { value: '+81', flag: japanFlag, name: '日本', alias: 'japan' },
    { value: '+55', flag: brazilFlag, name: '巴西', alias: 'brazil' },
    { value: '+84', flag: vietnamFlag, name: '越南', alias: 'vietnam' },
    { value: '+598', flag: uruguayFlag, name: '乌拉圭', alias: 'uruguay' },
    { value: '+60', flag: malaysiaFlag, name: '马来西亚', alias: 'malaysia' },
    { value: '+90', flag: turkeyFlag, name: '土耳其', alias: 'turkey' },
    { value: '+95', flag: myanmarFlag, name: '缅甸', alias: 'myanmar' },
    { value: '+886', flag: chinaFlag, name: '中国-台湾', alias: 'taiwan' },
];

const defaultVisible = [
    'china',
    'taiwan',
    'thailand',
    'india',
    'korea',
    'japan',
    'brazil',
    'vietnam',
    'uruguay',
    'malaysia',
    // 'turkey',
    // 'myanmar',
];


export default function (visibleCountry = defaultVisible) {
    return options.filter(({ alias }) => visibleCountry.indexOf(alias) >= 0);
}
