/**
 * 公告管理
 */

const BulletinList = () => import(/* webpackChunkName: "chunk!bulletin-list" */'../views/BulletinManage/BulletinList.vue');
const BulletinCreate = () => import(/* webpackChunkName: "chunk!bulletin-create" */'../views/BulletinManage/BulletinCreate.vue');

const group = 'bulletin_manage';

export default [
    {
        path: `/${group}/bulletin-list`,
        name: 'BulletinList',
        component: BulletinList,
        meta: { group },
    },
    {
        path: `/${group}/bulletin-create`,
        name: 'BulletinCreate',
        component: BulletinCreate,
        meta: { group },
    },
];
