/**
 * lease
 */

import Vue from 'vue';
import * as types from './mutation-types';
import * as urls from '../config/urls';
// import * as helpers from "../utils/helpers";
// import * as helpers from '../utils/helpers';

export default {
    namespaced: true,

    state: {
        leaseList: {},
        leaseLoading: false,
        leasePagination: 1,
        leasePageSize: 20,
        leaseFilters: {
            accountName: undefined,
            accountId: undefined,
        },
        leaseDetail: {},
        ACTIVE_STATUS_MAP: {
            0: 'debugging',
            1: 'unactivated',
            2: 'activated',
        },
        // leaseModel
        leaseModelList: {},
    },

    getters: {
        stat(state) {
            const { stat: { total = 0, workArea = 0, zzNum = 0, sortieCount = 0, modelNum = 0 } = {} } = state.leaseList;
            return { total, workArea, zzNum, sortieCount, modelNum };
        },

        activeStatusLabel: state => (status) => {
            const { [status]: label } = state.ACTIVE_STATUS_MAP;
            return label || '-';
        },
    },

    mutations: {
        [types.UPDATE_LIST](state, { type, list }) {
            state[`${type}List`] = list;
        },
        [types.UPDATE_DETAIL](state, { type, detail }) {
            state[`${type}Detail`] = detail;
        },
        [types.UPDATE_PAGE_SIZE](state, { type, size }) {
            state[`${type}PageSize`] = size;
        },
        [types.UPDATE_CURRENT_PAGINATION](state, { type, pagination }) {
            state[`${type}Pagination`] = pagination;
        },
        [types.UPDATE_FILTERS](state, { type, filters }) {
            state[`${type}Filters`] = filters;
        },
        [types.UPDATE_LOADING_STATUS](state, { type, isLoading }) {
            state[`${type}Loading`] = isLoading;
        },
    },

    actions: {
        getLeaseList({ commit, state }, pagination = 1) {
            const type = 'lease';

            return new Promise((resolve, reject) => {
                commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                Vue.http.get(urls.GET_LEASE_LIST, {
                    params: {
                        pageIndex: pagination,
                        size: state[`${type}PageSize`],
                        ...state[`${type}Filters`],
                    },
                }).then(({ data }) => {
                    commit(types.UPDATE_LIST, { type, list: data });
                    commit(types.UPDATE_CURRENT_PAGINATION, { type, pagination });
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    resolve(data);
                }).catch((error) => {
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    reject(error);
                });
            });
        },

        getLeaseDroneList() {
            return new Promise((resolve, reject) => {
                // commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                Vue.http.get(urls.GET_LEASE_DRONE_LIST).then(({ data }) => {
                    resolve(data);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        createLease({ dispatch, state }, formData) {
            const type = 'lease';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            return new Promise((resolve, reject) => {
                Vue.http.post(urls.CREATE_LEASE, formData).then((response) => {
                    dispatch(`get${Type}List`, state[`${type}Pagination`]);
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },
        //
        // // 更变分页Size
        changeLeaseListPageSize({ commit, dispatch, state }, size) {
            const type = 'lease';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            commit(types.UPDATE_PAGE_SIZE, { type, size });
            dispatch(`get${Type}List`, state[`${type}Pagination`]);
        },
        //
        // // 更新过滤条件
        changeLeaseListFilters({ commit, dispatch, state }, filters) {
            const type = 'lease';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            commit(types.UPDATE_FILTERS, { type, filters });
            dispatch(`get${Type}List`, state[`${type}Pagination`]);
        },
        //
        // // 获取详情
        // getLeaseDetail({ commit }, phone) {
        //     const type = 'lease';
        //
        //     return new Promise((resolve, reject) => {
        //         Vue.http.get(urls.GET_CLIENT_INFO_BY_PHONE, {
        //             params: {
        //                 phone,
        //             },
        //         }).then((response) => {
        //             commit(types.UPDATE_DETAIL, { type, detail: response.data || {} });
        //             resolve(response);
        //         }).catch((error) => {
        //             reject(error);
        //         });
        //     });
        // },

        // getLeaseDetail({ commit }, leaseId) {
        //     const type = 'lease';
        //
        //     return new Promise((resolve, reject) => {
        //         Vue.http.get(urls.GET_CLIENT_INFO_BY_PHONE, {
        //             params: {
        //                 leaseId,
        //             },
        //         }).then((response) => {
        //             commit(types.UPDATE_DETAIL, { type, detail: response.data });
        //             resolve(response);
        //         }).catch((error) => {
        //             reject(error);
        //         });
        //     });
        // },

        //
        // // 清空农机详情数据
        // clearLeaseDetail({ commit }) {
        //     const type = 'lease';
        //
        //     commit(types.UPDATE_DETAIL, { type, detail: {} });
        // },
        //
        // // 更新农机数据
        returnDrone({ dispatch, commit, state }, { id, returnTime }) {
            const type = 'lease';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            return new Promise((resolve, reject) => {
                commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                Vue.http.post(urls.RETURN_DRONE_OF_LEASE, { id, returnTime }).then((response) => {
                    dispatch(`get${Type}List`, state[`${type}Pagination`]);
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    resolve(response);
                }).catch((error) => {
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    reject(error);
                });
            });
        },

        cancelLease({ dispatch, commit, state }, { id }) {
            const type = 'lease';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            return new Promise((resolve, reject) => {
                commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                Vue.http.post(urls.CANCEL_LEASE, { id }).then((response) => {
                    dispatch(`get${Type}List`, state[`${type}Pagination`]);
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    resolve(response);
                }).catch((error) => {
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    reject(error);
                });
            });
        },

        //
        // // 编辑农机制造商标识
        // updateManufacturerSign({ dispatch, commit, state }, { leaseId, zzleaseNum }) {
        //     const type = 'lease';
        //     const Type = type.replace(/^\w/, _ => _.toUpperCase());
        //
        //     return new Promise((resolve, reject) => {
        //         commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
        //         const url = helpers.buildURL(urls.UPDATE_MANUFACTURER_SIGN, leaseId);
        //         Vue.http.put(url, { zzleaseNum }).then((response) => {
        //             dispatch(`get${Type}List`, state[`${type}Pagination`]);
        //             commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
        //             resolve(response);
        //         }).catch((error) => {
        //             commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
        //             reject(error);
        //         });
        //     });
        // },
        //
        // // 更新农机锁定状态
        // updateLeaseLock({ dispatch, commit, state }, { leaseId, opr, password }) {
        //     const type = 'lease';
        //     const Type = type.replace(/^\w/, _ => _.toUpperCase());
        //
        //     return new Promise((resolve, reject) => {
        //         commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
        //         Vue.http.put(urls.UPDATE_lease_LOCK, { leaseId, opr, password }).then((response) => {
        //             dispatch(`get${Type}List`, state[`${type}Pagination`]);
        //             commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
        //             resolve(response);
        //         }).catch((error) => {
        //             commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
        //             reject(error);
        //         });
        //     });
        // },
        //
        // // 更新农机销售状态
        // updateLeaseSaleStatus({ dispatch, commit, state }, { leaseId, saleType }) {
        //     const type = 'lease';
        //     const Type = type.replace(/^\w/, _ => _.toUpperCase());
        //
        //     return new Promise((resolve, reject) => {
        //         commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
        //         const url = helpers.buildURL(urls.UPDATE_lease_SALE_STATUS, leaseId);
        //         Vue.http.put(url, { saleType }).then((response) => {
        //             dispatch(`get${Type}List`, state[`${type}Pagination`]);
        //             commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
        //             resolve(response);
        //         }).catch((error) => {
        //             commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
        //             reject(error);
        //         });
        //     });
        // },
        //
        // // 获取农机型号下拉列表
        // getLeaseModelList({ commit }) {
        //     const type = 'leaseModel';
        //
        //     return new Promise((resolve, reject) => {
        //         Vue.http.get(urls.GET_OWN_lease_MODEL_SELECT_LIST).then((response) => {
        //             commit(types.UPDATE_LIST, { type, list: response.data });
        //             resolve(response);
        //         }).catch((error) => {
        //             reject(error);
        //         });
        //     });
        // },
        //
        // // 更新农机类型
        // updateDeviceType({ dispatch, state }, { leaseId, deviceType, sprayWidth }) {
        //     const type = 'lease';
        //     const Type = type.replace(/^\w/, _ => _.toUpperCase());
        //
        //     return new Promise((resolve, reject) => {
        //         const url = helpers.buildURL(urls.UPDATE_DEVICE_TYPE, leaseId);
        //         Vue.http.put(url, { deviceType, sprayWidth }).then((response) => {
        //             dispatch(`get${Type}List`, state[`${type}Pagination`]);
        //             resolve(response);
        //         }).catch((error) => {
        //             reject(error);
        //         });
        //     });
        // },

        // end
    },
};
