/**
 * user
 */

import Vue from 'vue';
import * as types from './mutation-types';
import * as urls from '../config/urls';
import * as auth from '../utils/auth';
import * as helpers from '../utils/helpers';
import { getDistrictKey } from '../utils/cascadeDistrict';
import { accountTypes, userTypes, subPlatformType, domainNameRoute } from '../config/routeAccessibility';

export default {
    namespaced: true,

    state: {
        userInfo: auth.getUserInfo() || {},
        // 账号类型
        userTypeMap: {
            1: { label: 'super_admin', disabled: true, abilities: ['all_permissions'] },
            // 2: { label: '分级管理员', disabled: false, abilities: [] },
            3: { label: 'staff', disabled: false, abilities: [] },
            4: { label: 'enterprise_admin', disabled: false, abilities: ['manage_staff', 'view_system_logs', 'business_admin_privileges'] },
            5: {
                label: 'business_admin',
                disabled: false,
                abilities: [
                    'mange_equipment',
                    'mange_no_fly_zone',
                    'mange_account',
                    'mange_team',
                    'mange_task',
                    'receive_l1_sms',
                    'send_announcement',
                    'receive_announcement',
                ],
            },
        },

        leaseModelOption: [],
        manufacturerByAccountOption: [],
    },

    getters: {
        isTenant(state) {
            return !!state.manufacturerByAccountOption.length;
        },

        isMuModel(state) {
            return state.leaseModelOption.find(item => item.code === 1);
        },

        isTimeModel(state) {
            return state.leaseModelOption.find(item => item.code === 2);
        },

        isAllModel(state) {
            return state.leaseModelOption.find(item => item.code === 3);
        },

        isLeaseAccount: (state, getters) => (getters.isMuModel || getters.isTimeModel || getters.isAllModel),

        phoneNum(state) {
            const { phoneNum } = state.userInfo || {};
            return phoneNum ? phoneNum.replace(/(\d{3})\d*(\d{3})/g, '$1***$2') : '';
        },
        isIndia() {
            const { host } = window.location;
            // 检查 host 是否包含特定的子字符串
            const indiaList = ['xagrotortek', 'syferocompanionpro', 'pixelrobotics', 'marutdrones', 'aipl', '192.250.226.217'];
            return indiaList.some(item => host.includes(item));
            // const includesXagro = host.includes('xagrotortek');
            // const includesSyfero = host.includes('syferocompanionpro');
            // const includesPix = host.includes('pixelrobotics');
            // const includesMar = host.includes('marutdrones');
            // const includesDgca = host.includes('aipl');
            // return includesXagro || includesSyfero || includesPix || includesMar;
        },

        isTW() {
            const { host } = window.location;
            // 检查 host 是否包含特定的子字符串
            const tWList = ['7adrones'];
            return tWList.some(item => host.includes(item));
            // const includesXagro = host.includes('xagrotortek');
            // const includesSyfero = host.includes('syferocompanionpro');
            // const includesPix = host.includes('pixelrobotics');
            // const includesMar = host.includes('marutdrones');
            // const includesDgca = host.includes('aipl');
            // return includesXagro || includesSyfero || includesPix || includesMar;
        },
        // 账号类型label
        userTypeLabel: state => (userType) => {
            const { [userType]: { label } = {} } = state.userTypeMap;
            return label || '-';
        },
        // 账号权限labels
        userTypeAbilities: state => (userType) => {
            const { [userType]: role } = state.userTypeMap;
            if (role) {
                return role.abilities;
            }
            return [];
        },
        // 通过键名访问userInfo的值
        getUserInfo: state => (key) => {
            const { [key]: value } = state.userInfo || {};
            return value || '';
        },
        // 是否是：同账户
        isSameAccount: (state, getters) => accountId => getters.getUserInfo('accountId') === accountId,
        // 账户是否通过审核
        isPassed: (state, getters) => getters.getUserInfo('checkStatus') === 2,
        // 当前账户是否是：平台方
        isPlatform: (state, getters) => getters.getUserInfo('accountType') === 1,
        // 当前账户是否是：子平台方
        isSubPlatform: (state, getters) => getters.getUserInfo('tag') === 1 && getters.getUserInfo('accountType') === 1,
        // 当前账户是否是：政府部门
        isGovernment: (state, getters) => getters.getUserInfo('accountType') === 2,
        // 当前账户是否是：制造商
        isManufacturer: (state, getters) => getters.getUserInfo('accountType') === 3,
        // 当前账户是否是：运营商
        isOperator: (state, getters) => getters.getUserInfo('accountType') === 4,
        // 当前账户是否是：个人运营人
        isPersonalOperator: (state, getters) => getters.isOperator && getters.getUserInfo('teamType') === 2,
        // 当前账号是否是：普通成员
        isNormalMember: (state, getters) => getters.getUserInfo('userType') === 3,
        // 当前账号是否可以有下级账号（其中accountCatalogLevel=1表示顶级账号，其他则为子级级别；其中canCreateSub表示这个子账号也可以创建自己的子账户）
        hasSubAccount: (state) => {
            const { accountCatalogLevel, vipLevel, canCreateSub } = state.userInfo || {};
            return (accountCatalogLevel === 1 && vipLevel > 0) || (accountCatalogLevel > 1 && !!canCreateSub);
        },
        // 获取账户管辖地区code
        adCode(state) {
            const { accountType, ruleProvince, ruleCity, ruleCounty } = state.userInfo || {};
            if (accountType === 2) {
                const name = ruleCounty || ruleCity || ruleProvince;
                if (name) {
                    return getDistrictKey(name);
                }
                return '';
            }
            return '';
        },
        // 账户和账号对应的路由名称
        routeNames: (state) => {
            const { accountType = 0, userType = 0, tag = undefined } = state.userInfo || {};
            const { [accountType]: routeNamesForAccount = [] } = accountTypes;
            const { [userType]: routeNamesForUser = [] } = userTypes;
            const { [tag]: routeNamesForSubPlatform = [] } = subPlatformType;
            const { [window.location.host]: routeNamesForDomainName = domainNameRoute.default } = domainNameRoute;
            return { routeNamesForAccount, routeNamesForUser, routeNamesForSubPlatform, routeNamesForDomainName };
        },
        // 显示某个菜单项
        showMenu: (state, getters) => (routeName) => {
            const { routeNamesForAccount, routeNamesForUser, routeNamesForSubPlatform, routeNamesForDomainName } = getters.routeNames;
            const { tag = undefined } = state.userInfo || {};
            if (tag) {
                return routeNamesForSubPlatform.indexOf(routeName) >= 0 && routeNamesForDomainName.indexOf(routeName) >= 0;
            }
            return routeNamesForAccount.indexOf(routeName) >= 0 && routeNamesForUser.indexOf(routeName) >= 0 && routeNamesForDomainName.indexOf(routeName) >= 0;
        },
        // 显示某个菜单组
        showMenuGroup: (state, getters) => (routeNames) => {
            const { routeNamesForAccount, routeNamesForUser, routeNamesForSubPlatform, routeNamesForDomainName } = getters.routeNames;
            const { tag = undefined } = state.userInfo || {};
            for (let i = 0, len = routeNames.length; i < len; i += 1) {
                if (tag) {
                    if (routeNamesForSubPlatform.indexOf(routeNames[i]) >= 0 && routeNamesForDomainName.indexOf(routeNames[i]) >= 0) {
                        return true;
                    }
                }
                if (routeNamesForAccount.indexOf(routeNames[i]) >= 0 && routeNamesForUser.indexOf(routeNames[i]) >= 0 && routeNamesForDomainName.indexOf(routeNames[i]) >= 0) {
                    return true;
                }
            }
            return false;
        },
    },

    mutations: {
        [types.UPDATE_USER_INFO](state, payload) {
            state.userInfo = payload;
        },
    },

    actions: {
        // 请求短信验证码（codeType：1=手机登录，2=账户注册，3=更改密码）// MARK: (4=提交资料，5=创建用户)已经拆分到另一个接口请求里
        requestSmsVerifyCode(store, { phoneNum, codeType }) {
            return new Promise((resolve, reject) => {
                Vue.axios.get(urls.REQUEST_SMS_VERIFY_CODE, {
                    params: { phoneNum, codeType },
                }).then((response) => {
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },
        // 校验验证码（checkOpr：2=账户注册，3=密码验证）
        requestCheckSmsVerifyCode(store, { phoneNum, verifyCode, checkOpr }) {
            return new Promise((resolve, reject) => {
                Vue.axios.get(urls.REQUEST_CHECK_SMS_VERIFY_CODE, {
                    params: { phoneNum, code: verifyCode, checkOpr },
                }).then((response) => {
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        // 请求密码登录
        requestPasswordLogin({ commit }, { phoneNum, password, verifyCode, rememberPassword = false }) {
            return new Promise((resolve, reject) => {
                Vue.axios.post(urls.REQUEST_PASSWORD_LOGIN, {
                    phoneNum, password, verifyCode, rememberPassword,
                }).then((response) => {
                    const { jToken: token } = response.data;
                    const phone = phoneNum.replaceAll(/\+\d*-/g, '');
                    const userInfo = {
                        phoneNum: phone,
                        ...response.data,
                    };
                    commit(types.UPDATE_USER_INFO, userInfo);
                    auth.clearAll();
                    auth.saveUserInfo(userInfo);
                    if (token) {
                        auth.saveToken(token, rememberPassword); // TODO 记住密码未实现
                    }
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

         getRentalModelByAccount({ state }) {
            return new Promise((resolve, reject) => {
                Vue.http.get(urls.GET_RENTAL_MODEL_BY_ACCOUNT).then((response) => {
                    // console.log('response', response);
                    state.leaseModelOption = Array.isArray(response?.data) ? response?.data : [];
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        getManufacturerByAccount({ state }) {
            return new Promise((resolve, reject) => {
                Vue.http.get(urls.GET_MANUFACTURER_BY_ACCOUNT).then((response) => {
                    // console.log('response', response);
                    state.manufacturerByAccountOption = Array.isArray(response?.data) ? response?.data : [];
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        // 请求短信验证码登录
        requestSmsLogin({ commit }, { phoneNum, verifyCode, rememberPassword }) {
            return new Promise((resolve, reject) => {
                Vue.axios.post(urls.REQUEST_SMS_LOGIN, {
                    phoneNum, verifyCode, rememberPassword,
                }).then((response) => {
                    const { jToken: token } = response.data;
                    const phone = phoneNum.replaceAll(/\+\d*-/g, '');
                    const userInfo = {
                        phoneNum: phone,
                        ...response.data,
                    };
                    commit(types.UPDATE_USER_INFO, userInfo);
                    auth.clearAll();
                    auth.saveUserInfo(userInfo);
                    if (token) {
                        auth.saveToken(token);
                    }
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        // 通过refreshToken登录
        requestRefreshLogin({ commit }, { refreshToken }) {
            return new Promise((resolve, reject) => {
                Vue.axios.post(urls.REQUEST_REFRESH_TOKEN, { refreshToken }).then((response) => {
                    const { jToken: token } = response.data;
                    const userInfo = {
                        phoneNum: '',
                        ...response.data,
                    };
                    commit(types.UPDATE_USER_INFO, userInfo);
                    auth.clearAll();
                    auth.saveUserInfo(userInfo);
                    if (token) {
                        auth.saveToken(token);
                    }
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        // 请求退出登录
        requestLogout({ commit }) {
            return new Promise((resolve, reject) => {
                Vue.axios.post(urls.REQUEST_LOGOUT).then((response) => {
                    commit(types.UPDATE_USER_INFO, null);
                    auth.removeToken();
                    auth.removeUserInfo();
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        // 请求重置密码
        requestResetPassword({ commit }, { phoneNum, password }) {
            return new Promise((resolve, reject) => {
                Vue.axios.put(urls.REQUEST_RESET_PASSWORD, { phoneNum, password }).then((response) => {
                    commit(types.UPDATE_USER_INFO, null);
                    auth.removeToken();
                    auth.removeUserInfo();
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        // 注册账户
        requestRegister(store, formData) {
            return new Promise((resolve, reject) => {
                Vue.axios.post(urls.REQUEST_REGISTER, formData).then((response) => {
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        // 更新用户信息
        updateUserInfo({ commit, state }, info = {}) {
            const data = helpers.update(state.userInfo, info);
            commit(types.UPDATE_USER_INFO, data);
        },

        // end
    },
};
