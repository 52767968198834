<template>
    <Card :class="$style.root">
        <div class="block">
            <TitleBig>{{ $t('作业区域统计') }}</TitleBig>
            <Empty class="empty" v-if="!allPeriodChinaY.length && !allPeriodForeignY.length">{{ $t('暂无数据') }}</Empty>
            <v-chart :option="chartOption0" :update-options="{ notMerge: true }" theme="light" autoresize v-else />
        </div>
        <div class="multi-column">
            <div class="block">
                <Title class="block-title">{{ $t('作业时间统计') }}</Title>
                <Empty class="empty" v-if="!allPeriodY.length">{{ $t('暂无数据') }}</Empty>
                <v-chart :option="chartOption1" :update-options="{ notMerge: true }" theme="light" autoresize v-else />
            </div>
            <div class="block" style="margin-left: 10px !important;">
                <Title class="block-title">{{ $t('本月时间统计') }}</Title>
                <Empty class="empty" v-if="!thisMonthY.length">{{ $t('暂无数据') }}</Empty>
                <v-chart :option="chartOption2" :update-options="{ notMerge: true }" theme="light" autoresize v-else />
            </div>
            <div class="block" v-if="false">
                <Title class="block-title">{{ $t('本年时间统计') }}</Title>
                <Empty class="empty" v-if="!thisYearY.length">{{ $t('暂无数据') }}</Empty>
                <v-chart :option="chartOption3" :update-options="{ notMerge: true }" theme="light" autoresize v-else />
            </div>
        </div>
    </Card>
</template>

<i18n src="./languages.json"></i18n>

<script>
    import { mapActions, mapGetters, mapState } from 'vuex';
    import _throttle from 'lodash.throttle';
    import * as echarts from 'echarts';
    import Card from './components/Card.vue';
    import Title from './components/Title.vue';
    import TitleBig from './components/TitleBig.vue';
    import Empty from '../../components/Empty.vue';

    let throttle = null;
    let ticker = null;

    const areaColor = [
        new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: 'rgba(50,225,169,1)' },
            { offset: 1, color: 'rgba(50,225,169,0.2)' },
        ]),
        new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: 'rgba(50,163,243,1)' },
            { offset: 1, color: 'rgba(50,163,243,0.2)' },
        ]),
    ];

    export default {
        name: 'SmallScreenPanel4',

        components: { Card, Title, TitleBig, Empty },

        data() {
            return {
                tooltip: {
                    trigger: 'axis',
                    backgroundColor: 'rgba(0,192,250,0.5)',
                    borderColor: 'rgba(0,192,250,0.8)',
                    textStyle: { color: 'white' },
                    axisPointer: {
                        lineStyle: {
                            color: 'rgba(0,192,250,0.4)',
                        },
                    },
                    formatter: (params) => {
                        if (Array.isArray(params)) {
                            const [{ name }] = params;
                            const title = `${name}`;
                            const rows = params.map(({ marker, seriesName, value }) => {
                                const nameEl = `<span style="flex: 1; margin-left: 3px;">${seriesName}：</span>`;
                                // const val = this.viewValue(value);
                                if (value > 100000) {
                                    const val = this.viewValue(value);
                                    const valueEl = `<span>${this.areaConversion(val).toFixed(2)} ${this.$t('万')}${this.$t(this.areaUnit)}</span>`;
                                    return `<div style="display: flex; align-items: center;">${marker}${nameEl}${valueEl}</div>`;
                                }
                                const valueEl = `<span>${this.areaConversion(value).toFixed(2)} ${this.$t(this.areaUnit)}</span>`;
                                return `<div style="display: flex; align-items: center;">${marker}${nameEl}${valueEl}</div>`;
                            }).filter(Boolean).join('');
                            return `<div>${title}</div>${rows}`;
                        }
                        const { marker, name, value } = params;
                        // let val = this.isEn ? (value / 1000000) : (value / 10000);
                        return `<div>${marker}${name}：${value} ${this.$t('万')}${this.$t(this.areaUnit)}</div>`;
                    },
                },
                xAxisOption: {
                    type: 'category',
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        color: 'rgba(255, 255, 255, 1)',
                        rotate: 30,
                        formatter: val => val
                            .replace('省', '')
                            .replace('市', '')
                            .replace(/^内蒙古.*/, '内蒙古')
                            .replace(/^新疆.*/, '新疆')
                            .replace(/^西藏.*/, '西藏')
                            .replace(/^广西.*/, '广西')
                            .replace(/^宁夏.*/, '宁夏'),
                    },
                },
                yAxisOption: {
                    type: 'value',
                    axisLabel: {
                        color: 'rgba(255, 255, 255, 1)',
                        formatter: (val) => {
                            const value = this.viewValue(val);
                            return `${this.areaConversion(value).toFixed(2)}${this.$t('万')}${this.$t(this.areaUnit)}`;
                        },
                    },
                    splitLine: {
                        lineStyle: {
                            color: 'rgba(255, 255, 255, 0.4)',
                        },
                    },
                },
            };
        },

        computed: {
            ...mapState('statScreen', [
                'allPeriodChinaSprayArea',
                'allPeriodForeignSprayArea',
                'cafSprayAreaAllPeriod',
                'cafSprayAreaThisYear',
                'cafSprayAreaThisMonth',
            ]),

            ...mapGetters('setting', ['areaConversion', 'areaUnit']),
            ...mapGetters('lang', ['isEn']),

            allPeriodChinaX() {
                const { xData } = this.allPeriodChinaSprayArea;
                return xData || [];
            },

            allPeriodChinaY() {
                const { yData: [data] = [] } = this.allPeriodChinaSprayArea;
                return data || [];
            },

            allPeriodForeignX() {
                const { xData } = this.allPeriodForeignSprayArea;
                return xData || [];
            },

            allPeriodForeignY() {
                const { yData: [data] = [] } = this.allPeriodForeignSprayArea;
                return data || [];
            },

            allPeriodPie() {
                const china = this.allPeriodChinaY.reduce((acc, cur) => acc + cur, 0);
                const foreign = this.allPeriodForeignY.reduce((acc, cur) => acc + cur, 0);
                const chinaValue = this.viewValue(china);
                const foreignValue = this.viewValue(foreign);
                return [
                    { name: this.$t('国内'), value: this.areaConversion(chinaValue).toFixed(2), selected: true },
                    { name: this.$t('海外'), value: this.areaConversion(foreignValue).toFixed(2) },
                ];
            },

            chartOption0() {
                const color = ['#96A9E1', '#F1814F'];
                return {
                    color,
                    grid: [
                        { left: 0, top: 10, bottom: 0, width: '65%', containLabel: true },
                        { left: '66%', top: 10, bottom: 0, right: 0, containLabel: true },
                        { left: 0, top: 0, bottom: 0, right: 0, containLabel: true },
                    ],
                    legend: [{
                        show: false,
                    }, {
                        show: false,
                    }, {
                        // bottom: 0,
                        top: 0,
                        right: 0,
                        // left: '86%',
                        textStyle: {
                            color: 'white',
                        },
                        orient: 'horizontal',
                        selectedMode: false,
                        data: [this.$t('国内'), this.$t('海外')],
                    }],
                    tooltip: this.tooltip,
                    xAxis: [{
                        data: this.allPeriodChinaX,
                        ...this.xAxisOption,
                        gridIndex: 0,
                    }, {
                        data: this.allPeriodForeignX,
                        ...this.xAxisOption,
                        gridIndex: 1,
                    }],
                    yAxis: [
                        { ...this.yAxisOption, gridIndex: 0 },
                        { ...this.yAxisOption, gridIndex: 1 },
                    ],
                    series: [{
                        name: this.$t('作业面积'),
                        type: 'bar',
                        barMinWidth: 10,
                        barMaxWidth: 40,
                        xAxisIndex: 0,
                        yAxisIndex: 0,
                        itemStyle: {
                            color: color[0],
                        },
                        data: this.allPeriodChinaY,
                    }, {
                        name: this.$t('作业面积'),
                        type: 'bar',
                        barMinWidth: 10,
                        barMaxWidth: 40,
                        xAxisIndex: 1,
                        yAxisIndex: 1,
                        itemStyle: {
                            color: color[1],
                        },
                        data: this.allPeriodForeignY,
                    }, {
                        type: 'pie',
                        top: 0,
                        left: '80%',
                        width: '22%',
                        height: '70%',
                        radius: ['55%', '74%'],
                        label: {
                            show: false,
                            position: 'center',
                            color: 'white',
                            lineHeight: 15,
                            // formatter: '{label|{b}}\n{value|{c}万亩}\n{parent|{d}%}',
                            formatter: ({ data: { name, value }, percent }) =>
                                // const val = this.viewValue(value);
                                `{label|${name}}\n{value|${value}${this.$t('万')}${this.$t(this.areaUnit)}}\n{parent|${percent}%}`,
                            rich: {
                                label: { fontSize: 18, lineHeight: 24 },
                                value: { fontSize: 14, color: '#999' },
                                parent: { fontSize: 10, color: '#999' },
                            },
                        },
                        tooltip: {
                            trigger: 'item',
                        },
                        selectedMode: true,
                        selectedOffset: 0,
                        select: {
                            label: { show: true },
                        },
                        data: this.allPeriodPie,
                    }],
                };
            },


            allPeriodX() {
                const { xData } = this.cafSprayAreaAllPeriod;
                return xData || [];
            },

            allPeriodY() {
                const { yData } = this.cafSprayAreaAllPeriod;
                return yData || [];
            },

            allPeriodZ() {
                const { zData } = this.cafSprayAreaAllPeriod;
                return zData || [];
            },

            thisYearX() {
                const { xData } = this.cafSprayAreaThisYear;
                return xData || [];
            },

            thisYearY() {
                const { yData } = this.cafSprayAreaThisYear;
                return yData || [];
            },

            thisYearZ() {
                const { zData } = this.cafSprayAreaThisYear;
                return zData || [];
            },

            thisMonthX() {
                const { xData } = this.cafSprayAreaThisMonth;
                return xData || [];
            },

            thisMonthY() {
                const { yData } = this.cafSprayAreaThisMonth;
                return yData || [];
            },

            thisMonthZ() {
                const { zData } = this.cafSprayAreaThisMonth;
                return zData || [];
            },

            chartOption1() {
                return {
                    color: ['rgba(50,225,169,1)', 'rgba(50,163,243,1)'],
                    grid: [{
                        left: 0,
                        right: 0,
                        top: 10,
                        bottom: 0,
                        containLabel: true,
                    }],
                    legend: {
                        right: 0,
                        orient: 'vertical',
                        selectedMode: false,
                        textStyle: { color: 'white' },
                    },
                    tooltip: this.tooltip,
                    xAxis: [{
                        type: 'category',
                        data: this.allPeriodX,
                        axisLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLabel: {
                            color: 'rgba(255, 255, 255, 1)',
                        },
                    }],
                    yAxis: [{
                        type: 'value',
                        axisLabel: {
                            color: 'rgba(255, 255, 255, 1)',
                            formatter: (val) => {
                                const value = this.viewValue(val);
                                return `${this.areaConversion(value).toFixed(2)}${this.$t('万')}${this.$t(this.areaUnit)}`;
                            },
                        },
                        splitLine: {
                            lineStyle: {
                                color: 'rgba(255, 255, 255, 0.4)',
                            },
                        },
                    }],
                    series: this.allPeriodY.map((data, index) => ({
                        name: this.allPeriodZ[index],
                        type: 'line',
                        smooth: true,
                        symbol: 'none',
                        areaStyle: {
                            opacity: 0.8,
                            color: areaColor[index],
                        },
                        emphasis: {
                            focus: 'series',
                        },
                        data,
                    })),
                };
            },

            chartOption2() {
                return {
                    color: ['rgba(50,225,169,1)', 'rgba(50,163,243,1)'],
                    grid: [{
                        left: 0,
                        right: 0,
                        top: 10,
                        bottom: 0,
                        containLabel: true,
                    }],
                    legend: {
                        right: 0,
                        orient: 'vertical',
                        selectedMode: false,
                        textStyle: { color: 'white' },
                    },
                    tooltip: this.tooltip,
                    xAxis: [{
                        type: 'category',
                        data: this.thisMonthX,
                        axisLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLabel: {
                            color: 'rgba(255, 255, 255, 1)',
                        },
                    }],
                    yAxis: [{
                        type: 'value',
                        axisLabel: {
                            color: 'rgba(255, 255, 255, 1)',
                            formatter: val => `${this.areaConversion(val / 10000).toFixed(2)}${this.$t('万')}${this.$t(this.areaUnit)}`,
                        },
                        splitLine: {
                            lineStyle: {
                                color: 'rgba(255, 255, 255, 0.4)',
                            },
                        },
                    }],
                    series: this.thisMonthY.map((data, index) => ({
                        name: this.thisMonthZ[index],
                        type: 'line',
                        smooth: true,
                        symbol: 'none',
                        areaStyle: {
                            opacity: 0.8,
                            color: areaColor[index],
                        },
                        emphasis: {
                            focus: 'series',
                        },
                        data,
                    })),
                };
            },

            chartOption3() {
                return {
                    color: ['rgba(50,225,169,1)', 'rgba(50,163,243,1)'],
                    grid: [{
                        left: 0,
                        right: 0,
                        top: 10,
                        bottom: 0,
                        containLabel: true,
                    }],
                    legend: {
                        right: 0,
                        orient: 'vertical',
                        selectedMode: false,
                        textStyle: { color: 'white' },
                    },
                    tooltip: this.tooltip,
                    xAxis: [{
                        type: 'category',
                        data: this.thisYearX,
                        axisLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLabel: {
                            color: 'rgba(255, 255, 255, 1)',
                        },
                    }],
                    yAxis: [{
                        type: 'value',
                        axisLabel: {
                            color: 'rgba(255, 255, 255, 1)',
                            formatter: val => `${this.areaConversion(val / 10000).toFixed(2)}${this.$t('万')}${this.$t(this.areaUnit)}`,
                        },
                        splitLine: {
                            lineStyle: {
                                color: 'rgba(255, 255, 255, 0.4)',
                            },
                        },
                    }],
                    series: this.thisYearY.map((data, index) => ({
                        name: this.thisYearZ[index],
                        type: 'line',
                        smooth: true,
                        symbol: 'none',
                        areaStyle: {
                            opacity: 0.8,
                            color: areaColor[index],
                        },
                        emphasis: {
                            focus: 'series',
                        },
                        data,
                    })),
                };
            },
        },

        mounted() {
            this.startLoadData();
        },

        destroyed() {
            this.stopLoadData();
        },

        methods: {
            ...mapActions('statScreen', ['getChinaPeriodSprayAreaPerRegion', 'getForeignPeriodSprayAreaPerRegion', 'getCafSprayAreaPerPeriod']),

            startLoadData() {
                this.stopLoadData();
                throttle = _throttle(this.loadData, 300000, { trailing: false });
                ticker = setInterval(throttle, 1000);
            },

            stopLoadData() {
                clearInterval(ticker);
            },

            loadData() {
                const chinaRequests = ['5'].map(periodType => this.getChinaPeriodSprayAreaPerRegion({ periodType }));
                const foreignRequests = ['1'].map(periodType => this.getForeignPeriodSprayAreaPerRegion({ periodType }));
                const cafSprayPerRequests = ['1', '2', '3'].map(periodType => this.getCafSprayAreaPerPeriod({ periodType }));
                Promise.all([...chinaRequests, ...foreignRequests, ...cafSprayPerRequests]).catch(({ message }) => {
                    if (message) this.$message.error(message);
                });
                // this.getCafSprayAreaPerPeriod({ periodType: 1 }).catch(({ message }) => {
                //     if (message) this.$message.error(message);
                // });
                // this.getCafSprayAreaPerPeriod({ periodType: 2 }).catch(({ message }) => {
                //     if (message) this.$message.error(message);
                // });
                // this.getCafSprayAreaPerPeriod({ periodType: 3 }).catch(({ message }) => {
                //     if (message) this.$message.error(message);
                // });
            },

            viewValue(value = 0) {
                return this.isEn ? (value / 1000000) : (value / 10000);
            },
        },
    };
</script>

<style lang="scss" module>
    .root {
        flex: 1;
        //width: 500px;
        height: 100%;
        padding: 30px 20px 20px;
        margin-left: 20px;
        display: flex;
        flex-direction: column;

        :global {
            .block {
                flex: 1;
                display: flex;
                flex-direction: column;
                margin: 0 !important;

                .block-title {
                    margin-bottom: 10px;
                }

                .echarts {
                    flex: 1;
                }
            }

            .multi-column {
                flex: 1;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                margin-top: 20px;

                .block {
                    //flex: 0 0 32%;
                    flex: 1;
                }
            }

            .empty {
                flex: 1;
            }
        }
    }
</style>
