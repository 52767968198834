/**
 * 设置
 */
import eventBus from '../utils/eventBus';

export default {
    methods: {
        onShowSettings() {
            eventBus.$emit('show-global-setting');
        },
    },
};
