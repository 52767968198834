/**
 * loading
 */

import * as types from './mutation-types';

export default {
    namespaced: true,

    state: {
        isLoading: false,
    },

    mutations: {
        [types.UPDATE_LOADING_STATUS](state, yes) {
            state.isLoading = yes;
        },
    },

    actions: {
        // 更新loading
        uploadLoading({ commit }, { isLoading }) {
            commit(types.UPDATE_LOADING_STATUS, isLoading);
        },
    },
};
