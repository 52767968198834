<template>
    <div :class="$style.root">
        <div class="left-line"></div>
        <h1 :data-text="text"><slot></slot></h1>
        <div class="right-line"></div>
    </div>
</template>

<script>
    export default {
        name: 'Headline',

        computed: {
            text() {
                const { default: [{ text } = {}] = [] } = this.$slots || {};
                return text || '-';
            },
        },
    };
</script>

<style lang="scss" module>
    .root {
        display: flex;
        justify-content: center;
        align-items: center;

        :global {
            h1 {
                //font-size: 48px;
                font-size: 40px;
                font-weight: bold;
                //color: rgba(#002B98, 68%);
                color: rgba(#89B5F8, 68%);
                margin: 0 30px;
                position: relative;
                -webkit-text-stroke: 1px #1B3FC6;
                //
                max-width: 630px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;

                &:hover {
                    //width: fit-content;
                    //overflow: unset;
                    //text-overflow: unset;
                    //animation-name: marquee;
                    //animation-duration: 4s;
                    //animation-iteration-count: infinite;
                    //animation-fill-mode: forwards;

                    //&::after {
                    //    //width: fit-content;
                    //    overflow: unset;
                    //    text-overflow: unset;
                    //    animation-name: marquee3;
                    //    animation-duration: 4s;
                    //    animation-iteration-count: infinite;
                    //    animation-fill-mode: forwards;
                    //}
                }

                //&::after:hover {
                //    //width: fit-content;
                //    //overflow: unset;
                //    //text-overflow: unset;
                //    //animation-name: marquee3;
                //    //animation-duration: 4s;
                //    //animation-iteration-count: infinite;
                //    //animation-fill-mode: forwards;
                //}
            }

            //h1::after {
            //    content: attr(data-text);
            //    position: absolute;
            //    left: 0;
            //    color: rgba(#89B5F8, 68%);
            //    mask-image: linear-gradient(0deg, transparent 10%, black 50%);
            //    pointer-events: none;
            //
            //    max-width: 580px;
            //    overflow: hidden;
            //    white-space: nowrap;
            //    text-overflow: ellipsis;
            //}

            .left-line {
                width: 200px;
                height: 2px;
                background: linear-gradient(-90deg, #015EEA 0%, rgba(0, 192, 250, 0) 100%);
            }

            .right-line {
                width: 300px;
                height: 2px;
                background: linear-gradient(90deg, #015EEA 0%, rgba(0, 192, 250, 0) 100%);
            }
        }
    }
</style>
