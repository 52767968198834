/**
 * droneLogStore
 */

import Vue from 'vue';
import * as types from './mutation-types';
import * as urls from '../config/urls';
import * as helpers from '../utils/helpers';

export default {
    namespaced: true,

    state: {
        // droneLog
        droneId: '',
        droneLogList: {},
        droneLogLoading: false,
        droneLogPagination: 1,
        droneLogPageSize: 20,
        droneLogType: '1',
        droneLogFilters: {
        },
        droneLogDetail: {},
    },

    getters: {},

    mutations: {
        [types.UPDATE_LIST](state, { type, list }) {
            state[`${type}List`] = list;
        },
        [types.UPDATE_DETAIL](state, { type, detail }) {
            state[`${type}Detail`] = detail;
        },
        [types.UPDATE_PAGE_SIZE](state, { type, size }) {
            state[`${type}PageSize`] = size;
        },
        [types.UPDATE_CURRENT_PAGINATION](state, { type, pagination }) {
            state[`${type}Pagination`] = pagination;
        },
        [types.UPDATE_CURRENT_ID](state, { type, id }) {
            state[`${type}Id`] = id;
        },
        [types.UPDATE_FILTERS](state, { type, filters }) {
            state[`${type}Filters`] = filters;
        },
        [types.UPDATE_TYPE](state, { type, theType }) {
            state[`${type}Type`] = theType;
        },
        [types.UPDATE_LOADING_STATUS](state, { type, isLoading }) {
            state[`${type}Loading`] = isLoading;
        },
    },

    actions: {
        // droneLog：列表
        getDroneLogList({ commit, state }, pagination = 1) {
            const type = 'droneLog';

            return new Promise((resolve, reject) => {
                commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                const url = helpers.buildURL(urls.GET_DRONE_LOG_LIST, state.droneId);
                Vue.http.get(url, {
                    params: {
                        pageIndex: pagination,
                        size: state[`${type}PageSize`],
                        ...state[`${type}Filters`],
                    },
                }).then((response) => {
                    commit(types.UPDATE_LIST, { type, list: response.data });
                    commit(types.UPDATE_CURRENT_PAGINATION, { type, pagination });
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    resolve(response);
                }).catch((error) => {
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    reject(error);
                });
            });
        },

        //  droneLog：变更列表分页Size
        changeDroneLogListPageSize({ commit, dispatch, state }, size) {
            const type = 'droneLog';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            commit(types.UPDATE_PAGE_SIZE, { type, size });
            dispatch(`get${Type}List`, state[`${type}Pagination`]);
        },

        //  droneLog：更新列表过滤条件
        changeDroneLogListFilters({ commit, dispatch, state }, filters) {
            const type = 'droneLog';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            const f = helpers.update(state[`${type}Filters`], filters);
            commit(types.UPDATE_FILTERS, { type, filters: f });
            dispatch(`get${Type}List`, state[`${type}Pagination`]);
        },

        // team: 改变当然id
        changeDroneId({ commit }, { droneId }) {
            const type = 'drone';

            commit(types.UPDATE_CURRENT_ID, { type, id: droneId });
        },

        // end
    },
};
