<template>
    <div id="app">
        <Map v-if="mapEnabled" />
        <GlobalSetting></GlobalSetting>
        <component :is="currentContainer"></component>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex';
    import Map from './components/Layout/Map.vue';
    import LayoutContainer from './components/Layout';
    import Login from './views/BasePages/Login';
    import Register from './views/BasePages/Register.vue';
    import RetrievePassword from './views/BasePages/RetrievePassword.vue';
    // import StatScreen from './views/StatScreen/StatScreen.vue';
    import StatScreen from './views/StatScreen/ScreenIndex.vue';
    import StatOverview from './views/StatSmallScreen/StatSmallScreen.vue';
    import GlobalSetting from './components/GlobalSetting.vue';
    import eventBus from './utils/eventBus';

    export default {
        name: 'App',

        components: { Map, LayoutContainer, Login, Register, RetrievePassword, StatScreen, StatOverview, GlobalSetting },

        computed: {
            ...mapState('lang', ['lang', 'langMapping']),

            currentContainer() {
                const { name } = this.$route;
                if (!name) return '';
                if (['Login', 'Register', 'RetrievePassword', 'StatScreen', 'StatOverview'].indexOf(name) >= 0) {
                    return name;
                }
                return 'LayoutContainer';
            },

            // 未登录的界面，不启用地图
            mapEnabled() {
                const { name } = this.$route;
                if (!name) return false;
                return !['Login', 'Register', 'RetrievePassword'].includes(name);
            },
        },

        mounted() {
            this.initLang();
            eventBus.$on('switch-element-ui-lang', this.switchUILang);
        },

        destroyed() {
            eventBus.$off('switch-element-ui-lang', this.switchUILang);
        },

        methods: {
            ...mapActions('lang', ['switchWebTitle']),

            // 初始化语言
            initLang() {
                if (this.lang in this.langMapping) {
                    this.$i18n.locale = this.lang;
                    this.switchUILang(this.lang);
                    this.switchWebTitle(this.lang);
                }
            },

            // 切换UI框架语言
            switchUILang(val) {
                const { [val]: lang } = this.$lang;
                this.locale.use(lang);
            },
        },
    };
</script>

<style lang="scss">
</style>
