/**
 * Bulletin
 */

import Vue from 'vue';
import * as types from './mutation-types';
import * as urls from '../config/urls';
import * as helpers from '../utils/helpers';

export default {
    namespaced: true,

    state: {
        bulletinType: '1',
        targetAccountType: {
            1: 'account.manufacturer',
            2: 'account.owner',
        },
        // bulletin
        bulletinList: {},
        bulletinLoading: false,
        bulletinPagination: 1,
        bulletinPageSize: 20,
        bulletinFilters: {
            order: 1,
        },
        bulletinDetail: {},
        // oddBulletin
        oddBulletinList: {},
        oddBulletinLoading: false,
        oddBulletinPagination: 1,
        oddBulletinPageSize: 20,
        oddBulletinFilters: {
            droneId: '',
            startTime: '',
            endTime: '',
            oddType: '',
            zzAccountName: '',
            yyAccountName: '',
            order: 1,
        },
        oddBulletinDetail: {},
        oddTypeMap: {
            1: 'intrude_nfz',
            2: 'blowing_anomaly',
            3: 'wrong_crops',
            4: 'abnormal_weather',
            5: 'wrong_acres',
        },
    },

    getters: {
        stat(state) {
            let list = {};
            if (state.bulletinType === '1') {
                list = state.oddBulletinList;
            } else if (state.bulletinType === '2') {
                list = state.bulletinList;
            }
            const { stat: { oddBulletinNum = 0, bulletinNum = 0 } = {} } = list;
            return { oddBulletinNum, bulletinNum };
        },

        oddTypeLabel: state => (oddType) => {
            const { [oddType]: label } = state.oddTypeMap;
            return label || '';
        },
    },

    mutations: {
        [types.UPDATE_LIST](state, { type, list }) {
            state[`${type}List`] = list;
        },
        [types.UPDATE_DETAIL](state, { type, detail }) {
            state[`${type}Detail`] = detail;
        },
        [types.UPDATE_PAGE_SIZE](state, { type, size }) {
            state[`${type}PageSize`] = size;
        },
        [types.UPDATE_CURRENT_PAGINATION](state, { type, pagination }) {
            state[`${type}Pagination`] = pagination;
        },
        [types.UPDATE_FILTERS](state, { type, filters }) {
            state[`${type}Filters`] = filters;
        },
        [types.UPDATE_TYPE](state, { type, theType }) {
            state[`${type}Type`] = theType;
        },
        [types.UPDATE_LOADING_STATUS](state, { type, isLoading }) {
            state[`${type}Loading`] = isLoading;
        },
    },

    actions: {
        // bulletin列表
        getBulletinList({ commit, state }, pagination = 1) {
            const type = 'bulletin';

            return new Promise((resolve, reject) => {
                commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                Vue.http.get(urls.GET_BULLETIN_LIST, {
                    params: {
                        currentPage: pagination,
                        pageSize: state[`${type}PageSize`],
                        ...state[`${type}Filters`],
                    },
                }).then((response) => {
                    commit(types.UPDATE_LIST, { type, list: response.data });
                    commit(types.UPDATE_CURRENT_PAGINATION, { type, pagination });
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    resolve(response);
                }).catch((error) => {
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    reject(error);
                });
            });
        },

        //  bulletin：变更列表分页Size
        changeBulletinListPageSize({ commit, dispatch, state }, size) {
            const type = 'bulletin';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            commit(types.UPDATE_PAGE_SIZE, { type, size });
            dispatch(`get${Type}List`, state[`${type}Pagination`]);
        },

        //  bulletin：更新列表过滤条件
        changeBulletinListFilters({ commit, dispatch, state }, filters) {
            const type = 'bulletin';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            const f = helpers.update(state[`${type}Filters`], filters);
            commit(types.UPDATE_FILTERS, { type, filters: f });
            dispatch(`get${Type}List`, state[`${type}Pagination`]);
        },

        //  bulletin：更改类型
        changeBulletinType({ commit }, theType) {
            const type = 'bulletin';

            commit(types.UPDATE_TYPE, { type, theType });
        },

        // oddBulletin列表
        getOddBulletinList({ commit, state }, pagination = 1) {
            const type = 'oddBulletin';

            return new Promise((resolve, reject) => {
                commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                Vue.http.get(urls.GET_ODD_BULLETIN_LIST, {
                    params: {
                        currentPage: pagination,
                        pageSize: state[`${type}PageSize`],
                        ...state[`${type}Filters`],
                    },
                }).then((response) => {
                    commit(types.UPDATE_LIST, { type, list: response.data });
                    commit(types.UPDATE_CURRENT_PAGINATION, { type, pagination });
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    resolve(response);
                }).catch((error) => {
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    reject(error);
                });
            });
        },

        //  oddBulletin：变更列表分页Size
        changeOddBulletinListPageSize({ commit, dispatch, state }, size) {
            const type = 'oddBulletin';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            commit(types.UPDATE_PAGE_SIZE, { type, size });
            dispatch(`get${Type}List`, state[`${type}Pagination`]);
        },

        //  oddBulletin：更新列表过滤条件
        changeOddBulletinListFilters({ commit, dispatch, state }, filters) {
            const type = 'oddBulletin';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            const f = helpers.update(state[`${type}Filters`], filters);
            commit(types.UPDATE_FILTERS, { type, filters: f });
            dispatch(`get${Type}List`, state[`${type}Pagination`]);
        },

        // 更新阅读状态
        updateBulletinRead({ state, commit }, bulletinIds) {
            return new Promise((resolve, reject) => {
                commit(types.UPDATE_LOADING_STATUS, { type: 'bulletin', isLoading: true });
                commit(types.UPDATE_LOADING_STATUS, { type: 'oddBulletin', isLoading: true });
                Vue.http.put(urls.UPDATE_BULLETIN_READ, {
                    bulletinIds,
                    oprType: state.bulletinType,
                }).then((response) => {
                    commit(types.UPDATE_LOADING_STATUS, { type: 'bulletin', isLoading: false });
                    commit(types.UPDATE_LOADING_STATUS, { type: 'oddBulletin', isLoading: false });
                    resolve(response);
                }).catch((error) => {
                    commit(types.UPDATE_LOADING_STATUS, { type: 'bulletin', isLoading: false });
                    commit(types.UPDATE_LOADING_STATUS, { type: 'oddBulletin', isLoading: false });
                    reject(error);
                });
            });
        },

        // 创建公告
        createBulletin(store, formData) {
            return new Promise((resolve, reject) => {
                Vue.http.post(urls.CREATE_BULLETIN, formData).then((response) => {
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        // end
    },
};
