/**
 * orderStore
 */

import Vue from 'vue';
import * as types from './mutation-types';
import * as urls from '../config/urls';
import * as helpers from '../utils/helpers';

export default {
    namespaced: true,

    state: {
        // order
        orderList: {},
        orderLoading: false,
        orderPagination: 1,
        orderPageSize: 20,
        orderFilters: {},
        orderDetail: {},
        // invoiceInfo
        invoiceInfoDetail: {},
    },

    getters: {},

    mutations: {
        [types.UPDATE_LIST](state, { type, list }) {
            state[`${type}List`] = list;
        },
        [types.UPDATE_DETAIL](state, { type, detail }) {
            state[`${type}Detail`] = detail;
        },
        [types.UPDATE_PAGE_SIZE](state, { type, size }) {
            state[`${type}PageSize`] = size;
        },
        [types.UPDATE_CURRENT_PAGINATION](state, { type, pagination }) {
            state[`${type}Pagination`] = pagination;
        },
        [types.UPDATE_CURRENT_ID](state, { type, id }) {
            state[`${type}Id`] = id;
        },
        [types.UPDATE_FILTERS](state, { type, filters }) {
            state[`${type}Filters`] = filters;
        },
        [types.UPDATE_LOADING_STATUS](state, { type, isLoading }) {
            state[`${type}Loading`] = isLoading;
        },
    },

    actions: {
        // order：列表
        getOrderList({ commit, state }, pagination = 1) {
            const type = 'order';

            return new Promise((resolve, reject) => {
                commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                Vue.http.get(urls.GET_ORDER_LIST, {
                    params: {
                        pageIndex: pagination,
                        size: state[`${type}PageSize`],
                        ...state[`${type}Filters`],
                    },
                }).then((response) => {
                    commit(types.UPDATE_LIST, { type, list: response.data });
                    commit(types.UPDATE_CURRENT_PAGINATION, { type, pagination });
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    resolve(response);
                }).catch((error) => {
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    reject(error);
                });
            });
        },

        //  order：变更列表分页Size
        changeOrderListPageSize({ commit, dispatch, state }, size) {
            const type = 'order';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            commit(types.UPDATE_PAGE_SIZE, { type, size });
            dispatch(`get${Type}List`, state[`${type}Pagination`]);
        },

        //  order：更新列表过滤条件
        changeOrderListFilters({ commit, dispatch, state }, filters) {
            const type = 'order';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            const f = helpers.update(state[`${type}Filters`], filters);
            commit(types.UPDATE_FILTERS, { type, filters: f });
            dispatch(`get${Type}List`, state[`${type}Pagination`]);
        },

        // order：下单
        createOrder(store, formData) {
            const data = helpers.pick(formData, [
                'orderType',
                'droneId',
                'year',
            ]);

            return new Promise((resolve, reject) => {
                Vue.http.post(urls.CREATE_ORDER, data).then((response) => {
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        // order：详情
        getOrderDetail({ commit }, { orderSn }) {
            const type = 'order';

            return new Promise((resolve, reject) => {
                const url = helpers.buildURL(urls.GET_ORDER_DETAIL, orderSn);
                Vue.http.get(url).then((response) => {
                    commit(types.UPDATE_DETAIL, { type, detail: response.data });
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        // invoiceInfo: 获取信息详情
        getInvoiceInfoDetail({ commit }) {
            const type = 'invoiceInfo';

            return new Promise((resolve, reject) => {
                Vue.http.get(urls.GET_INVOICE_INFO).then((response) => {
                    commit(types.UPDATE_DETAIL, { type, detail: response.data });
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        // invoiceInfo：更新
        updateInvoiceInfo(store, formData) {
            const data = helpers.pick(formData, [
                'ticketType',
                'busiName',
                'taxNum',
                'regAddress',
                'contactPhone',
                'bank',
                'bankAccount',
                'getterPhone',
                'getterName',
                'getterAddress',
            ]);

            return new Promise((resolve, reject) => {
                Vue.http.put(urls.UPDATE_INVOICE_INFO, data).then((response) => {
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        // order：申请发票
        requestApplyInvoice({ dispatch, state }, formData) {
            const type = 'order';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            const data = helpers.pick(formData, [
                'orderIds',
                'ticketType',
                'busiName',
                'taxNum',
                'regAddress',
                'contactPhone',
                'bank',
                'bankAccount',
                'getterPhone',
                'getterName',
                'getterAddress',
            ]);

            return new Promise((resolve, reject) => {
                Vue.http.post(urls.REQUEST_APPLY_INVOICE, data).then((response) => {
                    dispatch(`get${Type}List`, state[`${type}Pagination`]);
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        // end
    },
};
