import Vue from 'vue';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'overlayscrollbars/css/OverlayScrollbars.css';

import App from './App.vue';
import store from './store';
import router from './router';
import HttpPlugin from './plugins/http';
import GlobalFilters from './plugins/filters';
import i18n from './plugins/i18n';

import './plugins/directives';
import './plugins/element';
import './plugins/element-locale';
import './plugins/vuebar';
// import './plugins/amap';
import './plugins/vue-charts';
import './mixins/globalLoadingMixin';
import './styles/custom-bulma.scss';
import './styles/index.scss';

Vue.use(HttpPlugin, { router, store });
Vue.use(GlobalFilters);

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
}).$mount('#app');
