/**
 * DroneOdd
 * 农机违章记录
 */

import Vue from 'vue';
import * as types from './mutation-types';
import * as urls from '../config/urls';
import * as helpers from '../utils/helpers';

const UPDATE_CURRENT_DRONE_ID = 'UPDATE_CURRENT_DRONE_ID';

export default {
    namespaced: true,

    state: {
        // droneOdd
        droneId: 0,
        droneOddList: {},
        droneOddLoading: false,
        droneOddPagination: 1,
        droneOddPageSize: 20,
        droneOddFilters: {
            droneId: '',
            order: 1,
        },
        droneOddDetail: {},
    },

    getters: {},

    mutations: {
        [UPDATE_CURRENT_DRONE_ID](state, droneId) {
            state.droneId = droneId;
        },
        [types.UPDATE_LIST](state, { type, list }) {
            state[`${type}List`] = list;
        },
        [types.UPDATE_DETAIL](state, { type, detail }) {
            state[`${type}Detail`] = detail;
        },
        [types.UPDATE_PAGE_SIZE](state, { type, size }) {
            state[`${type}PageSize`] = size;
        },
        [types.UPDATE_CURRENT_PAGINATION](state, { type, pagination }) {
            state[`${type}Pagination`] = pagination;
        },
        [types.UPDATE_FILTERS](state, { type, filters }) {
            state[`${type}Filters`] = filters;
        },
        [types.UPDATE_TYPE](state, { type, theType }) {
            state[`${type}Type`] = theType;
        },
        [types.UPDATE_LOADING_STATUS](state, { type, isLoading }) {
            state[`${type}Loading`] = isLoading;
        },
    },

    actions: {
        // 更改当前农机ID
        changeCurrentDroneId({ commit }, droneId) {
            commit(UPDATE_CURRENT_DRONE_ID, droneId);
        },

        // droneOdd列表
        getDroneOddList({ commit, state }, pagination = 1) {
            const type = 'droneOdd';

            return new Promise((resolve, reject) => {
                commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                Vue.http.get(urls.GET_ODD_BULLETIN_LIST, {
                    params: {
                        currentPage: pagination,
                        pageSize: state[`${type}PageSize`],
                        ...state[`${type}Filters`],
                    },
                }).then((response) => {
                    commit(types.UPDATE_LIST, { type, list: response.data });
                    commit(types.UPDATE_CURRENT_PAGINATION, { type, pagination });
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    resolve(response);
                }).catch((error) => {
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    reject(error);
                });
            });
        },

        //  droneOdd：变更列表分页Size
        changeDroneOddListPageSize({ commit, dispatch, state }, size) {
            const type = 'droneOdd';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            commit(types.UPDATE_PAGE_SIZE, { type, size });
            dispatch(`get${Type}List`, state[`${type}Pagination`]);
        },

        //  droneOdd：更新列表过滤条件
        changeDroneOddListFilters({ commit, dispatch, state }, filters) {
            const type = 'droneOdd';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            const f = helpers.update(state[`${type}Filters`], filters);
            commit(types.UPDATE_FILTERS, { type, filters: f });
            dispatch(`get${Type}List`, state[`${type}Pagination`]);
        },

        // end
    },
};
