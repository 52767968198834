/**
 * 身份认证信息存取
 */

import jwtDecode from 'jwt-decode';

let token;

export function saveToken(accessToken) {
    localStorage.setItem('token', accessToken);
}

export function getToken(fromStorage = false) {
    if (fromStorage) {
        token = localStorage.getItem('token');
    }

    if (!token) {
        return false;
    }

    return token;
}

export function getPayload() {
    if (!token && !getToken(true)) {
        return {};
    }

    let payload = null;
    try {
        payload = jwtDecode(token);
    } catch (e) {
        //
    }

    return payload || {};
}

export function checkToken() {
    const { exp } = getPayload();

    if (!exp) {
        token = null;
        return false;
    }

    const valid = Date.now() < exp * 1000;
    if (!valid) {
        token = null;
    }

    return valid;
}

export function removeToken() {
    token = null;
    localStorage.removeItem('token');
}

// 取出url中携带的token
export function takeUrlToken({ query, path } = {}) {
    const { refreshToken: urlToken } = query || {};
    if (!urlToken) return '';

    let payload = null;
    try {
        payload = jwtDecode(urlToken);
    } catch (e) {
        //
    }

    const { exp } = payload || {};
    if (!exp) return '';

    const valid = Date.now() < exp * 1000;
    if (!valid) return '';

    setTimeout(() => {
        window.history.replaceState({}, '', path);
    }, 2000);

    return urlToken;
}

export function saveUserInfo(userInfo) {
    localStorage.setItem('userInfo', JSON.stringify(userInfo));
}

export function getUserInfo() {
    const userInfo = localStorage.getItem('userInfo');
    if (!userInfo) {
        return false;
    }
    try {
        const result = JSON.parse(userInfo);
        return typeof result === 'object' ? result : false;
    } catch (e) {
        return false;
    }
}

export function removeUserInfo() {
    localStorage.removeItem('userInfo');
}

export function clearAll() {
    const whiteList = ['bigScreen', 'BigScreen'];
    Object.keys(localStorage).forEach((key) => {
        if (!whiteList.includes(key)) localStorage.removeItem(key);
    });
}
