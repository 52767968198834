<template>
    <Box :class="$style.root">
        <div class="block">
            <TitleBig>{{ $t('作业时段统计') }}</TitleBig>
            <Empty class="empty" v-if="!allTimeY.length">{{ $t('暂无数据') }}</Empty>
            <v-chart :option="chartOption1" :update-options="{ notMerge: true }" theme="light" autoresize v-else />
            <Empty class="empty" v-if="!thisYearMonthY.length">{{ $t('暂无数据') }}</Empty>
            <v-chart :option="chartOption2" :update-options="{ notMerge: true }" theme="light" autoresize v-else />
        </div>
        <div class="block">
            <TitleBig>{{ $t('作业模式统计') }}</TitleBig>
            <Empty class="empty" v-if="!allPeriodSprayAreaPerModeData.length">{{ $t('暂无数据') }}</Empty>
            <v-chart ref="chart3" :option="chartOption3" :update-options="{ notMerge: true }" theme="light" autoresize v-else />
        </div>
    </Box>
</template>

<i18n src="./languages.json"></i18n>

<script>
    import { mapActions, mapGetters, mapState } from 'vuex';
    import _throttle from 'lodash.throttle';
    import * as echarts from 'echarts';
    import Box from '../StatScreen/components/Box.vue';
    import TitleBig from '../StatScreen/components/TitleBig.vue';
    import Empty from '../../components/Empty.vue';

    let throttle = null;
    let ticker = null;

    const areaColor = [
        new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: 'rgba(50,225,169,1)' },
            { offset: 1, color: 'rgba(50,225,169,0.2)' },
        ]),
        new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: 'rgba(50,163,243,1)' },
            { offset: 1, color: 'rgba(50,163,243,0.2)' },
        ]),
    ];

    export default {
        name: 'Panel2',

        components: { Box, TitleBig, Empty },

        computed: {
            ...mapState('statScreen', [
                'allSprayAreaPerHour',
                'thisYearMonthSprayAreaPerHour',
                'allPeriodSprayAreaPerMode',
                'thisMonthSprayAreaPerMode',
                'lastMonthSprayAreaPerMode',
                'thisYearSprayAreaPerMode',
                'lastYearSprayAreaPerMode',
            ]),
            ...mapGetters('setting', ['areaConversion', 'areaUnit']),

            allTimeX() {
                const { xData } = this.allSprayAreaPerHour;
                return xData || [];
            },

            allTimeY() {
                const { yData: [data] = [] } = this.allSprayAreaPerHour;
                return (data || []).map(this.areaConversion).map(val => +val.toFixed(2));
            },

            thisYearMonthX() {
                const { xData } = this.thisYearMonthSprayAreaPerHour;
                return xData || [];
            },

            thisYearMonthY() {
                const { yData } = this.thisYearMonthSprayAreaPerHour;
                return (yData || []).map(data => (data || []).map(this.areaConversion).map(val => +val.toFixed(2)));
            },

            thisYearMonthZ() {
                const { zData } = this.thisYearMonthSprayAreaPerHour;
                return zData || [];
            },

            allPeriodSprayAreaPerModeData() {
                const { xData, yData: [data] = [] } = this.allPeriodSprayAreaPerMode;
                return (xData || []).map((x, index) => ({
                    name: x,
                    value: +this.areaConversion((data || [])[index]).toFixed(2),
                }));
            },

            thisMonthSprayAreaPerModeData() {
                const { xData, yData: [data] = [] } = this.thisMonthSprayAreaPerMode;
                return (xData || []).map((x, index) => ({
                    name: x,
                    value: +this.areaConversion((data || [])[index]).toFixed(2),
                }));
            },

            lastMonthSprayAreaPerModeData() {
                const { xData, yData: [data] = [] } = this.lastMonthSprayAreaPerMode;
                return (xData || []).map((x, index) => ({
                    name: x,
                    value: +this.areaConversion((data || [])[index]).toFixed(2),
                }));
            },

            thisYearSprayAreaPerModeData() {
                const { xData, yData: [data] = [] } = this.thisYearSprayAreaPerMode;
                return (xData || []).map((x, index) => ({
                    name: x,
                    value: +this.areaConversion((data || [])[index]).toFixed(2),
                }));
            },

            lastYearSprayAreaPerModeData() {
                const { xData, yData: [data] = [] } = this.lastYearSprayAreaPerMode;
                return (xData || []).map((x, index) => ({
                    name: x,
                    value: +this.areaConversion((data || [])[index]).toFixed(2),
                }));
            },

            chartOption1() {
                return {
                    color: ['#32E1A9', '#3094E0'],
                    grid: [{
                        left: 0,
                        right: 0,
                        top: 10,
                        bottom: 10,
                        containLabel: true,
                    }],
                    legend: {
                        right: 0,
                        orient: 'vertical',
                        selectedMode: false,
                        textStyle: { color: 'white' },
                    },
                    tooltip: {
                        trigger: 'axis',
                        backgroundColor: 'rgba(0,192,250,0.5)',
                        borderColor: 'rgba(0,192,250,0.8)',
                        textStyle: { color: 'white' },
                        axisPointer: {
                            lineStyle: {
                                color: 'rgba(0,192,250,0.4)',
                            },
                        },
                        formatter: (params) => {
                            const [{ name }] = params;
                            const title = this.$t('每天n点', { n: name });
                            const rows = params.map(({ marker, seriesName, value }) => {
                                const nameEl = `<span style="flex: 1; margin-left: 3px;">${seriesName}：</span>`;
                                const valueEl = `<span>${value}${this.$t(this.areaUnit)}</span>`;
                                return `<div style="display: flex; align-items: center;">${marker}${nameEl}${valueEl}</div>`;
                            }).filter(Boolean).join('');
                            return `<div>${title}</div>${rows}`;
                        },
                    },
                    xAxis: [{
                        type: 'category',
                        data: this.allTimeX,
                        axisLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLabel: {
                            color: 'rgba(255, 255, 255, 1)',
                        },
                    }],
                    yAxis: [{
                        type: 'value',
                        axisLabel: {
                            color: 'rgba(255, 255, 255, 1)',
                            formatter: val => `${val}${this.$t(this.areaUnit)}`,
                        },
                        splitLine: {
                            lineStyle: {
                                color: 'rgba(255, 255, 255, 0.4)',
                            },
                        },
                    }],
                    series: [{
                        name: this.$t('累计'),
                        type: 'line',
                        smooth: true,
                        symbol: 'none',
                        data: this.allTimeY,
                        areaStyle: {
                            opacity: 0.8,
                            color: areaColor[0],
                        },
                        emphasis: {
                            focus: 'series',
                        },
                    }],
                };
            },

            chartOption2() {
                return {
                    color: ['#32E1A9', '#3094E0'],
                    grid: [{
                        left: 0,
                        right: 0,
                        top: 10,
                        bottom: 0,
                        containLabel: true,
                    }],
                    legend: {
                        right: 0,
                        orient: 'vertical',
                        selectedMode: false,
                        textStyle: { color: 'white' },
                    },
                    tooltip: {
                        trigger: 'axis',
                        backgroundColor: 'rgba(0,192,250,0.5)',
                        borderColor: 'rgba(0,192,250,0.8)',
                        textStyle: { color: 'white' },
                        axisPointer: {
                            lineStyle: {
                                color: 'rgba(0,192,250,0.4)',
                            },
                        },
                        formatter: (params) => {
                            const [{ name }] = params;
                            const title = this.$t('每天n点', { n: name });
                            const rows = params.map(({ marker, seriesName, value }) => {
                                const nameEl = `<span style="flex: 1; margin-left: 3px;">${seriesName}：</span>`;
                                const valueEl = `<span>${value}${this.$t(this.areaUnit)}</span>`;
                                return `<div style="display: flex; align-items: center;">${marker}${nameEl}${valueEl}</div>`;
                            }).filter(Boolean).join('');
                            return `<div>${title}</div>${rows}`;
                        },
                    },
                    xAxis: [{
                        type: 'category',
                        data: this.thisYearMonthX,
                        axisLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLabel: {
                            color: 'rgba(255, 255, 255, 1)',
                        },
                    }],
                    yAxis: [{
                        type: 'value',
                        axisLabel: {
                            color: 'rgba(255, 255, 255, 1)',
                            formatter: val => `${val}${this.$t(this.areaUnit)}`,
                        },
                        splitLine: {
                            lineStyle: {
                                color: 'rgba(255, 255, 255, 0.4)',
                            },
                        },
                    }],
                    series: this.thisYearMonthY.map((data, index) => ({
                        name: this.thisYearMonthZ[index],
                        type: 'line',
                        smooth: true,
                        symbol: 'none',
                        areaStyle: {
                            opacity: 0.8,
                            color: areaColor[index],
                        },
                        emphasis: {
                            focus: 'series',
                        },
                        data,
                    })),
                };
            },

            chartOption3() {
                const title = {
                    padding: 0,
                    textAlign: 'center',
                    textVerticalAlign: 'middle',
                    textStyle: {
                        color: 'white',
                        fontSize: 14,
                        fontWeight: 'normal',
                    },
                };
                const label = {
                    color: 'white',
                    alignTo: 'edge',
                    formatter: `{label|{b}}\n{value|{c}${this.$t(this.areaUnit)}}`,
                    edgeDistance: 0, // label离边缘的距离
                    lineHeight: 18,
                    overflow: 'none',
                    rich: {
                        label: { fontSize: 10 },
                        value: { fontSize: 10, color: 'rgba(255,255,255,0.5)' },
                    },
                };
                const labelLine = {
                    length: 6,
                    length2: 0,
                    maxSurfaceAngle: 80,
                };
                const genLabelLayout = (pieLeftPercent, pieWidthPercent) => (params) => {
                    const chartWidth = this.$refs.chart3.getWidth(); // 图表总宽度
                    const pieLeft = chartWidth * pieLeftPercent; // 饼图left偏移
                    const pieWidth = chartWidth * pieWidthPercent; // 饼图宽度
                    const isLeft = params.labelRect.x < pieLeft + (pieWidth / 2); // label是否在饼图左侧
                    const points = params.labelLinePoints;
                    if (points) points[2][0] = isLeft ? params.labelRect.x : params.labelRect.x + params.labelRect.width;

                    return { labelLinePoints: points };
                };

                return {
                    color: ['#96A9E1', '#F1814F'],
                    title: [
                        // left = pie_left + pie_width / 2
                        // top = pie_centerY
                        { ...title, text: this.$t('所有'), left: '16%', top: '50%' },
                        { ...title, text: this.$t('本月'), left: '50%', top: '27%' },
                        { ...title, text: this.$t('上月'), left: '50%', top: '73%' },
                        { ...title, text: this.$t('本年'), left: '84%', top: '27%' },
                        { ...title, text: this.$t('去年'), left: '84%', top: '73%' },
                    ],
                    series: [{
                        type: 'pie',
                        top: '25%',
                        left: 0,
                        width: '32%',
                        height: '50%',
                        radius: ['40%', '74%'],
                        label,
                        labelLine,
                        labelLayout: genLabelLayout(0, 0.32),
                        data: this.allPeriodSprayAreaPerModeData,
                    }, {
                        type: 'pie',
                        top: '2%',
                        left: '34%',
                        width: '32%',
                        height: '50%',
                        radius: ['40%', '74%'],
                        label,
                        labelLine,
                        labelLayout: genLabelLayout(0.34, 0.32),
                        data: this.thisMonthSprayAreaPerModeData,
                    }, {
                        type: 'pie',
                        top: '48%',
                        left: '34%',
                        width: '32%',
                        height: '50%',
                        radius: ['40%', '74%'],
                        label,
                        labelLine,
                        labelLayout: genLabelLayout(0.34, 0.32),
                        data: this.lastMonthSprayAreaPerModeData,
                    }, {
                        type: 'pie',
                        top: '2%',
                        left: '68%',
                        width: '32%',
                        height: '50%',
                        radius: ['40%', '74%'],
                        label,
                        labelLine,
                        labelLayout: genLabelLayout(0.68, 0.32),
                        data: this.thisYearSprayAreaPerModeData,
                    }, {
                        type: 'pie',
                        top: '48%',
                        left: '68%',
                        width: '32%',
                        height: '50%',
                        radius: ['40%', '74%'],
                        label,
                        labelLine,
                        labelLayout: genLabelLayout(0.68, 0.32),
                        data: this.lastYearSprayAreaPerModeData,
                    }],
                };
            },
        },

        mounted() {
            this.startLoadData();
        },

        destroyed() {
            this.stopLoadData();
        },

        methods: {
            ...mapActions('statScreen', ['getAllSprayAreaPerHour', 'getThisYearMonthSprayAreaPerHour', 'getPeriodSprayAreaPerMode']),

            startLoadData() {
                this.stopLoadData();
                throttle = _throttle(this.loadData, 300000, { trailing: false });
                ticker = setInterval(throttle, 1000);
            },

            stopLoadData() {
                clearInterval(ticker);
            },

            loadData() {
                this.getAllSprayAreaPerHour().catch(({ message }) => {
                    if (message) this.$message.error(message);
                });

                this.getThisYearMonthSprayAreaPerHour().catch(({ message }) => {
                    if (message) this.$message.error(message);
                });

                const requests = [1, 2, 3, 4, 5].map(periodType => this.getPeriodSprayAreaPerMode({ periodType }));
                Promise.all(requests).catch(({ message }) => {
                    if (message) this.$message.error(message);
                });
            },
        },
    };
</script>

<style lang="scss" module>
    .root {
        flex: 1;
        min-width: 510px;
        height: 100%;
        padding: 30px 20px 20px;
        margin-left: 20px;
        display: flex;
        flex-direction: column;

        :global {
            .block {
                flex: 1;
                display: flex;
                flex-direction: column;
                margin: 0 !important;

                &:first-of-type {
                    flex: 0 0 56%;
                }

                .empty,
                .echarts {
                    flex: 1;
                }
            }

            .block + .block {
                margin-top: 20px !important;
            }
        }
    }
</style>
