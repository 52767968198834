/**
 * Monitor
 */

import Vue from 'vue';
import * as types from './mutation-types';
import * as urls from '../config/urls';
import * as helpers from '../utils/helpers';
import mapConfig from '../config/map';

const mapSettings = {
    showHdMap: false,
    showNoFlyZone: true,
    showDrone: true,
    showOfflineDrone: false,
    showBlock: false, // 默认关闭（目的是保证政府默认不会自动显示）
    showAlert: true,
};

function getMapSettings() {
    const str = window.localStorage.getItem('mapSettings');
    return helpers.update(mapSettings, JSON.parse(str || '{}'));
}

// 监控请求参数
const monitorQueries = {
    maxLng: undefined,
    minLng: undefined,
    maxLat: undefined,
    minLat: undefined,
    region: undefined,
    zzAccountId: undefined,
    yyAccountId: undefined,
    deviceType: undefined,
    flyModelId: undefined,
};

function getMonitorQueries() {
    const settingsStr = window.localStorage.getItem('monitorQueries') || '{}';
    return helpers.update(monitorQueries, JSON.parse(settingsStr));
}

export default {
    namespaced: true,

    state: {
        // monitor
        monitorDetail: {},
        monitorQueries: getMonitorQueries(),
        droneDetail: {},
        // 实时监控页面的地图配置项
        mapOptions: {
            currentZoom: mapConfig.zoom,
            currentCenter: mapConfig.center,
            currentRange: {
                maxLat: 67.332486,
                maxLng: 135.227994,
                minLat: 18.186979,
                minLng: 49.880052,
            },
        },
        // 地图显示设置
        mapSettings: getMapSettings(),
        // 禁飞区
        noFlyZoneList: [],
        noFlyZoneDetail: {},
        // 地块
        blockList: [],
        // 农机
        droneList: [],
        // 离线农机
        offlineDroneList: [],
    },

    getters: {
        statInfo(state) {
            return state.monitorDetail;
        },
    },

    mutations: {
        [types.UPDATE_LIST](state, { type, list }) {
            state[`${type}List`] = list;
        },
        [types.UPDATE_DETAIL](state, { type, detail }) {
            state[`${type}Detail`] = detail;
        },
        [types.UPDATE_PAGE_SIZE](state, { type, size }) {
            state[`${type}PageSize`] = size;
        },
        [types.UPDATE_CURRENT_PAGINATION](state, { type, pagination }) {
            state[`${type}Pagination`] = pagination;
        },
        [types.UPDATE_FILTERS](state, { type, filters }) {
            state[`${type}Filters`] = filters;
        },
        [types.UPDATE_TYPE](state, { type, theType }) {
            state[`${type}Type`] = theType;
        },
        [types.UPDATE_MAP_OPTIONS](state, options) {
            state.mapOptions = helpers.update(state.mapOptions, options);
        },
        [types.UPDATE_MAP_SETTINGS](state, options) {
            state.mapSettings = options;
        },
        [types.UPDATE_MONITOR_QUERIES](state, queries) {
            state.monitorQueries = queries;
        },
        [types.UPDATE_LOADING_STATUS](state, { type, isLoading }) {
            state[`${type}Loading`] = isLoading;
        },
    },

    actions: {
        // monitor
        getMonitorDetail({ commit, state }) {
            const type = 'monitor';

            return new Promise((resolve, reject) => {
                Vue.http.get(urls.GET_NOW_MONITOR, {
                    params: state.monitorQueries,
                }).then((response) => {
                    const detail = response.data;
                    if (detail) {
                        commit(types.UPDATE_DETAIL, { type, detail });
                    }
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        // 保存监控参数 到localStorage中
        saveMonitorQueries({ state, commit }, queries = {}) {
            const newQueries = helpers.update(state.monitorQueries, queries);
            commit(types.UPDATE_MONITOR_QUERIES, newQueries);
            window.localStorage.setItem('monitorQueries', JSON.stringify(newQueries));
        },

        // 清除监控参数
        clearMonitorQueries() {
            window.localStorage.removeItem('monitorQueries');
        },

        // noFlyZone
        getNoFlyZoneList({ commit }) {
            const type = 'noFlyZone';
            return new Promise((resolve, reject) => {
                Vue.http.get(urls.GET_MONITOR_NO_FLY_ZONE_LIST).then((response) => {
                    commit(types.UPDATE_LIST, { type, list: response.data || [] });
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        // noFlyZone
        getNoFlyZoneDetail({ commit }, id) {
            const type = 'noFlyZone';
            return new Promise((resolve, reject) => {
                Vue.http.get(urls.GET_MONITOR_NO_FLY_ZONE_DETAIL, {
                    params: {
                        noflyZoneId: id,
                    },
                }).then((response) => {
                    commit(types.UPDATE_DETAIL, { type, detail: response.data || {} });
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        // block
        getBlockList({ commit, state }) {
            const type = 'block';
            return new Promise((resolve, reject) => {
                const { currentRange } = state.mapOptions;
                const params = helpers.update(state.monitorQueries, { ...currentRange });
                Vue.http.get(urls.GET_MONITOR_BLOCK_LIST, {
                    params,
                }).then((response) => {
                    commit(types.UPDATE_LIST, { type, list: response.data || [] });
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        // drone
        // getDroneList({ commit, state }, { mapRange = {} } = {}) {
        getDroneList({ commit }, { mapRange = { maxLat: 0, maxLng: 0, minLat: 0, minLng: 0 } } = {}) {
            const type = 'drone';
            return new Promise((resolve, reject) => {
                // const { currentRange } = state.mapOptions;
                // const params = helpers.update(state.monitorQueries, { ...currentRange, ...mapRange });
                const params = {
                    ...mapRange,
                };
                params.queryType = '1'; // 始终为1，加载非聚合数据
                console.log(params);
                Vue.http.get(urls.GET_ONLINE_DRONE_LIST, {
                    params,
                }).then((response) => {
                    commit(types.UPDATE_LIST, { type, list: response.data || [] });
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        // offlineDrone
        // getOfflineDroneList({ commit, state }) {
        getOfflineDroneList({ commit }) {
            const type = 'offlineDrone';

            return new Promise((resolve, reject) => {
                // const { currentRange } = state.mapOptions;
                // const params = helpers.update(state.monitorQueries, { ...currentRange });
                const params = {
                    maxLat: 0,
                    maxLng: 0,
                    minLat: 0,
                    minLng: 0,
                };
                params.queryType = '1'; // 始终为1，加载非聚合数据
                Vue.http.get(urls.GET_OFFLINE_DRONE_LIST, {
                    params,
                }).then((response) => {
                    commit(types.UPDATE_LIST, { type, list: response.data || [] });
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        // offlineDrone
        clearOfflineDroneList({ commit }) {
            const type = 'offlineDrone';
            commit(types.UPDATE_LIST, { type, list: [] });
        },

        // drone
        getDroneDetail({ commit }, id) {
            const type = 'drone';

            return new Promise((resolve, reject) => {
                Vue.http.get(urls.GET_MONITOR_DRONE_DETAIL, {
                    params: {
                        droneId: id,
                    },
                }).then((response) => {
                    commit(types.UPDATE_DETAIL, { type, detail: response.data || {} });
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        // drone
        clearDroneDetail({ commit }) {
            const type = 'drone';

            commit(types.UPDATE_DETAIL, { type, detail: {} });
        },

        // 更新地图配置项
        updateMapOptions({ commit }, options) {
            // const newOptions = options;
            // const { currentRange } = newOptions;
            // // 坐标转换
            // if (currentRange) {
            //     const { maxLng, minLng, maxLat, minLat } = currentRange;
            //     const max = helpers.coordToGPS([maxLng, maxLat]);
            //     const min = helpers.coordToGPS([minLng, minLat]);
            //     newOptions.currentRange = {
            //         maxLng: max[0],
            //         minLng: min[0],
            //         maxLat: max[1],
            //         minLat: min[1],
            //     };
            // }
            commit(types.UPDATE_MAP_OPTIONS, options);
        },

        // 获取地图设置 从localStorage中
        loadMapSettings({ state }) {
            const str = window.localStorage.getItem('mapSettings');
            return helpers.update(state.mapSettings, JSON.parse(str || '{}'));
        },

        // 保存地图设置 到localStorage中
        saveMapSettings({ state, commit }, options = {}) {
            const newOptions = helpers.update(state.mapSettings, options);
            commit(types.UPDATE_MAP_SETTINGS, newOptions);
            window.localStorage.setItem('mapSettings', JSON.stringify(newOptions));
        },

        // end
    },
};
