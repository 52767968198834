<template>
    <div :class="$style.root">
        <div class="scroll-wrapper">
            <div class="screen-main">
                <Heading>{{ $t('农机作业大数据管理平台') }}</Heading>
                <div class="screen-body">
                    <SmallScreenPanel1 />
                    <SmallScreenPanel4 />
                </div>
            </div>
            <SmallScreenPanel5 class="screen-side"></SmallScreenPanel5>
        </div>
    </div>
</template>

<i18n src="./languages.json"></i18n>

<script>
    import OverlayScrollbars from 'overlayscrollbars';
    import Heading from './components/Heading.vue';
    import SmallScreenPanel1 from './SmallScreenPanel1.vue';
    import SmallScreenPanel4 from './SmallScreenPanel4.vue';
    import SmallScreenPanel5 from './SmallScreenPanel5.vue';

    export default {
        name: 'SmallScreen',

        components: { Heading, SmallScreenPanel4, SmallScreenPanel1, SmallScreenPanel5 },

        data() {
            return {
                scrollInstance: null,
            };
        },

        mounted() {
            this.initScroll();
            window.addEventListener('mousewheel', this.onScroll);
        },

        destroyed() {
            window.removeEventListener('mousewheel', this.onScroll);
        },

        methods: {
            initScroll() {
                this.scrollInstance = OverlayScrollbars(this.$el, {
                    sizeAutoCapable: false,
                    className: 'os-theme-light',
                    overflowBehavior: {
                        x: 'scroll',
                        y: 'hidden',
                    },
                    scrollbars: {
                        autoHide: 'leave',
                        autoHideDelay: 300,
                    },
                });
            },

            onScroll({ deltaY }) {
                if (!this.scrollInstance) return;
                const offset = deltaY >= 0 ? `+= ${deltaY}px` : `-= ${Math.abs(deltaY)}px`;
                this.scrollInstance.scroll({ x: offset });
            },
        },
    };
</script>

<style lang="scss" module>
    .root {
        position: relative;
        height: 100%;

        //@media screen and (max-width: 1279px) {
        //    zoom: 70%;
        //}

        &:global {
            &::before {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                z-index: -1;
                background: linear-gradient(0deg, #030525 0%, #05072E 100%);
            }

            &::after {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                z-index: -1;
                background-image: linear-gradient(90deg, transparent 39px, rgba(255, 255, 255, 0.1) 39px), linear-gradient(transparent 39px, rgba(255, 255, 255, 0.1) 39px);
                background-size: 40px 40px;
            }
        }

        :global {
            .scroll-wrapper {
                height: 100%;
                padding: 30px 50px 30px 0;
                display: flex;
                //min-width: 3840px;
                //min-width: 2440px;
                width: 100%;
            }

            .os-scrollbar {
                .os-scrollbar-handle {
                    background-color: rgba(0, 156, 255, 0.3) !important;
                }

                &:hover .os-scrollbar-handle {
                    background-color: rgba(0, 156, 255, 0.6) !important;
                }
            }

            .screen-main {
                flex: 1;
                display: flex;
                flex-direction: column;

                .screen-body {
                    flex: 1;
                    padding: 30px 30px 0 50px;
                    display: flex;
                }
            }

            .screen-side {
                //width: 580px;
                //width: 480px;
                width: 420px;
            }
        }
    }
</style>
