<template>
    <div class="ui-root" v-loading="isLoading">
        <div class="columns is-mobile is-centered is-gapless ui-header">
            <div class="column is-8">
                <div class="columns is-mobile is-gapless is-vcentered">
                    <div class="column">
                        <img :src="`/images/${logoFilename}`" alt="logo" class="ui-logo">
                        <span>{{ !isCn ? $t('站点标题') : title }}<em>{{ $t('找回密码') }}</em></span>
                    </div>
                    <div class="column is-narrow ui-login">
                        <span><router-link to="/login" replace>{{ $t('去登录') }}<i class="el-icon-d-arrow-right"></i></router-link></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="columns is-mobile is-centered ui-steps">
            <div class="column is-8">
                <el-steps :active="step" finish-status="success" simple>
                    <!--                    <el-step :title="$t('获取验证码')"></el-step>-->
                    <el-step :title="$t('重置密码')"></el-step>
                    <el-step :title="$t('完成')"></el-step>
                </el-steps>
            </div>
        </div>
        <div class="columns is-mobile is-gapless is-centered ui-form">
            <div class="column is-narrow">
                <el-tabs v-model="stepStatus">
                    <el-tab-pane name="step1" v-if="false">
                        <el-form ref="stepOneForm" :model="stepOneForm" :rules="stepOneRules" label-width="120px">
                            <el-form-item prop="phoneNum" :label="$t('用户名')">
                                <el-input v-model="stepOneForm.phoneNum" :placeholder="$t('请输入手机号码')">
                                    <el-select v-model="mobilePhoneCountryCode" slot="prepend" style="min-width: 90px;">
                                        <el-option
                                            v-for="item in countryOptions"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                            <div style="display: flex;align-items: center">
                                                <img
                                                    style="width: 20px; height: 20px; object-fit: fill; margin-right: 8px"
                                                    :src="item.flag" />
                                                <span style="color: #8492a6; font-size: 13px;">{{ item.value }}</span>
                                            </div>
                                        </el-option>
                                    </el-select>
                                </el-input>
                            </el-form-item>
                            <el-form-item prop="verifyCode" :label="$t('验证码')">
                                <el-input v-model="stepOneForm.verifyCode" autocomplete="off" :placeholder="$t('请输入验证码')">
                                    <CountdownButton
                                        slot="append"
                                        :label="$t('获取验证码')"
                                        :started-tpl="`%n%${$t('等待重新获取')}`"
                                        @click="handleRequestVerifyCode('stepOneForm', $event)"></CountdownButton>
                                </el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" class="ui-full-button" @click="handleStepTwo('stepOneForm')">{{ $t('下一步') }}</el-button>
                            </el-form-item>
                        </el-form>
                    </el-tab-pane>
                    <el-tab-pane label="" name="step2">
                        <el-form ref="stepOneForm" :model="stepOneForm" :rules="stepOneRules" label-width="120px">
                            <el-form-item prop="phoneNum" :label="$t('用户名')">
                                <el-input v-model="stepOneForm.phoneNum" :placeholder="$t('username_tips')">
                                    <el-select v-if="isPhoneAccount" v-model="mobilePhoneCountryCode" slot="prepend" style="min-width: 90px;">
                                        <el-option
                                            v-for="item in countryOptions"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                            <div style="display: flex;align-items: center">
                                                <img
                                                    style="width: 20px; height: 20px; object-fit: fill; margin-right: 8px"
                                                    :src="item.flag" />
                                                <span style="color: #8492a6; font-size: 13px;">{{ item.value }}</span>
                                            </div>
                                        </el-option>
                                    </el-select>

                                    <el-tooltip slot="append" :content="isPhoneAccount ? $t('login_email') : $t('login_phone')" placement="top">
                                        <el-button icon="el-icon-refresh" @click="onSwitchAccountInput"></el-button>
                                    </el-tooltip>
                                </el-input>
                            </el-form-item>
                            <el-form-item prop="verifyCode" :label="$t('验证码')">
                                <el-input v-model="stepOneForm.verifyCode" autocomplete="off" :placeholder="$t('请输入验证码')">
                                    <CountdownButton
                                        slot="append"
                                        :label="$t('获取验证码')"
                                        :started-tpl="`%n%${$t('等待重新获取')}`"
                                        @click="handleRequestVerifyCode('stepOneForm', $event)"></CountdownButton>
                                </el-input>
                            </el-form-item>
                            <el-form-item v-if="false">
                                <el-button type="primary" class="ui-full-button" @click="handleStepTwo('stepOneForm')">{{ $t('下一步') }}</el-button>
                            </el-form-item>
                        </el-form>
                        <el-form ref="stepTwoForm" :model="stepTwoForm" :rules="stepTwoRules" label-width="120px">
                            <el-form-item prop="password" :label="$t('输入新密码')">
                                <el-input type="password" v-model="stepTwoForm.password" :placeholder="$t('请输入新密码')"></el-input>
                            </el-form-item>
                            <el-form-item prop="passwordConfirmation" :label="$t('重新输入新密码')">
                                <el-input type="password" v-model="stepTwoForm.passwordConfirmation" autocomplete="off" :placeholder="$t('请重新输入新密码')"></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" class="ui-full-button" @click="handleStepThree('stepTwoForm')">{{ $t('下一步') }}</el-button>
                            </el-form-item>
                        </el-form>
                    </el-tab-pane>
                    <el-tab-pane name="step3">
                        <div class="ui-finish has-text-centered">
                            <i class="el-icon-circle-check-outline"></i>
                            <p>{{ $t('密码重置成功') }}</p>
                            <el-button type="primary" @click="handleFinish">{{ $t('form.confirm') }}</el-button>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
    </div>
</template>

<i18n src="./languages.json"></i18n>

<script>
    import { mapState, mapActions, mapGetters } from 'vuex';
    import scrollbarMixin from '../../mixins/scrollbarMixin';
    import CountdownButton from '../../components/CountdownButton.vue';
    import { validateAccountNumber, validatePassword, validatePasswordCheck } from '../../utils/validators';
    import countryCode from '../../config/countryCode';

    export default {
        name: 'RetrievePassword',

        components: { CountdownButton },

        mixins: [scrollbarMixin],

        data() {
            return {
                mobilePhoneCountryCode: '+86',
                isPhoneAccount: true,
                step: 1,
                stepOneForm: {
                    phoneNum: '',
                    verifyCode: '',
                    checkOpr: '3',
                },
                stepOneRules: {
                    phoneNum: [
                        // { required: true, message: this.$t('请输入手机号码'), trigger: 'blur' },
                        // { pattern: /^\d+$/g, message: this.$t('请输入正确的手机号码'), trigger: 'blur' },
                        { required: true, message: this.$t('username_rule'), trigger: 'blur' },
                        { validator: validateAccountNumber, messages: [this.$t('phone_tips'), this.$t('email_tips')], trigger: 'blur' },
                    ],
                    verifyCode: [
                        { required: true, message: this.$t('请输入验证码'), trigger: 'blur' },
                        // { pattern: /^\d{4}$/g, message: '请输入4位验证码', trigger: 'blur' },
                    ],
                },
                stepTwoForm: {
                    password: '',
                    passwordConfirmation: '',
                },
                stepTwoRules: {
                    password: [
                        { required: true, message: this.$t('请输入密码'), trigger: 'blur' },
                        { validator: validatePassword(this, 'stepTwoForm'), trigger: 'blur' },
                    ],
                    passwordConfirmation: [
                        { required: true, message: this.$t('请再次输入密码'), trigger: 'blur' },
                        { validator: validatePasswordCheck(this, 'stepTwoForm'), trigger: 'blur' },
                    ],
                },
            };
        },

        computed: {
            ...mapState('theme', ['title', 'logoFilename']),
            ...mapGetters('user', ['isIndia']),
            ...mapGetters('lang', ['isCn']),

            countryOptions() {
                return this.isIndia ? countryCode(['india']) : countryCode();
            },

            stepStatus: {
                get() {
                    return `step${this.step + 1}`;
                },
                set() {
                },
            },

            // 加上国家码的手机号
            fullPhone() {
                // 如是手机号 加上国家码
                return this.isPhoneAccount ? `${this.mobilePhoneCountryCode}-${this.stepOneForm.phoneNum}` : this.stepOneForm.phoneNum;
            },
        },

        mounted() {
            if (this.isIndia) this.mobilePhoneCountryCode = '+91';
        },

        methods: {
            ...mapActions('user', ['requestSmsVerifyCode', 'requestCheckSmsVerifyCode', 'requestResetPassword']),

            // 去第二步
            handleStepTwo(formName) {
                this.$refs[formName].validate((valid) => {
                    if (!valid) {
                        return;
                    }
                    // 校验验证码
                    this.startLoading();
                    this.requestCheckSmsVerifyCode({ ...this[formName], phoneNum: this.fullPhone }).then(() => {
                        this.stopLoading();
                        this.step += 1;
                    }).catch(({ message }) => {
                        this.stopLoading();
                        if (message) {
                            this.$message.error(message);
                        }
                    });
                });
            },

            // 去第三步
            handleStepThree(formName) {
                // this.handleStepTwo('stepOneForm').then(() => {
                this.$refs.stepOneForm.validate((valid) => {
                    if (!valid) {
                        return;
                    }

                    this.$refs[formName].validate((valid2) => {
                        if (!valid2) {
                            return;
                        }
                        const formData = {
                            // phoneNum: this.stepOneForm.phoneNum,
                            phoneNum: this.fullPhone,
                            password: this.stepTwoForm.password,
                            verifyCode: this.stepOneForm.verifyCode,
                        };
                        // 重置密码
                        this.startLoading();
                        this.requestCheckSmsVerifyCode({ ...this.stepOneForm, phoneNum: this.fullPhone }).then(() => {
                            // this.stopLoading();
                            // this.step += 1;
                            this.requestResetPassword(formData).then(() => {
                                this.stopLoading();
                                this.step += 1;
                            }).catch(({ message }) => {
                                this.stopLoading();
                                if (message) {
                                    this.$message.error(message);
                                }
                            });
                        }).catch(({ message }) => {
                            this.stopLoading();
                            if (message) {
                                this.$message.error(message);
                            }
                        });
                    });
                });
                // })
            },

            // 完成重置
            handleFinish() {
                this.$router.replace('/login');
            },

            // 请求验证码
            handleRequestVerifyCode(formName, { countdownStart, countdownReset }) {
                this.$refs[formName].validateField('phoneNum', (validateMessage, invalidFields) => {
                    if (invalidFields) {
                        return;
                    }
                    // 开始倒计时
                    countdownStart();
                    this.startLoading();
                    this.requestSmsVerifyCode({
                        // phoneNum: this[formName].phoneNum,
                        phoneNum: this.fullPhone,
                        codeType: '3',
                    }).then(() => {
                        this.stopLoading();
                        this.$message.success(this.$t('验证码已发送'));
                    }).catch(({ message }) => {
                        // 恢复倒计时按钮原始状态
                        countdownReset();
                        this.stopLoading();
                        if (message) {
                            this.$message.error(message);
                        }
                    });
                });
            },

            // 切换账号输入框
            onSwitchAccountInput() {
                this.isPhoneAccount = !this.isPhoneAccount;
                // 副作用
                this.stepOneForm.phoneNum = '';
            },
        },
    };
</script>

<style lang="scss" scoped>
    .ui-header {
        height: 80px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.05);
        & > .column {
            padding: 0 20px !important;
        }
        .columns {
            height: 100%;
        }
        .ui-logo {
            height: 60px;
        }
        .ui-logo + span {
            color: $brandColor;
            font-size: 24px;
            margin-left: 10px;
            vertical-align: middle;
            em {
                font-style: normal;
                font-size: 16px;
                color: $grey-dark;
                margin-left: 10px;
            }
        }
        .ui-login span {
            color: $grey;
        }
    }

    .ui-steps {
        padding: 0 24px;
        .el-steps {
            margin: 50px 0;
        }
    }

    .el-tabs /deep/ .el-tabs__header {
        display: none;
    }

    .ui-form {
        padding: 0 24px;
        background-color: #f7f7f7;
        overflow: hidden;
        .el-form {
            margin: 80px 0;
        }
        .el-input {
            width: 320px;
        }
    }

    .ui-form .el-form + .el-form {
        margin-top: -58px;
    }

    .ui-finish {
        margin: 80px 0;
        color: $red;
        i {
            font-size: 50px;
            margin-bottom: 20px;
        }
        p {
            font-size: 20px;
            margin-bottom: 30px;
        }
    }

    .ui-full-button {
        width: 100%;
    }
</style>
