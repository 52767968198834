<template>
    <div :class="$style.root" :style="{ height: `${h}px` }">
        <!-- eslint-disable -->
        <svg xmlns="http://www.w3.org/2000/svg" :viewBox="`0 0 ${w} ${h}`">
            <defs>
                <linearGradient id="heading-lg-1" x1="50%" y1="0" x2="50%" y2="100%" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stop-color="#00c0fa" />
                    <stop offset="1" stop-color="#015eea" />
                </linearGradient>
                <radialGradient id="heading-lg-2" cx="1.04" cy="0.35" r="31.21" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stop-color="#fff" stop-opacity="0.7" />
                    <stop offset="1" stop-color="#fff" stop-opacity="0.1" />
                </radialGradient>
                <radialGradient id="heading-lg-3" cx="443.12" cy="104.74" r="35.03" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stop-color="#fff" stop-opacity="0.8" />
                    <stop offset="1" stop-color="#fff" stop-opacity="0.2" />
                </radialGradient>
            </defs>
            <polygon fill="#009cff" fill-opacity="0.1" fill-rule="evenodd" :points="`30.47 12.01 59.53 43.01 400.53 43.01 452.53 94.01 ${w - 10} 94.01 ${w - 10} 12.01 30.47 12.01`" />
            <polygon fill="url(#heading-lg-1)" :points="`${w} 2 4.59 2 51.73 52.73 390.7 52.73 418.34 79.47 443.7 104 ${w} 104 ${w} ${h} 442.89 ${h} 416.94 80.9 389.89 54.73 50.86 54.73 0 0 ${w} 0 ${w} 2`" />
            <path fill="#009cff" fill-rule="evenodd" d="M123.47,12l29.06,31h18L141.47,12Zm-31,0,29.06,31h18L110.47,12Zm-31,0L90.53,43h18L79.47,12Zm-31,0L59.53,43h18L48.47,12Z" />
            <path fill="url(#heading-lg-2)" d="M32.91,2H4.83l19,20.41c-.46.48-.94.95-1.43,1.4L.24,0H33C33,.67,33,1.34,32.91,2Z" />
            <path fill="url(#heading-lg-3)" d="M478,104.54c0,.49,0,1,0,1.46H443.13L417.18,80.9c.46-.49.92-1,1.4-1.43L443.94,104H478C478,104.18,478,104.36,478,104.54Z" />
        </svg>
        <!-- eslint-enable -->
        <div class="wrapper">
            <div class="home" @click="onGoHome">
                <i class="iconfont icon-home"></i>
            </div>
            <div class="sep" v-if="subject"></div>
            <h2 v-if="subject">{{ subject }}</h2>
            <Headline class="headline"><slot>Title</slot></Headline>
        </div>
    </div>
</template>

<script>
    import Headline from './Headline.vue';
    import requestTerminator from '../../../utils/requestTerminator';

    export default {
        name: 'Heading',

        components: { Headline },

        props: {
            subject: {
                type: String,
                default: '',
            },
        },

        data() {
            return {
                w: 1920,
                h: 106,
            };
        },

        computed: {},

        mounted() {
            this.init();
            window.addEventListener('resize', this.init);
        },

        methods: {
            init() {
                const { width } = this.$el.getBoundingClientRect();
                this.w = width;
            },

            onGoHome() {
                requestTerminator.abortAll();
                this.$router.push({ name: 'MonitorView' });
            },
        },
    };
</script>

<style lang="scss" module>
    .root {
        position: relative;

        :global {
            svg {
                transform: rotateY(180deg);
                pointer-events: none;
            }

            .wrapper {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                display: flex;
                align-items: center;
                padding-left: 50px;
                padding-right: 100px;

                .home {
                    width: 70px;
                    height: 70px;
                    line-height: 70px;
                    background: rgba(0, 156, 255, 0.2);
                    border-radius: 50%;
                    text-align: center;
                    cursor: pointer;

                    i {
                        font-size: 50px;
                        vertical-align: top;
                        color: #FFFFFF;
                    }
                }

                .sep {
                    width: 6px;
                    height: 70px;
                    background: #32A3F3;
                    border-radius: 2px;
                    margin: 0 40px;
                }

                h2 {
                    font-size: 36px;
                    font-weight: bold;
                    color: rgba(142, 208, 255, 0.3);
                    line-height: 1;
                }

                .headline {
                    flex: 1;
                }
            }
        }
    }
</style>
