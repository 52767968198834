<template>
    <el-container>
        <el-header height="80px">
            <Header></Header>
        </el-header>
        <el-container>
            <el-aside width="auto" class="aside-has-bar" v-bar>
                <SideMenu></SideMenu>
            </el-aside>
            <el-container v-bar>
                <div class="vuebar-content">
                    <el-main>
                        <router-view></router-view>
                    </el-main>
                </div>
            </el-container>
        </el-container>
    </el-container>
</template>

<script>
    import { mapGetters, mapState } from 'vuex';
    import _throttle from 'lodash.throttle';
    import Header from './HeaderPrimary.vue';
    import SideMenu from './SideMenu.vue';

    let idleThrottle;

    export default {
        name: 'LayoutContainer',

        components: { Header, SideMenu },

        data() {
            return {
                idledAt: Date.now(),
            };
        },

        computed: {
            ...mapGetters('user', ['isPlatform']),
            ...mapState('setting', ['isBigScreen']),
        },

        mounted() {
            if (this.isPlatform && this.isBigScreen) {
                this.initIdleStatus();
                this.autoSwitchBigScreen();
            }
        },

        destroyed() {
            if (idleThrottle) window.removeEventListener('mousemove', idleThrottle);
        },

        methods: {
            initIdleStatus() {
                idleThrottle = _throttle(() => {
                    this.idledAt = Date.now();
                }, 1000, { trailing: false });
                window.addEventListener('mousemove', idleThrottle);
            },

            autoSwitchBigScreen() {
                const timer = setInterval(() => {
                    // 如果闲置300秒，则跳转到大屏
                    if (Date.now() - this.idledAt > 1000 * 300) {
                        clearInterval(timer);
                        const { name } = this.$route;
                        if (name !== 'StatScreen') {
                            this.$router.push({
                                name: 'StatScreen',
                            });
                        }
                    }
                }, 1000);
            },
        },
    };
</script>
