/**
 * 实时监控管理
 */

const MonitorView = () => import(/* webpackChunkName: "chunk!monitor" */'../views/MonitorManage/Monitor.vue');

const group = 'monitor_manage';

export default [
    {
        path: `/${group}/monitor`,
        name: 'MonitorView',
        component: MonitorView,
        meta: { group },
    },
];
