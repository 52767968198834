/**
 * 请求终结者
 */

class RequestTerminator {
    // 请求id累加器
    #idCounter = 0;

    // 可终止的请求控制器池
    #controllerPool = {};

    get requestId() {
        return this.#idCounter;
    }

    // 创建一个终止控制器
    addController() {
        this.#idCounter += 1;
        const abortController = new AbortController();
        this.#controllerPool[this.#idCounter] = abortController;
        return abortController;
    }

    // 移除一个现存的控制器
    removeController(requestId) {
        if (requestId in this.#controllerPool) {
            delete this.#controllerPool[requestId];
        }
    }

    // 终止一个现存的控制器
    abort(requestId) {
        if (requestId in this.#controllerPool) {
            this.#controllerPool[requestId].abort();
            delete this.#controllerPool[requestId];
        }
    }

    // 终止所有现存的控制器
    abortAll() {
        Object.keys(this.#controllerPool).forEach(requestId => this.abort(requestId));
    }
}

export default new RequestTerminator();
