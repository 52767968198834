/*
 * 公共引用
 * 异步获取引用对象
 */

class CommonRefs {
    _refs = {};

    _pendingList = {};

    setRef(key, ref) {
        if (key in this._refs) return;

        this._refs[key] = ref;
        const { [key]: resolve } = this._pendingList;
        if (resolve) {
            resolve(ref);
            delete this._pendingList[key];
        }
    }

    getRef(key) {
        return new Promise((resolve) => {
            const { [key]: ref } = this._refs;
            if (ref) {
                resolve(ref);
            } else {
                this._pendingList[key] = resolve;
            }
        });
    }

    removeRef(key) {
        if (key in this._refs) {
            delete this._refs[key];
        }
    }
}

export default new CommonRefs();
