/**
 * DataStat
 */

import Vue from 'vue';
import * as types from './mutation-types';
import * as urls from '../config/urls';
import * as helpers from '../utils/helpers';

export default {
    namespaced: true,

    state: {
        // droneStatistics
        droneStatisticsList: {},
        droneStatisticsLoading: false,
        droneStatisticsPagination: 1,
        droneStatisticsPageSize: 20,
        droneStatisticsFilters: {
            startTime: '',
            endTime: '',
            deviceType: '',
        },
    },

    getters: {},

    mutations: {
        [types.UPDATE_LIST](state, { type, list }) {
            state[`${type}List`] = list;
        },
        [types.UPDATE_DETAIL](state, { type, detail }) {
            state[`${type}Detail`] = detail;
        },
        [types.UPDATE_PAGE_SIZE](state, { type, size }) {
            state[`${type}PageSize`] = size;
        },
        [types.UPDATE_CURRENT_PAGINATION](state, { type, pagination }) {
            state[`${type}Pagination`] = pagination;
        },
        [types.UPDATE_FILTERS](state, { type, filters }) {
            state[`${type}Filters`] = filters;
        },
        [types.UPDATE_LOADING_STATUS](state, { type, isLoading }) {
            state[`${type}Loading`] = isLoading;
        },
    },

    actions: {
        // droneStatistics: 获取农机统计
        getDroneStatisticsList({ commit, state }, pagination = 1) {
            const type = 'droneStatistics';
            return new Promise((resolve, reject) => {
                commit(types.UPDATE_LOADING_STATUS, { type, isLoading: true });
                Vue.http.get(urls.GET_DRONE_STATISTICS_LIST, {
                    params: {
                        currentPage: pagination,
                        pageSize: state[`${type}PageSize`],
                        ...state[`${type}Filters`],
                    },
                }).then((response) => {
                    commit(types.UPDATE_LIST, { type, list: response.data });
                    commit(types.UPDATE_CURRENT_PAGINATION, { type, pagination });
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    resolve(response);
                }).catch((error) => {
                    commit(types.UPDATE_LOADING_STATUS, { type, isLoading: false });
                    reject(error);
                });
            });
        },

        //  droneStatistics：变更列表分页Size
        changeDroneStatisticsListPageSize({ commit, dispatch, state }, size) {
            const type = 'droneStatistics';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            commit(types.UPDATE_PAGE_SIZE, { type, size });
            dispatch(`get${Type}List`, state[`${type}Pagination`]);
        },

        //  droneStatistics：更新列表过滤条件
        changeDroneStatisticsListFilters({ commit, dispatch, state }, filters) {
            const type = 'droneStatistics';
            const Type = type.replace(/^\w/, _ => _.toUpperCase());

            const f = helpers.update(state[`${type}Filters`], filters);
            commit(types.UPDATE_FILTERS, { type, filters: f });
            dispatch(`get${Type}List`, state[`${type}Pagination`]);
        },

        // droneStatistics: 获取农机统计所有数据
        getDroneStatisticsExportList({ state }, total) {
            const type = 'droneStatistics';
            return new Promise((resolve, reject) => {
                Vue.http.get(urls.GET_DRONE_STATISTICS_LIST, {
                    params: {
                        currentPage: 1,
                        pageSize: total,
                        ...state[`${type}Filters`],
                    },
                }).then((response) => {
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
            });
        },

        // end
    },
};
